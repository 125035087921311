import { TextField } from "@mui/material"
import { useState, useContext, useEffect } from "react"
import { Context } from '../Context';
import Loading from './Loading'

function MainQuestions(props) {

    const { userState, updateData, loading } = useContext(Context);
    const { projects, currProjectId } = userState;

    // load user's answers into form
    const [answers, setAnswers] = useState('')
    const [isDisabled, setDisabled] = useState(false)

    const pageNum = props.page
    const areFilledsDisabled = props.isDisabled
    // console.log('areFilledsDisabled', areFilledsDisabled)

    const styles = {
        inputLabelProps: {
            shrink: true,
            style: {
                color: '#f26a34',
                fontSize: 15
            }
        }, inputProps: {
            style: { fontSize: 15, }
        }
    }

    const eightQsTitlesStyle = {
        fontSize: 15,
        fontWeight: 'bold',
        color: '#219F8E',
        textAlign: 'left',
        marginLeft: 10,
        marginBottom: 5,
    }

    useEffect(() => {
        if (!currProjectId || !projects || projects.length === 0) return
        const currProj = projects.find(project => {
            return project.id === currProjectId
        })
        setAnswers(currProj)
        if (areFilledsDisabled) {
            setDisabled(true)
        }

    }, [currProjectId, projects, areFilledsDisabled])

    // update state of form as user types
    function handleChange(event) {
        // enable saving button
        setDisabled(true)
        // update state of form
        setAnswers(prevQuestions => {
            const { name, value } = event.target
            return {
                ...prevQuestions,
                step1: {
                    ...prevQuestions.step1,
                    [name]: value
                }
            }
        })
    }

    // onSubmit - save form values to global state
    function saveAnswers(event) {
        event.preventDefault()
        const updatedProjects = projects.map((oldProject) => {
            return oldProject.id === currProjectId ? { ...oldProject, step1: answers.step1 } : oldProject;
        });
        updateData('projects', (updatedProjects))
    }

    if (loading) {
        return (
            <div>
                <Loading />
            </div>
        )
    } else {
        return (
            <div >
                <form onSubmit={saveAnswers} >
                    {pageNum === 'Questions2' &&
                        <div className='main-question-container'>
                            <div className="question-area">
                                <h4>1. Write your question</h4>
                                <p>What is the main question/objective for your upcoming interview?</p>
                            </div>
                            <div className="input-area">
                                <TextField
                                    className="TextField-main"
                                    label="MAIN QUESTION"
                                    name="MainQ"
                                    value={answers ? answers.step1.MainQ : ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    multiline
                                    rows={1}
                                    placeholder='Example: "does your company have a CRM software?"'
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                ></TextField>
                            </div>
                        </div>
                    }
                    <div >
                        <div >
                            {pageNum === 'step1' &&
                                <h4>2. Fill in the 8 WH questions</h4>}
                            {pageNum === 'step1' &&
                                <p>Now for each of these "WH" question, try filling in the info according to your main objective</p>}
                            <div className="step-2-headers">
                                {pageNum === 'step2' &&
                                    <h5>2. Main questions</h5>}
                            </div>
                        </div>
                        <div style={{display: 'flex'}} >
                            <div>
                                <p className="eightQsTitles" style={eightQsTitlesStyle}>What?</p>
                                <TextField
                                    className="TextField"
                                    name="Q1"
                                    value={answers ? answers.step1.Q1 : ''}
                                    onChange={handleChange}
                                    label="Subject"
                                    multiline
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    disabled={isDisabled}
                                    placeholder="Example: finding out if the company has CRM and are they happy with it"
                                    helperText="Your main goal"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                />
                            </div>
                            <div>
                                <p className="eightQsTitles" style={eightQsTitlesStyle}>When?</p>
                                <TextField
                                    className="TextField"
                                    name="Q2"
                                    value={answers ? answers.step1.Q2 : ''}
                                    onChange={handleChange}
                                    label="Timing"
                                    multiline
                                    disabled={isDisabled}
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder="Example: this year"
                                    helperText="The relevant timing"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                />
                            </div>
                            <div>
                                <p className="eightQsTitles" style={eightQsTitlesStyle}>When?</p>
                                <TextField
                                    className="TextField"
                                    name="Q3"
                                    value={answers ? answers.step1.Q3 : ''}
                                    onChange={handleChange}
                                    label="Sequence"
                                    multiline
                                    disabled={isDisabled}
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder="Example: this month, because it's the end of the quarter"
                                    helperText="Think about relevent 'before...' or 'after... '"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                />
                            </div>
                            <div>
                                <p className="eightQsTitles" style={eightQsTitlesStyle}>Who?</p>
                                <TextField
                                    className="TextField"
                                    name="Q4"
                                    value={answers ? answers.step1.Q4 : ''}
                                    onChange={handleChange}
                                    label="Person"
                                    multiline
                                    disabled={isDisabled}
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder="Example: head of IT"
                                    helperText="The relevant person"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                />
                            </div>
                            <div>
                                <p className="eightQsTitles" style={eightQsTitlesStyle}>Who?</p>
                                <TextField
                                    className="TextField"
                                    name="Q5"
                                    value={answers ? answers.step1.Q5 : ''}
                                    onChange={handleChange}
                                    label="Entity"
                                    multiline
                                    disabled={isDisabled}
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder="Example: IT"
                                    helperText="The entity involved"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                />
                            </div>
                            <div>
                                <p className="eightQsTitles" style={eightQsTitlesStyle}>Where?</p>
                                <TextField
                                    className="TextField"
                                    name="Q6"
                                    value={answers ? answers.step1.Q6 : ''}
                                    onChange={handleChange}
                                    label="Geography"
                                    multiline
                                    disabled={isDisabled}
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder='Example: London'
                                    helperText="The relevant location"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                />
                            </div>
                            <div>
                                <p className="eightQsTitles" style={eightQsTitlesStyle}>Where?</p>
                                <TextField
                                    className="TextField"
                                    name="Q7"
                                    value={answers ? answers.step1.Q7 : ''}
                                    onChange={handleChange}
                                    label="Domain"
                                    multiline
                                    disabled={isDisabled}
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder='Example: all company branches'
                                    helperText="Related domain (e.g HR, IT)"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                />
                            </div>
                            <div>
                                <p className="eightQsTitles" style={eightQsTitlesStyle}>Why?</p>
                                <TextField
                                    className="TextField"
                                    name="Q8"
                                    label="Reason"
                                    value={answers ? answers.step1.Q8 : ''}
                                    onChange={handleChange}
                                    multiline
                                    disabled={isDisabled}
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder="Example: to gain new customers"
                                    helperText="Why you care about this objective"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                />
                            </div>
                        </div>
                    </div>
                    {pageNum === 'Questions2' && <div className='improved-question-container'>
                        <div className="question-area">
                            <h4>3. Improve your original question</h4>
                            <p>Read your original question and see what parts from the answers above you can add to it to.</p>
                        </div>
                        <div className="input-area">
                            <TextField
                                label="IMPROVED MAIN QUESTION"
                                name="ImprovedQ"
                                value={answers ? answers.step1.ImprovedQ : ''}
                                onChange={handleChange}
                                fullWidth
                                multiline
                                rows={2}
                                variant="outlined"
                                placeholder={answers.step1.MainQ}
                                helperText='Example: "Does your company have a CRM software? Are you happy with the performance? Is your department using it in all of your branches?"'
                                inputProps={styles.inputProps}
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>
                        </div>
                    </div>}
                    {pageNum === 'Questions2' && <div className="buttomNavigation">
                        <h5>Make sure to save your answers before moving forward</h5>
                        <button disabled={isDisabled} className='saveAnswersBtn'>Save Answers</button>
                    </div>}
                </form >
            </div >

        );
    }


}

export default MainQuestions
