import { useNavigate, useLocation } from "react-router-dom"
import { useContext, useState } from 'react';
import { Context } from '../Context';
import { db } from '../firebase';
import { getDoc, doc, setDoc } from "firebase/firestore";
import { confirmPasswordReset } from 'firebase/auth';
import { auth } from '../firebase';
import Loading from "../components/Loading";
import { Snackbar, Alert } from '@mui/material';

const Profile = () => {
    const { userState, loading } = useContext(Context);
    const { uid, tenantId } = userState;
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const actionCode = searchParams.get('oobCode');
    const [showSnackbar, setShowSnackbar] = useState(false);

    // Regex pattern for validating password:
    const validatePassword = (password) => {
        const regex = /^(?=.*[A-Za-z])(?=.*\d).{12,}$/;
        if (!regex.test(password)) {
            alert('Password must have at least 12 characters and contain at least one letter and one number');
            return false;
        }
        return true;
    };

    const handleResetPassword = async (e) => {
        e.preventDefault();
        if (!validatePassword(newPassword)) return;

        if (newPassword === confirmPassword) {
            try {

                await confirmPasswordReset(auth, actionCode, newPassword);
                console.log('Password has been reset successfully');

                if (uid && tenantId) {
                    // Update Firestore that password has been reset
                    const isUpdated = await updateHasChangedPassword();
                    if (!isUpdated) {
                        alert('An error occurred while updating the password, please try again or contact your administrator');
                        console.log('error updating password in firestore', isUpdated);
                        return;
                    }
                }
                // Only if update is successful, show snackbar and proceed with logout
                setShowSnackbar(true);

                // wait for 2 seconds before logging out user
                setTimeout(() => {
                    // logout user
                    auth.signOut();
                    // navigate to login page
                    navigate('/login');
                }, 2000);

            } catch (error) {
                alert('Password reset link is invalid or has expired, please go back to homepage and click on reset password to get a new link');
                console.log(error);
            }
        }
        else {
            alert('Passwords do not match');
        }
        // Reset form
        setNewPassword('');
        setConfirmPassword('');
    };

    // update firestore that password has been reset with hasChangedPassword = true
    const updateHasChangedPassword = async () => {
        try {
            const userRef = doc(db, 'tenants', tenantId, 'users', uid);
            const userSnap = await getDoc(userRef);

            if (!userSnap.exists()) {
                console.error('User document does not exist');
                return false;
            }

            await setDoc(userRef, { hasChangedPassword: true }, { merge: true });
            console.log('Password status has been updated successfully in firestore');
            return true;

        } catch (error) {
            console.log(error);
            alert('An error occurred while updating the password, please try again');
            return false;
        }
    }

    if (loading) return <Loading />;

    return (
        <div className='app-home-container'
            style={{ height: '100vh', justifyContent: 'center' }}>

            <Snackbar
                open={showSnackbar}
                autoHideDuration={5000}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                onClose={() => setShowSnackbar(false)}
            >
                <Alert size="small" onClick={() => setShowSnackbar(false)}>
                    Password has been reset successfully! You are being redirected to the login page
                </Alert>
            </Snackbar>

            <div className='profile-form-container'>
                <div className='profile-form-title'>
                    <h4>Reset Password</h4>
                    <p>Password must be at least 12 characters long and contain one number and one letter</p>
                </div>
                <form className="profile-form" onSubmit={handleResetPassword}>
                    <input
                        type="password"
                        value={newPassword || ''}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Enter new password"
                    />
                    <input
                        type="password"
                        value={confirmPassword || ''}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Enter new password again" />

                    <button
                        style={{ margin: '20px 0px' }}
                        type="submit">Save new password
                    </button>
                </form>
            </div>


        </div>
    );
};

export default Profile
