import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';

function RadarChart({ radarChartValues, onCanvasReady }) {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartCtx = chartRef.current.getContext('2d');
        Chart.defaults.font.size = 14;
        chartCtx.canvas.width = 700;
        chartCtx.canvas.height = 480;
        // console.log(radarChartValues, 'radarChartValues')

        const chart = new Chart(chartCtx, {
            type: 'radar',
            data: {
                labels: ['Networking Group', 'TIKO Role', 'Years of Acquaintance', 'TIKO Status', 'Is TIKO?'],
                datasets: [{
                    // label: 'Your Data',
                    data: radarChartValues,
                    fill: false,
                    backgroundColor: '#219F8E',
                    borderColor: '#219F8E',
                    pointBackgroundColor: '#219F8E',
                    pointBorderColor: '#219F8E',
                    pointHoverBackgroundColor: '#219F8E',
                    pointHoverBorderColor: '#219F8E',
                    borderWidth: 2,
                    pointRadius: 8,
                    pointHoverRadius: 30,
                    pointHoverBorderWidth: 2,
                    pointHitRadius: 30,
                }],
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                    title: {
                        display: false,
                    },
                },
                scales: {
                    r: {
                        pointLabels: {
                            font: {
                                size: 15,
                                weight: 'bold'
                            },
                            padding: 10,
                        },
                        grid: {
                            color: '#c7c7c7c6',
                            circular: false,
                            lineWidth: 2,
                            borderColor: '#c7c7c7c6',
                            drawBorder: true,
                            drawOnChartArea: true,
                            drawTicks: true,
                            tickLength: 10,
                            tickWidth: 2,
                            tickColor: '#c7c7c7c6',
                            tickBorderDash: [],
                            tickBorderDashOffset: 0.0,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            zeroLineWidth: 2,
                            zeroLineColor: '#c7c7c7c6',
                            zeroLineBorderDash: [],
                            zeroLineBorderDashOffset: 0.0,
                            offsetGridLines: true,
                            z: 0,
                        },
                        angleLines: {
                            display: true,
                            color: '#c7c7c7c6',

                        },
                        precision: 0,
                        suggestedMin: 1,
                        suggestedMax: 10,
                    }
                },
                elements: {
                    line: {
                        borderWidth: 1
                    }
                }
            },
        });

        // Notify the parent component when the canvas is ready
        if(onCanvasReady) {
            onCanvasReady(chartRef.current);
        }

        return () => {
            chart.destroy();
        };
    }, [radarChartValues, onCanvasReady]);

    return <canvas className='radar-chart' ref={chartRef} />;
};

export default RadarChart;