import React, { useEffect, useRef, useState } from 'react';
import { Chart } from 'chart.js/auto';

const countKeywords = (contacts) => {
    if (!contacts) {
        return {};
    }
    const keywordCounts = {};
    contacts.forEach(contact => {
        if (!contact.keywords) {
            return;
        }
        const keywords = contact.keywords.split(' '); // Assuming keywords are space-separated
        keywords.forEach(keyword => {
            if (keyword) {
                keywordCounts[keyword] = (keywordCounts[keyword] || 0) + 1;
            }
        });
    });
    // Convert the keywordCounts object to an array of [keyword, count] pairs
    const keywordArray = Object.entries(keywordCounts);

    // Sort the array by count in descending order and slice the top 10
    const topKeywords = keywordArray.sort((a, b) => b[1] - a[1]).slice(0, 10);

    // Convert the array back to an object
    const topKeywordCounts = Object.fromEntries(topKeywords);

    return topKeywordCounts;
};

const prepareChartData = (keywordCounts) => {
    const labels = Object.keys(keywordCounts);
    const data = Object.values(keywordCounts);

    return {
        labels,
        datasets: [
            {
                data,
                backgroundColor: [
                    'rgba(255, 99, 132, 0.2)',
                    'rgba(54, 162, 235, 0.2)',
                    'rgba(255, 206, 86, 0.2)',
                    'rgba(75, 192, 192, 0.2)',
                    'rgba(153, 102, 255, 0.2)',
                    'rgba(255, 159, 64, 0.2)',
                ],
                borderColor: [
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(75, 192, 192, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };
};

const KeywordChart = ({ contacts }) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const [chartData, setChartData] = useState({});

    useEffect(() => {
        const keywordCounts = countKeywords(contacts);
        const data = prepareChartData(keywordCounts);
        setChartData(data);
    }, [contacts]);

    useEffect(() => {
        if (chartInstance.current) {
            chartInstance.current.destroy(); // Destroy the previous chart instance if it exists
        }

        const ctx = chartRef.current.getContext('2d');
        // ctx.canvas.width = 300;

        const chart = new Chart(ctx, {

            type: 'bar',
            data: chartData,
            options: {
                responsive: true,
                maintainAspectRatio: true,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
                scales: {
                    y: {
                        ticks: {
                            beginAtZero: true,
                            precision: 0,
                        },
                        title: {
                            display: true,
                            text: 'Times used',
                            padding: 10,
                            color: '#2a2a2a'
                        }
                    },
                    x: {
                        title: {
                            display: false,
                            text: 'Keywords',
                            color: '#2a2a2a'

                        },
                        ticks: {
                            padding: 15,
                            color: '#2a2a2a',
                            font: {
                                size: 14,
                            }

                        }

                    }
                }
            }


        });

        return () => {
            chart.destroy(); // Cleanup chart instance on component unmount
        };
    }, [chartData]);

    return <canvas className='keywordChart' ref={chartRef} />;
};

export default KeywordChart;
