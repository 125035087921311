import { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from "react-router-dom";
import { Context } from '../../Context';
import Loading from '../../components/Loading';
import { Select, MenuItem, TextField, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import withAuthentication from "../../withAuthentication";
import { Tooltip } from 'react-tooltip'
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, HeadingLevel, AlignmentType } from "docx";


function Contacts3() {
    const { userState, updateData, loading } = useContext(Context);
    const { projects, currProjectId, contacts, contactsLists, currListId, role } = userState;


    const [currContact, setCurrContact] = useState('')
    const [mainQuestion, setMainQuestion] = useState('')
    const [chosenContacts, setChosenContacts] = useState('')
    const [userContacts, setUserContacts] = useState('')
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const newAnswersObj = useMemo(() => ({
        accurateInfo: '',
        conflict: '',
        describe: '',
        knowledge: '',
        networking: '',
        relevantInfo: '',
        reliableInfo: '',
        role: '',
        status: '',
        time: '',
        years: '',
    }),
        []
    );
    const [answers, setAnswers] = useState(newAnswersObj)
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState({});
    const [isDisabled, setDisabled] = useState(true)
    const [isModified, setIsModified] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const navigate = useNavigate();

    // option for netowrking question:
    const questionOneOptions = [
        { value: '20', label: '(E) Empathy' },
        { value: '15', label: '(D) Dunbar' },
        { value: '10', label: '(M) Memory' },
        { value: '5', label: '(P) Plato' },
    ]
    const questionTwoOptions = [
        { value: '20', label: '(A) Integration' },
        { value: '15', label: '(B) Pulling' },
        { value: '10', label: '(C) Observation' },
        { value: '5', label: '(D) Pushed Data' },
    ]
    const questionThreeOptions = [
        { value: '5', label: 'Up to 5 years' },
        { value: '20', label: 'Up to 10 years' },
        { value: '60', label: 'Up to 15 years' },
        { value: '90', label: '15 or more' },
    ]
    const questionFourOptions = [
        { value: '30', label: 'Active' },
        { value: '10', label: 'Pending' },
        { value: '5', label: 'Non-Active' },
    ]
    const questionFiveOptions = [
        { value: '50', label: 'TIKO' },
        { value: '100', label: 'TINO' },
        { value: '0', label: 'Not relevant' },
    ]

    // console.log(isModified, 'isModified')
    // console.log(answers, 'answers')
    // console.log(currContact, 'currContact')
    // console.log(currProjectId, 'currProjectId')

    // Dialog
    const [route, setRoute] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    const handleDialogOpen = (route) => {
        if (isModified) {
            setOpenDialog(true);
            setRoute(route);
        } else {
            navigate(route);
        }
    }

    const handleDialogClose = (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            navigate(route);
        }
    }

    // when page renders, calcualte chosenContacts and render them in select dropdown
    useEffect(() => {
        if (loading || !currListId || !contactsLists) return

        const chosenList = contactsLists.find(list => { return list.listId === currListId })
        if (!chosenList) return

        // filter out empty contacts
        const chosenContacts = chosenList.listData.filter(contact => { return contact.fullName !== '' })
        setChosenContacts(chosenContacts)

        // render chosen contacts in select dropdown
        const userContacts = chosenContacts.map(contact => { return <option key={contact.id} value={contact.fullName}>{contact.fullName}</option> })
        setUserContacts(userContacts)

        // set main question according to currProjectId
        const currProject = projects.find(project => { return project.id === currProjectId })
        const mainQ = currProject.step1.FinalQ ? currProject.step1.FinalQ : currProject.step1.MainQ
        setMainQuestion(mainQ)

    }, [currListId, contactsLists, loading, currProjectId, projects])

    // set answers for current contact
    useEffect(() => {
        if (loading || !currContact || !currProjectId || !projects) {
            setDisabled(true)
            return
        }
        // if chosen contact already has answers in it's analyze array, set answers to those answers
        if (currContact.analyze) {
            const userAnswersForCurrProject = currContact.analyze.find(
                answer => { return answer.questionId === currProjectId })
            if (userAnswersForCurrProject) {
                setAnswers(prevAnswers => ({
                    ...prevAnswers, ...userAnswersForCurrProject.answers
                }))
            } else {
                setAnswers(newAnswersObj)
            }
        }

    }, [currContact, loading, currProjectId, projects, newAnswersObj])

    // set current contact
    function selectContact(e) {
        const { value } = e.target
        // console.log(value, 'value')
        if (value === '') {
            setDisabled(true)
            return
        }
        // reset form:
        setAnswers(newAnswersObj)
        const selectedContact = chosenContacts.find(contact => { return contact.fullName === value })
        setCurrContact(selectedContact)
        setDisabled(false)
        setIsModified(true)
    }

    // update state for current contact
    function handleChange(e) {
        const { name, value } = e.target
        setMessage('')
        setIsModified(true)
        setAnswers(oldAnswers => ({
            ...oldAnswers,
            [name]: value
        }))

        // reset errors according to current input
        if (errors[name]) {
            setErrors(oldErrors => ({
                ...oldErrors,
                [name]: false
            }))
        }
    }

    async function saveAnswers(e) {
        e.preventDefault()
        if (!answers) return

        // let newErrors = {}
        // for (let key in answers) {
        //     if (!answers[key]) {
        //         // console.log(key, 'key')
        //         newErrors[key] = true
        //     }
        // }
        // if (Object.keys(newErrors).length > 0) {
        //     setErrors(newErrors)
        //     setMessage('Please fill in all fields before saving');
        //     setTimeout(() => {
        //         setMessage('')
        //     }, 4000);

        //     return
        // }

        const isQuestionExists = currContact.analyze.find(question => { return question.questionId === currProjectId })
        const newQuestion = { questionId: currProjectId, answers: answers }
        // 1. in analyze array, find question with current questionId, and update it's answers, if it doesn't exist, create it:
        if (isQuestionExists) {
            isQuestionExists.answers = answers
        } else {
            currContact.analyze.push(newQuestion)
        }

        // 2. update currContact in contacts array:
        const updatedContacts = contacts.map(contact => {
            if (contact.id === currContact.contactId) {
                return currContact
            }
            return contact
        })

        const updatedContactLists = contactsLists.map(list => {
            if (list.listId === currListId) {
                const updatedListData = list.listData.map(contact => {
                    if (contact.id === currContact.contactId) {
                        return currContact
                    }
                    return contact
                })
                list.listData = updatedListData
            }
            return list
        })

        setIsSaving(true)
        try {
            await updateData('contacts', updatedContacts)
            await updateData('contactsLists', updatedContactLists)

            setTimeout(() => {
                setIsSaving(false)
                setMessage('Answers saved successfully')
            }, 2000)

            setTimeout(() => {
                setMessage('')
                setIsModified(false)
            }, 2500)
        }
        catch (err) {
            console.log(err)
            setMessage('Error saving data', err);
            setTimeout(() => {
                setMessage('');
            }, 2000);
        }
    }

    // download as PDF
    function downloadPDF() {
        const doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true,
            compress: true,
            precision: 2,
            lineHeight: 1.3,
        })
        // Helper function to wrap text if it's too long
        function addWrappedText(text, x, y, maxWidth) {
            let splitText = doc.splitTextToSize(text, maxWidth);
            doc.text(splitText, x, y);
            return splitText.length; // Return number of lines
        }
        // content and formatting of the PDF document
        const maxWidth = doc.internal.pageSize.getWidth() - 20; // 10 units padding on each side 

        let currentY = 20;

        // pdf title
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.text(`Contact analysis for ${currContact.fullName}`,
            doc.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 10;
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.text(`${new Date().toLocaleDateString("en-GB", dateOptions)} `,
            doc.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 20;

        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text('Networking Profile:', 10, currentY);
        currentY += 10;
        // content
        const networkingProfile = [
            'Which networking group does this TIKO fit?: ' + answers.networking,
            'Which role does this TIKO fit?: ' + answers.role,
            'How many years have you known this TIKO?: ' + answers.years,
            'What is the current status of this TIKO?: ' + answers.status,
            'What will best describe this TIKO?: ' + answers.describe,
        ];

        networkingProfile.forEach(field => {
            let lines = addWrappedText(field, 10, currentY, maxWidth);
            currentY += lines * 7; // 7 units line height for font size 12
        });
        // space between sections:
        currentY += 10;

        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text('Past analysis: the clarity of the TIKO', 10, currentY);
        currentY += 10;

        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        const clarity = [
            'How relevant was the information this TIKO used to provide?: ' + answers.relevantInfo,
            'How reliable was the information?: ' + answers.reliableInfo,
            'How accurate was the information?: ' + answers.accurateInfo,
            'How was the information provided in the needed time?: ' + answers.knowledge,
        ];

        clarity.forEach(field => {
            let lines = addWrappedText(field, 10, currentY, maxWidth);
            currentY += lines * 7; // 7 units line height for font size 12
        });
        currentY += 10;

        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text('Knowledge VS. conflict of interests', 10, currentY);
        currentY += 10;

        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        const knowledge = [
            'Given your question, what is this TIKO level of knowledge and expertise?: ' + answers.knowledge,
            'Given your question, what is this TIKO level of conflict of interest?: ' + answers.conflict,
        ]
        knowledge.forEach(field => {
            let lines = addWrappedText(field, 10, currentY, maxWidth);
            currentY += lines * 7; // 7 units line height for font size 12
        }
        );
        currentY += 10;
        // save pdf
        doc.save(`Contact analysis for ${currContact.fullName}.pdf`);

    }
    function downloadDOC() {
        // create a new document
        const doc = new Document({
            sections: [{
                children: [
                    new Paragraph({
                        text: `Contact analysis for ${currContact.fullName}`,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                        text: new Date().toLocaleDateString("en-GB", dateOptions),
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                        text: `Networking Profile:`,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Which networking group does this TIKO fit?: ${answers.networking}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Which role does this TIKO fit?: ${answers.role}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `How many years have you known this TIKO?: ${answers.years}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `What is the current status of this TIKO?: ${answers.status}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `What will best describe this TIKO?: ${answers.describe}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Past analysis: the clarity of the TIKO`,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `How relevant was the information this TIKO used to provide?: ${answers.relevantInfo}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),

                    new Paragraph({
                        text: `How reliable was the information?: ${answers.reliableInfo}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,

                    }),
                    new Paragraph({
                        text: `How accurate was the information?: ${answers.accurateInfo}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,

                    }),
                    new Paragraph({
                        text: `How was the information provided in the needed time?: ${answers.knowledge}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,

                    }),
                    new Paragraph({
                        text: `Knowledge VS. conflict of interests`,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Given your question, what is this TIKO level of knowledge and expertise?: ${answers.knowledge}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,

                    }),
                    new Paragraph({
                        text: `Given your question, what is this TIKO level of conflict of interest?: ${answers.conflict}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,

                    }),


                ],
            }]

        });

        // Used to export the file into a .docx file
        Packer.toBlob(doc)
            .then((blob) => {
                saveAs(blob, `Contact analysis for ${currContact.fullName}.docx`);
            })
            .catch((error) => {
                console.error("Error generating document: ", error);
            });
    }

    if (loading) return <Loading />

    return (
        <div className="app-home-container">
            <div className='app-title'>
                <h4>3. Analyze your contacts
                    <i
                        data-tooltip-id="analyzeContacts"
                        data-tooltip-place="bottom"
                        data-tooltip-content="When you're done, choose another one until you have analyzed all contacts for from this list. Go back to the previous step, to add more contacts to this list."
                        className="fa fa-question-circle"
                        aria-hidden="true"
                    ></i>
                    <Tooltip className="tooltip" id="analyzeContacts" />
                </h4>
            </div>
            <div className='contacts3-main-container'>
                <div className='contacts3-section'>

                    <p>Reminder: your question</p>
                    <div className="reminder-section questions4-section" >
                        {mainQuestion}
                    </div>

                </div>
                <div style={{ marginBottom: '30px' }}>
                    <select
                        onChange={(e) => selectContact(e)}
                        style={{ width: '250px' }}
                        value={currContact ? currContact.fullName : ''}>
                        <option value="">Select contact to analyze</option>
                        {userContacts}
                    </select>
                </div>

                <div className='contacts3-section'>
                    <h6 style={{ paddingBottom: '0px' }} className='element-title'>
                        1. Networking Profile
                        <i
                            data-tooltip-id="networkingProfile"
                            position="right"
                            data-tooltip-content="?"
                            className="fa fa-question-circle"
                            aria-hidden="true"
                        ></i>
                        <Tooltip className="tooltip" id="networkingProfile" />
                    </h6>
                    <div className='question-group'>
                        <div className='question-container'>
                            <p>Which networking <span style={{ fontWeight: 'bold' }}>group</span> does this TIKO fit?</p>
                            <Select
                                className='contacts3-select'
                                disabled={isDisabled}
                                error={errors.networking}
                                value={answers ? answers.networking : ''}
                                name='networking'
                                displayEmpty={true}
                                renderValue={(selected) => {
                                    const selectedOption = questionOneOptions.find((option) => option.value === selected);
                                    return selectedOption ? selectedOption.label : "Select";
                                }}
                                onChange={handleChange}
                            >
                                {questionOneOptions && questionOneOptions.map((option, index) => {
                                    return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                })}
                            </Select>
                        </div>
                        <div className='question-container'>
                            <p>Which <span style={{ fontWeight: 'bold' }}>role</span> does this TIKO fit?</p>
                            <Select
                                className='contacts3-select'
                                disabled={isDisabled}
                                error={errors.role}
                                value={answers ? answers.role : ''}
                                name='role'
                                displayEmpty={true}
                                renderValue={(selected) => {
                                    const selectedOption = questionTwoOptions.find((option) => option.value === selected);
                                    return selectedOption ? selectedOption.label : "Select";
                                }}
                                onChange={handleChange}
                            >
                                {questionTwoOptions.map((option, index) => {
                                    return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                })}
                            </Select>
                        </div>
                        <div className='question-container'>
                            <p>How many years have you known this TIKO?</p>
                            <Select
                                className='contacts3-select'
                                disabled={isDisabled}
                                error={errors.years}
                                value={answers ? answers.years : ''}
                                name='years'
                                displayEmpty={true}
                                renderValue={(selected) => {
                                    const selectedOption = questionThreeOptions.find((option) => option.value === selected);
                                    return selectedOption ? selectedOption.label : "Select";
                                }}
                                onChange={handleChange}
                            >
                                {questionThreeOptions.map((option, index) => {
                                    return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                })}
                            </Select>
                        </div>
                        <div className='question-container'>
                            <p>What is the current status of this TIKO?</p>
                            <Select
                                className='contacts3-select'
                                disabled={isDisabled}
                                error={errors.status}
                                value={answers ? answers.status : ''}
                                name='status'
                                displayEmpty={true}
                                renderValue={(selected) => {
                                    const selectedOption = questionFourOptions.find((option) => option.value === selected);
                                    return selectedOption ? selectedOption.label : "Select";
                                }}
                                onChange={handleChange}
                            >
                                {questionFourOptions.map((option, index) => {
                                    return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                })}
                            </Select>
                        </div>
                        <div className='question-container'>
                            <p>What will best describe this TIKO?</p>
                            <Select
                                className='contacts3-select'
                                disabled={isDisabled}
                                error={errors.describe}
                                value={answers ? answers.describe : ''}
                                name='describe'
                                displayEmpty={true}
                                renderValue={(selected) => {
                                    const selectedOption = questionFiveOptions.find((option) => option.value === selected);
                                    return selectedOption ? selectedOption.label : "Select";
                                }}
                                onChange={handleChange}
                            >
                                {questionFiveOptions.map((option, index) => {
                                    return <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                                })}

                            </Select>
                        </div>
                    </div>
                    <h6 style={{ paddingBottom: '0px' }} className='element-title' >2. Past Analysis: evaluate your TIKO's Clarity
                        <i
                            data-tooltip-id="clarity"
                            position="right"
                            data-tooltip-content="?"
                            className="fa fa-question-circle"
                            aria-hidden="true"
                        ></i>
                        <Tooltip className="tooltip" id="clarity" />
                        <p style={{ fontSize: '16px', marginTop: '5px', color: 'grey' }}>0 = not relevant, 1 = low, 5 = High</p> </h6>
                    <div className='question-group'>

                        <div className='question-container'>
                            <p>How <span style={{ fontWeight: 'bold' }}>relevant</span> was the information this TIKO used to provide?</p>
                            <Select
                                className='contacts3-select'
                                disabled={isDisabled}
                                error={errors.relevantInfo}
                                value={answers ? answers.relevantInfo : ''}
                                name='relevantInfo'
                                displayEmpty={true}
                                renderValue={(selected) => selected === "" ? "Select" : selected}
                                onChange={(e) => handleChange(e)}>
                                <MenuItem value='0'>0</MenuItem>
                                <MenuItem value='1'>1</MenuItem>
                                <MenuItem value='2'>2</MenuItem>
                                <MenuItem value='3'>3</MenuItem>
                                <MenuItem value='4'>4</MenuItem>
                                <MenuItem value='5'>5</MenuItem>
                            </Select>
                        </div>
                        <div className='question-container'>
                            <p>How <span style={{ fontWeight: 'bold' }}>reliable</span> was the information?</p>
                            <Select
                                className='contacts3-select'
                                disabled={isDisabled}
                                error={errors.reliableInfo}
                                value={answers ? answers.reliableInfo : ''}
                                name='reliableInfo'
                                displayEmpty={true}
                                renderValue={(selected) => selected === "" ? "Select" : selected}
                                onChange={(e) => handleChange(e)}>
                                <MenuItem value='0'>0</MenuItem>
                                <MenuItem value='1'>1</MenuItem>
                                <MenuItem value='2'>2</MenuItem>
                                <MenuItem value='3'>3</MenuItem>
                                <MenuItem value='4'>4</MenuItem>
                                <MenuItem value='5'>5</MenuItem>
                            </Select>
                        </div>
                        <div className='question-container'>
                            <p>How <span style={{ fontWeight: 'bold' }}>accurate</span> was the information?</p>
                            <Select
                                className='contacts3-select'
                                disabled={isDisabled}
                                error={errors.accurateInfo}
                                value={answers ? answers.accurateInfo : ''}
                                name='accurateInfo'
                                displayEmpty={true}
                                renderValue={(selected) => selected === "" ? "Select" : selected}
                                onChange={(e) => handleChange(e)}>
                                <MenuItem value='0'>0</MenuItem>
                                <MenuItem value='1'>1</MenuItem>
                                <MenuItem value='2'>2</MenuItem>
                                <MenuItem value='3'>3</MenuItem>
                                <MenuItem value='4'>4</MenuItem>
                                <MenuItem value='5'>5</MenuItem>
                            </Select>
                        </div>
                        <div className='question-container'>
                            <p>How was the information provided <span style={{ fontWeight: 'bold' }}>in the needed time</span>?</p>
                            <Select
                                className='contacts3-select'
                                disabled={isDisabled}
                                value={answers ? answers.time : ''}
                                error={errors.time}
                                name='time'
                                displayEmpty={true}
                                renderValue={(selected) => selected === "" ? "Select" : selected}
                                onChange={(e) => handleChange(e)}>
                                <MenuItem value='0'>0</MenuItem>
                                <MenuItem value='1'>1</MenuItem>
                                <MenuItem value='2'>2</MenuItem>
                                <MenuItem value='3'>3</MenuItem>
                                <MenuItem value='4'>4</MenuItem>
                                <MenuItem value='5'>5</MenuItem>
                            </Select>
                        </div>
                    </div>

                    <h6 style={{ paddingBottom: '0px' }} className='element-title'>3. Knowledge VS. conflict of interests
                        <i
                            data-tooltip-id="conflict"
                            position="right"
                            data-tooltip-content="?"
                            className="fa fa-question-circle"
                            aria-hidden="true"
                        ></i>
                        <Tooltip className="tooltip" id="conflict" />
                        <p style={{ fontSize: '16px', marginTop: '5px', color: 'grey' }}>0 = not relevant, 1 = low, 5 = High</p> </h6>

                    <div style={{ marginBottom: '0px' }} className='question-group'>
                        <div className='question-container'>
                            <p>Given your question, what is this TIKO level of <span style={{ fontWeight: 'bold' }}>knowledge and expertise</span></p>
                            <Select
                                className='contacts3-select'
                                disabled={isDisabled}
                                error={errors.knowledge}
                                value={answers ? answers.knowledge : ''}
                                name='knowledge'
                                displayEmpty={true}
                                renderValue={(selected) => selected === "" ? "Select" : selected}
                                onChange={(e) => handleChange(e)}>
                                <MenuItem value='0'>0</MenuItem>
                                <MenuItem value='1'>1</MenuItem>
                                <MenuItem value='2'>2</MenuItem>
                                <MenuItem value='3'>3</MenuItem>
                                <MenuItem value='4'>4</MenuItem>
                                <MenuItem value='5'>5</MenuItem>
                            </Select>
                        </div>
                        <div className='question-container'>
                            <p>Given your question, what is this TIKO level of <span style={{ fontWeight: 'bold' }}>conflict of interest?</span></p>
                            <Select
                                className='contacts3-select'
                                disabled={isDisabled}
                                error={errors.conflict}
                                value={answers ? answers.conflict : ''}
                                name='conflict'
                                displayEmpty={true}
                                renderValue={(selected) => selected === "" ? "Select" : selected}
                                onChange={(e) => handleChange(e)}>
                                <MenuItem value='0'>0</MenuItem>
                                <MenuItem value='1'>1</MenuItem>
                                <MenuItem value='2'>2</MenuItem>
                                <MenuItem value='3'>3</MenuItem>
                                <MenuItem value='4'>4</MenuItem>
                                <MenuItem value='5'>5</MenuItem>
                            </Select>
                        </div>
                    </div>
                </div>
            </div>

            <p >Save your answers before anaylzing the next contact </p>
            <div className='bottomNavigation'>
                <button style={{ minWidth: '180px' }}
                    className='contacts3saveBtn'
                    onClick={(e) => saveAnswers(e)}>{currContact ? `Save Answers for ${currContact.fullName}` : 'Save Answers'}</button>
                <div className="successMessage">
                    {message ? message : ''}
                    {isSaving && (
                        <div>
                            <i className="fas fa-spinner fa-spin"></i> Saving...
                        </div>
                    )}
                </div>

                {role && role === 'admin' &&
                    <div>
                        <p>Download your interview conclusion as a PDF or DOC file</p>
                        <div style={{ display: 'flex' }} >
                            <button
                                style={{ width: '200px', margin: '10px' }}
                                onClick={downloadDOC}
                            >Download as DOC
                            </button>
                            <button
                                style={{ width: '200px', margin: '10px' }}
                                onClick={downloadPDF}
                            >Download as PDF
                            </button>
                            <button style={{ width: '220px', margin: '10px' }} onClick={(e) => window.print(e)}>Print / Download whole page</button>
                        </div>
                    </div>
                }
            </div>
            <div className='pagination'>
                <Dialog
                    open={openDialog}
                    onClose={() => handleDialogClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className="dialog-title" id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
                    <DialogContent className="dialog-content">
                        <DialogContentText id="alert-dialog-description">
                            You have unsaved changes. Are you sure you want to leave this page?
                            Your changes will be lost.
                        </DialogContentText>
                    </DialogContent>
                    <div className="dialog-actions">
                        <button className="dialog-button" onClick={() => handleDialogClose(true)} autoFocus>Yes</button>
                        <button style={{ backgroundColor: 'grey' }} className="dialog-button" onClick={() => handleDialogClose(false)}>Cancel</button>
                    </div>
                </Dialog>

                <button onClick={() => handleDialogOpen("/ContactsApp/Contacts2")}>Back to step 2
                </button>
            </div>
        </div >
    );

}

export default withAuthentication(Contacts3);
