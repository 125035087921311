
import { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Divider } from '@mui/material';

function ReportsTable({ reports }) {
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('reportDate');

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    function EnhancedTableHead(props) {
        const { order, orderBy, onRequestSort } = props;
        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property);
        };

        return (
            <TableHead >
                <TableRow  >
                    <TableCell style={{ width: '100px' }} >
                        Title
                    </TableCell>
                    <TableCell style={{ width: '100px' }} sortDirection={orderBy === 'reportDate' ? order : false}>
                        <TableSortLabel
                            active={orderBy === 'reportDate'}
                            direction={orderBy === 'reportDate' ? order : 'asc'}
                            onClick={createSortHandler('reportDate')}
                        >
                            Date
                        </TableSortLabel>
                    </TableCell>
                    <TableCell style={{ width: '100px' }}>
                        Conclusions
                    </TableCell>
                    <TableCell style={{ textAlign: 'center' }}>
                        Bullets Data
                        <Divider style={{ margin: '10px 0px' }} />
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ width: '100px', color: 'var(--strong-green)', textAlign: 'center' }}>Bullet Title</div>
                            <div style={{ width: '100px', color: 'var(--strong-green)', textAlign: 'center' }}>Bullet Date</div>
                            <div style={{ flex: 1, color: 'var(--strong-green)', textAlign: 'center' }}>Bullet Content</div>
                        </div>
                    </TableCell>
                </TableRow>
            </TableHead>
        );
    }

    const sortedReports = [...reports].sort((a, b) => {
        if (orderBy === 'reportDate') {
            return order === 'asc' ? new Date(a.reportDate) - new Date(b.reportDate) : new Date(b.reportDate) - new Date(a.reportDate);
        }
        return 0;
    });


    return (
        <TableContainer className='table-container' style={{ maxHeight: '70vh', marginTop: '20px' }}
        // component={Paper}
        >
            <Table >
                <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                />
                <TableBody  >
                    {sortedReports && sortedReports.map((report) => (
                        <TableRow key={report.reportId}>
                            <TableCell >{report.title}</TableCell>
                            <TableCell>{new Date(report.reportDate).toLocaleDateString()}</TableCell>
                            <TableCell>{report.conclusions && Array.isArray(report.conclusions) && report.conclusions.join(', ')}</TableCell>
                            <TableCell colSpan={3}>
                                <Table>
                                    <TableBody>
                                        {report.bullets && report.bullets.map((bullet, index) => (
                                            <TableRow key={bullet.id}>
                                                <TableCell style={{ width: '100px' }}>{bullet.bulletTitle}</TableCell>
                                                <TableCell style={{ width: '100px' }}>{new Date(bullet.bulletDate).toLocaleDateString()}</TableCell>
                                                <TableCell >{bullet.bulletContent}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ReportsTable;