import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import Loading from '../../components/Loading';
import withAuthentication from "../../withAuthentication";
import { nanoid } from 'nanoid'

// MUI Components
import { Select, MenuItem, FormControl, InputLabel, TextField, Accordion, AccordionSummary, AccordionDetails, Typography, Button } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Reports3() {
    const { userState, updateData, loading } = useContext(Context);
    const { reports, currReportId } = userState;

    const navigate = useNavigate();

    const [currReport, setCurrReport] = useState('')
    const [selectedConclusions, setSelectedConclusions] = useState([]);
    const [pastConclusionsDropdown, setPastConclusionsDropdown] = useState([])
    const [currBullets, setCurrBullets] = useState([])

    // question titles for each TextField
    const questionTitles = [
        "Subject", "Timing", "Sequence", "Person",
        "Entity", "Geography", "Domain", "Why?"
    ];

    // Helper texts for each TextField
    const questionHelpers = [
        "Main topic",
        "Relevant timing",
        "Relevant 'before...' or 'after...'",
        "Relevant person",
        "Entity involved",
        "Relevant location",
        "Related domain (e.g., HR, IT)",
        "Why you care about this objective"
    ];

    // Titles for the validation fields
    const validationTitles = ["Validity", "Clarity", "Knowledge", "Average"];

    // console.log(reports, 'reports')
    // console.log(currReport, 'currReport')
    // console.log(currBullets, 'currBullets')

    // setting the current report to the report with the current report id + including the bullets
    useEffect(() => {
        if (loading || !reports || reports.length === 0) return
        const report = reports.find(report => report.reportId === currReportId)
        setCurrReport(report)

        // set currBullets to the bullets in the report
        const reportBullets = report.bullets // array of bullet objects
        setCurrBullets(reportBullets)

        // find the conclusions in the report and set it to the selectedConclusions
        const reportConclusions = report.conclusions
        setSelectedConclusions(reportConclusions)
        console.log(reportConclusions, 'reportConclusions before dropdown')
        // build a dropdown of past conclusions to select from
        const pastConclusions = reportConclusions.map((conclusion, index) => {
            return <MenuItem key={index} value={conclusion}>{conclusion}</MenuItem>
        })
        setPastConclusionsDropdown(pastConclusions)

    }, [reports, loading, currReportId])

    // Function to add a new bullet
    const addBullet = () => {
        const newBullet = {
            id: nanoid(),
            bullet: '', // The selected conclusion
            questions: {
                question1: '',
                question2: '',
                question3: '',
                question4: '',
                question5: '',
                question6: '',
                question7: '',
                question8: '',
            },
            validation: {
                validity: 0,
                clarity: 0,
                knowledge: 0,
                average: 0,
            },
            bulletTitle: '',
            bulletContent: '',
            bulletDate: ''
        }
        setCurrBullets([...currBullets, newBullet]);
        console.log(currBullets, 'currBullets after add bullet')
    };

    // Function to remove a bullet
    const removeBullet = () => {
        // use bullet id to remove the bullet from the array
        const updatedBullets = [...currBullets];
        updatedBullets.pop();
        setCurrBullets(updatedBullets);
    };

    // Function to handle bullet change
    const handleBulletChange = (index, event) => {
        const updatedBullets = [...currBullets];
        updatedBullets[index].bullet = event.target.value;
        setCurrBullets(updatedBullets);
    };

    // Function to handle question change
    const handleQuestionChange = (index, questionKey, event) => {
        const updatedBullets = [...currBullets];
        updatedBullets[index].questions[questionKey] = event.target.value;
        setCurrBullets(updatedBullets);
    };

    // Handler for numeric input changes that also calculates the average
    const handleValidationChange = (fieldTitle, value) => {
        // reject any value that is not a number or is less than 0 or greater than 5:
        if (isNaN(value) || value < 0 || value > 5) return;

        // Ensure the input is a number and within a valid range if necessary
        const numberValue = value === '' ? 0 : Number(value);
        // in the vlidation object, find the fieldTitle and set it to the numberValue
        const newBullets = [...currBullets];
        const bulletIndex = newBullets.length - 1;
        newBullets[bulletIndex].validation[fieldTitle] = numberValue;

        // Calculate the average
        if (fieldTitle !== 'average') {
            const { validity, clarity, knowledge } = newBullets[bulletIndex].validation;
            const total = validity + clarity + knowledge;
            const average = total / 3;
            newBullets[bulletIndex].validation.average = average.toFixed(2);
        }
        setCurrBullets(newBullets);
    };

    const allQuestionsFilled = (questions) => {
        return Object.values(questions).every(question => question.trim() !== '');
    };

    // Function to save the current step
    const saveStep3 = async () => {
        if (loading || !currBullets || currBullets.length === 0) return
        const newReport = {
            ...currReport,
            bullets: currBullets,
        }
        await updateData('reports', reports.map(report => report.reportId === currReportId ? newReport : report))
        navigate('/ReportsApp/Reports4')
    }


    if (loading) return <Loading />;
    return (
        <div className="app-home-container">
            <div className="app-title">
                <h4>Reports 3: Structuring Insight (report bullets)</h4>
                <h6>Build each bullet using the 8WH</h6>
            </div>

            <div className="reports-main-container" >
                <div className="reports-container" >
                    {currBullets && currBullets.map((bullet, index) => (
                        <Accordion
                            key={bullet.id}
                            defaultExpanded={index === 0}
                            sx={{
                                width: '100%',
                                margin: '15px 0px',
                                borderRadius: '10px !important',
                                boxShadow: 'none',
                                border: 'none',
                                '&:before': {
                                    height: '0px',
                                    content: 'none',
                                }
                            }}

                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                            >
                                <div>
                                    <h6>Bullet {index + 1}: {bullet.bulletTitle}</h6>
                                    <p style={{ color: 'var(--grey-icons)' }}>{bullet.bulletDate}</p>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails >
                                <div className='element-body' style={{ borderRadius: '10px', border: '0.5px solid var(--grey-icons)' }}>
                                    <FormControl sx={{margin: '15px 0px'}}>
                                        <InputLabel
                                            id={`bullet-select-label-${index}`}
                                            sx={{ fontSize: '18px', padding: '0px 15px' }}>
                                            Select a bullet conclusion
                                        </InputLabel>
                                        <Select
                                            labelId={`bullet-select-label-${index}`}
                                            id={`bullet-select-${index}`}
                                            value={bullet.bullet || ''}
                                            label="Select a bullet conclusion"
                                            sx={{ width: '300px', margin: '0px 10px' }}
                                            onChange={(event) => handleBulletChange(index, event)}
                                        >
                                            {pastConclusionsDropdown}
                                        </Select>
                                    </FormControl>

                                    <div className='bullet-row'>
                                        {bullet.questions && Object.entries(bullet.questions).map(([questionKey, questionValue], questionIndex) => (
                                            <TextField
                                                key={questionKey}
                                                label={questionTitles[questionIndex]}
                                                id={`bullet-${index}-question-${questionIndex}`}
                                                variant="outlined"
                                                sx={{ margin: '5px 10px'}}
                                                fullWidth
                                                helperText={questionHelpers[questionIndex]}
                                                value={questionValue || ''}
                                                onChange={(event) => handleQuestionChange(index, questionKey, event)}
                                                InputLabelProps={{ shrink: true, style: { fontSize: '18px' } }}
                                                disabled={!bullet.bullet}
                                                multiline
                                                rows={6}
                                            />
                                        ))}
                                    </div>

                                    <div className='bullet-row'>
                                        {validationTitles.map((title, index) => {
                                            const isAverage = title === 'Average';
                                            const isDisabled = !isAverage && !allQuestionsFilled(bullet.questions);
                                            return (
                                                <TextField
                                                    key={`${bullet.id}-validation-${title}`}
                                                    label={title}
                                                    type="number"
                                                    variant="outlined"
                                                    sx={{ width: '10%', margin: '5px 10px' }}
                                                    inputProps={{min: 0, max: 5 ,  readOnly: isAverage ? true: false}}
                                                    value={bullet.validation[title.toLowerCase()] || 0}
                                                    onChange={(event) => handleValidationChange(title.toLowerCase(), event.target.value)}
                                                    fullWidth
                                                    helperText='0-5 scale'
                                                    disabled={isAverage || isDisabled}
                                                    InputLabelProps={{ shrink: true, style: { fontSize: '18px' } }}
                                                />
                                            );
                                        })}
                                    </div>
                                    <div className='bullet-row'>
                                        <TextField
                                            label="Bullet Title"
                                            id={`bullet-${index}-title`}
                                            variant="outlined"
                                            sx={{ margin: '10px' }}
                                            fullWidth
                                            value={bullet.bulletTitle || ''}
                                            InputLabelProps={{ shrink: true, style: { fontSize: '18px' } }}
                                            onChange={(event) => {
                                                const newBullets = [...currBullets];
                                                newBullets[index].bulletTitle = event.target.value;
                                                setCurrBullets(newBullets);
                                            }}
                                            disabled={!bullet.bullet}
                                        />

                                        <TextField
                                            label="Bullet Date"
                                            id={`bullet-${index}-date`}
                                            variant="outlined"
                                            sx={{ margin: '10px', width: '50%' }}
                                            fullWidth
                                            type='date'
                                            value={bullet.bulletDate || ''}
                                            InputLabelProps={{ shrink: true, style: { fontSize: '18px' } }}
                                            onChange={(event) => {
                                                const newBullets = [...currBullets];
                                                newBullets[index].bulletDate = event.target.value;
                                                setCurrBullets(newBullets);
                                            }}
                                            disabled={!bullet.bullet}
                                        />
                                    </div>
                                    <div className='bullet-row'>
                                        <TextField
                                            label="Bullet Content"
                                            id={`bullet-${index}-content`}
                                            variant="outlined"
                                            sx={{ margin: '10px' }}
                                            fullWidth
                                            multiline
                                            rows={4}
                                            InputLabelProps={{ shrink: true, style: { fontSize: '18px' } }}
                                            value={bullet.bulletContent || ''}
                                            onChange={(event) => {
                                                const newBullets = [...currBullets];
                                                newBullets[index].bulletContent = event.target.value;
                                                setCurrBullets(newBullets);
                                            }}
                                            disabled={!bullet.bullet}
                                        />
                                    </div>
                                    <button
                                        variant="contained"
                                        className='yellowButton'
                                        style={{ marginLeft: '10px' }}
                                        onClick={removeBullet}
                                        disabled={loading} // Disable the button when loading
                                    >
                                        Remove Bullet
                                    </button>
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
                <div className='bottomNavigation'>
                    <button
                        onClick={addBullet}
                        disabled={!selectedConclusions || !currBullets}
                    >
                        Add Another Bullet
                    </button>
                </div>
            </div >
            <div className="pagination">
                <button onClick={() => navigate('/ReportsApp/Reports2')}>Back to Previous Step</button>
                <button onClick={() => saveStep3()}>Save and move to the next step</button>
            </div>
        </div >
    );
}

export default withAuthentication(Reports3);
