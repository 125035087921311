import { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import { TextField, Select, MenuItem, OutlinedInput, FormControl, Chip, Box, Dialog, DialogContent, DialogContentText, DialogTitle, Divider } from "@mui/material"
import { nanoid } from 'nanoid'
import withAuthentication from "../../withAuthentication";
import Loading from '../../components/Loading';
import { Tooltip } from 'react-tooltip';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, HeadingLevel, AlignmentType } from "docx";


function Pulling1() {

    const { userState, updateData, loading } = useContext(Context);
    const { contactsLists, interviews, projects, conclusions, role } = userState;

    const [pastInterviewsDropDown, setPastInterviewsDropDown] = useState('')
    const [allContactsDropDown, setAllContactsDropDown] = useState('')
    const [currContact, setCurrContact] = useState('')
    const [userQuestionsDropDown, setUserQuestionsDropDown] = useState('')
    const [searchQuery, setSearchQuery] = useState('');
    const [isDisabled, setDisabled] = useState(true)
    const [isModified, setIsModified] = useState(false)
    const [message, setMessage] = useState('')
    const [currInterview, setCurrInterview] = useState('')
    const [errors, setErrors] = useState({})
    const [isSaving, setIsSaving] = useState(false)
    const interviewSelectionRef = useRef(null); // for scrolling to the new concluding section when a new conclusion is created

    const goals = ['Adding Perspectives', 'Validating Perspectives', 'What does the TIKO see?', 'Where does the TIKO see from?']
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

    const newInterview = {
        interviewId: nanoid(),
        interviewDate: new Date().toLocaleDateString("en-GB", dateOptions),
        interviewQuestion: '',
        interviewQuestionId: '',
        interviewee: '',
        locationCity: '',
        locationType: '',
        goals: [],
        q1: '',
        q2: '',
        q3: '',
        q4: '',
        q5: '',
        q6: '',
        q7: '',
        q8: '',
        final: '',
        hearingFirst: '',
        hearingSecond: '',
        knowFact: '',
        knowEst: '',
        heardFact: '',
        heardEst: '',
        commentsInterviewee: '',
        commentsContent: '',
        commentsGeneral: ''
    }

    const styles = {
        inputLabelProps: { shrink: true, style: { color: '#219F8E', fontSize: 20, paddingBottom: 5 } }, inputProps: { style: { fontSize: 15, size: "small", width: '18ch' } }
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: { maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP, maxWidth: '350px', textAlign: 'left' },
        },
    };

    // console.log(allContactsDropDown, 'allContactsDropDown')

    // Dialog
    const [route, setRoute] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    // const handleDialogOpen = (route) => {
    //     if (isModified) {
    //         setOpenDialog(true);
    //         setRoute(route);
    //     } else {
    //         navigate(route);
    //     }
    // }

    const handleDialogClose = (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            navigate(route);
        }
    }

    const navigate = useNavigate();

    // when user selects an interviewee, update the contactsDropDown and questionsDropDown:
    useEffect(() => {
        if (loading || !contactsLists || !interviews || !projects || projects.length === 0) return
        // first, filter out projects with no mainQ, and then map them to a dropDown:

        const projectsWithMainQ = projects.filter(project => project.step1.MainQ)

        // map the projects with mainQ to a dropDown:
        const userQuestions = projectsWithMainQ.map(project => (
            <MenuItem key={project.id} value={project.step1.FinalQ ? project.step1.FinalQ : project.step1.MainQ}>{project.step1.FinalQ ? project.step1.FinalQ : project.step1.MainQ}</MenuItem>))
        setUserQuestionsDropDown(userQuestions)

        // filter out contacts with no fullName, and then map them to a dropDown:
        const contactsWithNames = contactsLists.map((list) => {
            return (list.listData.filter((contact) => {
                return contact.fullName !== '' && contact.fullName !== undefined ? contact : null
            }))
        })
        // filter out past interviewees from contactsDropDown:
        const pastIntervieweesNamesArr = interviews.map((interview) => {
            return interview.interviewData.interviewee
        })
        // flatten the array of arrays:
        const contactsWithNamesArr = contactsWithNames.flat()

        // filter out past interviewees from contactsDropDown:
        const contactsWithNamesArrFiltered = contactsWithNamesArr.filter((contact) => {
            return !pastIntervieweesNamesArr.includes(contact.fullName)
        })
        // map the contacts with names to a dropDown:
        const contactsDropDown = contactsWithNamesArrFiltered.map((contact) => {
            return <MenuItem key={contact.id} value={contact.fullName}>{contact.fullName}</MenuItem>
        })
        setAllContactsDropDown(contactsDropDown)

    }, [interviews, contactsLists, currInterview, interviews.length, loading, projects])

    // when user deletes interviewee from interview, update contactsDropDown:
    useEffect(() => {
        if (loading || !currInterview) return
        const contactsWithNames = contactsLists.map((list) => {
            return (list.listData.filter((contact) => {
                return contact.fullName !== '' && contact.fullName !== undefined ? contact : null
            }))
        })
        const pastIntervieweesNamesArr = interviews.map((interview) => {
            return interview.interviewData.interviewee
        })
        const contactsWithNamesArr = contactsWithNames.flat()

        const contactsWithNamesArrFiltered = contactsWithNamesArr.filter((contact) => {
            return !pastIntervieweesNamesArr.includes(contact.fullName)
        })

        const contactsDropDown = contactsWithNamesArrFiltered.map((contact) => {
            return <MenuItem key={contact.id} value={contact.fullName}>{contact.fullName}</MenuItem>
        })
        setAllContactsDropDown(contactsDropDown)
    }, [currInterview, interviews, contactsLists, loading])

    useEffect(() => {
        if (loading || !interviews) return
        const pastIntervieweesNamesDropDown = interviews.map((interview) => {
            return <option key={interview.interviewId} value={interview.interviewData.interviewee}>{interview.interviewData.interviewee}</option>
        })
        setPastInterviewsDropDown(pastIntervieweesNamesDropDown)
    }, [interviews, loading])


    // for both interview and interviewee selection:
    function handleChange(e) {
        const { name, value, checked } = e.target;

        setMessage('')
        setIsModified(true)

        if (name === 'interviewSelection') {
            if (!value) {
                const emptyIntervieeField = { ...currInterview, interviewee: '' }
                setCurrInterview(emptyIntervieeField)
                return
            }
            if (checked) {
                const interviewData = interviews.find((interview) => {
                    return interview.interviewData.interviewee === value
                })
                const existingInterviewData = interviewData.interviewData
                const goalsObj = existingInterviewData.goals
                const goalsArr = Object.values(goalsObj)

                existingInterviewData.goals = goalsArr
                setCurrInterview(existingInterviewData)
                setCurrContact(existingInterviewData.interviewee)
                setDisabled(false)
            } else {
                const emptyIntervieeField = { ...currInterview, interviewee: '' }
                setCurrInterview(emptyIntervieeField)
                setCurrContact('')
                setDisabled(true)
            }

        }

        else if (name === 'intervieweeSelection') {
            if (!value) {
                const emptyIntervieeField = { ...currInterview, interviewee: '' }
                setCurrInterview(emptyIntervieeField)
                return
            }
            setDisabled(false)

        } else if (name === 'interviewQuestion') {
            if (!value) {
                const emptyIntervieeField = { ...currInterview, interviewQuestion: '' }
                setCurrInterview(emptyIntervieeField)
                return
            }
            setDisabled(false)
            // find the projectId of the selected question:
            const selectedProject = projects.find((project) => {
                return project.step1.MainQ === value || project.step1.FinalQ === value
            })
            const projectId = selectedProject.id
            // set interviewQuestionId to the projectId:
            setCurrInterview((prevAnswers) => ({
                ...prevAnswers,
                interviewQuestionId: projectId,
            }));
            // set interviewQuestion to the selected question:
            setCurrInterview((prevAnswers) => ({
                ...prevAnswers,
                interviewQuestion: value,
            }));

        } else if (name === 'goals') {
            const { target: { value }, } = e
            if (!value) {
                const emptyGoalsField = { ...currInterview, goals: [] }
                setCurrInterview(emptyGoalsField)
                return
            }
            setDisabled(false)
            const selectedGoals = typeof value === 'string' ? value.split(',') : value;
            setCurrInterview((prevAnswers) => ({
                ...prevAnswers,
                [name]: selectedGoals,
            }));

        }
        else {
            if (!value) {
                const emptyField = { ...currInterview, [name]: '' }
                setCurrInterview(emptyField)
                return
            }
            setDisabled(false)
            setCurrInterview((prevAnswers) => ({
                ...prevAnswers,
                [name]: value,
            }));
        }
        // reset error state according to input:
        if (errors[name]) {
            setErrors(oldErrors => ({
                ...oldErrors,
                [name]: false
            }))
        }
    }

    // filter interviews by search query:
    const filteredInterviews = interviews.filter((interview) => {
        if (searchQuery === '') return true

        const searchQueryLowerCase = searchQuery.toLowerCase()
        return (
            interview.interviewData.interviewee.toLowerCase().includes(searchQueryLowerCase) ||
            interview.interviewData.interviewDate.toLowerCase().includes(searchQueryLowerCase) ||
            interview.interviewData.interviewQuestion.toLowerCase().includes(searchQueryLowerCase) ||
            interview.interviewData.hearingFirst.toLowerCase().includes(searchQueryLowerCase) ||
            interview.interviewData.hearingSecond.toLowerCase().includes(searchQueryLowerCase)
        )
    })

    function createInterview() {
        setDisabled(false)
        setIsModified(true)
        setCurrInterview(newInterview)
        setCurrContact(newInterview.interviewee)

        // scroll to the new interview section:
        if (interviewSelectionRef.current) {
            interviewSelectionRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    const deleteInterview = async () => {
        // before deleting, confirm with user:
        const confirmation = window.confirm(`Are you sure you want to delete the interview with ${currInterview && currInterview.interviewee}?`)
        if (!confirmation) return

        const interviewToDelete = interviews.find((interview) => {
            return interview.interviewData.interviewee === currInterview.interviewee
        })
        // console.log(interviewToDelete, 'interviewToDelete')
        const interviewToDeleteId = interviewToDelete.interviewId
        // console.log(interviewToDeleteId, 'interviewToDeleteId')

        const newInterviewsArr = interviews.filter((interview) => {
            return interview.interviewId !== interviewToDeleteId
        })
        // console.log(newInterviewsArr, 'newInterviewsArr')

        // if this interview has a matching conclusion under the conclusion array, find it and delete it and then update the DB:
        const conclusionToDelete = conclusions.find((conclusion) => {
            return conclusion.conclusionData.interviewee === currInterview.interviewee
        })
        // console.log(conclusionToDelete, 'conclusionToDelete')

        if (conclusionToDelete) {
            const conclusionToDeleteId = conclusionToDelete.conclusionId
            const newConclusionsArr = conclusions.filter((conclusion) => {
                return conclusion.conclusionId !== conclusionToDeleteId
            })
            try {
                await updateData('conclusions', newConclusionsArr)
                console.log('conclusion deleted')
            }
            catch (err) {
                console.log(err)
                setMessage('something went wrong, please try again')
            }
        }

        const deleteBtn = document.getElementsByClassName('deleteBtn')
        deleteBtn.innerHTML = '<i className="fas fa-spinner fa-spin"></i>'

        // delete interview from DB (update new interviews array):
        try {
            setMessage('deleting interview...')
            updateData('interviews', newInterviewsArr)
            console.log('interview deleted')
            setTimeout(() => {
                setMessage('interview deleted successfully')
            }, 2000)

            setTimeout(() => {
                setMessage('')
                deleteBtn.innerHTML = 'Delete Interview'
                // reset form:
                setCurrInterview('')
                setCurrContact('')
                setDisabled(true)
            }, 3500)
        }
        catch (err) {
            console.log(err)
            setMessage('something went wrong, please try again')
        }

        setCurrInterview(newInterview)
        setMessage('')
        setCurrContact('')
        setDisabled(true)
    }

    async function saveInterview() {
        // before saving, confirm all fields are filled:
        // let newErrors = {}
        // for (let key in currInterview) {
        //     if (!currInterview[key]) {
        //         newErrors[key] = true
        //     }
        // }

        // if (Object.keys(newErrors).length > 0) {
        //     setErrors(newErrors)
        //     setMessage('please fill all fields')
        //     setTimeout(() => {
        //         setMessage('')
        //     }, 2000)
        //     return
        // }

        // convert goals array to object:
        const goalsObj = { goalText: '' }
        currInterview.goals.forEach((goal) => {
            goalsObj.goalText = goal
        })

        currInterview.goals = goalsObj

        const newInterviewObj = { interviewId: currInterview.interviewId, interviewData: currInterview, questionId: currInterview.interviewQuestionId }
        const interviewExists = interviews.find((interview) => { return interview.interviewId === currInterview.interviewId })

        let newInterviewsArr = []
        if (interviewExists) {
            newInterviewsArr = interviews.map((interview) => {
                return interview.interviewId === currInterview.interviewId ? newInterviewObj : interview
            })
        } else {
            newInterviewsArr = [...interviews, newInterviewObj]
        }

        setIsSaving(true)
        try {

            await updateData('interviews', newInterviewsArr)

            setTimeout(() => {
                setIsSaving(false)
                setMessage('Saved successfully')
            }, 2000)

            // reset form:
            setTimeout(() => {
                setMessage('')
                setIsModified(false)
                setCurrInterview('')
                setCurrContact('')
                setDisabled(true)
            }, 3500)

        } catch (err) {
            console.log(err)
            setMessage('Error saving data', err);
            setTimeout(() => {
                setMessage('');
            }, 2000);
        }
    }

    // download conclusion as pdf
    function downloadPDF() {
        // Create a new instance of jsPDF
        const doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true,
            compress: true,
            precision: 2,
            lineHeight: 1.3,
        })


        // Helper function to wrap text if it's too long
        function addWrappedText(text, x, y, maxWidth) {
            let splitText = doc.splitTextToSize(text, maxWidth);
            doc.text(splitText, x, y);
            return splitText.length; // Return number of lines
        }


        // content and formatting of the PDF document
        const maxWidth = doc.internal.pageSize.getWidth() - 20; // 10 units padding on each side
        let currentY = 20;


        // content and formatting of the PDF document
        doc.setFontSize(13);
        doc.setFont('helvetica', 'bold');
        doc.text(`Interview summary: ${currInterview.interviewee} `,
            doc.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 10;
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.text(`${new Date().toLocaleDateString("en-GB", dateOptions)} `,
            doc.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 20;

        // section 1
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text('Interview Details', 10, currentY);
        currentY += 10;

        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        const fields = [
            `Interview Question: ${currInterview.interviewQuestion}`,
            `City: ${currInterview.locationCity}`,
            `Location Type: ${currInterview.locationType}`,
            `Goals: ${currInterview.goals}`,
            `What (subject): ${currInterview.q1}`,
            `When (timing): ${currInterview.q2}`,
            `When (sequence): ${currInterview.q3}`,
            `Who (person): ${currInterview.q4}`,
            `Who (entity): ${currInterview.q5}`,
            `Where (geography): ${currInterview.q6}`,
            `Where (domain): ${currInterview.q7}`,
            `Why: ${currInterview.q8}`,
            `Final Pitch: ${currInterview.final}`,
        ];

        fields.forEach(field => {
            let lines = addWrappedText(field, 10, currentY, maxWidth);
            currentY += lines * 7; // 7 units line height for font size 12
        });

        // add space between sections:
        currentY += 10;

        // section 2
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text('Interview Analysis', 10, currentY);
        currentY += 10;

        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        const fields2 = [
            `First Hearing: ${currInterview.hearingFirst}`,
            `Second Hearing: ${currInterview.hearingSecond}`,
            `TIKO I Know (fact): ${currInterview.knowFact}`,
            `TIKO I Know (Estimation): ${currInterview.knowEst}`,
            `TIKO I Heard (fact): ${currInterview.heardFact}`,
            `TIKO I Heard (Estimation): ${currInterview.heardEst}`,
            `Interviewee: ${currInterview.commentsInterviewee}`,
            `Content: ${currInterview.commentsContent}`,
            `General: ${currInterview.commentsGeneral}`,
        ];

        fields2.forEach(field => {
            let lines = addWrappedText(field, 10, currentY, maxWidth);
            currentY += lines * 7; // 7 units line height for font size 12
        });


        // Save the PDF
        doc.save(`${currInterview.interviewee} - Interview Summary.pdf`)
    }

    // generate a docx document from the cv data and download to user's computer
    function downloadDOC() {
        // create a new document
        const doc = new Document({
            sections: [{
                children: [
                    new Paragraph({
                        text: `Interviewee: ${currInterview.interviewee}`,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                        text: new Date().toLocaleDateString("en-GB", dateOptions),
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                        text: `Interview Details`,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Interviewee: ${currInterview.interviewee}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Interviewee Question: ${currInterview.interviewQuestion}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `City: ${currInterview.locationCity}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Location Type: ${currInterview.locationType}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Interview Goals: ${currInterview.goals}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Your Pitch:`,
                        font: {
                            size: 14,
                            bold: true,
                        },
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `What (subject): ${currInterview.q1}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `When (timing): ${currInterview.q2}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `When (sequence): ${currInterview.q3}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `"Who (person): ${currInterview.q4}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Who (entity): ${currInterview.q5}`,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Where (geography): ${currInterview.q6}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Where (domain): ${currInterview.q7}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Why: ${currInterview.q8}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Final Pitch: ${currInterview.final}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),

                    new Paragraph({
                        text: `Interview Analysis`,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        font: {
                            size: 14,
                            bold: true,
                        },
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Internal Negative Know Fact: ${currInterview.internalNegativeKnowFact}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Hearing First: ${currInterview.hearingFirst}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Hearing Second: ${currInterview.hearingSecond}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `TIKO I Know (fact): ${currInterview.knowFact}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `TIKO I Know (Estimation): ${currInterview.knowEst}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `TIKO I Heard (fact): ${currInterview.heardFact}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `TIKO I Heard (Estimation) ${currInterview.heardEst}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Comments - Interviewee: ${currInterview.commentsInterviewee}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Comments - Content: ${currInterview.commentsContent}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Comments - General: ${currInterview.commentsGeneral}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                ],
            }]

        });

        // Used to export the file into a .docx file
        Packer.toBlob(doc)
            .then((blob) => {
                saveAs(blob, `Interview summary-${currInterview.interviewee}.docx`);
            })
            .catch((error) => {
                console.error("Error generating document: ", error);
            });
    }

    if (loading) return <Loading />

    return (
        <div className="app-home-container">
            <div className='app-title'>
                <h4>Pulling: Interview Preparation and Analysis Tool</h4>
                <p>Prepare for an interview and write better interview summaries</p>
            </div>
            <div className='pulling-top-container'>
                <div className='element-title'>
                    <h6 style={{ textAlign: 'left', }} >Search and load interviews</h6>

                </div>
                <div className='element-body' style={{ textAlign: 'left' }}>
                    <p>Select an interview from the list below to load its details</p>
                    <div className='pulling-table-btns'>
                        <div className='search-input-wrapper' style={{ width: '45%' }}>
                            <input
                                type='text'
                                className='search-input'
                                placeholder='Search interviews by interviewee, date, question or hearing...'
                                style={{ width: '100%' }}
                                value={searchQuery || ''}
                                onChange={(e) => setSearchQuery(e.target.value)}

                            />
                            <i className="fas fa-solid fa-magnifying-glass search-icon"></i>
                        </div>
                        <div>
                            <button
                                style={{ padding: '10px' }}
                                disabled={allContactsDropDown.length === 0}
                                onClick={createInterview}>Create new interview
                            </button>
                            <button
                                className='deleteBtn'
                                disabled={!currInterview || !currInterview.interviewee}
                                style={{ padding: '10px' }}
                                onClick={deleteInterview}>Delete selected interview
                            </button>
                        </div>

                    </div>
                    <div className='table-container '>
                        <table>
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Interviewee</th>
                                    <th>Date</th>
                                    <th>Question</th>
                                    <th>First Hearing</th>
                                    <th>Second Hearing</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredInterviews && filteredInterviews.map((interview) => {
                                    return (
                                        <tr key={interview.interviewId}>
                                            <td>
                                                <input
                                                    type='checkbox'
                                                    name='interviewSelection'
                                                    value={interview.interviewData.interviewee || ''}
                                                    checked={currInterview.interviewee === interview.interviewData.interviewee}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{interview.interviewData.interviewee || ''}</td>
                                            <td>{interview.interviewData.interviewDate || ''}</td>
                                            <td>{interview.interviewData.interviewQuestion || ''}</td>
                                            <td>{interview.interviewData.hearingFirst || ''}</td>
                                            <td>{interview.interviewData.hearingSecond || ''}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className='pulling-main-container' ref={interviewSelectionRef}>
                <div className='pulling-before-container'>
                    <div className='element-title'>
                        <h6 >Step 1: Interview Details</h6>
                    </div>
                    <div className='element-body'>
                        <div className='contactSelection'>
                            <TextField
                                label='Date'
                                name='interviewDate'
                                value={currInterview && currInterview?.interviewDate ? currInterview.interviewDate : ''}
                                inputProps={{ readOnly: true }}
                                sx={{ mb: 2 }}
                                disabled
                            />

                            <h6 className='grey-strip'>1. Select interviewee</h6>
                            <Select
                                name='interviewee'
                                displayEmpty={true}
                                error={errors.interviewee}
                                value={currInterview.interviewee || ''}
                                disabled={!currInterview.interviewId || isDisabled}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                                renderValue={(selected) => selected || 'Select contact name'}
                                onChange={handleChange}>
                                {allContactsDropDown}
                            </Select>
                        </div>
                        <div className='pulling-meeting-info'>
                            <h6 className='grey-strip'>2. Enter Interview Information</h6>
                            <p>Interview Question</p>
                            <Select
                                name='interviewQuestion'
                                displayEmpty={true}
                                error={errors.interviewQuestion}
                                value={currInterview.interviewQuestion || ''}
                                disabled={!currInterview.interviewId || isDisabled}
                                MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
                                renderValue={(selected) => selected || 'Select question'}
                                onChange={handleChange}>
                                {userQuestionsDropDown}
                            </Select>
                            <FormControl sx={{ mt: 1, maxWidth: 400 }}>
                                <p>Interview City</p>
                                <TextField
                                    name='locationCity'
                                    className='pulling-input'
                                    error={errors.locationCity}
                                    value={currInterview.locationCity || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    size="small"
                                    disabled={isDisabled}
                                    placeholder='City'
                                ></TextField>
                            </FormControl>
                            <FormControl sx={{ mt: 1, maxWidth: 400 }}>
                                <p>Interview Location Type</p>
                                <Select
                                    value={currInterview.locationType || ''}
                                    disabled={isDisabled}
                                    displayEmpty={true}
                                    error={errors.locationType}
                                    name='locationType'
                                    label='Location type'
                                    placeholder='Location type'
                                    onChange={handleChange}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => selected || 'Location type'}
                                >
                                    <MenuItem key={'Your place'} value='Your place'>Your place</MenuItem>
                                    <MenuItem key={'TIKO place'} value='TIKO place'>TIKO place</MenuItem>
                                    <MenuItem key={'Neutral place'} value='Neutral place'>Neutral place</MenuItem>
                                </Select>
                            </FormControl>
                            <FormControl sx={{ mt: 1, maxWidth: 400 }}>
                                <p>Interview Goals</p>
                                <Select
                                    multiple
                                    disabled={isDisabled}
                                    displayEmpty={true}
                                    error={errors.goals}
                                    name='goals'
                                    value={Array.isArray(currInterview.goals) ? currInterview.goals : []}
                                    onChange={handleChange}
                                    input={<OutlinedInput />}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return 'Select goals'
                                        }
                                        return (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )
                                    }}
                                    MenuProps={MenuProps}
                                >
                                    {goals.map((goal) => (
                                        <MenuItem
                                            key={goal}
                                            value={goal}
                                        >
                                            {goal}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                        </div>

                        <div className='pulling-pitch'>
                            <h6 className='grey-strip'>3. Prepare your pitch</h6>
                            <TextField
                                className="TextField-pulling"
                                label="What (subject)"
                                name="q1"
                                required
                                value={currInterview.q1 || ''}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                                error={errors.q1}
                                minRows={3}
                                maxRows={6}
                                fullWidth
                                size="small"
                                multiline
                                placeholder="What is the subject you mainly plan to talk about?"
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>
                            <TextField
                                className="TextField-pulling"
                                label="When (timing)"
                                name="q2"
                                required
                                value={currInterview.q2 || ''}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                                error={errors.q2}
                                minRows={3}
                                maxRows={6}
                                multiline
                                fullWidth
                                placeholder="Which time dimention is relevant?"
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>
                            <TextField
                                className="TextField-pulling"
                                label="When (sequence)"
                                name="q3"
                                required
                                value={currInterview.q3 || ''}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                                error={errors.q3}
                                minRows={3}
                                maxRows={6}
                                multiline
                                fullWidth
                                placeholder="Any specific reason that have cause you to look into this matter now?"
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>
                            <TextField
                                className="TextField-pulling"
                                label="Who (person)"
                                name="q4"
                                required
                                value={currInterview.q4 || ''}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                                error={errors.q4}
                                minRows={3}
                                maxRows={6}
                                multiline
                                fullWidth
                                placeholder="Who are you? Are there other relevant people?"
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>
                            <TextField
                                className="TextField-pulling"
                                label="Who (entity)"
                                name="q5"
                                required
                                value={currInterview.q5 || ''}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                                error={errors.q5}
                                minRows={3}
                                maxRows={6}
                                multiline
                                fullWidth
                                placeholder="Which entity/group connects to the 'What'? (if at all)"
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>
                            <TextField
                                className="TextField-pulling"
                                label="Where (geography)"
                                name="q6"
                                required
                                value={currInterview.q6 || ''}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                                error={errors.q6}
                                minRows={3}
                                maxRows={6}
                                multiline
                                fullWidth
                                placeholder="Which georgraphic dimention is relevant to this question?"
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>
                            <TextField
                                className="TextField-pulling"
                                label="Where (domain)"
                                name="q7"
                                required
                                value={currInterview.q7 || ''}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                                error={errors.q7}
                                minRows={3}
                                maxRows={6}
                                multiline
                                fullWidth
                                placeholder="Which domains are relevant to the 'what' question?"
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>
                            <TextField
                                className="TextField-pulling"
                                label="Why"
                                name="q8"
                                required
                                value={currInterview.q8 || ''}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={isDisabled}
                                error={errors.q8}
                                minRows={3}
                                maxRows={6}
                                multiline
                                fullWidth
                                placeholder="Why do you want to interview this specific person?"
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>
                            <TextField
                                className="TextField-pulling"
                                label="Final Pitch"
                                name="final"
                                required
                                value={currInterview.final || ''}
                                onChange={handleChange}
                                variant="filled"
                                disabled={isDisabled}
                                error={errors.final}
                                minRows={8}
                                maxRows={10}
                                multiline
                                fullWidth
                                placeholder="Enter your final pitch for the interview"
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>
                        </div>
                    </div>
                </div>
                <div className='pulling-after-container'>
                    <div className='element-title'>
                        <h6>Step 2: Interview Analysis</h6>
                    </div>
                    <div className='element-body'>
                        <div className='right-side-group group1'>
                            <h6 className='grey-strip' >1. Hearing
                                <i
                                    data-tooltip-id="interViewAnalysisHearing"
                                    position="right"
                                    data-tooltip-content="Keep the conversation on track by asking open-ended questions like 'And then...?' to encourage them to continue. Avoid interrupting with unrelated questions, as this can disrupt the flow of the conversation and make it harder to follow"
                                    className="fa fa-question-circle"
                                    aria-hidden="true"
                                ></i>
                                <Tooltip className="tooltip" id="interViewAnalysisHearing" /></h6>
                            <div className='textarea-group'>
                                <div>
                                    <p>Once you've asked a question, give the interviewee time to answer. Listen carefully to their response and try to identify the chronological 'starting point' of their story.</p>
                                    <TextField
                                        className="TextField-pulling"
                                        label="First Hearing"
                                        name="hearingFirst"
                                        required
                                        value={currInterview.hearingFirst || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={isDisabled || currInterview.interviewee === ''}
                                        error={errors.hearingFirst}
                                        fullWidth
                                        multiline
                                        minRows={6}
                                        maxRows={10}
                                        sx={{ marginTop: '10px' }}
                                        InputLabelProps={styles.inputLabelProps}
                                    ></TextField>
                                </div>
                                <div>
                                    <p>Identify key points from the first hearing that you would like to explore further. For each point, ask the relevant 8 WH questions to gain a deeper understanding.</p>

                                    <TextField
                                        className="TextField-pulling"
                                        label="Second Hearing"
                                        name="hearingSecond"
                                        required
                                        value={currInterview.hearingSecond || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={isDisabled || currInterview.interviewee === ''}
                                        error={errors.hearingSecond}
                                        fullWidth
                                        multiline
                                        minRows={6}
                                        maxRows={10}
                                        sx={{ marginTop: '10px' }}
                                        InputLabelProps={styles.inputLabelProps}
                                    ></TextField>
                                </div>
                            </div>
                        </div>
                        <div className='right-side-group group2'>
                            <h6 className='grey-strip'>2. I know/ I heard</h6>
                            <p>Review the points you entered for the second hearing and categorize them into the appropriate box</p>
                            <div style={{ flexDirection: 'row' }} className='textarea-group'>
                                <TextField
                                    className="TextField-pulling"
                                    label="TIKO I Know"
                                    name="knowFact"
                                    required
                                    value={currInterview.knowFact || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled || currInterview.interviewee === ''}
                                    fullWidth
                                    multiline
                                    minRows={10}
                                    maxRows={10}
                                    helperText='Fact'
                                    InputLabelProps={styles.inputLabelProps}
                                ></TextField>
                                <TextField
                                    className="TextField-pulling"
                                    label="TIKO I Know"
                                    name="knowEst"
                                    required
                                    value={currInterview.knowEst || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled || currInterview.interviewee === ''}
                                    error={errors.knowEst}
                                    fullWidth
                                    multiline
                                    minRows={10}
                                    maxRows={10}
                                    helperText='Estimation'
                                    InputLabelProps={styles.inputLabelProps}
                                ></TextField>
                                <TextField
                                    className="TextField-pulling"
                                    label="TIKO I Heard"
                                    name="heardFact"
                                    required
                                    value={currInterview.heardFact || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled || currInterview.interviewee === ''}
                                    error={errors.heardFact}
                                    fullWidth
                                    multiline
                                    minRows={10}
                                    maxRows={10}
                                    helperText='Fact'
                                    InputLabelProps={styles.inputLabelProps}
                                ></TextField>
                                <TextField
                                    className="TextField-pulling"
                                    label="TIKO I Heard"
                                    name="heardEst"
                                    required
                                    value={currInterview.heardEst || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled || currInterview.interviewee === ''}
                                    error={errors.heardEst}
                                    fullWidth
                                    multiline
                                    minRows={10}
                                    maxRows={10}
                                    helperText='Estimation'
                                    InputLabelProps={styles.inputLabelProps}
                                ></TextField>
                            </div>
                        </div>
                        <div className='right-side-group group3'>
                            <h6 className='grey-strip'>3. Comments</h6>
                            <p>
                                After the interview, take some time to reflect on the conversation. What did you learn? What questions do you still have? What do you want to know more about? Write down your thoughts here.
                            </p>

                            <div className='textarea-group'>
                                <TextField
                                    className="TextField-pulling"
                                    label="Comments: Interviewee"
                                    name="commentsInterviewee"
                                    required
                                    value={currInterview.commentsInterviewee || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled || currInterview.interviewee === ''}
                                    error={errors.commentsInterviewee}
                                    fullWidth
                                    multiline
                                    minRows={6}
                                    maxRows={10}
                                    InputLabelProps={styles.inputLabelProps}
                                ></TextField>
                                <TextField
                                    className="TextField-pulling"
                                    label="Comments: Content"
                                    name="commentsContent"
                                    required
                                    value={currInterview.commentsContent || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled || currInterview.interviewee === ''}
                                    error={errors.commentsContent}
                                    fullWidth
                                    multiline
                                    minRows={6}
                                    maxRows={10}
                                    InputLabelProps={styles.inputLabelProps}
                                ></TextField>
                                <TextField
                                    className="TextField-pulling"
                                    label="Comments: General"
                                    name="commentsGeneral"
                                    required
                                    value={currInterview.commentsGeneral || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled={isDisabled || currInterview.interviewee === ''}
                                    error={errors.commentsGeneral}
                                    fullWidth
                                    multiline
                                    minRows={6}
                                    maxRows={10}
                                    InputLabelProps={styles.inputLabelProps}
                                ></TextField>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
            <div className='bottomNavigation'>
                {/* <div >
                            <p>To save the interview, please fill out all fields</p>
                        </div> */}
                <button
                    style={{ width: '200px', marginTop: '20px' }}
                    className='savingBtn'
                    onClick={saveInterview}
                    disabled={isDisabled}>Save Interview
                </button>
                <div className="successMessage">
                    {message ? message : ''}
                    {isSaving && (
                        <div>
                            <i className="fas fa-spinner fa-spin"></i> Saving...
                        </div>
                    )}
                </div>

                {role && role === 'admin' &&
                    <div style={{ marginTop: '30px' }}>
                        <div style={{ display: 'flex' }} >
                            <button
                                style={{ width: '200px', margin: '10px' }}
                                onClick={downloadDOC}
                                disabled={isDisabled}>Download as DOC</button>
                            <button
                                style={{ width: '200px', margin: '10px' }}
                                onClick={downloadPDF}
                                disabled={isDisabled} >Download as PDF</button>
                        </div>
                        <button style={{ width: '250px' }} onClick={(e) => window.print(e)}>Print / Download whole page</button>
                    </div>
                }

            </div>
            <div className='pagination'>
                <Dialog
                    open={openDialog}
                    onClose={() => handleDialogClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className="dialog-title" id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
                    <DialogContent className="dialog-content">
                        <DialogContentText id="alert-dialog-description">
                            You have unsaved changes. Are you sure you want to leave this page?
                        </DialogContentText>
                    </DialogContent>
                    <div className="dialog-actions">
                        <button style={{ backgroundColor: 'grey' }} className="dialog-button" onClick={() => handleDialogClose(false)}>Cancel</button>
                        <button className="dialog-button" onClick={() => handleDialogClose(true)} autoFocus>Yes</button>
                    </div>
                </Dialog>
                {/* <button onClick={() => handleDialogOpen('/')} >Back to homepage</button> */}
            </div>
        </div>
    );
}

export default withAuthentication(Pulling1);