import { useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { auth } from '../firebase';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { Context } from "../Context";
import Loading from "../components/Loading";
import logo from '../images/logo-colors.png';

function Login() {
    const { setUserState, loading } = useContext(Context);

    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");

    const redirectUrl = new URLSearchParams(location.search).get("redirectTo") || '/';

    const validatePassword = () => {
        let isValid = true
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        // Trim the inputs
        const trimmedEmail = email.trim();
        const trimmedPassword = password.trim();

        if (trimmedPassword === '' || trimmedEmail === '') {
            isValid = false
            console.log('cant submit: cant validate user and password')
            setMessage('Please enter a valid email and password')
        } else if (!passwordRegex.test(trimmedPassword)) {
            isValid = false
            console.log('cant submit: cant validate user and password')
            setMessage('Password must have at least 8 characters and contain at least one letter and one number')
        }
        else if (!emailRegex.test(trimmedEmail)) {
            isValid = false
            console.log('cant submit: cant validate user and password')
            setMessage('Please enter a valid email')
        }
        return isValid
    }

    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            await sendPasswordResetEmail(auth, email);
            setMessage('Password reset email sent. Please check your email and follow the instructions.')

        } catch (error) {
            console.log('Error sending password reset email: ', error.message);
            setMessage(error.message)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('')

        if (validatePassword()) {
            try {
                const { user } = await signInWithEmailAndPassword(auth, email, password);
                setUserState(prevState => ({ ...prevState, user }));
                navigate(redirectUrl);

            } catch (err) {
                if (err.message === 'Firebase: Error (auth/user-not-found).') {
                    setMessage('Email not found. Please check your email and try again')
                } else if (err.message === 'Firebase: Error (auth/wrong-password).') {
                    setMessage('Incorrect password. Please try again')
                } else if (err.message === 'Firebase: Error (auth/too-many-requests).') {
                    setMessage('Too many login attempts. Please try again later')
                } else if (err.message === 'Firebase: Error (auth/invalid-email).') {
                    setMessage('Invalid email. Please try again')
                } else if (err.message === 'Firebase: Error (auth/invalid-password).') {
                    setMessage('Invalid password. Please try again')
                } else if (err.message === 'Firebase: Error (auth/user-disabled).') {
                    setMessage('User disabled. Please contact your administrator')
                } else {
                    setMessage('Error logging in. Please try again')
                }
            }
        } else {
            console.log('cant submit: cant validate user and password')
            setMessage('Please enter a valid email and password')
            setEmail('')
            setPassword('')
        }
    }
    // function moveToSignUp() {
    //     navigate('/signup');
    // }

    if (loading) return <Loading />

    return (
        <div className="login-background">
            <div className="login-main-container">
                <div className='login-top-container' >
                    <div className="login-logo-container">
                        <img src={logo} alt="KYS Logo" />
                        <h4 >KYS</h4>
                    </div>
                    <div style={{ margin: '15px' }}>
                        <h3>Login</h3>
                    </div>
                </div>

                <form className='login-form' onSubmit={handleSubmit}>
                    <div className="login-section">
                        <input
                            className="login-input"
                            type="email"
                            id="email"
                            placeholder="Email"
                            value={email || ''}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="login-section">
                        <input
                            className="login-input"
                            type="password"
                            id="password"
                            placeholder="Password"
                            value={password || ''}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="login-section">
                        <button
                            className="loginBtn"
                            type="submit"
                            disabled={loading}>Login
                        </button>
                    </div>
                </form>

                <div className="signin-help">
                    <div>
                        <p style={{ fontSize: '0.9rem' }}>
                            <button className='transparentBtn' onClick={handleResetPassword}>Forgot password</button>
                        </p>
                    </div>
                    <div>
                        <p style={{ fontSize: '0.9rem', textDecoration: 'underline' }}>
                            <a href={`mailto:info@humints.com`}>Trouble signing in</a>
                        </p>
                    </div>
                </div>
                <div className="successMessage">{message}</div>
                {/* <div style={{ marginTop: '45px', flexDirection: 'row' }} className="bottomNavigation">
                        <p style={{ fontSize: '0.9rem' }}>Don't have an account?</p>
                        <button className="transparentBtn" style={{ fontSize: '0.9rem' }} onClick={moveToSignUp}>Sign up</button>
                    </div> */}
            </div>

        </div>
    );

}

export default Login;