import React, {useContext, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

import { Context } from './Context';

const withAuthentication = (WrappedComponent, adminOnly, superAdminOnly) => {

    return (props) => {
        const navigate = useNavigate();
        const { userState, loading } = useContext(Context);
        const { isSuperAdmin  } = userState;
        // console.log('userState inside authentication', userState)
        // console.log('adminOnly inside authentication', adminOnly)

        useEffect(() => {
        if (loading) return 

        else if (adminOnly && userState.role !== 'admin') {
            console.log('user is not admin, redirecting to homepage')
            navigate('/');
        } 
        else if (userState.uid && superAdminOnly && !isSuperAdmin) {
            console.log('user is not super admin, redirecting to homepage')
            navigate('/');
        }
        else if (!userState || !userState.uid) {
            console.log('no user logged in, redirecting to login page')
            navigate('/login');
        }
        }, [userState, loading, isSuperAdmin, navigate]);

        return <WrappedComponent {...props} />;
    };
};

export default withAuthentication;
