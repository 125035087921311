import { useState, useContext, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import { TextField, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import MainQuestions from "../../components/MainQuestions";
import withAuthentication from "../../withAuthentication";
import Loading from "../../components/Loading";
import { Tooltip } from 'react-tooltip'
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, HeadingLevel, AlignmentType } from "docx";

function Questions4() {
    // getting state from Context
    const { userState, updateData, loading } = useContext(Context);
    const { projects, currProjectId, role } = userState;

    // const { projects, setProjects, currProjectId } = useContext(Context)
    const [isSaving, setIsSaving] = useState(false)
    const [isModified, setIsModified] = useState(false);
    const [oldImprovedQ, setOldImprovedQ] = useState('')
    const [mainQ, setMainQ] = useState('')
    const [answers, setAnswers] = useState('')
    const [message, setMessage] = useState('')
    const [route, setRoute] = useState("");
    const [open, setOpen] = useState(false);
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const navigate = useNavigate()

    // styling for text fields
    const styles = {
        inputLabelProps: {
            shrink: true,
            style: {
                color: '#219F8E',
                fontWeight: 'bold',
                fontSize: 15
            }
        }, inputProps: {
            style: { fontSize: 14, size: "small" }
        }
    }
    // Dialog functions
    const handleClickOpen = (route) => {
        if (isModified || !answers.step1.FinalQ) {
            setOpen(true);
            setRoute(route);
        } else {
            navigate(route);
        }
    }

    const handleClose = (confirm) => {
        setOpen(false);
        if (confirm) {
            navigate(route);
        }
    }

    // load user's answers into form
    useEffect(() => {
        if (loading || !currProjectId || !projects || projects.length === 0) return

        const currProj = projects.find(project => { return project.id === currProjectId })
        // setDisabled(false)
        setIsModified(false)
        setAnswers(currProj)
        setOldImprovedQ(currProj.step1.ImprovedQ)
        setMainQ(currProj.step1.MainQ)

    }, [projects, loading, currProjectId])

    // update state of form as user types
    function handleChange(event) {
        setIsModified(true)
        const { name, value } = event.target

        setAnswers(prevQuestions => {
            return {
                ...prevQuestions,
                step1: {
                    ...prevQuestions.step1,
                    [name]: value
                }
            }
        })
    }

    const saveAnswers = async (event) => {
        event.preventDefault()
        // check that all filleds are filled out before saving:
        if (!answers.step1.FinalQ) {
            alert('Please enter your final question')
            return
        }

        const updatedAnswers = projects.map((project) => {
            return project.id === currProjectId ? {
                ...project,
                step1: {
                    ...project.step1,
                    FinalQ: answers.step1.FinalQ
                }
            } : project
        })

        setIsSaving(true)
        try {
            await updateData('projects', updatedAnswers)

            setTimeout(() => {
                setIsSaving(false)
                setMessage('Saved successfully')
            }, 2000)

            setTimeout(() => {
                setMessage('')
                setIsModified(false)
            }, 2500)

        } catch (error) {
            console.log(error)
            setMessage('Error saving data', error);
            setTimeout(() => {
                setMessage('');
            }, 2000);
        }
    }

    // download original question, improved question, and final question and 8 WH questions as pdf:
    function downloadPDF() {
        const doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true,
            compress: true,
            precision: 2,
            lineHeight: 1.3,
        })
        // Helper function to wrap text if it's too long
        function addWrappedText(text, x, y, maxWidth) {
            let splitText = doc.splitTextToSize(text, maxWidth);
            doc.text(splitText, x, y);
            return splitText.length; // Return number of lines
        }
        // content and formatting of the PDF document
        const maxWidth = doc.internal.pageSize.getWidth() - 20; // 10 units padding on each side 

        let currentY = 20;
        // pdf title
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.text(`Questions Tool Summary for question: ${mainQ}`,
            doc.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 10;
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.text(`${new Date().toLocaleDateString("en-GB", dateOptions)} `,
            doc.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 20;
        // content

        const mainQuestions = [
            'Original Question: ' + mainQ,
            'Improved Question: ' + oldImprovedQ,
            'Final Question: ' + answers.step1.FinalQ,
        ]
        mainQuestions.forEach(field => {
            let lines = addWrappedText(field, 10, currentY, maxWidth);
            currentY += lines * 7; // 7 units line height for font size 12
        });
        // add space between sections:
        currentY += 10;
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text('8WH Questions:', 10, currentY);
        currentY += 10;

        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        const eightQuestions = [
            'What (subject): ' + answers.step1.Q1,
            'When (timing): ' + answers.step1.Q2,
            'When (sequence): ' + answers.step1.Q3,
            'Who (person): ' + answers.step1.Q4,
            'Who (entity): ' + answers.step1.Q5,
            'Where (geography): ' + answers.step1.Q6,
            'Where (domain): ' + answers.step1.Q7,
            'Why (reason): ' + answers.step1.Q8,
        ];

        eightQuestions.forEach(field => {
            let lines = addWrappedText(field, 10, currentY, maxWidth);
            currentY += lines * 7; // 7 units line height for font size 12
        });
        currentY += 10;
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text('Zoom in + Zoom out questions', 10, currentY);
        currentY += 10;

        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        const zoomInQuestions = [
            'Zoom in - What (subject): ' + answers.step2.zoomInQuestions.zoomin1,
            'Zoom in - When (timing): ' + answers.step2.zoomInQuestions.zoomin2,
            'Zoom in - When (sequence): ' + answers.step2.zoomInQuestions.zoomin3,
            'Zoom in - Who (person): ' + answers.step2.zoomInQuestions.zoomin4,
            'Zoom in - Who (entity): ' + answers.step2.zoomInQuestions.zoomin5,
            'Zoom in - Where (geography): ' + answers.step2.zoomInQuestions.zoomin6,
            'Zoom in - Where (domain): ' + answers.step2.zoomInQuestions.zoomin7,
            'Zoom in - Why (reason): ' + answers.step2.zoomInQuestions.zoomin8,

        ];

        zoomInQuestions.forEach(field => {
            let lines = addWrappedText(field, 10, currentY, maxWidth);
            currentY += lines * 7; // 7 units line height for font size 12
        }
        );
        currentY += 10;
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        const zoomOutQuestions = [
            'Zoom out - What (subject): ' + answers.step2.zoomOutQuestions.zoomout1,
            'Zoom out - When (timing): ' + answers.step2.zoomOutQuestions.zoomout2,
            'Zoom out - When (sequence): ' + answers.step2.zoomOutQuestions.zoomout3,
            'Zoom out - Who (person): ' + answers.step2.zoomOutQuestions.zoomout4,
            'Zoom out - Who (entity): ' + answers.step2.zoomOutQuestions.zoomout5,
            'Zoom out - Where (geography): ' + answers.step2.zoomOutQuestions.zoomout6,
            'Zoom out - Where (domain): ' + answers.step2.zoomOutQuestions.zoomout7,
            'Zoom out - Why (reason): ' + answers.step2.zoomOutQuestions.zoomout8,
        ];

        zoomOutQuestions.forEach(field => {
            let lines = addWrappedText(field, 10, currentY, maxWidth);
            currentY += lines * 7; // 7 units line height for font size 12
        }
        );
        // save pdf
        doc.save(`QuestionsToolSummary_${answers.step1.FinalQ}.pdf`);

    }
    function downloadDOC() {
        // create a new document
        const doc = new Document({
            sections: [{
                children: [
                    new Paragraph({
                        text: `Questions Tool Summary for question: ${mainQ}`,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                        text: new Date().toLocaleDateString("en-GB", dateOptions),
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                        text: `Main Questions`,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Original Question: ' + mainQ,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Improved Question: ' + oldImprovedQ,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Final Question: ' + answers.step1.FinalQ,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: '8WH Questions:',
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'What (subject): ' + answers.step1.Q1,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'When (timing): ' + answers.step1.Q2,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'When (sequence): ' + answers.step1.Q3,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Who (person): ' + answers.step1.Q4,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Who (entity): ' + answers.step1.Q5,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Where (geography): ' + answers.step1.Q6,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Where (domain): ' + answers.step1.Q7,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Why (reason): ' + answers.step1.Q8,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),

                    new Paragraph({
                        text: 'Zoom in + Zoom out questions',
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        font: {
                            size: 14,
                            bold: true,
                        },
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom in - What (subject): ' + answers.step2.zoomInQuestions.zoomin1,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom in - When (timing): ' + answers.step2.zoomInQuestions.zoomin2,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom in - When (sequence): ' + answers.step2.zoomInQuestions.zoomin3,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom in - Who (person): ' + answers.step2.zoomInQuestions.zoomin4,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom in - Who (entity): ' + answers.step2.zoomInQuestions.zoomin5,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom in - Where (geography): ' + answers.step2.zoomInQuestions.zoomin6,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom in - Where (domain): ' + answers.step2.zoomInQuestions.zoomin7,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom in - Why (reason): ' + answers.step2.zoomInQuestions.zoomin8,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom out - What (subject): ' + answers.step2.zoomOutQuestions.zoomout1,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom out - When (timing): ' + answers.step2.zoomOutQuestions.zoomout2,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom out - When (sequence): ' + answers.step2.zoomOutQuestions.zoomout3,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom out - Who (person): ' + answers.step2.zoomOutQuestions.zoomout4,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom out - Who (entity): ' + answers.step2.zoomOutQuestions.zoomout5,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom out - Where (geography): ' + answers.step2.zoomOutQuestions.zoomout6,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom out - Where (domain): ' + answers.step2.zoomOutQuestions.zoomout7,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: 'Zoom out - Why (reason): ' + answers.step2.zoomOutQuestions.zoomout8,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),

                ],
            }]

        });

        // Used to export the file into a .docx file
        Packer.toBlob(doc)
            .then((blob) => {
                saveAs(blob, `Question Summary-${answers.step1.FinalQ}.docx`);
            })
            .catch((error) => {
                console.error("Error generating document: ", error);
            });
    }

    if (loading) return <Loading />

    return (
        <div >
            <div className='app-home-container'>
                <div className="app-title">
                    <h4>Step 3: results<i
                        data-tooltip-id="step3-tooltip"
                        position="right"
                        data-tooltip-content="Here are your final answers for the 8 WH questions. At the bottom, rewrite your original question with the added knowlegde you gathered."
                        className="fa fa-question-circle"
                        aria-hidden="true"></i>
                    </h4>
                    <Tooltip className="tooltip" id="step3-tooltip" />
                    <h6>Use the information you gathered to rewrite your original question</h6>
                </div>

                <div className="questions4-main-container">
                    <h4>Overview of your answers</h4>
                    <div style={{ marginBottom: '10px' }}>
                        <h5>1. Reminder: this was your orignal question</h5>
                    </div>
                    <div className="reminder-section questions4-section" >
                        "{mainQ}"
                    </div>

                    <div style={{ marginBottom: '10px' }}>
                        <h5 >2. Reminder: your final 8 WH questions</h5>
                    </div>
                    <div style={{ justifyContent: 'space-evenly' }}
                        className="reminder-section questions4-section">
                        <MainQuestions isDisabled={true} />
                    </div>
                    <div style={{ marginBottom: '10px' }}>
                        <h5>3. Reminder: your improved question</h5>
                    </div>
                    <div className="reminder-section questions4-section">
                        "{oldImprovedQ}"
                    </div>
                    <h4>
                        Final question
                    </h4>
                    <div style={{ marginBottom: '10px' }}>
                        <h5>Now rewrite your question based on your 8 final WH answers. You can also add any other information you learned about your topic.</h5>
                    </div>

                    <div style={{ width: '100%' }} className="reminder-section questions4-section">
                        <TextField
                            name="FinalQ"
                            variant="outlined"
                            value={answers ? answers.step1?.FinalQ : ''}
                            onChange={handleChange}
                            multiline
                            fullWidth
                            rows={4}
                            inputProps={styles.inputProps}
                            InputLabelProps={styles.inputLabelProps}
                        />
                    </div>

                </div>
                <div className="bottomNavigation">
                    <button
                        style={{ width: '200px'}}
                        className="saveAnswersBtn"
                        disabled={isSaving}
                        onClick={saveAnswers}>Save final question
                    </button>
                    <div className="successMessage">
                        {message ? message : ''}
                        {isSaving && (
                            <div>
                                <i className="fas fa-spinner fa-spin"></i> Saving...
                            </div>
                        )}
                    </div>

                    {role && role === 'admin' &&
                        <div>
                            <p>Download your interview conclusion as a PDF or DOC file</p>
                            <div style={{ display: 'flex' }} >
                                <button
                                    style={{ width: '200px', margin: '10px' }}
                                    onClick={downloadDOC}
                                >Download as DOC
                                </button>
                                <button
                                    style={{ width: '200px', margin: '10px' }}
                                    onClick={downloadPDF}
                                >Download as PDF
                                </button>
                            </div>
                            <button style={{ width: '200px' }} onClick={(e) => window.print(e)}>Print whole page</button>

                        </div>
                    }

                </div>

                <Dialog
                    open={open}
                    onClose={() => handleClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className="dialog-title" id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
                    <DialogContent className="dialog-content">
                        <DialogContentText id="alert-dialog-description">
                            You have unsaved changes or you haven't filled out the final answer. <br></br>Are you sure you want to leave this page?
                        </DialogContentText>
                    </DialogContent>
                    <div className="dialog-actions">
                        <button style={{ backgroundColor: 'grey' }} className="dialog-button" onClick={() => handleClose(false)}>Cancel</button>
                        <button className="dialog-button" onClick={() => handleClose(true)} autoFocus>Yes</button>
                    </div>
                </Dialog>

                <div className='pagination'>
                    <button onClick={() => handleClickOpen("/QuestionsApp/Questions3")}>Back to step 2</button>
                    {/* <button onClick={() => handleClickOpen("/")}>Back to homepage</button> */}
                </div>

            </div>
        </div >
    );

}

export default withAuthentication(Questions4);
