import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import Loading from '../../components/Loading';
import withAuthentication from "../../withAuthentication";
// import { Tooltip } from 'react-tooltip'

import { TextField } from "@mui/material"


function Reports2() {
    const { userState, updateData, loading } = useContext(Context);
    const { reports, currReportId } = userState;

    const navigate = useNavigate();

    const [currReport, setCurrReport] = useState('')
    const [step1Fields, setStep1Fields] = useState([])
    const [step2Fields, setStep2Fields] = useState([])
    const [step3Fields, setStep3Fields] = useState([])


    console.log(reports, 'reports')
    // console.log(currReport, 'currReport')
    // console.log(currReportId, 'currReportId')

    useEffect(() => {
        if (loading || !reports || reports.length === 0) return
        const report = reports.find(report => report.reportId === currReportId)
        setCurrReport(report)
    }, [reports, loading, currReportId])

    // load steps data from the report object and display it in the reports page
    useEffect(() => {
        if (loading || !currReport) return

        const defaultFields = (num) => [...Array(5)].map((_, i) => ({ id: i + 1, value: '' }));

        const loadSteps = (steps, defaultSteps) => {
            return steps.length < 5 ? [...steps, ...defaultSteps.slice(steps.length)] : steps;
        }

        const step1 = currReport.step1
            ? currReport.step1.map((step) => ({ id: step.id, value: step.value }))
            : defaultFields();
        setStep1Fields(loadSteps(step1, defaultFields()));

        const step2 = currReport.step2
            ? currReport.step2.map((step) => ({ id: step.id, value: step.value }))
            : defaultFields();
        setStep2Fields(loadSteps(step2, defaultFields()));

        const step3 = currReport.step3
            ? currReport.step3.map((step) => ({ id: step.id, value: step.value }))
            : defaultFields();
        setStep3Fields(loadSteps(step3, defaultFields()));

    }, [currReport, loading])


    const addInputBox = () => {
        // max 9 input boxes:
        if (step1Fields.length >= 9) return
        const newId = step1Fields.length > 0 ? step1Fields[step1Fields.length - 1].id + 1 : 1
        const newFields = [...step1Fields, { id: newId, value: '' }]
        console.log(newFields, 'newFields')
        setStep1Fields(newFields)
    }

    const addInputBox2 = () => {
        // max 9 input boxes:
        if (step2Fields.length >= 9) return
        const newId = step2Fields.length > 0 ? step2Fields[step2Fields.length - 1].id + 1 : 1
        const newFields = [...step2Fields, { id: newId, value: '' }]
        setStep2Fields(newFields)
    }

    const addInputBox3 = () => {
        // max 9 input boxes:
        if (step3Fields.length >= 9) return
        const newId = step3Fields.length > 0 ? step3Fields[step3Fields.length - 1].id + 1 : 1
        const newFields = [...step3Fields, { id: newId, value: '' }]
        setStep3Fields(newFields)
    }

    const saveStep2 = async () => {
        if (!currReport) return
        const newReport = {
            ...currReport,
            step1: step1Fields,
            step2: step2Fields,
            step3: step3Fields,
        }
        await updateData('reports', reports.map(report => report.reportId === currReportId ? newReport : report))
        navigate('/ReportsApp/Reports3')
    }


    if (loading) return <Loading />;
    return (
        <div className="app-home-container">
            <div className="app-title">
                <h4>Reports: step 2</h4>
                <h6>What did we search and how</h6>
                <div style={{ margin: '20px 0px' }}>
                    <p><strong>Report name: "{currReport && currReport.title}"</strong></p>
                </div>
            </div>

            <div className="reports-main-container" >
                <div className="element-title">
                    <p> <strong>1. Process:</strong> follow chronology either the "info" or the "money" (max. 9)</p>
                </div>
                <div className="reports-row-fields element-body">
                    {step1Fields.map((field, index) => {
                        return (
                            <TextField
                                key={field.id}
                                fullWidth
                                value={field.value || ''}
                                label={`Phase ${index + 1}`}
                                multiline
                                sx={{ width: '15ch', margin: '5px' }}
                                rows={4}
                                // placeholder={`Enter phase ${index + 1} details`}
                                onChange={(e) => {
                                    const newFields = step1Fields.map((f) => {
                                        if (f.id === field.id) {
                                            return {
                                                ...f,
                                                value: e.target.value
                                            }
                                        }
                                        return f
                                    })
                                    setStep1Fields(newFields)
                                }}
                            >
                            </TextField>
                        )
                    })}
                    <button disabled={step1Fields.length === 9} style={{ width: '10vw' }} onClick={() => addInputBox()}>Add phase</button>
                </div>

                <div className="element-title">
                    <p><strong>2. Vulnerabilities:</strong> focus of the connectors (max. 9)</p>
                </div>
                <div className="reports-row-fields element-body">
                    {step2Fields.map((field, index) => {
                        return (
                            <TextField
                                key={field.id}
                                fullWidth
                                value={field.value || ''}
                                label={`Vu ${index + 1}`}
                                sx={{ width: '15ch', margin: '5px' }}
                                // placeholder={`Enter vulnerability ${index + 1} details`}
                                onChange={(e) => {
                                    const newFields = step2Fields.map((f) => {
                                        if (f.id === field.id) {
                                            return {
                                                ...f,
                                                value: e.target.value
                                            }
                                        }
                                        return f
                                    })
                                    setStep2Fields(newFields)
                                }}
                            >
                            </TextField>
                        )
                    })}
                    <button disabled={step2Fields.length === 9} style={{ width: '10vw' }} onClick={() => addInputBox2()}>Add vulnerability</button>
                </div>

                <div className="element-title">
                    <p><strong>3. TIKOs:</strong> "humanize" the connectors & search TIKOs (max. 9)</p>
                </div>
                <div className="reports-row-fields element-body">
                    {step3Fields.map((field, index) => {
                        return (
                            <TextField
                                key={field.id}
                                fullWidth
                                value={field.value || ''}
                                label={`TIKO ${index + 1}`}
                                sx={{ width: '15ch', margin: '5px' }}
                                // placeholder={`Enter conclusion ${index + 1} details`}
                                onChange={(e) => {
                                    const newFields = step3Fields.map((f) => {
                                        if (f.id === field.id) {
                                            return {
                                                ...f,
                                                value: e.target.value
                                            }
                                        }
                                        return f
                                    })
                                    setStep3Fields(newFields)
                                }}
                            >
                            </TextField>
                        )
                    })}
                    <button
                        disabled={step3Fields.length === 9}
                        style={{ width: '10vw' }}
                        onClick={() => addInputBox3()}>
                        Add TIKO
                    </button>
                </div>

            </div>


            <div className="pagination">
                <button onClick={() => navigate('/ReportsApp/Reports1')}>Back to step 1</button>
                <button onClick={() => saveStep2()}>Save and move to next step</button>
            </div>
        </div>
    );
}

export default withAuthentication(Reports2);
