import { useState, useContext, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import { Box, TextField, Modal, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import Loading from "../../components/Loading";
import withAuthentication from "../../withAuthentication";
import { Tooltip } from 'react-tooltip'

function Questions3() {
    const { userState, updateData, loading, error } = useContext(Context);
    const { projects, currProjectId, role } = userState;

    const [ImprovedQ, setImprovedQ] = useState('')
    const [isDisabled, setDisabled] = useState(false)
    const [isModified, setIsModified] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [message, setMessage] = useState('')
    const [formErrors, setFormErrors] = useState({});

    const navigate = useNavigate()
    const [answers, setAnswers] = useState(() => {
        return {
            step1: {
                MainQ: '',
                Q1: '',
                Q2: '',
                Q3: '',
                Q4: '',
                Q5: '',
                Q6: '',
                Q7: '',
                Q8: '',
                ImprovedQ: '',
                FinalQ: '',
            },
            step2: {
                zoomOutQuestions: {
                    zoomout1: '',
                    zoomout2: '',
                    zoomout3: '',
                    zoomout4: '',
                    zoomout5: '',
                    zoomout6: '',
                    zoomout7: '',
                    zoomout8: '',
                },
                zoomInQuestions: {
                    zoomin1: '',
                    zoomin2: '',
                    zoomin3: '',
                    zoomin4: '',
                    zoomin5: '',
                    zoomin6: '',
                    zoomin7: '',
                    zoomin8: '',
                },
            },
        };
    });

    // set styles for text fields
    const styles = useMemo(() => {
        return {
            inputLabelProps: {
                shrink: true,
                style: {
                    color: '#f26a34',
                    fontSize: 15
                }
            },
            inputProps: {
                style: { fontSize: 15 }
            }
        };
    }, []);


    const eightQsTitlesStyle = {
        fontSize: 15,
        fontWeight: 'bold',
        color: '#219F8E',
        textAlign: 'left',
        marginLeft: 10,
        marginBottom: 5,
    }

    // Modal
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    // modal styles
    const modalStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '60vw',
        height: '50vh',
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 8,
    };

    // Dialog
    const [route, setRoute] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    const handleDialogOpen = (route) => {
        if (isModified) {
            setOpenDialog(true);
            setRoute(route);
        } else {
            navigate(route);
        }
    }

    const handleDialogClose = (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            navigate(route);
        }
    }

    // load user's answers from previous step
    useEffect(() => {
        if (loading || !projects || projects.length === 0) return
        const currProj = projects.find(project => project.id === currProjectId)
        if (!currProj) return
        setAnswers(currProj)
        setImprovedQ(currProj.step1.ImprovedQ)
        setIsModified(false)

    }, [projects, currProjectId, loading])

    // update state of form as user types answers
    function handleChange(event) {
        const { name, value } = event.target
        let group
        if (name.startsWith('zoomout')) {
            group = 'zoomOutQuestions'
        } else if (name.startsWith('zoomin')) {
            group = 'zoomInQuestions'
        } else {
            group = null
        }

        setIsModified(true)
        setDisabled(true)
        setMessage('')

        if (group) {
            setAnswers(prevAnswers => ({
                ...prevAnswers,
                step2: {
                    ...prevAnswers.step2,
                    [group]: {
                        ...prevAnswers.step2[group],
                        [name]: value
                    }
                }
            }))
        } else {
            setAnswers(prevAnswers => {
                return {
                    ...prevAnswers,
                    step1: {
                        ...prevAnswers.step1,
                        [name]: value
                    }
                }
            })
        }

        if (formErrors?.step2?.[group]?.[name]) {
            const updatedErrors = { ...formErrors };
            delete updatedErrors.step2[group][name];
            setFormErrors(updatedErrors);
        }
    }

    function saveAnswers(event) {
        event.preventDefault()
        let errors = {};
        const mainQuestions = [answers.step1.Q1, answers.step1.Q2, answers.step1.Q3, answers.step1.Q4, answers.step1.Q5, answers.step1.Q6, answers.step1.Q7, answers.step1.Q8]
        const zoomOutQuestions = answers.step2.zoomOutQuestions
        const zoomInQuestions = answers.step2.zoomInQuestions

        // for (let key in mainQuestions) {
        //     if (mainQuestions[key] === '') {
        //         errors = {
        //             ...errors,
        //             step1: {
        //                 ...errors.step1,
        //                 [key]: true
        //             }
        //         }
        //     }
        // }

        // for (let key in zoomOutQuestions) {
        //     if (zoomOutQuestions[key] === '') {
        //         errors = {
        //             ...errors,
        //             step2: {
        //                 ...errors.step2,
        //                 zoomOutQuestions: {
        //                     ...errors.step2?.zoomOutQuestions,
        //                     [key]: true
        //                 }
        //             }
        //         }
        //     }
        // }

        // for (let key in zoomInQuestions) {
        //     if (zoomInQuestions[key] === '') {
        //         errors = {
        //             ...errors,
        //             step2: {
        //                 ...errors.step2,
        //                 zoomInQuestions: {
        //                     ...errors.step2?.zoomInQuestions,
        //                     [key]: true
        //                 }
        //             }
        //         }
        //     }
        // }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            setMessage('Please fill in all fields before saving');
            console.log('errors', errors)
            return;
        }

        // update db
        const updatedAnswers = projects.map((project) => {
            const eightQuestions = answers.step1
            return project.id === currProjectId ? {
                ...project,
                step1: eightQuestions,
                step2: {
                    ...project.step2,
                    zoomOutQuestions,
                    zoomInQuestions
                }
            }
                : project
        });

        setIsSaving(true)
        try {
            updateData('projects', (updatedAnswers))


            setTimeout(() => {
                setIsSaving(false)
                setMessage('Saved successfully')
            }, 2000)

            setTimeout(() => {
                setMessage('')
                setIsModified(false)
                setDisabled(false)
            }, 2500)


        } catch (err) {
            console.log(error)
            setMessage('Error saving data', error);
            setTimeout(() => {
                setMessage('');
            }, 2000);
        }
    }

    if (loading) return <Loading />

    return (
        <div >
            <div className='app-home-container'>
                <div className="app-title">
                    <h4 > Step 2: zoom-in / zoom-out<i
                        data-tooltip-id="step2-tooltip"
                        position="right"
                        data-tooltip-content="For each WH question from your main questions, add a zoom-out and a zoom-in questions"
                        className="fa fa-question-circle"
                        aria-hidden="true"></i>
                        <Tooltip className="tooltip" id="step2-tooltip" />
                    </h4>
                </div>

                <div className="questions3-main-container">
                    <h5>Reminder: your improved question</h5>
                    <div className="reminder-section questions3-section">
                        "{ImprovedQ}"
                    </div>

                    <div className="element-title">
                        <h5 >Zoom-out questions
                            <i
                                data-tooltip-id="Zoom-out-tooltip"
                                position="right"
                                data-tooltip-content="For each WH question, add a zoom-out question"
                                className="fa fa-question-circle"
                                aria-hidden="true"></i>
                            <Tooltip className="tooltip" id="Zoom-out-tooltip" />
                        </h5>
                    </div>
                    <div className='element-body questions3-section'>
                        <TextField
                            className="TextField"
                            name="zoomout1"
                            value={answers.step2.zoomOutQuestions.zoomout1 || ''}
                            onChange={handleChange}
                            label="What"
                            multiline
                            rows={5}
                            sx={{ width: '14ch' }}
                            inputProps={{
                                tabIndex: 1,
                                autoFocus: true,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomOutQuestions?.zoomout1 || false}
                        />
                        <TextField
                            className="TextField"
                            name="zoomout2"
                            value={answers.step2.zoomOutQuestions.zoomout2 || ''}
                            onChange={handleChange}
                            label="When"
                            multiline
                            sx={{ width: '14ch' }}
                            rows={5}
                            inputProps={{
                                tabIndex: 4,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomOutQuestions?.zoomout2 || false}
                        />
                        <TextField
                            className="TextField"
                            name="zoomout3"
                            value={answers.step2.zoomOutQuestions.zoomout3 || ''}
                            onChange={handleChange}
                            label="When"
                            multiline

                            sx={{ width: '14ch' }}
                            rows={5}

                            inputProps={{
                                tabIndex: 7,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomOutQuestions?.zoomout3 || false}
                        />
                        <TextField
                            className="TextField"
                            name="zoomout4"
                            value={answers.step2.zoomOutQuestions.zoomout4 || ''}
                            onChange={handleChange}
                            label="Who"
                            multiline

                            sx={{ width: '14ch' }}
                            rows={5}

                            inputProps={{
                                tabIndex: 10,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomOutQuestions?.zoomout4 || false}
                        />
                        <TextField
                            className="TextField"
                            name="zoomout5"
                            value={answers.step2.zoomOutQuestions.zoomout5 || ''}
                            onChange={handleChange}
                            label="Who"
                            multiline

                            sx={{ width: '14ch' }}
                            rows={5}

                            inputProps={{
                                tabIndex: 13,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomOutQuestions?.zoomout5 || false}
                        />
                        <TextField
                            className="TextField"
                            name="zoomout6"
                            value={answers.step2.zoomOutQuestions.zoomout6 || ''}
                            onChange={handleChange}
                            label="Where"
                            multiline

                            sx={{ width: '14ch' }}
                            rows={5}

                            inputProps={{
                                tabIndex: 16,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomOutQuestions?.zoomout6 || false}
                        />
                        <TextField
                            className="TextField"
                            name="zoomout7"
                            value={answers.step2.zoomOutQuestions.zoomout7 || ''}
                            onChange={handleChange}
                            label="Where"
                            multiline

                            sx={{ width: '14ch' }}
                            rows={5}

                            inputProps={{
                                tabIndex: 19,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomOutQuestions?.zoomout7 || false}
                        />
                        <TextField
                            className="TextField"
                            name="zoomout8"
                            value={answers.step2.zoomOutQuestions.zoomout8 || ''}
                            onChange={handleChange}
                            label="Why"
                            multiline

                            sx={{ width: '14ch' }}
                            rows={5}

                            inputProps={{
                                tabIndex: 22,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomOutQuestions?.zoomout8 || false}
                        />

                    </div>

                    <div className="element-title">
                        <h5>Your 8 WH questions
                            <i
                                data-tooltip-id="8q-tooltip"
                                position="right"
                                data-tooltip-content="These are the 8 WH questions you filled in previously"
                                className="fa fa-question-circle"
                                aria-hidden="true"></i>
                            <Tooltip className="tooltip" id="8q-tooltip" />
                        </h5>
                    </div>
                    <div className="element-body questions3-section">
                        <div >
                            <div className="eightQsTitles" style={eightQsTitlesStyle}>What?</div>
                            <TextField
                                className="TextField"
                                name="Q1"
                                value={answers.step1.Q1 || ''}
                                onChange={handleChange}
                                label="Subject"
                                multiline

                                sx={{ width: '14ch' }}
                                rows={7}

                                placeholder="Example: finding out if the company has CRM and are they happy with it"
                                helperText="Your main goal"
                                inputProps={{
                                    tabIndex: 2,
                                    ...styles.inputProps
                                }}
                                InputLabelProps={styles.inputLabelProps}
                                error={formErrors.Q1}
                            />
                        </div>
                        <div >
                            <p className="eightQsTitles" style={eightQsTitlesStyle}>When?</p>
                            <TextField
                                className="TextField"
                                name="Q2"
                                value={answers.step1.Q2 || ''}
                                onChange={handleChange}
                                label="Timing"
                                multiline

                                sx={{ width: '14ch' }}
                                rows={7}

                                placeholder="Example: this year"
                                helperText="The relevant timing"
                                inputProps={{
                                    tabIndex: 5,
                                    ...styles.inputProps
                                }}
                                InputLabelProps={styles.inputLabelProps}
                                error={formErrors.Q2}
                            />
                        </div>
                        <div >
                            <p className="eightQsTitles" style={eightQsTitlesStyle}>When?</p>
                            <TextField
                                className="TextField"
                                name="Q3"
                                value={answers.step1.Q3 || ''}
                                onChange={handleChange}
                                label="Sequence"
                                multiline

                                sx={{ width: '14ch' }}
                                rows={7}

                                placeholder="Example: this month, because it's the end of the quarter"
                                helperText="Think about relevent 'before...' or 'after... '"
                                inputProps={{
                                    tabIndex: 8,
                                    ...styles.inputProps
                                }}
                                InputLabelProps={styles.inputLabelProps}
                                error={formErrors.Q3}
                            />
                        </div>
                        <div >
                            <p className="eightQsTitles" style={eightQsTitlesStyle}>Who?</p>
                            <TextField
                                className="TextField"
                                name="Q4"
                                value={answers.step1.Q4 || ''}
                                onChange={handleChange}
                                label="Person"
                                multiline

                                sx={{ width: '14ch' }}
                                rows={7}

                                placeholder="Example: head of IT"
                                helperText="The relevant person"
                                inputProps={{
                                    tabIndex: 11,
                                    ...styles.inputProps
                                }}
                                InputLabelProps={styles.inputLabelProps}
                                error={formErrors.Q4}
                            />
                        </div>
                        <div >
                            <p className="eightQsTitles" style={eightQsTitlesStyle}>Who?</p>
                            <TextField
                                className="TextField"
                                name="Q5"
                                value={answers.step1.Q5 || ''}
                                onChange={handleChange}
                                label="Entity"
                                multiline

                                sx={{ width: '14ch' }}
                                rows={7}

                                placeholder="Example: IT"
                                helperText="The entity involved"
                                inputProps={{
                                    tabIndex: 14,
                                    ...styles.inputProps
                                }}
                                InputLabelProps={styles.inputLabelProps}
                                error={formErrors.Q5}
                            />
                        </div>
                        <div >
                            <p className="eightQsTitles" style={eightQsTitlesStyle}>Where?</p>
                            <TextField
                                className="TextField"
                                name="Q6"
                                value={answers.step1.Q6 || ''}
                                onChange={handleChange}
                                label="Geography"
                                multiline

                                sx={{ width: '14ch' }}
                                rows={7}

                                placeholder='Example: London'
                                helperText="The relevant location"
                                inputProps={{
                                    tabIndex: 17,
                                    ...styles.inputProps
                                }}
                                InputLabelProps={styles.inputLabelProps}
                                error={formErrors.Q6}
                            />
                        </div>
                        <div >
                            <p className="eightQsTitles" style={eightQsTitlesStyle}>Where?</p>
                            <TextField
                                className="TextField"
                                name="Q7"
                                value={answers.step1.Q7 || ''}
                                onChange={handleChange}
                                label="Domain"
                                multiline

                                sx={{ width: '14ch' }}
                                rows={7}

                                placeholder='Example: all company branches'
                                helperText="Related domain (e.g HR, IT)"
                                inputProps={{
                                    tabIndex: 20,
                                    ...styles.inputProps
                                }}
                                InputLabelProps={styles.inputLabelProps}
                                error={formErrors.Q7}
                            />
                        </div>
                        <div >
                            <p className="eightQsTitles" style={eightQsTitlesStyle}>Why?</p>
                            <TextField
                                className="TextField"
                                name="Q8"
                                label="Reason"
                                value={answers.step1.Q8 || ''}
                                onChange={handleChange}
                                multiline

                                sx={{ width: '14ch' }}
                                rows={7}

                                placeholder="Example: to gain new customers"
                                helperText="Why you care about this objective"
                                inputProps={{
                                    tabIndex: 23,
                                    ...styles.inputProps
                                }}
                                InputLabelProps={styles.inputLabelProps}
                                error={formErrors.Q8}
                            />
                        </div>

                    </div>

                    <div className="element-title">
                        <h5>Zoom-in questions
                            <i
                                data-tooltip-id="zoom-in-tooltip"
                                position="right"
                                data-tooltip-content="For each WH question, add a zoom-in question"
                                className="fa fa-question-circle"
                                aria-hidden="true"></i>
                            <Tooltip className="tooltip" id="zoom-in-tooltip" />
                        </h5>
                    </div>
                    <div className='element-body questions3-section'>
                        <TextField
                            className="TextField"
                            name="zoomin1"
                            value={answers.step2.zoomInQuestions.zoomin1 || ''}
                            onChange={handleChange}
                            label="What"
                            multiline

                            sx={{ width: '14ch' }}
                            rows={5}

                            inputProps={{
                                tabIndex: 3,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomInQuestions?.zoomin1 || false}
                        />
                        <TextField
                            className="TextField"
                            name="zoomin2"
                            value={answers.step2.zoomInQuestions.zoomin2 || ''}
                            onChange={handleChange}
                            label="When"
                            multiline

                            sx={{ width: '14ch' }}
                            rows={5}

                            inputProps={{
                                tabIndex: 6,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomInQuestions?.zoomin2 || false}
                        />
                        <TextField
                            className="TextField"
                            name="zoomin3"
                            value={answers.step2.zoomInQuestions.zoomin3 || ''}
                            onChange={handleChange}
                            label="When"
                            multiline

                            sx={{ width: '14ch' }}
                            rows={5}

                            inputProps={{
                                tabIndex: 9,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomInQuestions?.zoomin3 || false}
                        />
                        <TextField
                            className="TextField"
                            name="zoomin4"
                            value={answers.step2.zoomInQuestions.zoomin4 || ''}
                            onChange={handleChange}
                            label="Who"
                            multiline

                            sx={{ width: '14ch' }}
                            rows={5}

                            inputProps={{
                                tabIndex: 12,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomInQuestions?.zoomin4 || false}
                        />
                        <TextField
                            className="TextField"
                            name="zoomin5"
                            value={answers.step2.zoomInQuestions.zoomin5 || ''}
                            onChange={handleChange}
                            label="Who"
                            multiline

                            sx={{ width: '14ch' }}
                            rows={5}

                            inputProps={{
                                tabIndex: 15,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomInQuestions?.zoomin5 || false}
                        />
                        <TextField
                            className="TextField"
                            name="zoomin6"
                            value={answers.step2.zoomInQuestions.zoomin6 || ''}
                            onChange={handleChange}
                            label="Where"
                            multiline

                            sx={{ width: '14ch' }}
                            rows={5}

                            inputProps={{
                                tabIndex: 18,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomInQuestions?.zoomin6 || false}
                        />
                        <TextField
                            className="TextField"
                            name="zoomin7"
                            value={answers.step2.zoomInQuestions.zoomin7 || ''}
                            onChange={handleChange}
                            label="Where"
                            multiline

                            sx={{ width: '14ch' }}
                            rows={5}

                            inputProps={{
                                tabIndex: 21,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomInQuestions?.zoomin7 || false}
                        />
                        <TextField
                            className="TextField"
                            name="zoomin8"
                            value={answers.step2.zoomInQuestions.zoomin8 || ''}
                            onChange={handleChange}
                            label="Why"
                            multiline

                            sx={{ width: '14ch' }}
                            rows={5}

                            inputProps={{
                                tabIndex: 24,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors?.step2?.zoomInQuestions?.zoomin8 || false}
                        />

                    </div>

                    <div className="bottomNavigation">
                        <p >Don't forget to save your answers</p>
                        <button
                            style={{ width: '200px' }}
                            onClick={saveAnswers}
                            disabled={isSaving}
                            className='saveAnswersBtn'>Save Answers
                        </button>
                        {role === 'admin' && <button style={{ width: '200px' }} onClick={(e) => window.print(e)}>Print / Download page</button>
                        }

                        <div className="successMessage">
                            {message ? message : ''}
                            {isSaving && (
                                <div>
                                    <i className="fas fa-spinner fa-spin"></i> Saving...
                                </div>
                            )}
                        </div>

                    </div>
                </div>

                <Dialog
                    open={openDialog}
                    onClose={() => handleDialogClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className="dialog-title" id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
                    <DialogContent className="dialog-content">
                        <DialogContentText id="alert-dialog-description">
                            You have unsaved changes. Are you sure you want to leave this page?
                        </DialogContentText>
                    </DialogContent>
                    <div className="dialog-actions">
                        <button style={{ backgroundColor: 'grey' }} className="dialog-button" onClick={() => handleDialogClose(false)}>Cancel</button>
                        <button className="dialog-button" onClick={() => handleDialogClose(true)} autoFocus>Yes</button>
                    </div>
                </Dialog>

                <div className='pagination'>
                    <button onClick={() => handleDialogOpen("/QuestionsApp/Questions2")}>Back to step 1</button>

                    <div >
                        <button disabled={isDisabled || isModified} onClick={handleOpen}>Next Step</button>
                        <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                        >
                            <Box sx={modalStyle}>
                                <h4 id="modal-modal-title" >Improve your 8 WH questions</h4>
                                <p id="modal-modal-description" >
                                    Before moving on to the next and final step, you should make sure your 8 WH questions are ready. </p>
                                <p>Take a moment to <strong>review the zoom-in and zoom-out sections for each question.</strong>
                                    <strong> See what information you can take from it and add to its respective main question </strong> that will improve it.
                                </p>
                                <h5 id="modal-modal-description" style={{ marginTop: '30px' }}>
                                    Are you ready to proceed to the next step?
                                </h5>
                                <div style={{ marginTop: '10px', display: 'flex', width: '100%', justifyContent: 'center', gap: '30px' }} className="modalBtns">
                                    <button onClick={handleClose}>No, I want to review my 8 WH questions</button>
                                    <button onClick={() => navigate("/QuestionsApp/Questions4")}>Yes, I'm ready to move to the next step</button>
                                </div>
                            </Box>
                        </Modal>
                    </div>
                </div>

            </div>
        </div >
    );

}

export default withAuthentication(Questions3);