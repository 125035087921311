import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import Loading from '../../components/Loading';
import withAuthentication from "../../withAuthentication";

// MUI Components
import { TextField, Modal, Box, Typography, Checkbox, FormControlLabel, Select, MenuItem, FormControl, InputLabel, Accordion, AccordionSummary, AccordionDetails, Button } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

function Reports4() {
    const { userState, updateData, loading } = useContext(Context);
    const { reports, currReportId } = userState;

    const navigate = useNavigate();
    const [currReport, setCurrReport] = useState('')
    const [currBullets, setCurrBullets] = useState([])

    // console.log(reports, 'reports')
    // console.log(currReport, 'currReport')
    // console.log(currBullets, 'currBullets')

    // setting the current report to the report with the current report id + including the bullets
    useEffect(() => {
        if (loading || !reports || reports.length === 0) return
        const report = reports.find(report => report.reportId === currReportId)
        setCurrReport(report)

        // set currBullets to the bullets in the report
        const reportBullets = report.bullets // array of bullet objects
        setCurrBullets(reportBullets)

    }, [reports, loading, currReportId])


    // Modal
    const [modalOpen, setModalOpen] = useState(false);
    const [checklist, setChecklist] = useState([
        { label: 'Are your bullets concise?', checked: false },
        { label: 'Did you define your target group or audience?', checked: false },
        { label: 'Did you prioritize your key points?', checked: false },
        { label: 'Did you Limit each bullet to one idea?', checked: false },
        { label: 'Did you include validation levels (V1,V2,V3.....)', checked: false },
        { label: 'Did you avoid jargon, TINO terms which they might not understand?', checked: false },
        { label: 'Did you highlight keywords?', checked: false },
        { label: 'Did you check if you need to add context bullet point which without your main might be unclear?', checked: false },
        { label: 'Did you bullet points are easily readable from a distance?', checked: false },
    ]);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    const handleCheck = (index) => {
        setChecklist(checklist.map((item, i) => ({
            ...item,
            checked: index === i ? !item.checked : item.checked
        })));
    };
    const allChecked = checklist.every(item => item.checked);

    // Modal style
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 600,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    // Function to save the current step
    const saveStep4 = async () => {
        if (loading || !currBullets || currBullets.length === 0) return
        const newReport = {
            ...currReport,
            bullets: currBullets,
        }
        await updateData('reports', reports.map(report => report.reportId === currReportId ? newReport : report))
        // close the modal
        handleModalClose()
        // navigate to homepage
        navigate('/')
    }


    if (loading) return <Loading />;
    return (
        <div className="app-home-container">
            <div className="app-title">
                <h4>Reports 4: Bullets Summary</h4>
                <h6>Overview of all your bullets</h6>
            </div>

            <div className="reports-main-container" >
                <div className="reports-container">
                    {currReport && currReport.bullets && currReport.bullets.map((bullet, index) => (
                        <Accordion
                            key={bullet.id}
                            defaultExpanded={index === 0}
                            sx={{
                                width: '100%',
                                margin: '15px 0px',
                                borderRadius: '10px !important',
                                boxShadow: 'none',
                                border: 'none',
                                '&:before': {
                                    height: '0px',
                                    content: 'none',
                                }
                            }}
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id={`panel${index}-header`}
                            >
                                <div>
                                    <h6>Bullet {index + 1}: {bullet.bulletTitle}</h6>
                                    <p style={{ color: 'var(--grey-icons)' }}>{bullet.bulletDate}</p>
                                </div>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div className='element-body' style={{ borderRadius: '10px', border: '0.5px solid var(--grey-icons)' }}>
                                    <TextField
                                        label="Bullet Title"
                                        id={`bullet-${index}-title`}
                                        value={bullet.bulletTitle || ''}
                                        variant="outlined"
                                        sx={{ margin: '10px 0px' }}
                                        fullWidth
                                        InputLabelProps={{ shrink: true, style: {fontSize: '18px'} } }
                                        inputProps={{ readOnly: true }}
                                        disabled
                                    />

                                    <TextField
                                        label="Bullet Date"
                                        id={`bullet-${index}-date`}
                                        type='date'
                                        value={bullet.bulletDate || ''}
                                        variant="outlined"
                                        sx={{ margin: '10px 0px' }}
                                        fullWidth
                                        InputLabelProps={{ shrink: true, style: {fontSize: '18px'} } }
                                        inputProps={{ readOnly: true }}
                                        disabled
                                    />
                                    <TextField
                                        label="Bullet Content"
                                        id={`bullet-${index}-content`}
                                        variant="outlined"
                                        sx={{ margin: '10px 0px' }}
                                        value={bullet.bulletContent || ''}
                                        fullWidth
                                        multiline
                                        rows={5}
                                        InputLabelProps={{ shrink: true, style: {fontSize: '18px'} } }
                                        inputProps={{ readOnly: true }}
                                        disabled
                                    />
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </div>
            </div>
            <div>
                <Modal open={modalOpen} onClose={handleModalClose}>
                    <Box sx={modalStyle} >
                        <Typography variant="h6" component="h2" style={{ marginBottom: '10px' }}>
                            Reports 4: Final Checklist
                        </Typography>
                        {checklist.map((item, index) => (
                            <FormControlLabel
                                style={{ display: 'flex', textAlign: 'left', justifyContent: 'space-between' }}
                                key={index}
                                control={<Checkbox checked={item.checked} onChange={() => handleCheck(index)} />}
                                label={item.label}
                                labelPlacement="start"
                            />
                        ))}
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 4 }}>
                            <button onClick={handleModalClose} color="secondary">
                                Back to Page
                            </button>
                            <button
                                onClick={saveStep4}
                                color="primary"
                                variant="contained"
                                disabled={!allChecked}
                            >
                                Save and Finish
                            </button>
                        </Box>
                    </Box>
                </Modal>

            </div>
            <div className="pagination">
                <button onClick={() => navigate('/ReportsApp/Reports3')}>Back to Previous Step</button>
                <button onClick={handleModalOpen}>Save and Finish</button>
            </div>
        </div>
    );
}

export default withAuthentication(Reports4);
