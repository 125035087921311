import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';

function LineGraph({ changesSum, decisionsSum, years, onCanvasReady }) {
    const canvasRef = useRef(null);
    // console.log('changesData in lineGraph:', changesSum)
    // console.log('decisionsData in lineGraph:', decisionsSum)
    // console.log('years in lineGraph:', years)

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.canvas.width = 1000;
        ctx.canvas.height = 450;

        // create the chart data
        const chartData = {
            labels: years,
            datasets: [
                {
                    label: 'Changes',
                    data: changesSum,
                    fill: true,
                    borderColor: '#336BB0',
                    tension: 0.1,
                },

                {
                    label: 'Decisions',
                    data: decisionsSum,
                    fill: true,
                    borderColor: '#219F8E',
                    tension: 0.1,
                },
            ],

        };

        // create the chart options
        const chartOptions = {
            responsive: true,
            maintainAspectRatio: true,
            layout: {
                padding: 15,
            },
            plugins: {
                title: {
                    display: true,
                    text: 'Your Changes & Decisions',
                    font: {
                        size: 16,
                        family: 'Roboto'
                    },
                    padding: 10,
                },
                legend: {
                    position: 'top',
                    labels: {
                        font: {
                            size: 16,
                            family: 'Roboto'
                        },
                        height: 20,

                    },

                },
            },
            scales: {
                x: {
                    title: {
                        display: true,
                        text: 'Year',
                        font: {
                            size: 16,
                            family: 'Roboto'
                        },
                        padding: 25,

                    },
                    ticks: {
                        font: {
                            size: 14,
                            family: 'Roboto'
                        }
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'Changes & decisions values',
                        font: {
                            size: 16,
                            family: 'Roboto'
                        },
                        padding: 15,
                    },
                    ticks: {
                        font: {
                            size: 14,
                            family: 'Roboto'
                        },
                        backdropPadding: {
                            y: 20
                        }
                    },
                    min: 0,
                    // max: 10,
                },
            },
        };

        // create the chart
        const chart = new Chart(ctx, {
            type: 'line',
            data: chartData,
            options: chartOptions,
        });

        // Notify the parent component when the canvas is ready
        if (onCanvasReady) {
            onCanvasReady(canvasRef.current);
        }
        // clean up the chart when the component unmounts
        return () => {
            chart.destroy();
        };
        
    }, [changesSum, decisionsSum, years, onCanvasReady]);

    return <canvas ref={canvasRef}></canvas>;
}

export default LineGraph;
