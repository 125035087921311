import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';

function ContactChart({ contactChartValues, onCanvasReady }) {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartCtx = chartRef.current.getContext('2d');
        Chart.defaults.font.size = 16;
        chartCtx.canvas.width = 750;
        chartCtx.canvas.height = 400;

        const chart = new Chart(chartCtx, {
            type: 'bar',
            data: {
                labels: ['Relevant', 'Reliable', 'Accurate', 'Timing', 'Clarity', 'Knowledge', 'Conflict of Interests', 'Priority'],
                datasets: [{
                    label: 'Network',
                    data: contactChartValues,
                    backgroundColor: [
                        '#219F8Eff',
                        '#34A686ff',
                        '#48AD7Eff',
                        '#5BB475ff',
                        '#6FBB6Dff',
                        '#82C265ff',
                        '#96C95Dff',
                        '#A9D055ff',
                        '#BDD74Dff',
                        '#D0DE44ff',
                        '#E4E53Cff',
                        '#F7EC34ff',
                    ],
                    borderColor: 'transparent',
                    borderWidth: 1,
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'top',
                        textAlign: 'center',
                        align: 'center',
                        display: false,
                    },
                    title: {
                        display: false,
                        text: 'Clarity & Conflict of Interests',
                        color: 'navy',
                        position: 'bottom',
                        textAlign: 'center',
                        align: 'center',
                        font: {
                            size: 16,
                            weight: 'bold'
                        },
                    },

                },
                scales: {
                    y: {
                        ticks: {
                            beginAtZero: true,
                            precision: 0,
                            font: {
                                size: 16,
                                weight: 'bold'
                            }
                        },
                        title: {
                            display: true,
                            text: 'Score',
                            padding: 20,
                            color: '#2f3540'
                        }
                    },
                    x: {
                        ticks: {
                            beginAtZero: true,
                            precision: 0,
                            font: {
                                size: 16,
                            }
                        },
                        title: {
                            display: true,
                            text: 'Contact Analysis',
                            padding: 20,
                            color: '#2f3540',
                        }
                    }
                }
            }
        });

        // Notify the parent component when the canvas is ready
        if(onCanvasReady) {
            onCanvasReady(chartRef.current);
        }

        return () => {
            chart.destroy();
        };
    }, [contactChartValues, onCanvasReady]);

    return <canvas className='contact-chart' ref={chartRef} />;
};

export default ContactChart;