import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js';

function CVChart({ resourceRightValues, scoreRightValues, onCanvasReady }) {
    const chartRef = useRef(null);
    
    useEffect(() => {
        const chartCtx = chartRef.current.getContext('2d');
        chartCtx.canvas.width = 220;
        chartCtx.canvas.height = 400;
        Chart.defaults.font.size = 13;
        const chart = new Chart(chartCtx, {
            type: 'bar',
            data: {
                labels: resourceRightValues,
                datasets: [{
                    label: scoreRightValues ? 'Your Right Leg Resources' : 'NO DATA',
                    data: scoreRightValues,
                    backgroundColor: '#219F8E',
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'bottom',
                        textAlign: 'center',
                        align: 'center',
                        display: true,
                        labels: {
                            color: '#2f3540',
                            boxWidth: 0,
                            boxHeight: 0,
                            padding: 10,
                            font: {
                                size: 15,
                                weight: 'bold'
                            }
                        }
                        
                    },
                    layout: {
                        // padding: {
                        //     left: 50,
                        //     right: 50,
                        //     top: 50,
                        //     bottom: 50
                        // }
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            beginAtZero: true,
                            precision: 0,
                        },
                        title: {
                            display: true,
                            text: 'Score',
                            padding: 10,
                            color: '#2a2a2a'
                        }
                    },
                    x: {
                        title: {
                            display: false,
                            text: 'Resource',
                            color: '#2a2a2a'

                        },
                        ticks: {
                            padding: 15,
                            color: '#2a2a2a',
                            font: {
                                size: 14,
                            }

                        }

                    }
                }
            }
        });

         // Notify the parent component when the canvas is ready
         if(onCanvasReady) {
            onCanvasReady(chartRef.current);
        }

        return () => {
            chart.destroy();
        };
    }, [resourceRightValues, scoreRightValues, onCanvasReady]);

    return <canvas ref={chartRef} />;
};

export default CVChart;