import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import { nanoid } from 'nanoid';
import { TextField, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import withAuthentication from "../../withAuthentication";
import CVChart from '../../components/CVChart';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
import NetworkChart from '../../components/NetworkChart';
import Loading from '../../components/Loading';
import { Tooltip } from 'react-tooltip';
const docx = require("docx");

function CV1() {

    const { userState, updateData, loading } = useContext(Context);
    const { contactsLists, results, currListId, cv } = userState;

    const navigate = useNavigate();

    const [cvData, setCvData] = useState([])
    const [isDisabled, setDisabled] = useState(false)
    const [isModified, setModified] = useState(false)
    const [resourceRightValues, setResourceRightValues] = useState('')
    const [scoreRightValues, setScoreRightValues] = useState('')
    const [currList, setCurrList] = useState([])
    const [chosenListData, setChosenListData] = useState([])
    const [networkChartValues, setNetworkChartValues] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [listOptions, setListOptions] = useState([])
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState({});
    const [isSaving, setIsSaving] = useState(false)
    const newCV = {
        id: nanoid(), experience: '', education: '', pitch1: '', pitch2: '', pitch3: '', pitch4: '', pitch5: '', pitch6: '', pitch7: '', pitch8: '', finalpitch: '',
    }

    const styles =
    {
        inputLabelProps: { shrink: true, style: { fontSize: 20, color: '#219F8E', fontWeight: 'bold' } },
        inputProps: { style: { margin: '15px 0px', padding: '10px 0px' } }
    }

    // Dialog
    const [route, setRoute] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    // const handleDialogOpen = (route) => {
    //     if (isModified) {
    //         setOpenDialog(true);
    //         setRoute(route);
    //     } else {
    //         navigate(route);
    //     }
    // }

    const handleDialogClose = (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            navigate(route);
        }
    }

    // console.log(currListId, 'currListId')
    // console.log(currList, 'currList')
    // console.log(chosenListData, 'chosenListData')
    // console.log(resourceRightValues, 'resourceRightValues')
    // console.log(scoreRightValues, 'scoreRightValues')
    // console.log(networkChartValues, 'networkChartValues')
    // console.log(results, 'results')


    useEffect(() => {
        if (loading || !cv) return
        if (cv && cv.length === 0) {
            setCvData(newCV)
            return
        }
        setCvData(cv)
    }, [cv, loading])

    // when cv data changes, update the cv data in the db
    useEffect(() => {
        if (loading || !cv || !contactsLists || contactsLists.length === 0) return
        const listOptions = contactsLists.map(list => {
            return <option key={list.listId} value={list.listId}>{list.listName}</option>
        })
        setListOptions(listOptions)

        if (!currListId) {
            // if there is no currListId in the context, set the first list in the contactsLists array as the currList
            // updateData('currListId', contactsLists[0].listId)
            // const chosenList = contactsLists.find(list => { return list.listId === currListId })
            // const chosenListData = chosenList.listData.filter(contact => { return contact.fullName ? contact : null })
            // setCurrList(chosenList)
            // setChosenListData(chosenListData)
            return

        } else if (currListId) {
            const chosenList = contactsLists.find(list => { return list.listId === currListId })
            // console.log(chosenList, 'chosenList')
            const chosenListData = chosenList.listData
            // filter out contacts with no name:
            const filteredContacts = chosenListData.filter(contact => { return contact.fullName ? contact : null })
            // console.log(filteredContacts, 'filteredContacts')
            setChosenListData(chosenListData)
            setCurrList(chosenList)
        }

    }, [currListId, cv, contactsLists, results, loading, currList])

    // fetch and update extra skills chart:
    useEffect(() => {
        if (loading || !results || results.length === 0) return
        if (!results || results.length === 0) return

        // get only the results that have a contact value of 'yourself'
        const usersPersonalResults = results.filter(result => result.contact === 'yourself');
        if (!usersPersonalResults || usersPersonalResults.length === 0) return

        // map over filtered results and return object with resourceRight and scoreRight values
        const extractedResults = usersPersonalResults[0].results.map(({ resourceRight, scoreRight }) => ({ resourceRight, scoreRight }));
        // extract resourceRight and scoreRight that have values
        const resourceRightValues = extractedResults.map(result => result.resourceRight).filter(result => result !== null && result !== '')
        const scoreRightValues = extractedResults.map(result => result.scoreRight).filter(result => result !== null && result !== '')
        if (resourceRightValues.length === 0 || scoreRightValues.length === 0) return
        setResourceRightValues(resourceRightValues)
        setScoreRightValues(scoreRightValues)

    }, [results, loading])

    //pdf and docx buttons change to disabled when cv data changes
    useEffect(() => {
        if (loading || !cv) return
        if (cvData !== cv) {
            setDisabled(false)
            setModified(true)
            return
        } else {
            setDisabled(true)
            setModified(false)
        }
    }, [cvData, cv, loading])

    useEffect(() => {
        if (loading || !chosenListData || !currList || currList.length === 0) return

        // 1. Calculations for network chart
        let networkMembers = chosenListData.length;
        // 5
        let maximalStrengthAquaintanceUpper = calculateSumOfSelectedValues(5, "upper");
        let maximalStrengthAquaintanceLower = calculateSumOfSelectedValues(5, "lower");
        let maximalStrengthAquaintance = (maximalStrengthAquaintanceUpper + maximalStrengthAquaintanceLower) / 2;
        // 1
        let minimalStrengthAquaintanceUpper = calculateSumOfSelectedValues(1, "upper");
        let minimalStrengthAquaintanceLower = calculateSumOfSelectedValues(1, "lower");
        let minimalStrengthAquaintance = (minimalStrengthAquaintanceUpper + minimalStrengthAquaintanceLower) / 2;
        // 2
        let twoWayRelationsUpper = calculateSumOfSelectedValues(2, "upper");
        let twoWayRelationsLower = calculateSumOfSelectedValues(2, "lower");
        let twoWayRelations = (twoWayRelationsUpper + twoWayRelationsLower) / 2;
        // 3
        let threeWayRelationsUpper = calculateSumOfSelectedValues(3, "upper");
        let threeWayRelationsLower = calculateSumOfSelectedValues(3, "lower");
        let threeWayRelations = (threeWayRelationsUpper + threeWayRelationsLower) / 2;
        // 4
        let fourWayRelationsUpper = calculateSumOfSelectedValues(4, "upper");
        let fourWayRelationsLower = calculateSumOfSelectedValues(4, "lower");
        let fourWayRelations = (fourWayRelationsUpper + fourWayRelationsLower) / 2;
        // 0
        let zeroClosenessUpper = calculateSumOfSelectedValues(0, "upper");
        let zeroClosenessLower = calculateSumOfSelectedValues(0, "lower");
        let zeroClosenessNum = (zeroClosenessUpper + zeroClosenessLower) / 2;

        // number of times each type of realtion closenes appears in table (minimial(1)+maximal(5)+2+3+4), excluding 0
        let relations = maximalStrengthAquaintance + minimalStrengthAquaintance +
            twoWayRelations + threeWayRelations + fourWayRelations;
        // sum of all relations - minimial(1)+maximal(5)+2+3+4, excluding 0
        let sumOfRelations = (5 * maximalStrengthAquaintance) + (1 * minimalStrengthAquaintance) +
            (2 * twoWayRelations) + (3 * threeWayRelations) + (4 * fourWayRelations);

        // sum of all relations, not including 0 closeness, divided by the sum of all relations, 
        //including 0 closeness:
        let networkRelationsStrength = (networkMembers - 1) !== 0 ? sumOfRelations / relations : 0;

        let maxmialDensity = (networkMembers * (networkMembers - 1)) / 2; // maximal number of relations in the network
        let acquaintancesNum = maxmialDensity - zeroClosenessNum;
        let networkDensity = acquaintancesNum / maxmialDensity;

        // console.log(networkMembers, 'networkMembers')
        // console.log(maximalStrengthAquaintance, 'maximalStrengthAquaintance')
        // console.log(minimalStrengthAquaintance, 'minimalStrengthAquaintance')
        // console.log(twoWayRelations, 'twoWayRelations')
        // console.log(threeWayRelations, 'threeWayRelations')
        // console.log(fourWayRelations, 'fourWayRelations')
        // console.log(zeroClosenessNum, 'zeroClosenessNum')
        // console.log(networkRelationsStrength, 'networkRelationsStrength')
        // console.log(maxmialDensity, 'maxmialDensity')
        // console.log(acquaintancesNum, 'acquaintancesNum')
        // console.log(networkDensity, 'networkDensity')


        let networkChartValues = [networkMembers, maxmialDensity, zeroClosenessNum, acquaintancesNum,
            networkDensity, networkRelationsStrength, minimalStrengthAquaintance, twoWayRelations,
            threeWayRelations, fourWayRelations, maximalStrengthAquaintance]
        setNetworkChartValues(networkChartValues)

    }, [chosenListData, currList, loading])

    function convertTableObjectToArray(tableObject) {
        const tableArray = [];
        // if tableObject is empty or if it's not an object return:
        if (!tableObject) return
        const rows = Object.keys(tableObject);
        const cols = Object.keys(tableObject[rows[0]]);
        const sortedCols = cols.map(col => parseInt(col.slice(3))).sort((a, b) => a - b);
        const sortedRows = rows.map(row => parseInt(row.slice(3))).sort((a, b) => a - b);

        for (var i = 0; i < sortedRows.length; i++) {
            var row = [];
            var rowIndex = sortedRows[i];
            for (var j = 0; j < sortedCols.length; j++) {
                var colIndex = sortedCols[j];
                var cell = {
                    selected: tableObject[`row${rowIndex}`][`col${colIndex}`].selected,
                    index: tableObject[`row${rowIndex}`][`col${colIndex}`].index,
                };
                row.push(cell);
            }
            tableArray.push(row);
        }
        return tableArray;
    }

    // calculate the sum of selected values in a table (upper or lower part):
    function calculateSumOfSelectedValues(value, part) {

        if (!contactsLists || contactsLists.length === 0 || !currList.listTable || currList.listTable.length === 0) return

        const maxmialDensity = chosenListData.length * (chosenListData.length - 1);
        let table = currList.listTable
        if (!table || table.length === 0) return
        // console.log(table, 'table')
        const tableArr = convertTableObjectToArray(table)
        // console.log(tableArr, 'tableArr')
        let counter = 0
        tableArr.forEach((row, i) => {
            row.forEach((cell, j) => {
                if (cell.index[0] === cell.index[1]) return // if the cell is on the diagonal, skip it
                if (part === "upper" && j <= i) return; // if the cell is in the upper part of the table, skip it
                if (part === "lower" && j >= i) return; // if the cell is in the lower part of the table, skip it
                else if (cell.selected === value) {
                    counter++
                }
            })
        })
        return Math.min(counter, maxmialDensity);
    }

    // handle change event for the text fields
    async function handleChange(e) {
        const { name, value } = e.target
        setMessage('')
        setModified(true)
        setErrors({})
        if (name === 'listSelect') {
            if (value === '') {
                // reset all charts:
                setNetworkChartValues([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                setResourceRightValues('')
                setScoreRightValues('')
                setCurrList([])
                setChosenListData([])
                try {
                    await updateData('currListId', '')
                } catch (err) {
                    console.log(err)
                }
                return
            }
            try {
                await updateData('currListId', value)
            } catch (err) {
                console.log(err)
            }
            return
        }

        // console.log(name, value, 'name, value')
        setCvData(prevState => ({
            ...prevState,
            [name]: value
        }))

    }

    async function resetForm() {
        if (window.confirm('Are you sure you want to reset the form? This will erase all data and cannot be undone.')) {
            setCvData(newCV)
            try {
                await updateData('cv', newCV)
                setMessage('Form reset successfully')
            } catch (err) {
                console.log(err)
                setMessage('Error resetting form')
            }
        }
    }

    // save to db
    async function saveCV() {
        // check if all fields are filled:
        let newErrors = {};
        for (let key in cvData) {
            if (!cvData[key]) {
                newErrors[key] = true;
            }
        }

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            setMessage('Please fill in all fields before saving');
            setTimeout(() => {
                setMessage('')
            }, 4000);
            return;
        }

        setIsSaving(true)
        try {
            await updateData('cv', cvData)

            setTimeout(() => {
                setIsSaving(false)
                setMessage('cv saved successfully')
            }, 2000)

            setTimeout(() => {
                setMessage('')
                setModified(false)
            }, 2500)

        } catch (error) {
            console.log(error)
            setMessage('Error saving data', error);
            setTimeout(() => {
                setMessage('');
            }, 2000);
        }
    }

    // generate a pdf document from the cv data and download to user's computer
    const chartRefs = {};
    function canvasToPngDataURL(canvas) {
        return canvas.toDataURL('image/png');
    }
    // handle canvas ready event and store the canvas reference in the chartRefs object
    const handleCanvasReady = (chartName, canvas) => {
        chartRefs[chartName] = canvas;
    };

    // generate a pdf document from the cv data and download to user's computer
    function generatePdf() {
        let firstnameCapital = userState.firstName.charAt(0).toUpperCase() + userState.firstName.slice(1)
        let lastnameCapital = userState.lastName.charAt(0).toUpperCase() + userState.lastName.slice(1)
        const userNameInCapital = firstnameCapital + ' ' + lastnameCapital

        // Create a new instance of jsPDF
        const pdf = new jsPDF({
            orientation: 'p',
            unit: 'mm', format: 'a4',
            putOnlyUsedFonts: true,
            compress: true,
            precision: 2,
            lineHeight: 1.3,
        })
        let currentY = 20;
        // Define the content and formatting of the PDF document
        pdf.setFontSize(14);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`CV of ${userNameInCapital}`,
            pdf.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 10;

        pdf.setFontSize(11);
        pdf.setFont('helvetica', 'bold');
        pdf.text('Experience', 10, currentY);
        currentY += 5;
        pdf.setFont('helvetica', 'normal');
        pdf.text(cv.experience, 10, currentY);
        currentY += 20;

        pdf.setFontSize(11);
        pdf.setFont('helvetica', 'bold');
        pdf.text('Education', 10, currentY);
        currentY += 5;
        pdf.setFont('helvetica', 'normal');
        pdf.text(cv.education, 10, currentY);
        currentY += 20;

        pdf.setFontSize(11);
        pdf.setFont('helvetica', 'bold');
        pdf.text('Summary', 10, currentY);
        currentY += 5;
        pdf.setFont('helvetica', 'normal');
        pdf.text(cv.finalpitch, 10, currentY);
        currentY += 20;

        pdf.setFontSize(11);
        pdf.setFont('helvetica', 'bold');
        pdf.text('Unrecognized Skills', 10, currentY);
        currentY += 5;
        pdf.setFont('helvetica', 'normal');
        pdf.text('List network', 10, currentY);
        currentY += 5;

        let imgWidth = 100;  // Width of the image in the PDF
        let imgHeight = 80; // Height of the image in the PDF
        let networkChartData = canvasToPngDataURL(chartRefs['networkChart']);
        let resultsData = canvasToPngDataURL(chartRefs['cvChart']);

        const pageWidth = pdf.internal.pageSize.getWidth();
        const xCentered = (pageWidth - imgWidth) / 2;
        pdf.addImage(networkChartData, 'PNG', xCentered, currentY, imgWidth, imgHeight);  // 10 is the left margin, adjust as needed
        currentY += 80;

        // add cv chart
        pdf.setFontSize(11);
        pdf.setFont('helvetica', 'normal');
        pdf.text('Extra skills', 10, currentY);
        currentY += 5;
        imgHeight = 80
        imgWidth = 60
        pdf.addImage(resultsData, 'PNG', xCentered, currentY, imgWidth, imgHeight);  // 10 is the left margin, adjust as needed
        currentY += 10;
        // Generate the PDF document and trigger a download
        pdf.save('cv.pdf');
    }

    // generate a docx document from the cv data and download to user's computer
    function generateDocx() {
        const doc = new docx.Document({
            title: "CV",

            sections: [{
                children: [
                    new docx.Paragraph({
                        text: "CV",
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        heading: docx.HeadingLevel.HEADING_1,
                    }),
                    new docx.Paragraph({
                        text: "Experience",
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: docx.HeadingLevel.HEADING_3,
                    }),
                    new docx.Paragraph({
                        text: cv.experience,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                    }),
                    new docx.Paragraph({
                        text: "Education",
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: docx.HeadingLevel.HEADING_3,
                    }),
                    new docx.Paragraph({
                        text: cv.education,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                    }),
                    new docx.Paragraph({
                        text: "Summary",
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: docx.HeadingLevel.HEADING_3,
                    }),
                    new docx.Paragraph({
                        text: cv.finalpitch,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                    }),

                ],
            }]

        });

        // Used to export the file into a .docx file
        docx.Packer.toBlob(doc)
            .then((blob) => {
                saveAs(blob, "cv.docx");
            })
            .catch((error) => {
                console.error("Error generating document: ", error);
            });
    }

    if (loading) return <Loading />

    return (

        <div className="app-home-container">
            <div className='app-title'>
                <h4 >KYS CV
                    <i data-tooltip-id="cvTitle"
                        position="right"
                        data-tooltip-content="The TINO approach recognizes that resumes (C.V.s) are typically presented in various formats,
                            including those tailored specifically for social media platforms. However, these formats often focus
                            on showcasing only the 'recognized knowledge' that individuals intentionally highlight to impress
                            potential employers. In contrast, there is a wealth of 'unrecognized knowledge' that individuals possess, which consists
                            of two primary components that need to be incorporated into the C.V: (1) Right-Leg Skills (2) TINO Network"
                        className="fa fa-question-circle"
                        aria-hidden="true"></i>
                    <Tooltip className="tooltip" id="cvTitle" />
                </h4>
                <h6 >Offer your hidden resources</h6>
                <p> Add both your recognized as well as your unrecognized knowledge to your C.V. </p>
                <p>You can download your CV as a .DOC or .PDF file when you are done.</p>

            </div>
            <div className='cv-top-container'>
                <div className='element-title'>
                    <h6 >Recognized Skills</h6>
                </div>

                <div className='cv-group element-body'>
                    <div className='cv-box'>
                        <p >Work Experience</p>
                        <TextField
                            className="TextField-pulling-area"
                            name="experience"
                            value={cvData.experience || ''}
                            onChange={handleChange}
                            placeholder='Add your work experience here. Include your job title, company name, and a brief description of your responsibilities.'
                            variant="outlined"
                            multiline
                            fullWidth
                            rows={10}
                            error={errors.experience}
                        />
                    </div>
                    <div className='cv-box'>
                        <p>Education</p>
                        <TextField
                            className="TextField-pulling-area"
                            name="education"
                            value={cvData.education || ''}
                            onChange={handleChange}
                            placeholder='Add your education here. Include the name of the institution, the degree you obtained, and the year you graduated.'
                            variant="outlined"
                            multiline
                            fullWidth
                            rows={10}
                            error={errors.education}
                        />
                    </div>
                </div>
            </div>
            <div className='cv-middle-container'>
                <div className='element-title'>
                    <h6>Unrecognized Skills</h6>
                </div>
                <div className='cv-group element-body'>
                    <div className='cv-box'>
                        <p>List network</p>
                        <div className='cv-box-title'>
                            <p>{listOptions.length === 0 ? 'You have no contacts lists' :
                                'Select list to see its network summary:'}</p>
                            <select
                                name="listSelect"
                                style={{ width: 'fit-content', padding: '5px' }}
                                value={currList ? currList?.listId : ''}
                                disabled={listOptions.length === 0}
                                onChange={handleChange}>
                                {listOptions}
                            </select>
                        </div>
                        <div className='cv-graph-container'>
                            <NetworkChart networkChartValues={networkChartValues} onCanvasReady={(canvas) => handleCanvasReady('networkChart', canvas)} page={'cv'} />
                        </div>
                    </div>

                    <div className='cv-box'>
                        <p >Extra skills</p>

                        {!resourceRightValues ?
                            <p >
                                * You must first use the Results tool to see your unrecognized right leg resources here</p>
                            :
                            <div className='cv-graph-container rightleg' >
                                <CVChart resourceRightValues={resourceRightValues} scoreRightValues={scoreRightValues} onCanvasReady={(canvas) => handleCanvasReady('cvChart', canvas)} />
                            </div>
                        }

                    </div>
                </div>

            </div>
            <div className='cv-bottom-container'>
                <div className='element-title'>
                    <h6 >Pitch and final CV</h6>
                </div>
                <div className='cv-group element-body'>
                    <div className='cv-box'>
                        <p >Building Your Pitch</p>
                        <TextField
                            className="TextField-pulling"
                            label="Who P"
                            name="pitch1"
                            value={cvData.pitch1 || ''}
                            onChange={handleChange}
                            variant="standard"
                            size="small"
                            multiline
                            fullWidth
                            placeholder="Who are you submitting your CV to?"
                            InputLabelProps={styles.inputLabelProps}
                            InputProps={styles.inputProps}
                            error={errors.pitch1}
                        ></TextField>
                        <TextField
                            className="TextField-pulling"
                            label="Where D"
                            name="pitch2"
                            value={cvData.pitch2 || ''}
                            onChange={handleChange}
                            variant="standard"
                            multiline
                            fullWidth
                            placeholder="Which domains might be in a need of your skills?"
                            InputLabelProps={styles.inputLabelProps}
                            InputProps={styles.inputProps}
                            error={errors.pitch2}
                        ></TextField>
                        <TextField
                            className="TextField-pulling"
                            label="What"
                            name="pitch3"
                            value={cvData.pitch3 || ''}
                            onChange={handleChange}
                            variant="standard"
                            multiline
                            fullWidth
                            placeholder="What is the offering (knowledge/network/skills)?"
                            InputLabelProps={styles.inputLabelProps}
                            InputProps={styles.inputProps}
                            error={errors.pitch3}
                        ></TextField>
                        <TextField
                            className="TextField-pulling"
                            label="When S"
                            name="pitch4"
                            value={cvData.pitch4 || ''}
                            onChange={handleChange}
                            variant="standard"
                            multiline
                            fullWidth
                            placeholder="What is the reason you are offering your skills?"
                            InputLabelProps={styles.inputLabelProps}
                            InputProps={styles.inputProps}
                            error={errors.pitch4}
                        ></TextField>
                        <TextField
                            className="TextField-pulling"
                            label="When T"
                            name="pitch5"
                            value={cvData.pitch5 || ''}
                            onChange={handleChange}
                            variant="standard"
                            multiline
                            fullWidth
                            placeholder="What is the reason you are offering your skills NOW? Or if not now, then when?"
                            InputLabelProps={styles.inputLabelProps}
                            InputProps={styles.inputProps}
                            error={errors.pitch5}
                        ></TextField>
                        <TextField
                            className="TextField-pulling"
                            label="Who E"
                            name="pitch6"
                            value={cvData.pitch6 || ''}
                            onChange={handleChange}
                            variant="standard"
                            multiline
                            fullWidth
                            placeholder="Which entity/group is connected to you"
                            InputLabelProps={styles.inputLabelProps}
                            InputProps={styles.inputProps}
                            error={errors.pitch6}
                        ></TextField>
                        <TextField
                            className="TextField-pulling"
                            label="Where G"
                            name="pitch7"
                            value={cvData.pitch7 || ''}
                            onChange={handleChange}
                            variant="standard"
                            multiline
                            fullWidth
                            placeholder="Which georgraphic location is relevant to your skills?"
                            InputLabelProps={styles.inputLabelProps}
                            InputProps={styles.inputProps}
                            error={errors.pitch7}
                        ></TextField>
                        <TextField
                            className="TextField-pulling"
                            label="Why"
                            name="pitch8"
                            value={cvData.pitch8 || ''}
                            onChange={handleChange}
                            variant="standard"
                            multiline
                            fullWidth
                            placeholder="What is the motivation behind your offer?"
                            InputLabelProps={styles.inputLabelProps}
                            InputProps={styles.inputProps}
                            error={errors.pitch8}
                        ></TextField>
                    </div>
                    <div className='cv-box'>
                        <p >Your Final Pitch</p>
                        <TextField
                            className="TextField-pulling"
                            label="Final Pitch"
                            name="finalpitch"
                            value={cvData.finalpitch || ''}
                            onChange={handleChange}
                            variant="outlined"
                            multiline
                            fullWidth
                            rows={28}
                            placeholder='Add your final pitch here'
                            InputLabelProps={styles.inputLabelProps}
                            error={errors.finalpitch}
                        />
                    </div>
                </div>
            </div>
            <div className='bottomNavigation '>
                <div>
                    <div>
                        <button
                            style={{ width: '200px' }}
                            className='savingBtn'
                            onClick={saveCV}
                            disabled={isDisabled}>Save CV
                        </button>
                        <button
                            style={{ width: '200px' }}
                            className='savingBtn'
                            onClick={resetForm} >Reset Form
                        </button>
                    </div>
                    <div>
                        <button
                            style={{ width: '200px' }}
                            onClick={generateDocx}
                            disabled={!isDisabled}>Download as DOC
                        </button>
                        <button
                            style={{ width: '200px' }}
                            onClick={generatePdf}
                            disabled={!isDisabled} >Download as PDF
                        </button>
                    </div>
                </div>
                <div className="successMessage">
                    {message ? message : ''}
                    {isSaving && (
                        <div>
                            <i className="fas fa-spinner fa-spin"></i> Saving...
                        </div>
                    )}
                </div>
            </div>
            <div className='pagination'>
                <Dialog
                    open={openDialog}
                    onClose={() => handleDialogClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className="dialog-title" id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
                    <DialogContent className="dialog-content">
                        <DialogContentText id="alert-dialog-description">
                            You have unsaved changes. Are you sure you want to leave this page?
                        </DialogContentText>
                    </DialogContent>
                    <div className="dialog-actions">
                        <button style={{ backgroundColor: 'grey' }} className="dialog-button" onClick={() => handleDialogClose(false)}>Cancel</button>
                        <button className="dialog-button" onClick={() => handleDialogClose(true)} autoFocus>Yes</button>
                    </div>
                </Dialog>
                {/* <button onClick={() => handleDialogOpen('/')}>Back to homepage</button> */}
            </div>
        </div>

    );


}
export default withAuthentication(CV1);
