import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import Loading from '../../components/Loading';
import withAuthentication from "../../withAuthentication";
import { Tooltip } from 'react-tooltip'


function Analysis1() {

    const { userState, updateData, loading } = useContext(Context);
    const { contactsLists, currListId } = userState;

    const navigate = useNavigate();

    const [isDisabled, setDisabled] = useState(false)
    const [isHasTables, setIsHasTables] = useState(false)
    const [selectedListId, setSelectedListId] = useState('')
    const [selectedList, setSelectedList] = useState(null)
    const [listsDropDown, setListsDropDown] = useState([])
    const [chosenTableData, setChosenTableData] = useState([])
    const [listContacts, setListContacts] = useState([]) // filter out contacts with no names
    const [message, setMessage] = useState('')

    // console.log(isHasTables, 'isHasTables')

    // when user selects list, check if selected list has tables
    useEffect(() => {
        if (loading || !contactsLists || contactsLists.length === 0) return

        // map over contacts lists to render dropdown of all contact lists
        const lists = contactsLists.map(list => (
            <option key={list.listId} value={list.listId}>Name: {list.listName}, Date Created: {list.listDate}</option>
        ))
        setListsDropDown(lists)

        if (selectedListId) {
            // check if selected list has tables
            const currList = contactsLists.find(list => list.listId === selectedListId)

            const contactsWithNames = currList.listData && currList.listData.filter(contact => { return (contact.fullName !== '') })
            setListContacts(contactsWithNames)

            const listTableData = currList.listTable ? currList.listTable : null
            setChosenTableData(listTableData)

            if (!listTableData || listTableData.length === 0 || Object.keys(listTableData).length === 0) {
                setIsHasTables(false)
                // check if list has contacts with names, and if not, disable create new table button and show message
                if (contactsWithNames.length === 0) {
                    setMessage('* This list is empty. To use this list, go back to the contacts app and add contacts')
                    setDisabled(true)
                    return
                } else {
                    setMessage('No table found for selected list. Create a new table to begin')
                    setDisabled(false)
                    return
                }
            } else {
                setIsHasTables(true)
            }
        }

    }, [contactsLists, currListId, selectedListId, isHasTables, chosenTableData, loading]);

    async function handleChange(e) {
        const { value } = e.target
        if (value === '') {
            setMessage('')
            setDisabled(false)
            setSelectedList(null)
            setSelectedListId('')
            setIsHasTables(false)
            return
        }
        const currList = contactsLists.find(list => list.listId === value)
        setSelectedList(currList)
        setSelectedListId(value)
        try {
            await updateData('currListId', value)
        } catch (err) {
            console.log(err)
        }
    }

    function getNewTableObject() {
        const newTable = {};
        for (let i = 0; i < listContacts.length; i++) {
            newTable[`row${i}`] = {};
            for (let j = 0; j < listContacts.length; j++) {
                newTable[`row${i}`][`col${j}`] = { selected: 0, index: [i, j] };
            }
        }
        return newTable;
    }

    async function createNewTable() {
        // 1. get a new table object
        const tableDataObject = getNewTableObject()

        // 2. add new table to selected list in db
        const updatedContactLists = contactsLists && contactsLists.map(list => {
            if (list.listId === selectedListId) {
                return { ...list, listTable: tableDataObject }
            } else {
                return list
            }
        })

        try {
            await updateData('contactsLists', updatedContactLists)
            setMessage('table created sucessfuly')

            setTimeout(() => {
                navigate('/AnalysisApp/Analysis2')
            }, 1500)

        }
        catch (err) {
            console.log(err)
            setMessage('error updating contact lists in db')
        }
    }

    async function deleteTable() {
        // make sure user wants to delete table
        const confirmDelete = window.confirm('Are you sure you want to delete this table? This action cannot be undone.')
        if (!confirmDelete) return

        const updatedContactLists = contactsLists.map(list => {
            if (list.listId === selectedListId) {
                return { ...list, listTable: [] }
            } else {
                return list
            }
        })
        try {
            setMessage('deleting table...')
            await updateData('contactsLists', updatedContactLists)
            setTimeout(() => {
                setMessage('table deleted sucessfuly')
            }, 2000)

        } catch (err) {
            console.log(err)
            setTimeout(() => {
                setMessage('error deleting table')
            }, 3000)
        }finally{
            setTimeout(() => {
                setMessage('')
            }, 5000)
        }
    }

    if (loading) return <Loading />

    return (
        <div className="app-home-container" style={{ justifyContent: 'flex-start' }}>
            <div className='app-title'>
                <h4>Network Analysis
                    <i
                        data-tooltip-id="analysisTitle"
                        data-tooltip-content="In each TIKO list, your top 20 contacts are the most valuable. By analyzing their cross-connections, you can enhance your network efficiency."
                        className="fa fa-question-circle"
                        aria-hidden="true"
                    ></i>
                    <Tooltip className="tooltip" id="analysisTitle" />
                </h4>
                <h6>Analyze your contact lists and networks</h6>
            </div>
            <div className='analysis-main-container'>
                <div style={{ width: '100%' }}>
                    <h6 className='element-title' >Step 1: Select contact list
                        <i data-tooltip-id="analysisStep1"
                            position="right"
                            data-tooltip-content="Each list can only have one network analysis table"
                            className="fa fa-question-circle"
                            aria-hidden="true"
                        ></i>
                        <Tooltip className="tooltip" id="analysisStep1" />
                    </h6>
                    <div className='element-body'>
                        <select
                            name='listSelection'
                            onChange={handleChange}>
                            <option value="">{isDisabled ? 'No contact lists available' : 'Select list'}</option>
                            {listsDropDown}
                        </select>
                    </div>
                </div>
                <div >
                    <h6 className='element-title' >Step 2: Load, delete, or create a new table
                        <i data-tooltip-id="analysisStep2"
                            position="right"
                            data-tooltip-content="Click 'Load table' to continue or 'Delete table' to delete this table and create a new one"
                            className="fa fa-question-circle"
                            aria-hidden="true"
                        ></i>
                        <Tooltip className="tooltip" id="analysisStep2" />

                    </h6>
                    <div className='element-body'>
                        <div className='analysisBtns-group '>
                            <button style={{ margin: '5px', minWidth: '130px' }} onClick={() => navigate('/AnalysisApp/Analysis2')} disabled={!selectedList || !selectedListId || !isHasTables}>Load table</button>
                            <button style={{ margin: '5px', minWidth: '130px' }} onClick={deleteTable} disabled={!selectedList || !selectedListId || !isHasTables} >Delete table</button>
                            <button style={{ margin: '5px', minWidth: '130px' }} onClick={createNewTable} disabled={!selectedList || isHasTables || listContacts.length === 0}>Create new table</button>
                        </div>
                    </div>
                </div>
                <p className='successMessage' style={{ height: '10px', maxWidth: '450px', textAlign: 'left' }}>{message}</p>
            </div>

            {/* <div className='pagination'>

                <button onClick={() => navigate('/')}>Back to homepage</button>

            </div> */}

        </div>
    );


}

export default withAuthentication(Analysis1);