import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import { db } from '../../firebase';
import { collection, getDocs } from "firebase/firestore";

// components
import Loading from '../../components/Loading';
import ReportsTable from '../../components/ReportsTable';
import withAuthentication from "../../withAuthentication";
import { nanoid } from 'nanoid'

// UI
import { Select, MenuItem, Input } from "@mui/material"
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

// Tabs
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 2 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


function Reports1() {
    const { userState, updateData, loading } = useContext(Context);
    const { reports, conclusions, tenantId } = userState;

    const [value, setValue] = useState(0);
    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState('');
    const [selectedConclusions, setSelectedConclusions] = useState([]);
    const [selectedReportTitle, setSelectedReportTitle] = useState('')
    const [selectedReportId, setSelectedReportId] = useState('')
    const [pastReports, setPastReports] = useState(reports)
    const [reportTitle, setReportTitle] = useState('')
    const [searchQueryQuestions, setSearchQueryQuestions] = useState('');
    const [searchQueryInterviews, setSearchQueryInterviews] = useState('');
    const [searchQueryConclusions, setSearchQueryConclusions] = useState('');

    const navigate = useNavigate();


    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    // search bar for reports table
    const [searchTerm, setSearchTerm] = useState('');

    const newReport = {
        reportId: nanoid(),
        reportDate: new Date().toISOString(),
        conclusions: [selectedConclusions],
        title: reportTitle,
        step1: [],
        step2: [],
        step3: [],
        bullets: [
            {
                id: nanoid(),
                bullet: '', // bullet conclusions
                questions: {
                    question1: '',
                    question2: '',
                    question3: '',
                    question4: '',
                    question5: '',
                    question6: '',
                    question7: '',
                    question8: '',
                },
                validation: {
                    validity: '',
                    clarity: '',
                    knowledge: '',
                }
            }
        ],

    }

    // console.log(conclusions, 'conclusions')
    // console.log(savedConclusions, 'savedConclusions')
    // console.log(selectedConclusions, 'selectedConclusions')
    // console.log(reports, 'reports')
    // console.log(users, 'users')


    const handleReportSelection = (e) => {
        setSelectedReportTitle(e.target.value)
        // get report id from the selected report title
        const selectedReport = reports.find(report => report.title === e.target.value)
        setSelectedReportId(selectedReport.reportId)
    }

    const handleSelectConclusion = (event) => {
        setSelectedConclusions(event.target.value);
    };

    const createNewReport = async () => {
        if (selectedConclusions.length === 0) return
        setSelectedReportId(newReport.reportId)
        try {
            await updateData('reports', [...reports, newReport])
        }
        catch (err) {
            console.log(err)
            setTimeout(() => {
                setMessage(err.message)
            }, 2000);
            setMessage('')
        }
        try {
            await updateData('currReportId', newReport.reportId)
        }
        catch (err) {
            console.log(err)
            setTimeout(() => {
                setMessage(err.message)
            }, 2000);
            setMessage('')
        }
        navigate('/ReportsApp/Reports2')
    }

    const loadReport = async () => {
        if (!selectedReportId || !reports || reports.length === 0) return
        try {
            await updateData('currReportId', selectedReportId)
            navigate('/ReportsApp/Reports2')
        }   // catch any errors
        catch (err) {
            console.log(err)
            setTimeout(() => {
                setMessage(err.message)
            }, 2000);
            setMessage('')
        }

    }

    const deleteReport = async () => {
        if (!selectedReportId || !reports || reports.length === 0) return
        // confirm delete:
        const confirmDelete = window.confirm('Are you sure you want to delete this report? This action cannot be undone.')
        if (!confirmDelete) return

        const newReports = reports.filter(report => report.reportId !== selectedReportId)
        await updateData('reports', newReports)

        setSelectedReportId('')
        setPastReports(newReports)
    }

    // Filter reports based on the search term
    const filteredReports = reports && reports.filter((report) => {
        const searchContent = report.title
            + ' ' + (Array.isArray(report.conclusions) && report.conclusions.join(' '))
            + ' ' + report.bullets && report.bullets.map(bullet => bullet.bulletContent).join(' ');

        return searchContent.toLowerCase().includes(searchTerm.toLowerCase());
    });

    // fetch users data
    useEffect(() => {
        if (loading) return;
        const fetchAllUsers = async () => {
            setMessage(''); // Clear any previous error messages
            try {
                const usersRef = collection(db, 'tenants', tenantId, 'users');
                const userSnapshot = await getDocs(usersRef);
                const users = userSnapshot.docs.map(doc => {
                    const userData = doc.data();
                    return {
                        uid: doc.id,
                        ...userData
                    };
                });
                setUsers(users);
            } catch (err) {
                console.log(err);
                setMessage(err.message);
            }
        };
        fetchAllUsers();
    }, [loading, tenantId]);


    const filteredUsersByQuestions = users.flatMap((user) => {
        if (!searchQueryQuestions) return (user.projects || []).map(project => ({
            ...project,
            firstName: user.firstName,
            lastName: user.lastName
        }));

        const searchTermLowerCase = searchQueryQuestions.toLowerCase();

        return (user.projects || []).filter(project => {
            const userMatches = (
                (user.firstName && user.firstName.toLowerCase().includes(searchTermLowerCase)) ||
                (user.lastName && user.lastName.toLowerCase().includes(searchTermLowerCase))
            );

            const projectMatches = (
                (project.step1 && project.step1.FinalQ && project.step1.FinalQ.toLowerCase().includes(searchTermLowerCase)) ||
                (project.projectDate && project.projectDate.toLowerCase().includes(searchTermLowerCase))
            );

            return userMatches || projectMatches;
        }).map(project => ({
            ...project,
            firstName: user.firstName,
            lastName: user.lastName
        }));
    });


    const filteredUsersByInterviews = users.filter((user) => {
        if (!searchQueryInterviews) return true;

        const searchTermLowerCase = searchQueryInterviews.toLowerCase();
        const userMatches = (
            (user.firstName && user.firstName.toLowerCase().includes(searchTermLowerCase)) ||
            (user.lastName && user.lastName.toLowerCase().includes(searchTermLowerCase))
        );

        const interviewMatches = user.interviews && user.interviews.some(interview => {
            return (
                (interview.interviewData.interviewQuestion && interview.interviewData.interviewQuestion.toLowerCase().includes(searchTermLowerCase)) ||
                (interview.interviewData.hearingFirst && interview.interviewData.hearingFirst.toLowerCase().includes(searchTermLowerCase)) ||
                (interview.interviewData.hearingSecond && interview.interviewData.hearingSecond.toLowerCase().includes(searchTermLowerCase)) ||
                (interview.interviewData.interviewee && interview.interviewData.interviewee.toLowerCase().includes(searchTermLowerCase)) ||
                (interview.interviewData.interviewDate && interview.interviewData.interviewDate.toLowerCase().includes(searchTermLowerCase))
            );
        });

        return userMatches || interviewMatches;
    });


    const filteredUsersByConclusions = users.flatMap((user) => {
        if (!searchQueryConclusions) return (user.conclusions || []).map(conclusion => ({
            ...conclusion,
            userName: `${user.firstName} ${user.lastName}`
        }));

        const searchTermLowerCase = searchQueryConclusions.toLowerCase();

        return (user.conclusions || []).filter(conclusion => {
            const userMatches = (
                (user.firstName && user.firstName.toLowerCase().includes(searchTermLowerCase)) ||
                (user.lastName && user.lastName.toLowerCase().includes(searchTermLowerCase))
            );

            const conclusionMatches = (
                (conclusion.conclusionData.finalConclusion && conclusion.conclusionData.finalConclusion.toLowerCase().includes(searchTermLowerCase)) ||
                (conclusion.conclusionData.interviewee && conclusion.conclusionData.interviewee.toLowerCase().includes(searchTermLowerCase)) ||
                (conclusion.conclusionData.selectedQuestion && conclusion.conclusionData.selectedQuestion.toLowerCase().includes(searchTermLowerCase)) ||
                (conclusion.conclusionData.conclusionDate && conclusion.conclusionData.conclusionDate.toLowerCase().includes(searchTermLowerCase))
            );

            return userMatches || conclusionMatches;
        }).map(conclusion => ({
            ...conclusion,
            userName: `${user.firstName} ${user.lastName}`
        }));
    });


    const handleQuestionSearch = (searchQuery) => {
        setSearchQueryQuestions(searchQuery);
    };


    if (loading) return <Loading />;

    return (
        <div className="app-home-container" style={{ justifyContent: 'flex-start' }}>
            <div className="app-title">
                <h4>Reports: step 1</h4>
                <h6>Load an existing report or start a new one by selecting one or more conlcusions</h6>
                <h6>You can also search for past reports, questions, interviews and conclusions</h6>
                {message && <p className="successMessage">{message}</p>}
            </div>
            <div className="reports-main-container">
                <div className="reports-container ">
                    <div className='element-title green'>
                        <h6 >Load existing report or create a new one</h6>
                    </div>
                    <div className="element-body">

                        <h6 style={{ margin: '0px 5px 5px' }}>Select existing report</h6>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Select
                                label="Select Past Report"
                                value={selectedReportTitle || ''}
                                disabled={reports && reports.length === 0}
                                displayEmpty
                                onChange={handleReportSelection}
                                sx={{
                                    width: '20vw',
                                    '& .MuiSelect-select': {
                                        padding: '10px',
                                    },
                                }}
                                renderValue={(selected) => {
                                    if (!selected) {
                                        return 'Select Past Report'
                                    }
                                    return selected
                                }}
                            >
                                {reports && reports.map((report) => {
                                    return <MenuItem key={report.reportId} value={report.title}>{report.title}</MenuItem>
                                })}
                            </Select>

                            <button
                                style={{ margin: '0px 10px' }}
                                disabled={(reports && reports.length === 0) || !selectedReportId || !selectedReportTitle}
                                onClick={() => loadReport()}>Load Report
                                <i className="fas fa-file-import"></i>
                            </button>
                            <button
                                disabled={(reports && reports.length === 0) || !selectedReportId || !selectedReportTitle}
                                style={{ margin: '0px 0px' }}
                                onClick={() => deleteReport()}>Delete Report
                                <i className="fas fa-trash-alt"></i>
                            </button>
                        </div>

                        <h6 style={{ margin: '15px 5px 5px' }}>Begin new report</h6>
                        <div style={{ display: 'flex', gap: '10px' }}>
                            <Select
                                label="Select business conclusion"
                                value={selectedConclusions || []}
                                onChange={handleSelectConclusion}
                                disabled={conclusions && conclusions.length === 0}
                                displayEmpty
                                sx={{
                                    width: '20vw',
                                    '& .MuiSelect-select': {
                                        padding: '10px',
                                    }
                                }}

                                renderValue={(selected) => {
                                    if (!selected || selected.length === 0) {
                                        return 'Select Business Conclusion'
                                    }
                                    return selected
                                }
                                }
                            >
                                {conclusions && conclusions.map((conclusion) => {
                                    // filter out empty conclusions:
                                    if (!conclusion.conclusionData.finalConclusion) return null
                                    return <MenuItem key={conclusion.conclusionId} value={conclusion.conclusionData.finalConclusion}>
                                        {conclusion.conclusionData.finalConclusion
                                        }</MenuItem>
                                })}
                            </Select>

                            <Input
                                style={{ margin: '0px 10px', width: '30vw' }}
                                placeholder="Report Title"
                                onChange={(e) => setReportTitle(e.target.value)}
                                value={newReport.title || ''}
                            />
                            <button
                                disabled={selectedConclusions.length === 0 || !newReport.title}
                                style={{ margin: '0px 10px' }}
                                onClick={() => createNewReport()}>Create Report
                                <i className="fas fa-file"></i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="reports-container ">
                    <div className='element-title green'>
                        <h6 >Search past reports</h6>
                    </div>
                    <div className="element-body">
                        <div className="search-input-wrapper">
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Search by title, conclusions or bullet content"
                                value={searchTerm || ''}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                style={{ width: '40%' }}
                            />
                            <i className="fas fa-solid fa-magnifying-glass search-icon"></i>
                        </div>
                        <ReportsTable reports={filteredReports} />
                    </div>
                </div>
                <div className="reports-container ">
                    <div className='element-title green'>
                        <h6 >Search your employees' data</h6>
                    </div>
                    <Box className='element-body'>
                        <Box >
                            <Tabs value={value} onChange={handleTabChange} aria-label="tabs">
                                <Tab label="Search users questions" {...a11yProps(0)}
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '0.95rem',
                                        '&:hover': {
                                            backgroundColor: 'var(--light-grey)',
                                            color: 'var(--strong-green) !important',
                                        },

                                    }} />
                                <Tab label="Search users interviews" {...a11yProps(1)}
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '0.95rem',
                                        '&:hover': {
                                            backgroundColor: 'var(--light-grey)',
                                            color: 'var(--strong-green) !important',
                                        },
                                    }} />
                                <Tab label="Search users conclusions" {...a11yProps(2)}
                                    sx={{
                                        textTransform: 'none',
                                        fontSize: '0.95rem',
                                        '&:hover': {
                                            backgroundColor: 'var(--light-grey)',
                                            color: 'var(--strong-green) !important',
                                        },
                                    }} />
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <div className="search-input-wrapper">
                                <input
                                    className="search-input"
                                    type="text"
                                    placeholder="Search your employees' questions"
                                    value={searchQueryQuestions || ''}
                                    onChange={(e) => handleQuestionSearch(e.target.value)}
                                    name="searchQuery-questions"
                                    style={{ width: '30%' }}
                                />
                                <i className="fas fa-solid fa-magnifying-glass search-icon"></i>
                            </div>

                            <div className='table-container' style={{ maxHeight: '70vh', marginTop: '20px' }} >
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '150px' }}>Date</th>
                                            <th style={{ width: '150px' }}>User name</th>
                                            <th >Final question</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredUsersByQuestions.map((project) => (
                                            <tr key={project.projectId}>
                                                <td>{project.projectDate}</td>
                                                <td>{project.firstName} {project.lastName}</td>
                                                <td>{project.step1.FinalQ}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <div className="search-input-wrapper">
                                <input
                                    type="text"
                                    className="search-input"
                                    value={searchQueryInterviews || ''}
                                    onChange={(e) => setSearchQueryInterviews(e.target.value)}
                                    placeholder="Search employees' interviews"
                                    name="searchQuery-interviews"
                                    style={{ width: '30%' }}
                                />
                                <i className="fas fa-solid fa-magnifying-glass search-icon"></i>
                            </div>
                            <div className='table-container' style={{ maxHeight: '70vh', marginTop: '20px' }} >
                                <table>
                                    <thead>
                                        <tr>
                                            <th style={{ width: '80px' }}>Date</th>
                                            <th style={{ width: '80px' }}>User name</th>
                                            <th style={{ width: '80px' }}>Interviewee</th>
                                            <th style={{ width: '150px' }}>Question</th>
                                            <th style={{ width: '150px' }}>Hearing First</th>
                                            <th style={{ width: '150px' }}>Hearing Second</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredUsersByInterviews && filteredUsersByInterviews.map((user) => {
                                            return user.interviews ? user.interviews.map((interview) => {
                                                const interviewMatches = (
                                                    // add search for {user.firstName} {user.lastName}:
                                                    (user.firstName && user.firstName.toLowerCase().includes(searchQueryInterviews.toLowerCase())) ||
                                                    (user.lastName && user.lastName.toLowerCase().includes(searchQueryInterviews.toLowerCase())) ||
                                                    (interview.interviewData.interviewQuestion && interview.interviewData.interviewQuestion.toLowerCase().includes(searchQueryInterviews.toLowerCase())) ||
                                                    (interview.interviewData.hearingFirst && interview.interviewData.hearingFirst.toLowerCase().includes(searchQueryInterviews.toLowerCase())) ||
                                                    (interview.interviewData.hearingSecond && interview.interviewData.hearingSecond.toLowerCase().includes(searchQueryInterviews.toLowerCase())) ||
                                                    (interview.interviewData.interviewee && interview.interviewData.interviewee.toLowerCase().includes(searchQueryInterviews.toLowerCase())) ||
                                                    (interview.interviewData.interviewDate && interview.interviewData.interviewDate.toLowerCase().includes(searchQueryInterviews.toLowerCase()))
                                                );
                                                return interviewMatches ? (
                                                    <tr key={interview.interviewId}>
                                                        <td>{interview.interviewData.interviewDate}</td>
                                                        <td>{user.firstName} {user.lastName}</td>
                                                        <td>{interview.interviewData.interviewee}</td>
                                                        <td>{interview.interviewData.interviewQuestion}</td>
                                                        <td>{interview.interviewData.hearingFirst}</td>
                                                        <td>{interview.interviewData.hearingSecond}</td>
                                                    </tr>
                                                ) : null;
                                            }) : null;
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                            <div className="search-input-wrapper">
                                <input
                                    type="text"
                                    className="search-input"
                                    value={searchQueryConclusions || ''}
                                    onChange={(e) => setSearchQueryConclusions(e.target.value)}
                                    placeholder="Search employees' conclusions"
                                    name="searchQuery-conclusions"
                                    style={{ width: '30%' }}
                                />
                                <i className="fas fa-solid fa-magnifying-glass search-icon"></i>
                            </div>
                            <div className='table-container' style={{ maxHeight: '70vh', marginTop: '20px' }}>
                                <table >
                                    <thead>
                                        <tr>
                                            <th style={{ width: '80px' }}>Date</th>
                                            <th style={{ width: '80px' }}>user name</th>
                                            <th style={{ width: '80px' }}>Interviewee</th>
                                            <th style={{ width: '200px' }}>Question (objective)</th>
                                            <th style={{ width: '200px' }}>Final conclusion</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredUsersByConclusions && filteredUsersByConclusions.map((conclusion) => (
                                            <tr key={conclusion.conclusionId}>
                                                <td>{conclusion.conclusionData.conclusionDate}</td>
                                                <td>{conclusion.userName}</td>
                                                <td>{conclusion.conclusionData.interviewee}</td>
                                                <td>{conclusion.conclusionData.selectedQuestion}</td>
                                                <td>{conclusion.conclusionData.finalConclusion}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </CustomTabPanel>
                    </Box>
                </div>
            </div>

            {/* <div className="pagination">
                <button onClick={() => navigate('/')}>Back to homepage</button>
            </div> */}
        </div >
    );
}

export default withAuthentication(Reports1);

// const normalizeDate = (dateString) => {
//     if (!dateString) return '';
//     // If the date string is in a recognizable format, return it in "YYYY-MM-DD" format.
//     const date = new Date(dateString);
//     if (isNaN(date.getTime())) return ''; // Invalid date
//     return date.toISOString().split('T')[0]; // Extracts the "YYYY-MM-DD" part
// };