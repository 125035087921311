
function Loading() {
    return (
        <div className="app-loading">

            <h5 style={{ marginBottom: '15px', color: 'var(--strong-green)' }}>Loading...</h5>
            <div style={{ marginTop: '25px' }}>
                <i 
                style={{ color: 'var(--strong-green)', fontSize: '50px' }}
                className="fa fa-spinner fa-spin"></i>
            </div>
        </div>
    )
}
export default Loading;
