import { useContext, useState } from "react";
import { Context } from "../Context";
import Loading from "../components/Loading";
import CookieBanner from '../components/CookieBanner';

function CookiePolicy() {
    const { loading } = useContext(Context);
    const [showBanner, setShowBanner] = useState(false);

    const handleShowBanner = () => {
        setShowBanner(!showBanner);
    };

    if (loading) {
        return <Loading />;
    }
    return (
        <div className="app-home-container">
            <div className='app-title'>
                <h2>Cookie Policy & Terms of Use</h2>
                <p>This document was last updated on: June 18, 2024</p>
            </div>
            <div className="cookie-container">
                <h3>Cookie Policy</h3>
                <div className="cookie-text">
                    <p>HUMINT Solutions ("Company", "us", "we", or "our") operates the https://tinokys.com/ and https://humints.com websites (hereinafter referred to as the "Service").
                        This page informs you of our policies regarding the use of cookies and similar tracking technologies on our Service.
                        We use cookies to provide and improve the Service. By using the Service, you agree to the use of cookies in accordance with this policy.
                        Questions or concerns? Reading this cookie notice will help you understand your cookie rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns,
                        please contact us at <a style={{ textDecoration: 'underline' }} href="mailto:info@humints.com">info@humints.com</a>.</p>
                </div>
                <div className="cookie-text">
                    <h5>What are cookies?</h5>
                    <p>Cookies are small files stored on your device (computer or mobile device) that hold data specific to a particular user and website. This allows the server to deliver a page tailored to a particular user, or the page itself can contain some script which is aware of the data in the cookie and so is able to carry information from one visit to the website (or related site) to the next.</p>
                </div>
                <div className="cookie-text">
                    <h5>How we use cookies</h5>
                    <p>We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
                </div>
                <div className="cookie-text">
                    <h5>Types of cookies we use</h5>
                    <p>We use the following types of cookies:</p>
                    <ul>
                        <li><strong>Necessary Cookies:</strong> Necessary cookies enable core functionality such as security, network management, and accessibility. You may disable these by changing your browser settings, but this may affect how the website functions.</li>
                        <li><strong>Preference Cookies:</strong> Preference cookies enable a website to remember information that changes the way the website behaves or looks, like your preferred language or the region that you are in.</li>
                        <li><strong>Statistics Cookies:</strong> Statistic cookies help website owners to understand how visitors interact with websites by collecting and reporting information anonymously.</li>
                        <li><strong>Marketing Cookies:</strong> Marketing cookies are used to track visitors across websites. The intention is to display ads that are relevant and engaging for the individual user and thereby more valuable for publishers and third party advertisers.</li>
                    </ul>
                </div>
                <div className="cookie-text">
                    <h5>Consent to use cookies</h5>
                    <p>By using our Service, you consent to the use of cookies in accordance with this Cookie Policy. When you visit our website, you will be presented with a cookie banner requesting your consent to set those cookies which are not strictly necessary for the provision of the website's services.</p>
                </div>
                <div className="cookie-text">
                    <h5>How to manage cookies</h5>
                    <p>You have the right to decide whether to accept or reject cookies. You can exercise your cookie preferences by clicking on the appropriate opt-out links provided below.</p>
                    <p>Additionally, most web browsers provide controls that allow you to manage cookie preferences. You can set your browser to refuse cookies or delete certain cookies. Please note that if you choose to block cookies, this may impair or prevent due functioning of the Service.</p>
                    <ul>
                        <li>For the Chrome web browser, please visit this page from Google: <a href="https://support.google.com/accounts/answer/32050">https://support.google.com/accounts/answer/32050</a></li>
                        <li>For the Internet Explorer web browser, please visit this page from Microsoft: <a href="http://support.microsoft.com/kb/278835">http://support.microsoft.com/kb/278835</a></li>
                        <li>For the Firefox web browser, please visit this page from Mozilla: <a href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored">https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a></li>
                        <li>For the Safari web browser, please visit this page from Apple: <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a></li>
                    </ul>
                    <p>
                        To opt out of being tracked by Google Analytics across all websites, visit <a href="http://tools.google.com/dlpage/gaoptout">http://tools.google.com/dlpage/gaoptout</a>.
                    </p>
                    <p>For any other web browser, please visit your web browser's official web pages.</p>

                    <button onClick={handleShowBanner}>Change Cookie Preferences</button>
                    <CookieBanner onShowBanner={showBanner} />
                </div>
                <div className="cookie-text">
                    <h5>Your rights under GDPR</h5>
                    <p>If you are a resident of the European Economic Area (EEA), you have certain data protection rights regarding your personal data. You have the right to access, rectify, or erase your personal data, and to restrict or object to certain data processing activities.</p>
                    <p>To make such requests, please contact us at <a style={{ textDecoration: 'underline' }} href="mailto:info@humints.com">info@humints.com</a>. We may ask you to verify your identity before responding to such requests. You also have the right to lodge a complaint with a data protection authority about our collection and use of your personal data. For more information, please contact your local data protection authority in the EEA.</p>
                </div>
                <div className="cookie-text">
                    <h5>More information about cookies</h5>
                    <p>You can learn more about cookies at the following third-party websites:</p>
                    <ul>
                        <li><a href="https://www.allaboutcookies.org">All About Cookies</a></li>
                        <li><a href="https://www.networkadvertising.org">Network Advertising Initiative</a></li>
                    </ul>
                </div>
                <div className="cookie-text">
                    <h5>Changes to this Cookie Policy</h5>
                    <p>We may update our Cookie Policy from time to time. We will notify you of any changes by posting the new Cookie Policy on this page. We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the top of this Cookie Policy.</p>
                    <p>You are advised to review this Cookie Policy periodically for any changes. Changes to this Cookie Policy are effective when they are posted on this page.</p>
                </div>

                <h3>Terms of Use</h3>
                <div className="cookie-text">
                    <h5>Introduction</h5>
                    <p>Welcome to HUMINT Solutions ("Company", "we", "our", "us"). These Terms of Use ("Terms") govern your use of our websites located at https://tinokys.com/ and https://humints.com (collectively, the "Service").</p>

                </div>
                <div className="cookie-text">
                    <h5>Acceptance of Terms</h5>
                    <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms, then you may not access the Service.</p>
                </div>
                <div className="cookie-text">
                    <h5>Termination</h5>
                    <p>We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
                    <p>All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.</p>
                </div>
                <div className="cookie-text">
                    <h5>Use of the Service</h5>
                    <p>You agree to use the Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service:</p>
                    <ul>
                        <li>In any way that violates any applicable national or international law or regulation.</li>
                        <li>For the purpose of exploiting, harming, or attempting to exploit or harm minors in any way by exposing them to inappropriate content or otherwise.</li>
                        <li>To transmit, or procure the sending of, any advertising or promotional material, including any "junk mail", "chain letter," "spam," or any other similar solicitation.</li>
                        <li>To impersonate or attempt to impersonate the Company, a Company employee, another user, or any other person or entity.</li>
                        <li>To engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Service, or which, as determined by us, may harm the Company or users of the Service or expose them to liability.</li>
                    </ul>
                </div>
                <div className="cookie-text">
                    <h5>Links to Other Websites</h5>
                    <p>Our Service may contain links to third-party websites or services that are not owned or controlled by HUMINT Solutions.</p>
                    <p>HUMINT Solutions has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that HUMINT Solutions shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such websites or services.</p>
                </div>
                <div className="cookie-text">
                    <h5>Changes to Terms</h5>
                    <p>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page. You are advised to review these Terms periodically for any changes. Changes to these Terms are effective when they are posted on this page.</p>
                </div>
                <div className="cookie-text">
                    <h5>Miscellaneous</h5>
                    <p>These Terms constitute the entire agreement between HUMINT Solutions and you concerning your use of the Service, superseding any prior agreements between you and HUMINT Solutions regarding the Service.</p>
                    <p>If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions of these Terms will remain in effect. These Terms are governed by the laws of the State of Israel.</p>

                </div>

                <div className="cookie-text">
                    <h5>Contact Us</h5>
                    <p>If you have any questions about this Cookie Policy, please <a style={{ textDecoration: 'underline' }} href="mailto:info@humints.com">email us</a>.</p>
                </div>
            </div>
        </div>
    )
}

export default CookiePolicy;
