import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getAuth, connectAuthEmulator } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyDLdVkbGpqpiyTRLMes9F5tsdCawbkj1tY",
    authDomain: "tinokys-1181e.firebaseapp.com",
    projectId: "tinokys-1181e",
    storageBucket: "tinokys-1181e.appspot.com",
    messagingSenderId: "311744533734",
    appId: "1:311744533734:web:3a971306bbabf63d539727",
    measurementId: "G-8DE9C2RE94"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const functions = getFunctions(app);
const analytics = getAnalytics(app);

// Comment off to connect to emulators in development environment
// if (process.env.NODE_ENV === 'development') {
//     try {
//         connectFirestoreEmulator(db, 'localhost', 8080);
//         connectFunctionsEmulator(functions, 'localhost', 5001);
//         connectAuthEmulator(auth, 'http://localhost:9099');
//         console.log('Connected to emulators');
//     } catch (e) {
//         console.error('Could not connect to emulators', e);

//     }
// }

export { db, firebaseConfig, app, auth, functions, analytics };