import React from 'react'
import { useContext, useEffect, useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import { Context } from '../Context';
import CountUp from 'react-countup';

// HOC
import withAuthentication from "../withAuthentication";

// Components
import Loading from "../components/Loading";

// MUI Components
import { Alert, Snackbar, Button, Paper, Card, CardContent, Grid } from '@mui/material';

// Charts
import KeywordChart from '../components/KeywordChart';

// Icons
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import QuizIcon from '@mui/icons-material/Quiz';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PsychologyIcon from '@mui/icons-material/Psychology';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import MailIcon from '@mui/icons-material/Mail';

function Homepage() {
    const { userState, loading } = useContext(Context);
    const { projects, interviews, hasChangedPassword, contacts, concluding, questionsFromAdmin } = userState;

    // for password reset email
    const [showSnackbar, setShowSnackbar] = useState({ open: false, message: '' });

    // state calculations for dashboard
    const [openProjects, setOpenProjects] = useState([])
    const [closedProjects, setClosedProjects] = useState([])
    const [unansweredAdminQuestions, setUnansweredAdminQuestions] = useState([])
    const [contactsAnalyzed, setContactsAnalyzed] = useState(0)

    const cardStyle = {
        borderRadius: '10px',
        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
        transition: 'transform 0.3s ease',
        padding: '0px',
        '&:hover': {
            transform: 'translateY(-5px)',
            boxShadow: '0px 6px 20px rgba(0, 0, 0, 0.15)',
        }
    }
    const iconStyle = {
        backgroundColor: '#E0F2F1',
        borderRadius: '50%',
        padding: '8px',
        fontSize: '40px',
        color: '#009688',
    };

    // calculations for dashboard
    // check if user has projects (questions) and filter empty projects
    useEffect(() => {
        if (loading) return
        if (projects && projects.length > 0) {
            // filter empty projects
            const emptyProjects = projects.filter(project => !project.step1.MainQ && !project.step1.FinalQ && !project.step1.ImprovedQ &&
                !project.step1.Q1 && !project.step1.Q2 && !project.step1.Q3 && !project.step1.Q4 && !project.step1.Q5 &&
                !project.step1.Q6 && !project.step1.Q7 && !project.step1.Q8 && !project.step2.zoomInQuestions.zoomin1 &&
                !project.step2.zoomInQuestions.zoomin2 && !project.step2.zoomInQuestions.zoomin3 && !project.step2.zoomInQuestions.zoomin4
                && !project.step2.zoomInQuestions.zoomin5 && !project.step2.zoomInQuestions.zoomin6 &&
                !project.step2.zoomInQuestions.zoomin7 && !project.step2.zoomInQuestions.zoomin8 &&
                !project.step2.zoomOutQuestions.zoomout1 && !project.step2.zoomOutQuestions.zoomout2
                && !project.step2.zoomOutQuestions.zoomout3 && !project.step2.zoomOutQuestions.zoomout4
                && !project.step2.zoomOutQuestions.zoomout5 && !project.step2.zoomOutQuestions.zoomout6
                && !project.step2.zoomOutQuestions.zoomout7 && !project.step2.zoomOutQuestions.zoomout8)

            if (emptyProjects.length > 0) {
                setOpenProjects(projects.filter(p => !emptyProjects.includes(p)))
            }

            const filteredProjects = projects.filter(p => p.step1.MainQ !== "" && p.step1.FinalQ !== "")
            setClosedProjects(filteredProjects)
        }

    }, [projects, loading]);

    // check if user has unanswered questions from admin
    useEffect(() => {
        if (loading) return

        if (questionsFromAdmin && Array.isArray(questionsFromAdmin) && questionsFromAdmin.length > 0) {
            const allQuestionsFromAdminArray = questionsFromAdmin.map(q => q.question)
            const mainQuestionsFromProjects = projects && projects.map(p => p.step1.MainQ)
            const unansweredQuestions = allQuestionsFromAdminArray.filter(q => !mainQuestionsFromProjects.includes(q))
            setUnansweredAdminQuestions(unansweredQuestions)
        }
    }, [questionsFromAdmin, loading, projects]);

    // check how many contacts have been analyzed
    useEffect(() => {
        if (loading) return

        if (contacts && contacts.length > 0) {
            const contactsAnalyzed = contacts.filter(c =>
                c.analyze && c.analyze.length > 0 && c.analyze[0].answers !== '' && Object.keys(c.analyze[0].answers).length > 0
            ).length;
            setContactsAnalyzed(contactsAnalyzed)
        }
    }, [contacts, loading]);

    // send password reset email:
    const handlePasswordReset = async () => {
        console.log('Attempting to send password reset email to:', auth.currentUser.email);
        try {
            await sendPasswordResetEmail(auth, auth.currentUser.email);

            setShowSnackbar({
                open: true,
                message: 'Password reset email sent successfully! Please check your inbox.',
            });
        } catch (error) {
            console.error('Error sending password reset email:', error);
            setShowSnackbar({
                open: true,
                message: 'Failed to send password reset email. Please try again later.',
            });
        }
    };

    if (loading) return <Loading />

    return (
        <div className="homepage-container">

            {(!hasChangedPassword || hasChangedPassword === false) &&
                <div style={{ marginBottom: '25px' }}>
                    <Paper
                        elevation={4}
                        style={{ width: '50vw', padding: '20px', backgroundColor: 'white', color: '#2a2a2a', borderRadius: '10px' }}>
                        <p>Please reset your password for security reasons. Click the button below to send a password reset email to your inbox and follow the instructions. It should only take a few minutes.</p>
                        <Button
                            variant="contained"
                            style={{ marginTop: '15px', textTransform: 'none', color: 'white' }}
                            onClick={handlePasswordReset}>
                            Send Password Reset Email
                        </Button>
                    </Paper>
                    <Snackbar
                        open={showSnackbar.open}
                        autoHideDuration={6000}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        onClose={() => setShowSnackbar({ open: false, message: '' })}
                    >
                        <Alert onClose={() => setShowSnackbar({ open: false, message: '' })} sx={{ width: '100%' }}>
                            {showSnackbar.message}
                        </Alert>
                    </Snackbar>
                </div>
            }

            <div className="dashboard-container">
                <Grid container spacing={4} >
                    <Grid item xs={12} sm={3}>
                        <Card sx={cardStyle} >
                            <CardContent className="homepage-card-content">
                                <MailIcon className="homepage-card-icon" sx={iconStyle} />
                                <h4>
                                    <CountUp
                                        start={0}
                                        end={unansweredAdminQuestions && unansweredAdminQuestions.length ? unansweredAdminQuestions.length : 0}
                                        duration={1.75}
                                    />
                                </h4>
                                <h6>Admin questions waiting</h6>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Card sx={cardStyle}>
                            <CardContent className="homepage-card-content">
                                <QuestionAnswerIcon className="homepage-card-icon" sx={iconStyle} />
                                <h4 >
                                    <CountUp
                                        start={0}
                                        end={openProjects && openProjects.length ? openProjects.length : 0}
                                        duration={1.75}
                                    />
                                </h4>
                                <h6  >Questions started</h6>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Card sx={cardStyle}>
                            <CardContent className="homepage-card-content">
                                <QuizIcon className="homepage-card-icon" sx={iconStyle} />
                                <h4 >
                                    <CountUp
                                        start={0}
                                        end={closedProjects && closedProjects.length ? closedProjects.length : 0}
                                        duration={1.75}
                                    />
                                </h4>
                                <h6  >Questions fully analyzed</h6>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Card sx={cardStyle}>
                            <CardContent className='homepage-card-content'>
                                <PersonAddIcon className="homepage-card-icon" sx={iconStyle} />
                                <h4 >
                                    <CountUp
                                        start={0}
                                        end={contacts && contacts.length ? contacts.length : 0}
                                        duration={1.75}
                                    />
                                </h4>
                                <h6 >Contacts added</h6>
                            </CardContent>
                        </Card>
                    </Grid>


                    <Grid item xs={12} sm={9}>
                        <Card sx={cardStyle}>
                            <CardContent className="homepage-card-content">
                                <h6 >Your 10 most used keywords</h6>
                                <KeywordChart contacts={contacts} />
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={3} >
                        <Grid item xs={12} sm={12}>
                            <Card sx={cardStyle}>
                                <CardContent className="homepage-card-content">
                                    <PersonSearchIcon className="homepage-card-icon" sx={iconStyle} />
                                    <h4 >
                                        <CountUp
                                            start={0}
                                            end={contactsAnalyzed || 0}
                                            duration={1.75}
                                        />
                                    </h4>
                                    <h6 >Contacts analyzed</h6>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Card sx={cardStyle} style={{ marginTop: '20px' }}>
                                <CardContent className="homepage-card-content">
                                    <PsychologyIcon className="homepage-card-icon" sx={iconStyle} />
                                    <h4>
                                        <CountUp
                                            start={0}
                                            end={interviews && interviews.length ? interviews.length : 0}
                                            duration={1.75}
                                        />
                                    </h4>
                                    <h6>Interviews conducted</h6>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Card sx={cardStyle} style={{ marginTop: '20px' }}>
                                <CardContent className="homepage-card-content">
                                    <PsychologyAltIcon className="homepage-card-icon" sx={iconStyle} />
                                    <h4>
                                        <CountUp
                                            start={0}
                                            end={concluding && concluding.length ? concluding.length : 0}
                                            duration={1.75}
                                        />
                                    </h4>
                                    <h6>Interviews analyzed</h6>
                                </CardContent>
                            </Card>
                        </Grid>

                        {/* <Grid item xs={12} sm={12}>
                            <Card sx={cardStyle} style={{ marginTop: '20px' }}>
                                <CardContent className="homepage-card-content">
                                <PersonSearchIcon sx={iconStyle} />
                                    <h4>{results && results.length ? results.length : 0}</h4>
                                    <h6>Right leg analysis</h6>
                                </CardContent>
                            </Card>
                        </Grid> */}

                    </Grid>

                </Grid>
            </div>
        </div>
    );

}

export default withAuthentication(Homepage, false, false);