import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import { nanoid } from 'nanoid'
import LineGraph from '../../components/LineGraph';
import Loading from '../../components/Loading';
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import withAuthentication from "../../withAuthentication";
import { Tooltip } from 'react-tooltip';
import jsPDF from 'jspdf';

function Map1() {
    const { userState, updateData, loading } = useContext(Context);
    const { maps, age } = userState;
    const navigate = useNavigate()

    const [isDisabled, setDisabled] = useState(false)
    const [isModified, setModified] = useState(false)
    const [userAge, setUserAge] = useState('')
    const [message, setMessage] = useState('')
    const [isSaving, setSaving] = useState(false)
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };

    // render two maps and keep state for any change
    const [renderedChangesMap, setRenderedChangesdMap] = useState([])
    const [renderedDecisionsMap, setRenderedDecisionsdMap] = useState([])

    // header based on user's age
    const [tableRowYears, setTableRowYears] = useState([])

    // updating data locally for each individual <select> as user selects
    const [changesMap, setChangesMap] = useState([])
    const [decisionsMap, setDecisionsMap] = useState([])

    // calculations based on user's age
    const year = (new Date()).getFullYear() + 1;
    const years = Array.from(new Array(80), (val, index) => (year) - (index));
    const startingYear = year - userAge
    const mapYears = Array.from(new Array(userAge), (val, index) => (index) + (startingYear));
    const categories = ['Education', 'Career', 'Family', 'Health', 'Wealth', 'ClarityDev']
    const chartRefs = {};

    // Initialize arrays to hold sum of changes and decisions for each category (for graph)
    const [changesSum, setChangesSum] = useState(0);
    const [decisionsSum, setDecisionsSum] = useState(0);

    // Dialog
    const [route, setRoute] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    // const handleDialogOpen = (route) => {
    //     if (isModified) {
    //         setOpenDialog(true);
    //         setRoute(route);
    //     } else {
    //         navigate(route);
    //     }
    // }

    const handleDialogClose = (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            navigate(route);
        }
    }

    // load user's data from maps 
    useEffect(() => {
        if (loading || !maps) return
        //if maps is an array, it means it's empty, else, convert maps to nested arrays:
        if (Array.isArray(maps)) return
        const newChangesMap = Object.keys(maps.changes)
            .sort((a, b) => a.localeCompare(b)) // Sort the keys in the correct order
            .map((key) => maps.changes[key]);

        const newDecisionsMap = Object.keys(maps.decisions)
            .sort((a, b) => a.localeCompare(b)) // Sort the keys in the correct order
            .map((key) => maps.decisions[key]);

        setChangesMap(newChangesMap);
        setDecisionsMap(newDecisionsMap);

        if (age) {
            setUserAge(age)
        }
        const headers = getHeaders();
        setTableRowYears(headers);
        return

    }, [maps, loading, userAge,]);

    function createUserMaps() {
        const newChangesMap = createNewMap();
        const newDecisionap = createNewMap();
        setChangesMap(newChangesMap);
        setDecisionsMap(newDecisionap);
        setModified(true);
        const headers = getHeaders();
        setTableRowYears(headers);
    }

    // update map data based on user's selection
    useEffect(() => {
        if (loading || !maps || changesMap.length === 0) return

        renderChangesMap()
        renderDecisionMap()

        // calculate sum of the cell.selected values entered for each category (row) of each map:
        const newChangesSum = Array(changesMap[0].length).fill(0);
        const newDecisionsSum = Array(decisionsMap[0].length).fill(0);

        changesMap.forEach(row => {
            row.forEach((cell, index) => {
                const { selected } = cell;
                if (!Number.isNaN(selected)) {
                    newChangesSum[index] += parseInt(selected);
                }
            });
        });
        decisionsMap.forEach(row => {
            row.forEach((cell, index) => {
                const { selected } = cell;
                if (!Number.isNaN(selected)) {
                    newDecisionsSum[index] += parseInt(selected);
                }
            });
        });

        setChangesSum(newChangesSum);
        setDecisionsSum(newDecisionsSum);

    }, [changesMap, decisionsMap, loading, maps]);

    // create new map data and set state for each map
    function createNewMap() {
        const newMap = getDataMap()
        return newMap
    }

    // create new empty map data array based on user's age
    function getDataMap() {
        var newMap = []

        for (var i = 0; i < 6; i++) {
            newMap[i] = []
            for (var j = 0; j < userAge; j++) {
                newMap[i][j] = { selected: 0, index: [i, j] }
            }
        }
        return newMap
    }

    function renderChangesMap() {
        if (loading || !maps || changesMap.length === 0) return
        var classnames = 'mapsSelect ';
        const changesTable = categories.map((category, index) => {
            var tdData = [];
            changesMap[index].forEach((cell, cellIndex) => {
                var tdClassName = 'tableNums mapsTd';
                var selectName = cell.index[1];
                var selectValue = cell.selected;
                var td = (
                    <td key={nanoid()} className={tdClassName}>
                        <select
                            className={cell.selected === 0 ? classnames : classnames + 'optionSelected'}
                            name={selectName}
                            value={selectValue}
                            onChange={handleChange}
                            disabled={isDisabled}
                        >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </td>
                );
                tdData.push(td);
            });
            return (

                <tr className={index} key={nanoid()}>
                    <th className='tableNums mapsTh' scope="row">{category}</th>
                    {tdData}
                </tr>
            );
        });
        setRenderedChangesdMap(changesTable);
    }

    function renderDecisionMap() {
        if (loading || !maps || decisionsMap.length === 0) return
        var classnames = 'mapsSelect ';
        const decisionsTable = categories.map((category, index) => {
            var tdData = [];
            decisionsMap[index].forEach((cell, cellIndex) => {
                var tdClassName = 'tableNums mapsTd';
                var selectName = cell.index[1];
                var selectValue = cell.selected;

                var td = (
                    <td key={nanoid()} className={tdClassName}>
                        <select
                            className={cell.selected === 0 ? classnames : classnames + 'optionSelected'}
                            name={selectName}
                            value={selectValue}
                            onChange={handleChange}
                            disabled={isDisabled}
                        >
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </td>
                );
                tdData.push(td);
            });
            return (
                <tr className={index} key={nanoid()}>
                    <th className='tableNums mapsTh' scope="row">{category}</th>
                    {tdData}
                </tr>
            );
        });
        setRenderedDecisionsdMap(decisionsTable);
    }

    // get table headers
    function getHeaders() {
        const tableRowYears = mapYears.map((year) => (
            <th key={nanoid()} className='tableNums mapsTh' scope="row">{year}</th>
        ))
        return tableRowYears
    }

    function handleChange(e) {
        const { name, value } = e.target
        setModified(true)

        if (!value) return
        if (name === 'birthYear') {
            const age = year - (value)
            setUserAge(age)

        } else {
            const tableType = e.target.parentElement.parentElement.parentElement.className
            const rowIdx = e.target.parentElement.parentElement.className
            const colIdx = name
            const selected = value
            updateTable(+colIdx, +rowIdx, +selected, tableType)
        }
    }

    function updateTable(colIdx, rowIdx, selected, tableType) {
        if (tableType === "changes") {
            const updatedMap = [...changesMap];
            updatedMap[rowIdx][colIdx].selected = selected;
            setChangesMap(updatedMap);
        } else {
            const updatedMap = [...decisionsMap];
            updatedMap[rowIdx][colIdx].selected = selected;
            setDecisionsMap(updatedMap);
        }
    }

    async function deleteMaps() {
        // before deleting maps, ask user to confirm:
        const confirmDelete = window.confirm('Are you sure you want to delete your maps? This will erase all your data. This action cannot be undone.')
        if (!confirmDelete) return

        // if user confirms, delete maps from db:
        const emptyMapsObject = {
            changes: {},
            decisions: {}
        };
        try {
            await updateData('maps', emptyMapsObject)
            await updateData('age', '')

            setMessage('Maps deleted successfully')
            setTimeout(() => {
                setMessage('')
            }, 3500)

        } catch (error) {
            console.error('Error deleting maps:', error);
            setMessage('Error deleting maps')
        }
    }

    async function saveMaps() {
        const mapsObject = {
            changes: changesMap.reduce((acc, cur, i) => ({ ...acc, [`row${i}`]: cur }), {}),
            decisions: decisionsMap.reduce((acc, cur, i) => ({ ...acc, [`row${i}`]: cur }), {}),
        };

        setSaving(true)
        try {
            await updateData('maps', mapsObject)
            await updateData('age', userAge)

            setTimeout(() => {
                setSaving(false)
                setMessage('Maps saved successfully')
            }, 2000)

            setTimeout(() => {
                setMessage('')
                setDisabled(false)
                setModified(false)
            }, 2500)

        } catch (error) {
            console.log(error)
            setMessage('Error saving data', error);
            setTimeout(() => {
                setMessage('');
            }, 2000);
        }
    }

    // functions to allow user to grab content of charts and download them as png:
    function canvasToPngDataURL(canvas) {
        return canvas.toDataURL('image/png');
    }

    const handleCanvasReady = (chartName, canvas) => {
        chartRefs[chartName] = canvas;
    };

    const downloadChartAsPDF = (chartName) => {
        const pngData = canvasToPngDataURL(chartRefs[chartName]);
        let firstnameCapital = userState.firstName.charAt(0).toUpperCase() + userState.firstName.slice(1)
        let lastnameCapital = userState.lastName.charAt(0).toUpperCase() + userState.lastName.slice(1)
        const userNameInCapital = firstnameCapital + ' ' + lastnameCapital
        //download as pdf:
        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true,
            compress: true,
            precision: 2,
            lineHeight: 1.3,
        })

        let currentY = 14;
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'bold');

        pdf.text(`Changes & Decisions - ${userNameInCapital} `,
            pdf.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 10;
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'normal');
        pdf.text(`${new Date().toLocaleDateString("en-GB", dateOptions)} `,
            pdf.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 10;
        // set image width as full page width minus left and right margin
        const imgWidth = pdf.internal.pageSize.getWidth() - 20;
        const imgHeight = (imgWidth * 9) / 16;

        const pageWidth = pdf.internal.pageSize.getWidth();
        const xCentered = (pageWidth - imgWidth) / 2;

        pdf.addImage(pngData, 'PNG', xCentered, currentY, imgWidth, imgHeight);  // 10 is the left margin, adjust as needed
        pdf.save(`Changes & Decisions Maps- ${userNameInCapital}`); // Generated PDF
    };

    if (loading) return <Loading />

    return (
        <div >
            <div className="app-home-container">
                <div className='app-title'>
                    <h4>Changes & Decisions - Right Leg Map Tool
                        <i data-tooltip-id="mapTitle"
                            data-tooltip-place="bottom"
                            data-tooltip-content="For each map, select the strength of the impact of each event in your life according to the categories on the left side of the table,
                             and the years on the top. 0 = no impact, 5 = high impact. The sum of the selected values for each category will be displayed in the graph below."                            className="fa fa-question-circle" aria-hidden="true"></i>
                        <Tooltip
                            className="tooltip"
                            style={{ zIndex: 1 }}
                            id="mapTitle" />
                    </h4>
                    <h6>Create maps to analyze your life</h6>
                    <p>Map meaningful changes and related decisions, while indicating the impact strength of each event. This exercise will provide you with a visual representation of your life's journey, helping you identify key milestones, understand the consequences of decisions, and gain valuable insights for future planning and personal development</p>
                </div>
                <div className='map-btns-top-container '>
                    <div >
                        <select
                            name='birthYear'
                            style={{ width: 'fit-content' }}
                            disabled={changesMap.length !== 0 && userAge !== 0}
                            value={startingYear || ''}
                            onChange={handleChange}>
                            <option value="">When were you born?</option>
                            {years.map((year, index) => {
                                return <option key={`year${index}`} value={year}>{year}</option>
                            })}
                        </select>
                        <button disabled={changesMap.length !== 0} onClick={createUserMaps}>Create Maps</button>
                    </div>
                    <button disabled={changesMap.length === 0} onClick={deleteMaps}>Reset maps and start over</button>
                </div>
                <p className='successMessage'>{(!maps || maps.length === 0 || changesMap.length === 0) ? '' : '* You have already created your maps. Continue working on them below'}</p>
                <p className='successMessage' style={{ height: '5px' }}>{message}</p>

                <div className='map-container'>
                    <div className='map-top-container'>
                        {changesMap.length === 0 ? <div className='mapsEmptyContainers'></div> :
                            <div className='changes-container'>
                                <div className='element-title'>
                                    <h6>Changes Map</h6>
                                </div>
                                <div className='table-container element-body' style={{ overflowY: 'hidden', overflowX: 'scroll' }}>

                                    <table style={{ whiteSpace: 'nowrap' }} className='changes-table'>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                {tableRowYears}
                                            </tr>
                                        </thead>
                                        <tbody className='changes' >
                                            {renderedChangesMap}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                        {changesMap.length === 0 ? <div className='mapsEmptyContainers'></div> :
                            <div className='decisions-container'>
                                <div className='element-title'>
                                    <h6>Decisions Map</h6>
                                </div>
                                <div className='table-container element-body' style={{ overflowY: 'hidden', overflowX: 'scroll' }}>
                                    <table style={{ whiteSpace: 'nowrap' }} className='decisions-table'>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                {tableRowYears}
                                            </tr>
                                        </thead>
                                        <tbody className='decisions' >
                                            {renderedDecisionsMap}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        }
                    </div>
                    {changesMap.length === 0 ? <div className='mapsEmptyContainers'></div> :
                        <div className='map-bottom-container element-body'>
                            <LineGraph changesSum={changesSum} decisionsSum={decisionsSum} years={mapYears} onCanvasReady={(canvas) => handleCanvasReady('lineGraph', canvas)} />
                        </div>
                    }
                    {changesMap.length === 0 ?
                        <div className='mapsEmptyContainers'></div> :

                        <div style={{ flexDirection: 'column' }} className='bottomNavigation'>
                            <button
                                style={{ width: '250px' }}
                                className='savingBtn'
                                onClick={saveMaps}
                                disabled={isDisabled}>Save Maps
                            </button>
                            <div>
                                <button
                                    style={{ width: '250px', marginTop: '20px' }}
                                    className='savingBtn'
                                    onClick={() => downloadChartAsPDF('lineGraph')}
                                    disabled={isDisabled}>Download Graph as PDF
                                </button>

                                <button
                                    style={{ width: '250px', marginTop: '20px' }}
                                    onClick={(e) => window.print(e)}>Print / Download whole page
                                </button>
                            </div>
                            <div className="successMessage">
                                {message ? message : ''}
                                {isSaving && (
                                    <div>
                                        <i className="fas fa-spinner fa-spin"></i> Saving...
                                    </div>
                                )}
                            </div>
                        </div>
                    }

                </div>

                <div className='pagination'>
                    <Dialog
                        open={openDialog}
                        onClose={() => handleDialogClose(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle className="dialog-title" id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
                        <DialogContent className="dialog-content">
                            <DialogContentText id="alert-dialog-description">
                                You have unsaved changes. Are you sure you want to leave this page?
                            </DialogContentText>
                        </DialogContent>
                        <div className="dialog-actions">
                            <button style={{ backgroundColor: 'grey' }} className="dialog-button" onClick={() => handleDialogClose(false)}>Cancel</button>
                            <button className="dialog-button" onClick={() => handleDialogClose(true)} autoFocus>Yes</button>
                        </div>
                    </Dialog>
                    {/* <button onClick={() => handleDialogOpen('/')}>Back to homepage</button> */}
                </div>
            </div>
        </div>
    );

}

export default withAuthentication(Map1);