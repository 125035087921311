import React, { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';

function NetworkChart({ networkChartValues, page, onCanvasReady }) {
    const chartRef = useRef(null);

    useEffect(() => {
        const chartCtx = chartRef.current.getContext('2d');
        Chart.defaults.font.size = 15;
        // console.log(networkChartValues, 'networkChartValues')
        if (page === 'cv') {
            chartCtx.canvas.width = 600;
            chartCtx.canvas.height = 400;
            Chart.defaults.font.size = 14;
        } else {
            chartCtx.canvas.width = 1000;
            chartCtx.canvas.height = 450;
        }
       
        const chart = new Chart(chartCtx, {
            type: 'bar',
            data: {
                labels: ['Network Members', 'Maxmial Density',
                'Relations with 0','Number of mutual relations', 
                'Network Density',
                    'Network Relations Strength',
                    'Minimal Strength Acquaintance (1)',
                    'Strength 2 Acquaintance (2)',
                    'Strength 3 Acquaintance (3)',
                    'Strength 4 Acquaintance (4)',
                    'Maximal Strength Acquaintance (5)'],
                datasets: [{
                    label: 'Score',
                    data: networkChartValues,
                    backgroundColor: [
                        '#219F8Eff',
                        '#34A686ff',
                        '#48AD7Eff',
                        '#5BB475ff',
                        '#6FBB6Dff',
                        '#82C265ff',
                        '#96C95Dff',
                        '#A9D055ff',
                        '#BDD74Dff',
                        '#D0DE44ff',
                        '#E4E53Cff',
                        '#F7EC34ff',
                        '#F7EC34ff',
                        '#F7EC34ff',
                        '#F7EC34ff',
                        '#F7EC34ff',
                        '#F7EC34ff',
                        '#F7EC34ff',
                    ],
                    borderColor: 'transparent',
                    borderWidth: 1,
                    barPercentage: 0.7, // set to 0.8 for 20% gap between bars
                    categoryPercentage: 0.8 // set to 0.9 for 10% gap between categories
                }]
            },
            options: {
                indexAxis: 'y',
                responsive: false,
                // maintainAspectRatio: true,
                plugins: {
                    legend: {
                        position: 'bottom',
                        textAlign: 'center',
                        align: 'center',
                        display: (page === 'cv') ? true : false,
                        padding: 20,
                        labels: {
                            color: '#2f3540',
                            boxWidth: 0,
                            boxHeight: 0,
                            padding: 10,
                            font: {
                                size: 15,
                                weight: 'bold'
                            }
                        }

                    },
                    layout: {
                        padding: {
                            left: 50,
                            right: 50,
                            top: 100,
                            bottom: 50
                        }
                    }
                },
                scales: {
                    x: {
                        ticks: {
                            beginAtZero: true,
                            precision: 0,
                            font: {
                                size: 14,
                            },
                            stepSize: 0.1,
                            min: 0,
                            max: 1.0,
                            suggestedMin: 0,
                            suggestedMax: 1.0,
                            autoSkip: false,

                        },
                        grid: {
                            display: true,
                            drawBorder: true,
                            drawOnChartArea: true,
                            drawTicks: true,
                            tickMarkLength: 0,
                            color: '#efefef',
                            borderDash: [0, 0],
                            zeroLineWidth: 0,
                            zeroLineColor: '#efefef',
                            zeroLineBorderDash: [0, 0],
                            offset: false,
                            borderDashOffset: 0,
                            lineWidth: 1,
                        },
                        title: {
                            display: true,
                            text: 'Score',
                            padding: 10,
                            color: '#2a2a2a',
                        }
                    },
                    y: {
                        title: {
                            display: false,
                            text: 'Network Strength Metrics',
                            padding: 10,
                            color: '#2a2a2a'
                        }
                    }
                }
            }
        });

         // Notify the parent component when the canvas is ready
         if(onCanvasReady) {
            onCanvasReady(chartRef.current);
        }

        return () => {
            chart.destroy();
        };
    }, [networkChartValues, page, onCanvasReady]);

    return <canvas ref={chartRef} />;
}

export default NetworkChart;