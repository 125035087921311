import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import { nanoid } from 'nanoid';
import Loading from '../../components/Loading';
import { Box, TextField, Modal, Select, MenuItem } from "@mui/material"
import withAuthentication from "../../withAuthentication";
import { Tooltip } from 'react-tooltip'

function Contacts1() {

    const { userState, updateData, loading } = useContext(Context);
    const { projects, currProjectId, contactsLists, contacts, role } = userState;

    const navigate = useNavigate();
    const [isDisabled, setDisabled] = useState(false)

    // Lists functionality
    const [currListName, setCurrListName] = useState('')
    const [newListName, setNewListName] = useState('')
    const [currQuestionId, setCurrQuestionId] = useState('')
    const [selectedQuestionText, setSelectedQuestionText] = useState('')
    const [message, setMessage] = useState('')
    const [mainQDropDown, setMainQDropDown] = useState('')
    const [currSelectedListType, setCurrSelectedListType] = useState('')
    const [allListsTable, setAllListsTable] = useState('')
    const [listsWithSelectedQuestion, setListsWithSelectedQuestion] = useState('')
    const [listsWithSelectedQuestionDropDown, setListsWithSelectedQuestionDropDown] = useState('')
    // const [listsWithoutSelectedQuestion, setListsWithoutSelectedQuestion] = useState('')
    // const [listsWithoutSelectedQuestionDropDown, setListsWithoutSelectedQuestionDropDown] = useState('')

    // Contacts functionality
    const [contactToUpdate, setContactToUpdate] = useState(
        { id: nanoid(), fullName: '', phone: '', email: '', whatsapp: '', linkedin: '', facebook: '', instagram: '', twitter: '', keywords: '', networkType: '' })
    const [newContact, setNewContact] = useState({
        id: nanoid(), fullName: '', phone: '', email: '', whatsapp: '', linkedin: '', facebook: '', instagram: '', twitter: '', keywords: '', lists: [], dateAdded: new Date().toLocaleDateString('en-UK')
    })
    const [errorContact, setErrorContact] = useState({
        fullName: '', phone: '', email: '', whatsapp: '', linkedin: '', facebook: '', instagram: '', twitter: '', keywords: '', networkType: ''
    })
    const [allContactsTable, setAllContactsTable] = useState('')
    const [searchQuery, setSearchQuery] = useState('')

    // modal functionality
    const [mode, setMode] = useState('create'); // Initialize with 'create' mode
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '90vw',
        minHeight: '70vh',
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 2,
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
    };

    // console.log(contacts, 'contacts')
    // console.log(projects, 'projects')
    // console.log(allListsTable, 'allListsTable')
    // console.log(contactsLists, 'contactsLists')
    // console.log('currQuestionId:', currQuestionId)
    // console.log('newListName:', newListName)
    // console.log('currListName:', currListName)
    // console.log(listsWithoutSelectedQuestion, 'listsWithoutSelectedQuestion')

    //  function to check if a contact is in a list
    function isUserInList(contact) {
        const listsContactIsInArray = []
        const listContactIsIn = contactsLists.filter((list) => {
            return list.listData.some((contactInList) => {
                return contactInList.fullName === contact.fullName && contactInList.phone === contact.phone;
            });
        });

        if (listContactIsIn.length === 0) return 'not in any list'
        // map over listContactIsIn and return listNames only 
        listContactIsIn.map((list) => {
            return listsContactIsInArray.push(list.listName)
        })
        return listsContactIsInArray.join(', ')
    }

    function questionsAnalayzed(contact) {
        let questionsIds = []
        // inside the contact, there's a list of questions that the contact has been analyzed for. It's under a key called "analyze" which is an array where each item has an answers key and an questionId key. we need to prepare an array of the questionIds 
        contact.analyze && contact.analyze.map((question) => {
            if (question.questionId) {
                return questionsIds.push(question.questionId)
            }
            else {
                return null
            }
        })

        // now we have an array of questionIds, we need to find the questions in the projects array and return the question text
        let questionsText = []
        questionsIds.map((questionId) => {
            const question = projects.find((project) => { return project.id === questionId })
            if (!question) return null
            if (!question.step1) return null
            return questionsText.push(question.step1.FinalQ ? question.step1.FinalQ : question.step1.MainQ)
        }
        )
        return questionsText.join(', ')
    }

    // render dropdown with projects with MainQ / FinalQ for the user to choose from
    useEffect(() => {
        if (loading || !projects || projects.length === 0) {
            setDisabled(true)
            return
        } else {
            // return only projects with MainQ / FinalQ
            const projectsWithMainQ = projects.filter(project => project.step1.MainQ)
            // dropdown with projects with MainQ / FinalQ
            const mainQDropDown = projectsWithMainQ.map(project =>
            (<option key={project.id} value={project.id} >
                {project.step1.FinalQ ? project.step1.FinalQ : project.step1.MainQ}
            </option>))
            setMainQDropDown(mainQDropDown)
        }
    }, [projects, currProjectId, contactsLists, loading]);

    // Prepare data for the lists dropwdowns
    useEffect(() => {
        if (loading || !currQuestionId || !projects || projects.length === 0 || !contactsLists) return

        // first Select: 'list that were used with this question'
        const listsWithSelectedQuestion = contactsLists.filter((list) => {
            return list.listQuestions.some((questionId) => {
                return questionId.questionId === currQuestionId;
            });
        });
        setListsWithSelectedQuestion(listsWithSelectedQuestion)
        // filter out lists that have list.listData empty:
        // const listsWithSelectedQuestionFiltered = listsWithSelectedQuestion.filter((list) => {
        //     return list.listData.length > 0
        // })

        // prepare dropdown 
        const listsWithSelectedQuestionDropDown = listsWithSelectedQuestion && listsWithSelectedQuestion.map((list) => {
            return (<option key={list.listId} value={list.listName}>{list.listName}</option>);
        })

        setListsWithSelectedQuestionDropDown(listsWithSelectedQuestionDropDown)

        setDisabled(false)
    }, [currQuestionId, loading, projects, contactsLists, currProjectId])

    // Generate lists table
    useEffect(() => {
        if (loading || !projects || projects.length === 0) return
        const thead =
            <tr >
                <th >List Name</th>
                <th >Date Created</th>
                <th style={{ width: '250px' }}>Question Analyzed</th>
                <th >Delete List</th>
            </tr>

        let tbody = null
        if (contactsLists && contactsLists.length === 0) {
            tbody = (
                <tr style={{ height: '10vh' }} >
                    <td colSpan="4">You have no lists. Create one to begin.</td>
                </tr>
            )
        }
        else {
            tbody = contactsLists && contactsLists.map((list) => {
                return (
                    <tr key={list.listId}>
                        <td >{list.listName}</td>
                        <td >{list.listDate}</td>
                        <td >{list.listQuestions && list.listQuestions.map((question) => { return question.questionText.slice(0, 50) })}...
                        </td>
                        <td >
                            <button
                                className='transparentBtn'
                                style={{ padding: '6px' }}
                                onClick={(e) => deleteList(e, list.listId)}>
                                <i className="fa-solid fa-trash fa-sm"></i>
                            </button>
                        </td>
                    </tr>
                )
            })
        }
        const lists =
            <div className='contacts-table-container'>
                <table className="contacts-table">
                    <thead >
                        {thead}
                    </thead>
                    <tbody>{tbody}</tbody>
                </table>
            </div>
        setAllListsTable(lists)
    }, [contactsLists, loading, projects, contacts])


    // LISTS FUNCTIONALITY
    const newContactList = {
        listData: [],
        listId: nanoid(),
        listName: newListName,
        listQuestions: [{ questionId: currProjectId, questionText: selectedQuestionText }],
        listTable: [],
        listDate: new Date().toLocaleDateString('en-UK')
    }

    async function createNewList() {
        // check if this name already exists in the contactsLists array, if so, disable button:
        const newListNameExists = contactsLists.some((list) => { return list.listName === newListName; });
        if (newListNameExists) {
            alert('This name already exists, please choose a different name')
            setNewListName('')
            return
        }

        try {
            await updateData("currListId", newContactList.listId);
            const updatedContactLists = [...contactsLists, newContactList];

            // set to state the question id the user selected
            await updateData("currProjectId", currQuestionId);
            await updateData("contactsLists", updatedContactLists);

            setTimeout(() => {
                setMessage('New list created successfully');
                setTimeout(() => {
                    setMessage('');
                    setCurrListName(newContactList.listName);
                    setDisabled(false);
                    setNewListName('');
                    navigate('/ContactsApp/Contacts2'); // Navigate to the next page
                }, 1000);
            }, 1000);

        } catch (error) {
            console.error("Error creating project: ", error);
            setMessage(error.code);
            setTimeout(() => {
                setMessage('');
            }, 3000);
        }
    }

    async function deleteList(e, listId) {
        e.preventDefault()
        const confirmDelete = window.confirm('Are you sure you want to delete this list? This cannot be undone.')
        if (!confirmDelete) return

        const newLists = contactsLists.filter((list) => list.listId !== listId)

        try {
            await updateData('contactsLists', newLists)
            setMessage('List deleted successfully')
            setTimeout(() => {
                setMessage('')
            }, 3000)
        } catch (error) {
            console.log(error)
            setMessage('Error deleting list')
            setTimeout(() => {
                setMessage('')
            }, 3000)
        }
    }

    // handle change for list selection (multiple dropdowns)
    async function handleChange(e) {
        const { name, value } = e.target

        // if the user selects a question, update the currProjectId in the database
        if (name === 'selectedQuestion') {
            if (!value) {
                setCurrQuestionId('')
                return
            }
            setCurrQuestionId(value)
            // set question text
            const selectedQuestion = projects.find((project) => { return project.id === value })
            const selectedQuestionText = selectedQuestion.step1.FinalQ ? selectedQuestion.step1.FinalQ : selectedQuestion.step1.MainQ
            setSelectedQuestionText(selectedQuestionText)

            try {
                await updateData('currProjectId', value)
            } catch (error) {
                console.log(error)
            }

            // if the user selects a list, update the currListId in the database
        } else if (name === 'selectedList-load') {
            if (!value) {
                setCurrListName('')
                return
            }
            setCurrSelectedListType(name)
            setCurrListName(value)
            const selectedList = contactsLists.find((list) => { return list.listName === value; });
            try {
                await updateData('currListId', selectedList.listId);
                await updateData("currProjectId", currQuestionId);
            } catch (error) {
                console.log(error)
            }

            // add new list name
        } else if (name === 'listName') {
            setNewListName(value);
        }
    }

    // CONTACTS FUNCTIONALITY
    const filteredContacts = contacts.filter((contact) => {
        if (!searchQuery) return true
        const searchTermLowerCase = searchQuery.toLowerCase();

        return (
            (contact.fullName && contact.fullName.toLowerCase().includes(searchTermLowerCase)) ||
            (contact.phone && contact.phone.toLowerCase().includes(searchTermLowerCase)) ||
            (contact.email && contact.email.toLowerCase().includes(searchTermLowerCase)) ||
            (contact.whatsapp && contact.whatsapp.toLowerCase().includes(searchTermLowerCase)) ||
            (contact.linkedin && contact.linkedin.toLowerCase().includes(searchTermLowerCase)) ||
            (contact.facebook && contact.facebook.toLowerCase().includes(searchTermLowerCase)) ||
            (contact.instagram && contact.instagram.toLowerCase().includes(searchTermLowerCase)) ||
            (contact.twitter && contact.twitter.toLowerCase().includes(searchTermLowerCase)) ||
            (contact.keywords && contact.keywords.toLowerCase().includes(searchTermLowerCase)) ||
            (contact.networkType && contact.networkType.toLowerCase().includes(searchTermLowerCase))
        )
    })

    // Generate contacts table
    useEffect(() => {
        if (loading || !projects || projects.length === 0) return
        const thead =
            <tr >
                <th >#</th>
                <th  >Full Name</th>
                <th ><i className="fa fa-phone"></i></th>
                <th><i className="fa-solid fa-envelope"></i></th>
                <th><i className="fa-brands fa-solid fa-whatsapp"></i></th>
                <th><i className="fa-brands fa-linkedin"></i></th>
                <th><i className="fa-brands fa-facebook"></i></th>
                <th><i className="fa-brands fa-solid fa-instagram"></i></th>
                <th><i className="fa-brands fa-twitter"></i></th>
                <th >Keywords</th>
                <th >Network Type</th>
                <th >Lists contact is in</th>
                <th >Questions analyzed</th>
                <th >Update</th>
                <th >Delete</th>
            </tr>

        let tbody = null

        tbody = filteredContacts && filteredContacts.map((contact, index) => {
            return (
                <tr key={contact.id}>
                    <td >{index + 1 || ''}</td>
                    <td >{contact.fullName || ''}</td>
                    <td >{contact.phone || ''}</td>
                    <td >{contact.email || ''}</td>
                    <td >{contact.whatsapp || ''}</td>
                    <td >{contact.linkedin || ''}</td>
                    <td >{contact.facebook || ''}</td>
                    <td >{contact.instagram || ''}</td>
                    <td >{contact.twitter || ''}</td>
                    <td >{contact.keywords || ''}</td>
                    <td >{contact.networkType || ''}</td>
                    <td >{(isUserInList(contact)) || ''}</td>
                    <td >{(questionsAnalayzed(contact)) || ''}</td>
                    <td >
                        <button
                            className='transparentBtn'
                            onClick={(e) => openUpdateContactsModal(e, contact)}>
                            <i className="fa-solid fa-pen" ></i>
                        </button>
                    </td>
                    <td >
                        <button
                            data-testid={`delete-contact-${contact.id}`}
                            className='transparentBtn'
                            onClick={(e) => deleteContact(e, contact)}>
                            <i className="fa-solid fa-trash" ></i>
                        </button>
                    </td>
                </tr>
            )
        })


        const allContactsTable = (
            <div className='table-container contacts-table-container'>
                <table className='contacts-table'>
                    <thead >
                        {thead}
                    </thead>
                    <tbody>
                        {tbody}
                    </tbody>
                </table>
            </div>
        )
        setAllContactsTable(allContactsTable)

    }, [contactsLists, loading, projects, contacts, searchQuery])

    function openCreateContactsModal() {
        // clear contact
        setNewContact({
            id: nanoid(), fullName: '', phone: '', email: '', whatsapp: '', linkedin: '', facebook: '', instagram: '', twitter: '', keywords: '', lists: [], dateAdded: new Date().toLocaleDateString('en-UK'), networkType: ''
        })
        setErrorContact({ fullName: '', phone: '', email: '', whatsapp: '', linkedin: '', facebook: '', instagram: '', twitter: '', keywords: '', networkType: '' })
        setMode('create')
        setMessage('')
        handleOpen()
    }

    function openUpdateContactsModal(e, contact) {
        e.preventDefault()
        setErrorContact({ fullName: '', phone: '', email: '', whatsapp: '', linkedin: '', facebook: '', instagram: '', twitter: '', keywords: '', networkType: '' })
        setContactToUpdate(contact)
        setMessage('')
        setMode('update')
        handleOpen()
    }

    function validateContact() {
        // check if name field is empty
        if (!newContact.fullName) {
            setErrorContact({ ...errorContact, fullName: 'Please enter a name' })
            return
        }

        // check if contact name doesn't already exist in contacts array
        const fullNameExists = contacts.some((contact) => { return contact.fullName === newContact.fullName });
        if (fullNameExists) {
            setErrorContact({ ...errorContact, fullName: 'This name already exists in your contacts', })
            return
        }
        // check if contact email doesn't already exist in contacts array
        const emailExists = contacts.some((contact) => { return contact.email === newContact.email });
        if (emailExists) {
            setErrorContact({ ...errorContact, email: 'This email already exists in your contacts', })
            return
        }
        // check if contact phone doesn't already exist in contacts array
        const phoneExists = contacts.some((contact) => { return contact.phone === newContact.phone });
        if (phoneExists) {
            setErrorContact({ ...errorContact, phone: 'This phone number already exists in your contacts', })
            return
        }

        if (newContact.email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/;
            if (!emailRegex.test(newContact.email)) {
                setErrorContact({ ...errorContact, email: 'Please enter a valid email' });
                return;
            }
        }

        if (newContact.phone) {
            const phoneRegex = /^\+?\d{0,100}$/; // 100 digits, no spaces, no special characters
            if (!phoneRegex.test(newContact.phone)) {
                setErrorContact({ ...errorContact, phone: 'Please enter a valid phone number: no spaces or special characters and up to 100 digits' });
                return;
            }
        }
        // limit the number of characters in the fields
        if (newContact.fullName.length > 100) {
            setErrorContact({ ...errorContact, fullName: 'Name must be less than 100 characters' })
            return
        }

        if ((newContact.email.length)
            || (newContact.whatsapp.length) || (newContact.linkedin.length) || (newContact.facebook.length) || (newContact.instagram.length) || (newContact.twitter.length) > 100) {
            setErrorContact({ ...errorContact, email: 'Email must be less than 100 characters', whatsapp: 'Whatsapp must be less than 100 characters', linkedin: 'Linkedin must be less than 100 characters', facebook: 'Facebook must be less than 100 characters', instagram: 'Instagram must be less than 100 characters', twitter: 'Twitter must be less than 100 characters' })
        }
        // limit keywords to 500 characters
        if (newContact.keywords.length > 1000) {
            setErrorContact({ ...errorContact, keywords: 'Keywords must be less than 1000 characters' })
            return
        }
    }

    async function createContact(e) {
        e.preventDefault()
        validateContact()

        if (errorContact.fullName || errorContact.phone || errorContact.email || errorContact.facebook ||
            errorContact.instagram || errorContact.linkedin || errorContact.twitter || errorContact.whatsapp || errorContact.keywords || errorContact.networkType
        ) return

        setErrorContact({ fullName: '', phone: '', email: '', whatsapp: '', linkedin: '', facebook: '', instagram: '', twitter: '', keywords: '', networkType: '' })

        const newContacts = [...contacts, newContact]
        setMessage('Creating contact...')

        try {
            await updateData('contacts', newContacts)
            setMessage('Contact created successfully')
            setTimeout(() => {
                setMessage('')
            }, 3000)
        } catch (error) {
            console.log(error)
            setMessage('Error creating contact')
            setTimeout(() => {
                setMessage('')
            }, 3000)
        } finally {
            setMessage('')
            handleClose()
        }
    }

    async function deleteContact(e, contact) {
        e.preventDefault();
        const confirmDelete = window.confirm(
            "Are you sure you want to delete this contact? This cannot be undone. If you just want to delete this contact from a specific list and not from your whole contacts list, go to the lists page and delete the contact from there."
        );
        if (!confirmDelete) return;

        const newContacts = contacts.filter((c) => c.id !== contact.id);
        // also find all lists that contain this contact using their id and delete them from this list

        const newLists = contactsLists.map((list) => {
            return {
                ...list,
                listData:
                    list.listData.filter(
                        (c) => c.id !== contact.id
                    )
            }
        })

        try {
            await updateData("contacts", newContacts);
            await updateData("contactsLists", newLists);
            setMessage("Contact deleted successfully");
            setTimeout(() => {
                setMessage("");
            }, 3000);
        } catch (error) {
            console.log(error);
            setMessage("Error deleting contact", error);
            setTimeout(() => {
                setMessage("");
            }, 3000);
        }
    }

    async function updateContact(e) {
        e.preventDefault()
        // check if name field is empty
        if (!contactToUpdate.fullName) {
            setErrorContact({ ...errorContact, fullName: 'Please enter a name' })
            return
        }

        if (contactToUpdate.email) {
            const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,}$/;
            if (!emailRegex.test(contactToUpdate.email)) {
                setErrorContact({ ...errorContact, email: 'Please enter a valid email' });
                return;
            }
        }

        if (contactToUpdate.phone) {
            // regex of up to 20 digits, no spaces, no special characters
            const phoneRegex = /^\d{0,100}$/;
            if (!phoneRegex.test(contactToUpdate.phone)) {
                setErrorContact({ ...errorContact, phone: 'Please enter a valid phone number: no spaces or special characters and up to 100 digits' });
                return;
            }
        }

        // limit the number of characters in the fields
        if (contactToUpdate.fullName.length > 100) {
            setErrorContact({ ...errorContact, fullName: 'Name must be less than 100 characters' })
            return
        }

        // limit keywords to 2000 characters
        if (contactToUpdate.keywords.length > 2000) {
            setErrorContact({ ...errorContact, keywords: 'Keywords must be less than 2000 characters' })
            return
        }

        setErrorContact({ fullName: '', phone: '', email: '', whatsapp: '', linkedin: '', facebook: '', instagram: '', twitter: '', keywords: '', networkType: '' })

        // update contact in contacts array in state
        const newContacts = contacts && contacts.map((contact) => {
            if (contact.id === contactToUpdate.id) {
                return contactToUpdate
            }
            return contact
        })

        setMessage('Updating contact...')
        // 1. update contact property in database
        try {
            await updateData('contacts', newContacts)
            setTimeout(() => {
                setMessage('Contact updated successfully')
            }, 3000)
        } catch (error) {
            console.log(error)
            setTimeout(() => {
                setMessage('Error updating contact', error)
            }, 3000)
        } finally {
            setMessage('')
            handleClose()
        }

        // // 2. also find this contact in all lists and update it there too:
        const newLists = contactsLists.map((list) => {
            const contactToUpdateInList = list.listData.find((c) => c.id === contactToUpdate.id)
            if (contactToUpdateInList) {
                const contactToUpdateIndex = list.listData.findIndex((c) => c.id === contactToUpdate.id && c.fullName === contactToUpdate.fullName && c.phone === contactToUpdate.phone)
                list.listData.splice(contactToUpdateIndex, 1, contactToUpdate)
            }
            return list
        })

        try {
            await updateData('contacts', newContacts)
            await updateData('contactsLists', newLists)
            setMessage('Contact updated successfully')
        } catch (error) {
            console.log(error)
            setMessage('Error updating contact', error)
        }
    }


    if (loading) return <Loading />

    return (
        <div className="app-home-container" >
            <div className='app-title' >
                <h4>Contacts Tool
                    <i
                        data-tooltip-id="contacts1Title"
                        data-tooltip-place="bottom"
                        data-tooltip-content="This tool allows you to create and manage lists of contacts that you can analyze for specific business questions. You can create a new list for a new question or load a list that you have already created for a specific question. You can also add, update and delete contacts from the contacts table bellow."
                        className="fa fa-question-circle"
                        aria-hidden="true"
                    ></i>
                    <Tooltip className="tooltip" id="contacts1Title" />
                </h4>
                <h6>Analyze the contacts in your network </h6>
                <p>
                    Create lists of contacts that you can analyze for specific business questions.
                </p>
                <p>
                    You need to add contacts to the contacts table below in order to be able to add them to a list
                </p>
                <div style={{ height: '20px' }}>
                    <p className='successMessage'>{message}</p>
                </div>
                {role && role === 'admin' &&
                    <div>
                        <button style={{ width: '220px' }} onClick={(e) => window.print(e)}>Print / Download whole page</button>
                    </div>
                }
            </div>

            <div className='contacts1-mainQ-container'>
                <div className='contacts1-top-container'>
                    <div className='contacts1-left-container'>
                        <div className='element-title'>
                            <h6>Create or load contacts lists</h6>
                        </div>
                        <div className='contacts1-left-content-container element-body'>
                            <div >
                                <h6 style={{ padding: '10px' }}>Step 1: choose a question

                                    {/* <i
                                        data-tooltip-id="selectBusinessQuestion"
                                        data-tooltip-content="Choose the business question you want to analyze your contacts for."
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                        data-tooltip-variant="info"
                                        data-tooltip-place="right"
                                    ></i>
                                    <Tooltip className="tooltip" id="selectBusinessQuestion" /> */}
                                </h6>

                                <p >
                                    {isDisabled ?
                                        'You must have at least one FINAL business question to use this tool. Use the Questions App first and then come back to this step.' :
                                        ''}</p>
                                <select
                                    name='selectedQuestion'
                                    style={{ margin: 0 }}
                                    data-testid='question-select'
                                    disabled={isDisabled}
                                    onChange={handleChange}>
                                    <option value="">Select question</option>
                                    {mainQDropDown}
                                </select>

                            </div>
                            <div>

                                <h6 style={{ padding: '10px' }}> Step 2: Load or create a contact list
                                    {/* <i
                                        data-tooltip-id="loadQuestion"
                                        data-tooltip-content="If you have previously created a list for the selected question, you can load it. If you have another list with different contacts that you would like to analyze for this question, you can also load it. However, if you have not created a list yet or want to create a new list specifically for this question, you can create a new one below."
                                        className="fa fa-question-circle"
                                        aria-hidden="true"
                                    ></i>
                                    <Tooltip className="tooltip" id="loadQuestion" /> */}
                                </h6>
                                <div >
                                    <div >
                                        <div className='list-details 1'>
                                            <select
                                                name='selectedList-load'
                                                disabled={!currQuestionId || listsWithSelectedQuestion.length === 0}
                                                onChange={handleChange}>
                                                <option value="">Lists that were used with this question</option>
                                                {listsWithSelectedQuestionDropDown}
                                            </select>
                                            <button
                                                style={{ width: '10vw' }}
                                                disabled={!currQuestionId || !currListName || listsWithSelectedQuestion.length === 0 || currSelectedListType !== 'selectedList-load'}
                                                onClick={() => navigate('/ContactsApp/Contacts2')}> Load list
                                            </button>
                                        </div>
                                    </div>
                                    <div className='list-details list3'>
                                        <input
                                            className='contacts-input'
                                            name='listName'
                                            disabled={currQuestionId.length === 0}
                                            value={newListName || ''}
                                            onChange={handleChange}
                                            placeholder="Enter a name for the new list"
                                        />
                                        <button
                                            style={{ width: '10vw' }}
                                            disabled={!newListName}
                                            onClick={createNewList}>
                                            Create list
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='contacts1-right-container '>
                        <div className='element-title'>
                            <h6>Manage your contacts lists</h6>
                        </div>
                        <div className='contacts1-right-content-container element-body'>
                            {allListsTable}
                        </div>
                    </div>
                </div>


                <div className='contacts1-bottom-container'>
                    <div className='element-title'>
                        <h6>Manage your contacts</h6>
                    </div>
                    <div className='element-body'>
                        <div className='admin-btns'>
                            <button
                                onClick={openCreateContactsModal}
                                className="addUserBtn">Add new contact
                            </button>
                            <div className='search-input-wrapper'>
                                <input
                                    type="text"
                                    placeholder="Search contacts"
                                    value={searchQuery || ''}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    name='searchContacts'
                                    className='search-input'
                                />
                                <i className="fas fa-solid fa-magnifying-glass search-icon"></i>
                            </div>
                        </div>

                        <div className='contacts1-bottom-table-container'>
                            {allContactsTable}
                        </div>
                    </div>
                </div>
            </div>
            <div >
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={modalStyle}  >
                        {mode === 'create' ?
                            <h5 style={{ margin: '20px' }}>Add new contact</h5> :
                            <h5 style={{ margin: '20px' }}>Update contact</h5>
                        }
                        <form onSubmit={mode === 'create' ? createContact : updateContact}>
                            <div style={{ textAlign: 'left', margin: '10px' }}>
                                <h6 style={{ margin: '15px 10px' }}>
                                    Personal details
                                </h6>
                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        name="fullName"
                                        label="Full Name"
                                        variant="outlined"
                                        fullWidth
                                        type='text'
                                        inputProps={{ maxLength: 50 }}
                                        sx={{ margin: '0 10px' }}
                                        value={mode === 'create' ? (newContact.fullName || '') : (contactToUpdate.fullName || '')}
                                        onChange={(e) => {
                                            if (mode === 'create') {
                                                setNewContact({ ...newContact, fullName: e.target.value })
                                                setErrorContact({ ...errorContact, fullName: '' })
                                            }
                                            else {
                                                setContactToUpdate({ ...contactToUpdate, fullName: e.target.value })
                                                setErrorContact({ ...errorContact, fullName: '' })
                                            }
                                        }}
                                        error={Boolean(errorContact.fullName)}
                                        helperText={errorContact.fullName}
                                    />
                                    <TextField
                                        name="phone"
                                        label="Phone number"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ margin: '0 10px' }}
                                        value={mode === 'create' ? (newContact.phone || '') : (contactToUpdate.phone || '')}
                                        onChange={(e) => {
                                            const value = e.target.value;
                                            const regex = /^\+?\d{0,50}$/;
                                            if (mode === 'create') {
                                                if (!regex.test(value)) {
                                                    setErrorContact({ ...errorContact, phone: 'Please enter a valid phone number: no spaces or special characters like -' });
                                                    return;
                                                }
                                                setNewContact({ ...newContact, phone: e.target.value })
                                                setErrorContact({ ...errorContact, phone: '' })
                                            }
                                            else {
                                                if (!regex.test(value)) {
                                                    setErrorContact({ ...errorContact, phone: 'Please enter a valid phone number: no spaces or special characters' });
                                                    return;
                                                }
                                                setContactToUpdate({ ...contactToUpdate, phone: e.target.value })
                                                setErrorContact({ ...errorContact, phone: '' })
                                            }
                                        }}
                                        error={Boolean(errorContact.phone)}
                                        helperText={errorContact.phone}
                                    />
                                    <TextField
                                        name="email"
                                        label="Email"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ margin: '0 10px' }}
                                        value={mode === 'create' ? (newContact.email || '') : (contactToUpdate.email || '')}
                                        onChange={(e) => {
                                            if (mode === 'create') {
                                                setNewContact({ ...newContact, email: e.target.value })
                                                setErrorContact({ ...errorContact, email: '' })
                                            }
                                            else {
                                                setContactToUpdate({ ...contactToUpdate, email: e.target.value })
                                                setErrorContact({ ...errorContact, email: '' })
                                            }
                                        }}
                                        error={Boolean(errorContact.email)}
                                        helperText={errorContact.email}
                                    />

                                    <Select
                                        name="networkType"
                                        label="Network Type"
                                        fullWidth
                                        sx={{ margin: '0 10px' }}
                                        renderValue={(selected) => {
                                            if (!selected) {
                                                return 'Network type'
                                            }
                                            return selected
                                        }}
                                        displayEmpty
                                        value={mode === 'create' ? (newContact.networkType || '') : (contactToUpdate.networkType || '')}
                                        onChange={(e) => {
                                            if (mode === 'create') {
                                                setNewContact({ ...newContact, networkType: e.target.value })
                                                setErrorContact({ ...errorContact, networkType: '' })
                                            }
                                            else {
                                                setContactToUpdate({ ...contactToUpdate, networkType: e.target.value })
                                                setErrorContact({ ...errorContact, networkType: '' })
                                            }
                                        }}
                                    >
                                        <MenuItem value="internal">Internal</MenuItem>
                                        <MenuItem value="external">External</MenuItem>
                                    </Select>
                                </div>

                            </div>
                            <div style={{ textAlign: 'left', margin: '10px' }}>
                                <h6 style={{ margin: '15px 10px' }}>
                                    Social media
                                </h6>
                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        name="whatsapp"
                                        label="Whatsapp / Cellphone"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ margin: '0 10px' }}
                                        value={mode === 'create' ? (newContact.whatsapp || '') : (contactToUpdate.whatsapp || '')}
                                        onChange={(e) => {
                                            if (mode === 'create') {
                                                setNewContact({ ...newContact, whatsapp: e.target.value })
                                                setErrorContact({ ...errorContact, whatsapp: '' })
                                            }
                                            else {
                                                setContactToUpdate({ ...contactToUpdate, whatsapp: e.target.value })
                                                setErrorContact({ ...errorContact, whatsapp: '' })
                                            }
                                        }}
                                        error={Boolean(errorContact.whatsapp)}
                                        helperText={errorContact.whatsapp}

                                    />
                                    <TextField
                                        name="linkedin"
                                        label="Linkedin"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ margin: '0 10px' }}
                                        value={mode === 'create' ? (newContact.linkedin || '') : (contactToUpdate.linkedin || '')}
                                        onChange={(e) => {
                                            if (mode === 'create') {
                                                setNewContact({ ...newContact, linkedin: e.target.value })
                                                setErrorContact({ ...errorContact, linkedin: '' })
                                            }
                                            else {
                                                setContactToUpdate({ ...contactToUpdate, linkedin: e.target.value })
                                                setErrorContact({ ...errorContact, linkedin: '' })
                                            }
                                        }}
                                    />
                                    <TextField
                                        name="facebook"
                                        label="Facebook"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ margin: '0 10px' }}
                                        value={mode === 'create' ? (newContact.facebook || '') : (contactToUpdate.facebook || '')}
                                        onChange={(e) => {
                                            if (mode === 'create') {
                                                setNewContact({ ...newContact, facebook: e.target.value })
                                                setErrorContact({ ...errorContact, facebook: '' })
                                            }
                                            else {
                                                setContactToUpdate({ ...contactToUpdate, facebook: e.target.value })
                                                setErrorContact({ ...errorContact, facebook: '' })
                                            }
                                        }}
                                        error={Boolean(errorContact.facebook)}
                                        helperText={errorContact.facebook}
                                    />

                                    <TextField
                                        name="instagram"
                                        label="Instagram"
                                        fullWidth
                                        variant="outlined"
                                        sx={{ margin: '0 10px' }}
                                        value={mode === 'create' ? (newContact.instagram || '') : (contactToUpdate.instagram || '')}
                                        onChange={(e) => {
                                            if (mode === 'create') {
                                                setNewContact({ ...newContact, instagram: e.target.value })
                                                setErrorContact({ ...errorContact, instagram: '' })
                                            }
                                            else {
                                                setContactToUpdate({ ...contactToUpdate, instagram: e.target.value })
                                                setErrorContact({ ...errorContact, instagram: '' })
                                            }
                                        }}
                                        error={Boolean(errorContact.instagram)}
                                        helperText={errorContact.instagram}
                                    />
                                    <TextField
                                        name="twitter"
                                        fullWidth
                                        variant="outlined"
                                        label="Twitter"
                                        sx={{ margin: '0 10px' }}
                                        value={mode === 'create' ? (newContact.twitter || '') : (contactToUpdate.twitter || '')}
                                        onChange={(e) => {
                                            if (mode === 'create') {
                                                setNewContact({ ...newContact, twitter: e.target.value })
                                                setErrorContact({ ...errorContact, twitter: '' })
                                            }
                                            else {
                                                setContactToUpdate({ ...contactToUpdate, twitter: e.target.value })
                                                setErrorContact({ ...errorContact, twitter: '' })
                                            }
                                        }}
                                        error={Boolean(errorContact.twitter)}
                                        helperText={errorContact.twitter}
                                    />
                                </div>
                            </div>
                            <div style={{ textAlign: 'left', margin: '10px' }}>
                                <h6 style={{ margin: '15px 10px' }}>
                                    Keywords
                                </h6>
                                <div style={{ display: 'flex' }}>
                                    <TextField
                                        name="keywords"
                                        label="Add words separated by spaces that describe this contact"
                                        fullWidth
                                        multiline
                                        rows={2}
                                        variant="outlined"
                                        sx={{ margin: '0 10px' }}
                                        value={mode === 'create' ? (newContact.keywords || '') : (contactToUpdate.keywords || '')}
                                        onChange={(e) => {
                                            // prevent the usage of commas
                                            if (e.target.value.includes(',')) {
                                                setErrorContact({ ...errorContact, keywords: 'Please use spaces to separate keywords, not commas' })
                                                return
                                            }
                                            if (mode === 'create') {
                                                setNewContact({ ...newContact, keywords: e.target.value })
                                                setErrorContact({ ...errorContact, keywords: '' })
                                            }
                                            else {
                                                setContactToUpdate({ ...contactToUpdate, keywords: e.target.value })
                                                setErrorContact({ ...errorContact, keywords: '' })
                                            }
                                        }}
                                        placeholder='For example: "Marketing Sales SEO"'
                                        error={Boolean(errorContact.keywords)}
                                        helperText={errorContact.keywords}
                                    />
                                </div>
                            </div>
                            <div >
                                {mode === 'create' ? (
                                    <button
                                        style={{ margin: '20px' }}
                                        type="submit"
                                        className="addUserBtn">Create contact
                                    </button>) : (
                                    <button
                                        style={{ margin: '20px' }}
                                        type="submit"
                                        className="addUserBtn">Update contact
                                    </button>)}
                                <button
                                    className="greyButton"
                                    type="button"
                                    onClick={handleClose}>
                                    Cancel
                                </button>
                                <div style={{ height: '20px' }}>
                                    <p className='successMessage'>{message}</p>
                                </div>
                            </div>
                        </form>
                    </Box>
                </Modal>
            </div>
            {/* <div className='pagination'>
                <button onClick={() => { navigate("/") }} >Back to homepage</button>
            </div> */}
        </div >
    );
}

export default withAuthentication(Contacts1);

