import { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import { nanoid } from 'nanoid'
import Loading from '../../components/Loading';
import withAuthentication from "../../withAuthentication";
import { Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material"


function Analysis2() {

    const { userState, updateData, loading } = useContext(Context);
    const { contactsLists, currListId, role } = userState;

    const [isModified, setIsModified] = useState(false)
    const [renderedTable, setRenderedTable] = useState([])
    const [message, setMessage] = useState('')
    const [currList, setCurrList] = useState(null)
    const [currListName, setCurrListName] = useState('')
    const [tableData, setTableData] = useState('')
    const [listContacts, setListContacts] = useState('')
    const [thead, setThead] = useState('')
    const [isSaving, setIsSaving] = useState(false)

    const navigate = useNavigate();

    // console.log(isModified, 'isModified')
    // console.log(contactsLists, 'contactsLists')
    // console.log(currTable, 'currTable')
    // console.log(tableData, 'tableData')
    // console.log(tableData.length, 'tableData.length')
    // console.log(listContacts, 'listContacts')
    // console.log(currList, 'currList')
    // console.log(currListId, 'currListId')

    // Dialog
    const [route, setRoute] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    const handleDialogOpen = (route) => {
        if (isModified) {
            setOpenDialog(true);
            setRoute(route);
        } else {
            navigate(route);
        }
    }

    const handleDialogClose = (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            navigate(route);
        }
    }

    // extract currList from contactsLists + filter out contacts without a name + convert tableData to array for rendering
    useEffect(() => {
        if (loading || !contactsLists || contactsLists.length === 0) return;
        const currList = contactsLists.find(list => { return (list.listId === currListId) })
        if (!currList || currList.length === 0) return;

        // 1. set currList and filter out contacts without a name
        setCurrList(currList)
        setCurrListName(currList.listName)
        const listContacts = currList ? currList.listData && currList.listData.filter(contact => { return (contact.fullName !== '') }) : ''
        setListContacts(listContacts)

        // 2. check if user has tableData in chosen list:
        const chosenData = currList.listTable

        // 3. if user has tableData, set tableData to chosenData, else, set tableData to empty array and set message to 'you must create a table first'
        if (!chosenData) {
            setTableData([])
            setMessage('You must create a table first. Go back to the table page to create a table.')
            return
        }
        // check if tableData is empty
        if (Object.keys(chosenData).length === 0) {
            setTableData([])
            setMessage('You must create a table first. Go back to the table page to create a table.')
            return
        }

        // 4. if user has tableData, convert tableData to array for rendering
        const newArryTable = convertTableObjectToArray(chosenData)
        // checking if newArryTable has more rows than listContacts, if so, add empty rows to newArryTable
        if (listContacts.length > newArryTable.length) {
            const numCellsToAdd = listContacts.length - newArryTable.length;
            const updatedRows = addCellsToExistingRows(newArryTable, numCellsToAdd);
            const numEmptyRows = listContacts.length - newArryTable.length;
            const numCols = listContacts.length;
            const emptyRows = addEmptyRows(numEmptyRows, numCols);

            const newTableData = [...updatedRows, ...emptyRows];
            setTableData(newTableData);


        } else {
            setTableData(newArryTable);
        }
        return


    }, [currListId, currList, contactsLists, loading])

    function convertTableObjectToArray(tableObject) {
        const tableArray = [];
        const rows = Object.keys(tableObject);
        const cols = Object.keys(tableObject[rows[0]]);
        const sortedCols = cols.map(col => parseInt(col.slice(3))).sort((a, b) => a - b);
        const sortedRows = rows.map(row => parseInt(row.slice(3))).sort((a, b) => a - b);

        for (var i = 0; i < sortedRows.length; i++) {
            var row = [];
            var rowIndex = sortedRows[i];
            for (var j = 0; j < sortedCols.length; j++) {
                var colIndex = sortedCols[j];
                var cell = {
                    selected: tableObject[`row${rowIndex}`][`col${colIndex}`].selected,
                    index: tableObject[`row${rowIndex}`][`col${colIndex}`].index,
                };
                row.push(cell);
            }
            tableArray.push(row);
        }
        return tableArray;
    }

    const updateTable = useCallback((colIdx, rowIdx, selected) => {
        const updatedTable = tableData.map((row, rowIndex) => {
            if (rowIndex === rowIdx) {
                return row.map((item, colIndex) => {
                    if (colIndex === colIdx) {
                        return {
                            ...item,
                            selected: selected
                        };
                    }
                    return { ...item };
                });
            }
            return [...row];
        });

        setTableData(updatedTable);
    }, [tableData]);

    const handleChange = useCallback((e) => {
        const rowIdx = e.target.parentElement.parentElement.className
        const { name, value } = e.target
        const colIdx = name
        var selected = value
        updateTable(+colIdx, +rowIdx, +selected)
        setIsModified(true)
    }, [updateTable])

    const renderTable = useCallback(() => {
        if (!listContacts || listContacts.length === 0 || !tableData || tableData.length === 0 || loading) return;

        var renderedTable = [];
        for (var i = 0; i < listContacts.length; i++) {
            var tdData = [];
            for (var j = 0; j < listContacts.length; j++) {
                var tdClassName = 'analysis-td';
                if (i === j) {
                    tdClassName += ' disabled';
                }
                var selectedValue = tableData[i]?.[j]?.selected || '';
                var colorClass = selectedValue ? ' selected-bg-color' : '';

                var td = (
                    <td key={nanoid()} className={tdClassName}>
                        <select
                            name={j}
                            disabled={i === j ? true : false}
                            value={selectedValue}
                            onChange={handleChange}
                            className={`analysis-select + ${colorClass}`}
                        >
                            <option value=""></option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </td>
                );
                tdData.push(td)
            }
            renderedTable.push(
                <tr key={i} className={i}>
                    <th className="tableNums">{listContacts[i].contactNum}</th>
                    <th className="tableNames">{listContacts[i].fullName}</th>
                    {tdData}
                </tr>
            );
        }
        setRenderedTable(renderedTable);
        getHeaders()

    }, [listContacts, tableData, handleChange, loading])

    // evertime tableData changes, renderTable:
    useEffect(() => {
        if (loading || !listContacts || listContacts.length === 0 || !tableData || tableData.length === 0) {
            return;
        }
        renderTable();
    }, [tableData, renderTable, loading, listContacts])

    // Function to add cells to existing rows
    function addCellsToExistingRows(existingRows, numCellsToAdd) {
        return existingRows.map((row, rowIndex) => {
            const cells = [...row];
            for (let i = 0; i < numCellsToAdd; i++) {
                const columnIndex = row.length + i;
                cells.push({ selected: 0, index: [rowIndex, columnIndex] });
            }
            return cells;
        });
    }

    // Function to add empty rows
    function addEmptyRows(numEmptyRows, numCols) {
        const emptyRows = [];
        for (let i = 0; i < numEmptyRows; i++) {
            const row = [];
            for (let j = 0; j < numCols; j++) {
                row.push({ selected: 0, index: [tableData.length + i, j] });
            }
            emptyRows.push(row);
        }
        return emptyRows;
    }

    // get table headers
    function getHeaders() {
        if (listContacts.length === 0) return;
        // create THs by mapping and only returning contact names and numbers from contacts array that have values for fullName:
        const tableRowNames = listContacts.map(({ fullName }) => (
            <th key={nanoid()} style={{ backgroundColor: '#F7EC34' }} className={`colName ${fullName}`} scope="col">{fullName}</th>
        ))

        const tableRowNums = listContacts.map(({ contactNum }) => (
            <th key={nanoid()} className='tableNums' scope="row">{contactNum}</th>
        ))
        const thead = <thead><tr><td></td><td></td>{tableRowNums}</tr><tr><td></td><td></td>{tableRowNames}</tr></thead>
        setThead(thead)
    }

    async function saveSelection() {
        if (loading || !tableData || tableData.length === 0 || !contactsLists || contactsLists.length === 0) return;

        let updatedContactLists = []

        if (Array.isArray(tableData)) {
            // converting tableData to object BEFORE saving to db:
            const newTableData = {}

            tableData.forEach((row, rowIndex) => {
                row.forEach((item, colIndex) => {
                    const cellValue = item || { selected: 0, index: [rowIndex, colIndex] };

                    const rowKey = `row${rowIndex}`;
                    const colKey = `col${colIndex}`;

                    if (!newTableData[rowKey]) {
                        newTableData[rowKey] = {};
                    }

                    newTableData[rowKey][colKey] = cellValue;
                });
            });

            // console.log('tableData converted back to object:', newTableData);

            // update contactsLists with new tableData:
            updatedContactLists = contactsLists.map(list => {
                return list.listId === currListId ? { ...list, listTable: newTableData } : list
            })

        } else {
            // tableData is an object, saving it as is - update contactsLists with new tableData:
            updatedContactLists = contactsLists.map(list => {
                return list.listId === currListId ? { ...list, listTable: tableData } : list
            })
        }

        setIsSaving(true)
        try {
            await updateData('contactsLists', updatedContactLists)

            setTimeout(() => {
                setIsSaving(false)
                setMessage('Saved successfully')
            }, 2000)

            setTimeout(() => {
                setMessage('')
                setIsModified(false)
            }, 2500)
        }
        catch (err) {
            console.log(err)
            setMessage('Error saving data', err);
            setTimeout(() => {
                setMessage('');
            }, 2000);
        }
    }

    if (loading) return <Loading />

    return (
        <div className="app-home-container">
            <div className='app-title'>
                <h4>Anaylze Your Network Relationships</h4>
                <h6>Select the relationships between your contacts</h6>
            </div>
            <div className='analysis2-main-container '>
                <div>
                    <p ><strong>You are analyzing list:</strong> "{currListName}"</p>
                    <p>Click on the each cell in the table to select the relationships between your contacts: 1 = low, 5 = high</p>
                </div>
                <div className='table-container analysis-table'>
                    <table className='analysis-table'>
                        {thead}
                        <tbody >
                            {renderedTable}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className='bottomNavigation'>
                <p >Save your table to see your network analysis</p>
                <div>
                    <button
                        style={{ minWidth: '200px' }}
                        className='savingBtn'
                        disabled={!isModified}
                        onClick={saveSelection}>Save table
                    </button>
                    <button
                        onClick={() => navigate('/AnalysisApp/Analysis3')}
                        disabled={isModified}> See your network analysis
                    </button>
                </div>
                <div className="successMessage">
                {message ? message : ''}
                {isSaving && (
                    <div>
                        <i className="fas fa-spinner fa-spin"></i> Saving...
                    </div>
                )}
            </div>
            </div>


            {role && role === 'admin' &&
                <button style={{ width: '200px', marginTop: '20px' }} onClick={(e) => window.print(e)}>Print / Download page</button>
            }


            <div className='pagination'>
                <Dialog
                    open={openDialog}
                    onClose={() => handleDialogClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className="dialog-title" id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
                    <DialogContent className="dialog-content">
                        <DialogContentText id="alert-dialog-description">
                            You have unsaved changes. Are you sure you want to leave this page?
                        </DialogContentText>
                    </DialogContent>
                    <div className="dialog-actions">
                        <button style={{ backgroundColor: 'grey' }} className="dialog-button" onClick={() => handleDialogClose(false)}>Cancel</button>
                        <button className="dialog-button" onClick={() => handleDialogClose(true)} autoFocus>Yes</button>
                    </div>
                </Dialog>
                <button onClick={() => handleDialogOpen("/AnalysisApp/Analysis1")}>Back to list selection</button>
                {/* <button onClick={() => handleDialogOpen("/")}>Back to homepage</button> */}
            </div>
        </div>
    );

}

export default withAuthentication(Analysis2);