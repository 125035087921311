import React, { useState, useEffect } from 'react';
import Snackbar from '@mui/material/Snackbar';
import { analytics } from '../firebase';
import { setAnalyticsCollectionEnabled } from 'firebase/analytics';

const CookieBanner = ({ onShowBanner }) => {
    const [open, setOpen] = useState(false);
  
    useEffect(() => {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');
      if (cookiesAccepted === null) {
        setOpen(true);
      // if user already accepted cookies or rejected, do not show the banner:
        }
        else if (cookiesAccepted === 'true') {
        setAnalyticsCollectionEnabled(analytics, true);
        }
        else if (cookiesAccepted === 'false') {
        setAnalyticsCollectionEnabled(analytics, false);
        }

    }, []);
  
    const handleAccept = () => {
      localStorage.setItem('cookiesAccepted', 'true');
      setAnalyticsCollectionEnabled(analytics, true);
      setOpen(false);
    };
  
    const handleReject = () => {
      localStorage.setItem('cookiesAccepted', 'false');
      setAnalyticsCollectionEnabled(analytics, false);
      setOpen(false);
    };

    useEffect(() => {
        if (onShowBanner) {
          setOpen(true);
        }
      }, [onShowBanner]);
      
    return (
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        message="We use cookies to ensure you get the best experience on our app. For more information, please read our Privacy Policy and Terms of Service (top right side, in the user menu). By clicking Accept, you agree to our use of cookies."
        sx={{
            '& .MuiSnackbarContent-root': {
                color: 'black',
                backgroundColor: 'whitesmoke',
                fontSize: '1rem',
            },
        }}
        action={
          <>
            <button  size="medium" onClick={handleAccept}>
              Accept
            </button>
            <button  size="medium" onClick={handleReject}>
              Reject
            </button>
          </>
        }
      />
    );
  };
  
  export default CookieBanner;