import { useEffect, useRef } from 'react';
import { Chart } from 'chart.js/auto';

function ResultsChart({ scores, onCanvasReady }) {
    const chartRef = useRef(null);
    const scoreLeft = scores.reduce((total, { scoreLeft }) => total + parseInt(scoreLeft || 0), 0);
    const scoreRight = scores.reduce((total, { scoreRight }) => total + parseInt(scoreRight || 0), 0);
 
    useEffect(() => {
        const chartCtx = chartRef.current.getContext('2d');
        chartCtx.canvas.width = 350;
        chartCtx.canvas.height = 150;

        const chart = new Chart(chartCtx, {
            type: 'bar',
            data: {
                labels: ['Left Leg Score', 'Right Leg Score'],
                datasets: [{
                    data: [scoreLeft, scoreRight],
                    backgroundColor: ['#ff7486', '#219F8E'],
                    tension: 0.1,
                    label: 'Scores',
                }],

            },
            options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        position: 'bottom',
                        display: false,
                    },
                },
                scales: {
                    y: {
                        ticks: {
                            beginAtZero: true,
                            precision: 0
                        }
                    }
                }
            }
        });

        // Notify the parent component when the canvas is ready
        if (onCanvasReady) {
            onCanvasReady(chartRef.current);
        }

        return () => {
            chart.destroy();
        };
    }, [scoreLeft, scoreRight, onCanvasReady]);

    return (
        <div><canvas ref={chartRef} /></div>
    );
}

export default ResultsChart;