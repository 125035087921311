import { createContext, useState, useEffect, useRef } from "react";
import { doc, setDoc, getDoc, updateDoc, collection } from 'firebase/firestore';
import { db, auth } from './firebase';

// Creating context to manage state for all components and pages
const Context = createContext();

// Creating the provider to wrap the root
function ContextProvider({ children }) {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const tenantIdRef = useRef(null);
    const [isTenantIdSet, setIsTenantIdSet] = useState(false);

    // global state for all components
    const [userState, setUserState] = useState({
        user: null,
        uid: '',
        tenantId: '',
        role: '',
        projects: [],
        currProjectId: '',
        contacts: [],
        contactsLists: [],
        currTable: [],
        currListId: '',
        interviews: [],
        conclusions: [],
        maps: [],
        results: [],
        cv: [],
        reports: [],
        currReportId: '',
        age: '',
        firstName: '',
        lastName: '',
        email: '',
        isSuperAdmin: false,
        hasChangedPassword: false,
        questionsFromAdmin: [], // questions that were sent by the admin to the user
        adminQuestions: [], // only admins use this
    });
    // console.log('userState', userState);

    // observer to check if user is logged in and if so, check if they are an admin
    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (user) => {
            if (user) {
                try {
                    const idTokenResult = await user.getIdTokenResult();
                    tenantIdRef.current = idTokenResult.claims.tenantId;

                    // set tenantId in state
                    setUserState(prevState => ({ ...prevState, tenantId: tenantIdRef.current }));

                    setIsTenantIdSet(true);
                    // Confirm the user is an Admin:
                    if (!!idTokenResult.claims.admin) {
                        setUserState(prevState => ({
                            ...prevState,
                            uid: user.uid,
                            role: 'admin',
                        }));
                        if (!!idTokenResult.claims.superAdmin) {
                            setUserState(prevState => ({
                                ...prevState,
                                isSuperAdmin: true,
                            }));
                        }
                    }
                    else {
                        setUserState(prevState => ({
                            ...prevState,
                            uid: user.uid,
                            role: 'user',
                        }));
                    }

                } catch (err) {
                    console.log(err.message);
                }
            } else {
                setUserState({ uid: null, role: '', tenantId: '' });
            }
            setLoading(prevLoading => false); // indicate that data has finished loading
        });
        // Clean up the observer on component unmount
        return () => unsubscribe();
    }, []);

    // Get data from DB and load to state
    useEffect(() => {
        if (!userState.uid || !tenantIdRef.current) return;

        const getUserData = async () => {
            const userRef = doc(db, 'tenants', tenantIdRef.current, 'users', userState.uid);
            try {
                const userSnap = await getDoc(userRef);

                if (userSnap.exists()) {
                    const userData = userSnap.data();
                    const dataFields = ['projects', 'currProjectId', 'contacts', 'contactsLists', 'currTable', 'currListId', 'interviews', 'conclusions', 'maps', 'results', 'cv', 'age', 'firstName', 'lastName', 'reports', 'currReportId', 'hasChangedPassword', 'questionsFromAdmin', 'adminQuestions', 'email'];
                    const newState = { user: userState.user || '' };

                    dataFields.forEach(field => {
                        if (field === 'hasChangedPassword') {
                            newState[field] = userData[field] || false;
                            return;
                        }
                        newState[field] = userData[field] || [];
                    });
                    setUserState((prevState) => ({ ...prevState, ...newState }));

                } else {
                    console.log('No user data found');
                    setError('Cannot find user');
                }
            } catch (err) {
                console.error("Error getting document from DB: ", err.message);
                setLoading(false); // indicate that data has finished loading
            };
        }
        if (userState.uid && tenantIdRef.current) {
            getUserData();
        } else {
            setLoading(false); // if user is not logged in, indicate that data has finished loading
            console.log('no user logged in, cannot reach db')
        }
    }, [userState.uid, userState.user, tenantIdRef.current, isTenantIdSet]);


    // Update data in DB and state 
    const updateData = async (field, data) => {

        const uid = userState.uid
        try {
            if (!uid) return
            const docRef = doc(db, 'tenants', tenantIdRef.current, 'users', uid);
            await setDoc(docRef, { [field]: data }, { merge: true });

            setUserState(prevState => ({ ...prevState, [field]: data }));

        } catch (err) {
            console.error("Error updating document in DB: ", err.message);
        }
    }

    return <Context.Provider value={{ userState, setUserState, updateData, loading, error }}>
        {children}
    </Context.Provider>;
}

export { ContextProvider, Context }
