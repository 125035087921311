import { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import { nanoid } from 'nanoid'
import ResultsChart from '../../components/ResultsChart';
import { TextField, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import withAuthentication from "../../withAuthentication";
import Loading from '../../components/Loading';
import { Tooltip } from 'react-tooltip'
// import jsPDF from 'jspdf';


function Results1() {

    const { userState, updateData, loading } = useContext(Context);
    const { contactsLists, results } = userState;

    // state for results table
    const [isDisabled, setIsDisabled] = useState(true)
    const [isModified, setModified] = useState(false)
    const [selectedType, setSelectedType] = useState('')

    const [resultsData, setResultsData] = useState([])
    const [selectedResults, setSelectedResults] = useState('')
    const [currContact, setCurrContact] = useState('')
    const [isUserAnalysisExists, setUserAnalysisExists] = useState(false)
    const [userContacts, setUserContacts] = useState([])
    const [scores, setScores] = useState([])
    const [allResults, setAllResults] = useState([])
    const [cells, setCells] = useState([])
    const [message, setMessage] = useState('')
    const [isSaving, setIsSaving] = useState(false)

    const tableRef = useRef(null);
    const containerRef = useRef(null);
    const bottomPageRef = useRef(null);
    const navigate = useNavigate()
    // const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    // const chartRefs = {};

    // console.log(isUserAnalysisExists, 'isUserAnalysisExists')
    // console.log(resultsData, 'resultsData')
    // console.log(currContact, 'currContact')
    // console.log(results, 'results')

    const styles = {
        inputLabelProps: {
            shrink: true,
            style: {
                fontSize: 12,
            }
        }, inputProps: {
            style: {
                fontSize: 15,
                textAlign: 'center',
            }
        }
    }
    // Dialog
    const [route, setRoute] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    const handleDialogOpen = (route) => {
        if (isModified) {
            setOpenDialog(true);
            setRoute(route);
        } else {
            navigate(route);
        }
    }

    const handleDialogClose = (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            navigate(route);
        }
    }

    // updating results from db + checking if user self-analysis exists:
    useEffect(() => {
        if (loading || !results) return

        if (results.length > 0) {
            const isUserAnalysis = results.find((result) => {
                if (result.contact === 'yourself') {
                    return true;
                }
                return false;
            });

            if (isUserAnalysis) setUserAnalysisExists(true);

            const allResultsDropDwon = results.map((result) => {
                return (result.contact !== '' ? <option key={result.resultId} value={result.contact}>{result.contact}</option> : null)
            });

            setAllResults(allResultsDropDwon);

        }
        if (resultsData && resultsData.length === 0) {
            // if empty, create new results array with 10 empty placeholders
            const newResultsData = createResultsArray();
            setResultsData(newResultsData);
        }

    }, [results, contactsLists, loading, resultsData]);


    // updating userContacts:
    useEffect(() => {
        if (loading || !contactsLists || contactsLists.length === 0) return
        // create an array of all contacts:
        const allContacts = contactsLists.map((contactList) => {
            return contactList.listData.filter((contact) => contact.fullName)
        }).flat()

        // filter out contacts that already have results:
        const allResultsNames = results.map((result) => result.contact)
        const filteredContacts = allContacts.filter((contact) => !allResultsNames.includes(contact.fullName))

        // render a dropdown of remaining contacts:
        const allContactsDropDwon = filteredContacts.map((contact) => { return <option key={contact.id} value={contact.fullName}>{contact.fullName}</option> })
        setUserContacts(allContactsDropDwon)
    }, [results, contactsLists, loading])

    // updating score graph:
    useEffect(() => {
        if (loading || !resultsData || resultsData.length === 0) return
        const scores = resultsData.map(({ scoreLeft, scoreRight }) => ({ scoreLeft, scoreRight }));
        setScores(scores)

    }, [resultsData, loading])


    // if resultsData changes, re-render cells
    useEffect(() => {
        if (loading || !resultsData) return
        renderCells()

    }, [resultsData, currContact, loading])

    function createAnalsis() {
        var newResultsData = createResultsArray()
        setResultsData(newResultsData)
        setIsDisabled(false)
        setModified(true)

        // move user down to table area using ref to container
        if (bottomPageRef.current) {
            bottomPageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    // create new array of 10 empty table fields
    function createResultsArray() {
        const resultsArray = []
        var result = ''
        for (let i = 1; i < 11; i++) {
            result = {
                id: nanoid(), resultNum: `${i}`, resourceLeft: ``, scoreLeft: '', q1: '', q2: '', q3: '', resourceRight: ``, scoreRight: '', errors: {}
            }
            resultsArray.push(result)
        }
        return resultsArray
    }

    function loadAnalysis() {
        if (loading || !resultsData || resultsData.length === 0 || !results) return
        var selectedResults = results.find((result) => result.contact === currContact)
        setSelectedResults(selectedResults)
        setResultsData(selectedResults.results)
        setIsDisabled(false)

        // move user down to table area using ref to container
        if (bottomPageRef.current) {
            bottomPageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }

    async function deleteAnalysis() {
        if (loading || !resultsData || resultsData.length === 0 || !currContact || !results) return

        // confirm that user wants to delete analysis:
        var confirmDelete = window.confirm(`Are you sure you want to delete ${currContact}'s analysis?`)
        if (!confirmDelete) return

        if (currContact === 'yourself') {
            setUserAnalysisExists(false)
        }
        const newResults = results.filter((result) => result.contact !== currContact)


        try {
            await updateData('results', newResults)
            setCurrContact('')
            // also delete name from dropdown
            const newAllResults = allResults.filter((result) => result.contact !== currContact)
            setAllResults(newAllResults)

            setMessage('Analysis deleted successfully')

            setTimeout(() => {
                setMessage('')
            }, 2500)
        } catch (err) {
            console.log(err)
            setMessage('Something went wrong')
        }

    }

    // handle changes in top part + table cells
    function handleChange(e, resultNum) {
        if (loading || !resultsData) return
        setMessage('')
        setModified(true)
        const { name, value } = e.target

        if (name === 'load-selection' || name === 'analyze-selection' || name === 'contact-selection') {
            setCurrContact(value)
            setSelectedType(name)
            return
        }

        // table cells:
        setResultsData(prevResults => prevResults.map(result => {
            if (result.resultNum === resultNum) {
                return { ...result, [name]: value }
            }
            return result
        }))

        // remove error message if user starts typing, from specific cell:
        setResultsData(prevResults => prevResults.map(result => {
            if (result.resultNum === resultNum) {
                // Reset all errors for the row that is currently being modified
                const resetErrors = {
                    resourceLeft: false,
                    scoreLeft: false,
                    q1: false,
                    q2: false,
                    q3: false,
                    resourceRight: false,
                    scoreRight: false,
                };

                return {
                    ...result,
                    [name]: value,
                    errors: resetErrors, // clear all the errors for this row
                };
            }
            return result;
        }));
    }

    // hide table rows background when scrolling down
    useEffect(() => {
        const containerNode = containerRef.current || document.body;

        const handleScroll = () => {
            const table = tableRef.current;
            const headerHeight = table.querySelector('thead').offsetHeight;
            const rows = table.querySelectorAll('tbody tr');

            rows.forEach(row => {
                const rowTop = row.getBoundingClientRect().top - containerNode.getBoundingClientRect().top;
                if (rowTop < headerHeight) {
                    row.querySelectorAll('td').forEach(td => {
                        td.classList.add('transparent-bg');
                    });
                } else {
                    row.querySelectorAll('td').forEach(td => {
                        td.classList.remove('transparent-bg');
                    });
                }
            });
        };

        containerNode.addEventListener('scroll', handleScroll);

        // Cleanup
        return () => {
            containerNode.removeEventListener('scroll', handleScroll);
        };
    }, []);

    function renderCells() {
        if (loading || !resultsData) return
        const cells = resultsData.map(({ id, resultNum, resourceLeft, scoreLeft, q1, q2, q3, resourceRight, scoreRight, errors }) => (
            <tr key={id}>
                <td style={{ backgroundColor: 'transparent', border: 'none', borderRadius: '10px', padding: '0px' }}>
                    {resultNum}
                </td>
                <td >
                    <TextField
                        disabled={isDisabled}
                        name='resourceLeft'
                        defaultValue={resourceLeft || ''}
                        type='text'
                        onChange={(e) => handleChange(e, resultNum)}
                        placeholder="Left leg resource"
                        multiline
                        variant='standard'
                        inputProps={styles.inputProps}
                        InputProps={{ disableUnderline: true }}
                        error={errors?.resourceLeft}
                    />
                </td>
                <td >
                    <TextField
                        disabled={isDisabled}
                        name='scoreLeft'
                        defaultValue={scoreLeft || ''}
                        type='number'
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (value > 10 || value < 1) {
                                alert('Score must be between 1-10')
                                e.target.value = ''
                                return
                            }
                            handleChange(e, resultNum)
                        }}
                        variant='standard'
                        sx={{ width: '60px' }}
                        inputProps={styles.inputProps}
                        InputProps={{ disableUnderline: true }}
                        placeholder='Score'
                        error={errors?.scoreLeft}
                    />
                </td>
                <td >
                    <TextField
                        disabled={isDisabled}
                        name='q1'
                        defaultValue={q1 || ''}
                        type='text'
                        onChange={(e) => handleChange(e, resultNum)}
                        multiline
                        variant='standard'
                        inputProps={styles.inputProps}
                        InputProps={{ disableUnderline: true }}
                        error={errors?.q1}
                    />
                </td>
                <td >
                    <TextField
                        disabled={isDisabled}
                        name='q2'
                        defaultValue={q2 || ''}
                        type='text'
                        onChange={(e) => handleChange(e, resultNum)}
                        multiline
                        variant='standard'
                        inputProps={styles.inputProps}
                        InputProps={{ disableUnderline: true }}
                        error={errors?.q2}
                    />
                </td>
                <td >
                    <TextField
                        disabled={isDisabled}
                        name='q3'
                        defaultValue={q3 || ''}
                        type='text'
                        onChange={(e) => handleChange(e, resultNum)}
                        multiline
                        variant='standard'
                        inputProps={styles.inputProps}
                        InputProps={{ disableUnderline: true }}
                        error={errors?.q3}
                    />
                </td>
                <td >
                    <TextField
                        disabled={isDisabled}
                        name='resourceRight'
                        defaultValue={resourceRight || ''}
                        type='text'
                        onChange={(e) => handleChange(e, resultNum)}
                        placeholder="Right leg resource"
                        multiline
                        variant='standard'
                        inputProps={styles.inputProps}
                        InputProps={{ disableUnderline: true }}
                        error={errors?.resourceRight}
                    />
                </td>
                <td >
                    <TextField
                        disabled={isDisabled}
                        name='scoreRight'
                        defaultValue={scoreRight || ''}
                        type='number'
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            if (value > 10 || value < 1) {
                                alert('Score must be between 1-10')
                                e.target.value = ''
                                return
                            }
                            handleChange(e, resultNum)
                        }}
                        variant='standard'
                        sx={{ width: '60px' }}
                        placeholder='Score'
                        inputProps={styles.inputProps}
                        InputProps={{ disableUnderline: true }}
                        error={errors?.scoreRight}
                    />
                </td>
            </tr>
        ))
        setCells(cells)
    }

    function validateResultsData() {
        let isFormValid = true;

        setResultsData(prevResultsData => prevResultsData.map(result => {
            const { resourceLeft, scoreLeft, q1, q2, q3, resourceRight, scoreRight } = result;
            const isRowEmpty = !(resourceLeft || scoreLeft || q1 || q2 || q3 || resourceRight || scoreRight);

            if (!isRowEmpty && (!resourceLeft || !scoreLeft || !q1 || !q2 || !q3 || !resourceRight || !scoreRight)) {
                isFormValid = false;

                return {
                    ...result,
                    errors: {
                        resourceLeft: !resourceLeft,
                        scoreLeft: !scoreLeft,
                        q1: !q1,
                        q2: !q2,
                        q3: !q3,
                        resourceRight: !resourceRight,
                        scoreRight: !scoreRight,
                    }
                };
            }

            return result;
        }));

        return isFormValid;
    }

    async function saveAnalysis() {
        const isFormValid = validateResultsData()
        if (!isFormValid) {
            setMessage('Please fill in all fields in the rows with data')
            return
        }

        var selectedResults = results.find((result) => result.contact === currContact)
        let updatedResults
        if (!results || results.length === 0 || !selectedResults) {
            const newResult = { contact: currContact, results: resultsData, resultId: nanoid() }
            updatedResults = [...results, newResult]

        } else {
            selectedResults.results = resultsData
            updatedResults = [...results]
        }

        setIsSaving(true)
        try {
            await updateData('results', updatedResults)
            setTimeout(() => {
                setMessage('Analysis saved successfully')
                setIsSaving(false)
            }, 2000)

            setTimeout(() => {
                setMessage('')
                setCurrContact('')
                // create new resultsData array
                const newResultsData = createResultsArray()
                setResultsData(newResultsData)
                setIsDisabled(true)
                setModified(false)
            }, 2500)

        } catch (error) {
            console.log(error)
            setMessage('Error saving data', error);
            setTimeout(() => {
                setMessage('');
            }, 2000);
        }
    }

    if (loading) return <Loading />

    return (
        <div className="app-home-container">
            <div className='app-title'>
                <h4>Right Leg Hidden Resources</h4>
                <h6>Find your unique hidden resources based on your left leg results</h6>
            </div>
            <div className="successMessage">
                {message}
            </div>
            <div className='results-top-container'>
                <div style={{ alignItems: 'flex-start' }} className='results-top-container-left '>
                    <h6 className='element-title'>Load or delete existing analysis</h6>
                    <div className='analysis-btns element-body'>
                        <div className='btn-group'>
                            <select
                                className='results-select'
                                name='load-selection'
                                value={currContact || ''}
                                disabled={!results || results.length === 0}
                                onChange={handleChange}>
                                <option value="">Select past analysis</option>
                                {allResults}
                            </select>
                            <button
                                style={{ minWidth: '120px' }}
                                disabled={!allResults || results.length === 0 || !currContact || selectedType !== 'load-selection'}
                                onClick={loadAnalysis} >Load
                            </button>
                            <button
                                style={{ minWidth: '120px' }}
                                disabled={!allResults || results.length === 0 || !currContact || selectedType !== 'load-selection'}
                                onClick={deleteAnalysis} >Delete
                            </button>
                        </div>
                    </div>

                    <h6 className='element-title'>Start new analysis</h6>
                    <div className='analysis-btns element-body'>
                        <div className='btn-group' style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }} >
                            <select
                                className='results-select'
                                name='analyze-selection'
                                value={currContact || ''}
                                onChange={handleChange}>
                                <option value="">Select who to analyze</option>
                                <option disabled={isUserAnalysisExists} value="yourself">Yourself</option>
                                <option disabled={!userContacts || userContacts.length === 0} value="someone-else">Someone else</option>
                            </select>
                            <select
                                className='results-select'
                                name='contact-selection'
                                value={currContact || ''}
                                disabled={!currContact || currContact === 'yourself' || userContacts.length === 0 || selectedType === 'load-selection'}
                                onChange={handleChange}>
                                <option value="">Select contact</option>
                                {userContacts}
                            </select>
                            <button
                                style={{ minWidth: '120px' }}
                                disabled={!currContact || currContact === 'someone-else' || selectedType === 'load-selection'}
                                onClick={createAnalsis} >
                                Start
                            </button>
                        </div>

                    </div>
                </div>

                <div className='results-top-container-right'>
                    <h6 className='element-title'>Results Summary</h6>
                    <div style={{ alignItems: 'center', padding: '30px' }} className='analysis-btns element-body'>
                        <p className='grey-strip'>Left Leg vs. Right Leg</p>
                        <ResultsChart scores={scores} />
                    </div>
                </div>
            </div>

            <div className='results-bottom-container' ref={bottomPageRef}>
                <div className='results-table-bar'>
                    <h5 style={{ marginLeft: '360px', fontSize: '1.2rem', fontFamily: 'Fira Sans, sans-serif' }} >Left Leg</h5>
                    <h5 style={{ marginLeft: '280px', fontSize: '1.2rem', fontFamily: 'Fira Sans, sans-serif' }}>Right Leg</h5>
                </div>
                <div className='table-container' ref={containerRef} style={{ height: '75vh' }}>
                    <table className='results-table' ref={tableRef}  >
                        <thead >
                            <tr style={{ lineHeight: '40px' }}>
                                <th style={{ backgroundColor: 'transparent' }} ></th>
                                <th colSpan={2} >Resource
                                    <i data-tooltip-id="ResourceL"
                                        data-tooltip-content="Left-Leg: Assign a descriptive title to each demanding milestone....(e.g., 'paralyzed legs')"
                                        className="fa fa-question-circle"
                                        aria-hidden="true">
                                        <Tooltip className="tooltip" id="ResourceL" />
                                    </i>
                                </th>
                                <th >Description
                                    <i data-tooltip-id="Description"
                                        data-tooltip-content="Provide sufficient context and details to clearly convey the significance and nature of that milestone"
                                        className="fa fa-question-circle"
                                        aria-hidden="true">
                                        <Tooltip className="tooltip" id="Description" />
                                    </i>
                                </th>
                                <th >Limitation
                                    <i data-tooltip-id="Limitation"
                                        data-tooltip-content="Identify the specific factors that are causing limitations in your situation. These factors could be related to resources, skills, time, external conditions, or any other relevant aspects. (. e.g., 'can't walk')"
                                        className="fa fa-question-circle"
                                        aria-hidden="true">
                                        <Tooltip className="tooltip" id="Limitation" />
                                    </i>
                                </th>
                                <th >Compensation
                                    <i data-tooltip-id="Compensation"
                                        data-tooltip-content='Describe the direct most viable and promising compensation for each limitation including the specific actions or adjustments it entails (e.g., extra uses of the arms to move the wheelchair)'
                                        className="fa fa-question-circle"
                                        aria-hidden="true">
                                        <Tooltip className="tooltip" id="Compensation" />
                                    </i>
                                </th>
                                <th colSpan={2} >Resource
                                    <i data-tooltip-id="ResourceR"
                                        data-tooltip-content="Right-Leg: Assign a descriptive title to each extra-able skill.... (e.g., 'Strong arms')"
                                        className="fa fa-question-circle"
                                        aria-hidden="true">
                                        <Tooltip className="tooltip" id="ResourceR" />
                                    </i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {cells}
                        </tbody>
                    </table>

                </div>

            </div>
            <div className='bottomNavigation' style={{ marginTop: '35px' }}>
                <button className='savingBtn' style={{ width: '180px' }} onClick={saveAnalysis}>Save Analysis</button>
                <button style={{ width: '180px', marginTop: '15px' }} onClick={(e) => window.print(e)}>Print / Download page</button>
                <div className="successMessage">
                    {message ? message : ''}
                    {isSaving && (
                        <div>
                            <i className="fas fa-spinner fa-spin"></i> Saving...
                        </div>
                    )}
                </div>
            </div>
            <div className='pagination'>
                <Dialog
                    open={openDialog}
                    onClose={() => handleDialogClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className="dialog-title" id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
                    <DialogContent className="dialog-content">
                        <DialogContentText id="alert-dialog-description">
                            You have unsaved changes. Are you sure you want to leave this page?
                        </DialogContentText>
                    </DialogContent>
                    <div className="dialog-actions">
                        <button style={{ backgroundColor: 'grey' }} className="dialog-button" onClick={() => handleDialogClose(false)}>Cancel</button>
                        <button className="dialog-button" onClick={() => handleDialogClose(true)} autoFocus>Yes</button>
                    </div>
                </Dialog>
                {/* <button onClick={() => handleDialogOpen('/')}>Back to homepage</button> */}
            </div>
        </div >


    );
}

export default withAuthentication(Results1);
