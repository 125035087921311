import "./App.css"
import { Routes, Route, useLocation, Link } from "react-router-dom"
import React, { useState, useContext, useEffect, useRef } from 'react';
import { Context } from './Context';

// MUI UI components:
import { Box, CssBaseline, AppBar, Toolbar, IconButton, List, ListItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { Menu as MenuIcon, ChevronLeft as ChevronLeftIcon, QuestionAnswer as QuestionAnswerIcon, Assessment as AssessmentIcon } from '@mui/icons-material';

import MapIcon from '@mui/icons-material/Map';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import ListItemButton from '@mui/material/ListItemButton';
import MuiDrawer from '@mui/material/Drawer';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PeopleIcon from '@mui/icons-material/People';
import PsychologyIcon from '@mui/icons-material/Psychology';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ArticleIcon from '@mui/icons-material/Article';
import SpaceDashboardIcon from '@mui/icons-material/SpaceDashboard';
import GradingIcon from '@mui/icons-material/Grading';

// more style components:
import { Tooltip } from 'react-tooltip';
import { styled } from '@mui/material/styles';

// Images
import logo from './images/logo-colors.png';

// My components:
import Header from "./components/Header"
import ScrollToTop from './components/ScrollToTop';
// import Footer from './components/Footer';
// import Layout from './components/Layout';

// Pages:
import Homepage from "./pages/Homepage";
import Login from "./pages/Login";
// import Signup from "./pages/Signup";
import Privacy from "./pages/Privacy";
import Admin from "./pages/Admin";
import SuperAdmin from "./pages/SuperAdmin";
import Questions1 from "./pages/QuestionsApp/Questions1";
import Questions2 from "./pages/QuestionsApp/Questions2";
import Questions3 from "./pages/QuestionsApp/Questions3";
import Questions4 from "./pages/QuestionsApp/Questions4";
import Contacts1 from "./pages/ContactsApp/Contacts1";
import Contacts2 from "./pages/ContactsApp/Contacts2";
import Contacts3 from "./pages/ContactsApp/Contacts3";
import Analysis1 from "./pages/AnalysisApp/Analysis1";
import Analysis2 from "./pages/AnalysisApp/Analysis2";
import Analysis3 from "./pages/AnalysisApp/Analysis3";
import Pulling1 from "./pages/PullingApp/Pulling1";
import Concluding1 from "./pages/ConcludingApp/Concluding1";
import Map1 from "./pages/MapApp/Map1";
import Results1 from "./pages/ResultsApp/Results1";
import CV1 from "./pages/CvApp/CV1";
import Reports1 from "./pages/ReportsApp/Reports1";
import Reports2 from "./pages/ReportsApp/Reports2";
import Reports3 from "./pages/ReportsApp/Reports3";
import Reports4 from "./pages/ReportsApp/Reports4";
import Profile from "./pages/Profile";
import CookiePolicy from "./pages/CookiePolicy";
import CookieBanner from "./components/CookieBanner";

const drawerWidth = 200;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  // marginLeft: `-${drawerWidth}px`,
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const AppBarStyled = styled(AppBar, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: `${drawerWidth}px`,
      backgroundColor: 'white',
      boxShadow: 'none',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    ...(!open && {
      // width: `calc(100% - ${drawerWidth}px)`,
      width: '100%',
      marginLeft: `${drawerWidth}px`,
      backgroundColor: 'white',
      boxShadow: 'none',
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }
    )
  }),
);

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    zIndex: theme.zIndex.appBar - 1,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    position: 'fixed', // Add this line to make it overlay on top
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

function App() {
  const { userState, loading } = useContext(Context);
  const { isSuperAdmin, firstName, projects, contactsLists, interviews, maps, results, role, contacts, concluding } = userState;

  const [userFirstName, setUserFirstName] = useState(null);
  const drawerRef = useRef(null);
  const location = useLocation();
  const [selectedPath, setSelectedPath] = useState(location.pathname); // for highlighting the selected path in the sidebar

  // Determine if the current path is the login page or the profile page
  const isLoginPage = location.pathname === '/login';
  const isProfilePage = location.pathname.includes('/profile');

  // styles for the selected path in the sidebar
  const getIconStyles = (path, isEnabled) => ({
    backgroundColor: selectedPath === path ? 'var(--light-grey)' : 'transparent',
    color: selectedPath === path ? 'var(--strong-green)' : isEnabled ? '' : '#c5c5c5',
    cursor: isEnabled ? '' : 'default',
    '.MuiListItemIcon-root': {
      color: selectedPath === path ? 'var(--strong-green)' : isEnabled ? '' : '#c5c5c5',
    },
    '&:hover': {
      backgroundColor: 'var(--light-grey) !important',
      color: isEnabled ? 'var(--strong-green)' : '#c5c5c5', // grey out the button if it's disabled
    },
    // add hover also to the icon iside the button when the button is hovered that's inside ListItemIcon
    '&:hover .MuiListItemIcon-root': {
      color: isEnabled ? 'var(--strong-green)' : '#c5c5c5',
    },
  });

  // setting the selected path in the sidebar
  useEffect(() => {
    setSelectedPath(location.pathname);
  }, [location.pathname]);

  // setting the user's first name
  useEffect(() => {
    if (loading) return
    if (firstName && firstName.length > 0) {
      const userName =
        firstName.charAt(0).toUpperCase() +
        firstName.slice(1) + ", ";
      setUserFirstName(userName)
    }
  }, [firstName, loading, projects, contactsLists, interviews, maps, results]);

  // sidebar state
  const [open, setOpen] = useState(false);
  const handleDrawerOpen = () => { setOpen(true); };
  const handleDrawerClose = () => { setOpen(false); };
  const handleClickOutside = (event) => {
    if (drawerRef.current && !drawerRef.current.contains(event.target)) {
      handleDrawerClose();
    }
  };

  // close sidebar when clicking outside
  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  // state for enabling/disabling links in sidebar
  // const [checkedLinkes, setCheckedLinks] = useState(false);
  const [contactsLinkEnabled, setContactsLinkEnabled] = useState(false);
  const [networkLinkEnabled, setNetworkLinkEnabled] = useState(false);
  const [pullingLinkEnabled, setPullingLinkEnabled] = useState(false);
  const [concludingLinkEnabled, setConcludingLinkEnabled] = useState(false);
  const [mapLinkEnabled, setMapLinkEnabled] = useState(false); // for now, everyone can use
  const [resultsLinkEnabled, setResultsLinkEnabled] = useState(false);
  const [cvLinkEnabled, setCvLinkEnabled] = useState(false);

  // button names for the sidebar
  const appNamesTop = ['Dashboard']
  const appInnovationTools = ['Questions', 'Contacts', 'Analysis', 'Pulling', 'Concluding']
  const appCVTools = ['Map', 'Results', 'CV']
  const appAdminButtons = ['Reports', 'Admin Panel']

  // Tooltips for the sidebar
  const toolTipsInnovation = [
    open ? '' : 'Questions',
    contactsLinkEnabled ? open ? '' : 'Contacts' : 'Use the questions tool to unlock this tool',
    networkLinkEnabled ? open ? '' : 'Analysis' : 'Use the contacts tool to unlock this tool',
    pullingLinkEnabled ? open ? '' : 'Pulling' : 'Use the analysis tool to unlock this tool',
    concludingLinkEnabled ? open ? '' : 'Concluding' : 'Use the pulling tool to unlock this tool'
  ];
  const toolTipsCV = [
    mapLinkEnabled ? open ? '' : 'Map' : 'Use the concluding tool to unlock this tool',
    resultsLinkEnabled ? open ? '' : 'Results' : 'Use the map tool to unlock this tool',
    cvLinkEnabled ? open ? '' : 'CV' : 'Use the results tool to analyze yourself to unlock this tool'
  ]
  const toolTipsAdmin = [open ? '' : 'Reports', open ? '' : 'Admin Panel']

  // conditions to whether user can click on certain buttons in sidebar or not
  const checkLinks = () => {
    setContactsLinkEnabled(canClickContactsLink())
    setNetworkLinkEnabled(canClickNetworkLink())
    setPullingLinkEnabled(canClickPullingLink())
    setConcludingLinkEnabled(canClickConcludingLink())
    setMapLinkEnabled(canClickMapLink())
    setResultsLinkEnabled(canClickResultsLink())
    setCvLinkEnabled(canClickCVLink())
    // setCheckedLinks(true)
  }

  useEffect(() => {
    if (loading) return
    checkLinks()
  }, [loading, projects, contactsLists, interviews, maps, results, role, contacts, concluding])

  const canClickContactsLink = () => {
    if (!projects || projects.length === 0) return false
    for (let i = 0; i < projects.length; i++) {
      if (projects[i].step1.MainQ !== "" && projects[i].step1.FinalQ !== "") {
        return true
      }
    }
    return false
  }
  const canClickNetworkLink = () => {
    if (!contactsLists || contactsLists.length === 0) return false

    for (let i = 0; i < contactsLists.length; i++) {
      for (let j = 0; j < contactsLists[i].listData.length; j++) {
        if (contactsLists[i].listData[j].fullName !== "") {
          return true
        }
      }
    }
    return false
  }
  const canClickPullingLink = () => { return canClickNetworkLink() } // pulling is the same as network 

  const canClickConcludingLink = () => {
    if (!interviews || interviews.length === 0) return false
    for (let i = 0; i < interviews.length; i++) {
      if (interviews[i].interviewData) {
        return true
      }
    }
    return false
  }
  const canClickMapLink = () => { return true } // for now, everyone can use

  const canClickResultsLink = () => {
    if (!maps) return false
    // check if the map object has any data
    var size = Object.keys(maps).length
    if (size > 0) {
      return true
    }
    return false
  }
  const canClickCVLink = () => {
    if (!results || results.length === 0) return false
    // check if any of the items in the results array has contact called "yourself":
    for (let i = 0; i < results.length; i++) {
      if (results[i].contact === "yourself") {
        return true
      }
    }
    return false
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {!(isLoginPage || isProfilePage) && (
        <>
          <AppBarStyled position="fixed" open={open}>
            <Toolbar
              sx={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton
                  color="black"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  className="sidebar-toggle-icon"
                  sx={{
                    marginRight: 1,
                    ...(open && { display: 'none' }),
                    '&:hover': {
                      borderRadius: '10%',
                      backgroundColor: 'var(--light-grey)',
                      color: 'var(--strong-green) !important',
                    },

                  }}
                >
                  <MenuIcon />
                  <ChevronRightIcon sx={{ marginLeft: 0.5 }} />
                </IconButton>
                <h6 >{userFirstName}welcome to KYS</h6>
              </div>
              <Header location={location} />
            </Toolbar>
          </AppBarStyled>

          <Drawer ref={drawerRef} variant="permanent" open={open} className="MuiDrawer-root">
            <DrawerHeader style={{ display: 'flex', justifyContent: 'space-between' }}>
              {open &&
                <div className='nav-container' >
                  <Link to="/">
                    <div className='logo-container'>
                      <img src={logo} className="logo" alt="logo" />
                      <h4 className="logo-text" >KYS</h4>
                    </div>
                  </Link>
                </div>
              }
              <IconButton onClick={handleDrawerClose} >
                {open && <ChevronLeftIcon sx={{
                  '&:hover': {
                    borderRadius: '10%',
                    backgroundColor: 'var(--light-grey)',
                    color: 'var(--strong-green) !important',
                  },
                }} />}
              </IconButton>
            </DrawerHeader>

            <List sx={{ padding: 0 }}>
              {appNamesTop && appNamesTop.map((text, index) => (
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    component={Link}
                    to={'/'}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      ...getIconStyles('/', true),
                    }}
                    data-tooltip-id='dashboard'
                    position="right"
                    data-tooltip-content={open ? '' : 'Dashboard'}
                    aria-hidden="true"
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      {<SpaceDashboardIcon sx={{ ...getIconStyles('/', true) }} />}
                    </ListItemIcon>
                    <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>

            <List sx={{ padding: 0 }}>
              {open &&
                <ListItem sx={{ fontWeight: '500', paddingTop: 1 }}>
                  Innovation tools
                </ListItem>
              }
              {appInnovationTools && appInnovationTools.map((text, index) => {
                const path = [
                  '/QuestionsApp/Questions1',
                  '/ContactsApp/Contacts1',
                  '/AnalysisApp/Analysis1',
                  '/PullingApp/Pulling1',
                  '/ConcludingApp/Concluding1'
                ][index];

                const isEnabled = [true, contactsLinkEnabled, networkLinkEnabled, pullingLinkEnabled, concludingLinkEnabled][index];
                const tooltipContent = toolTipsInnovation[index] ? toolTipsInnovation[index] : '';

                return (
                  <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                      component={Link}
                      to={isEnabled ? path : ''}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        ...getIconStyles(path, isEnabled),
                      }}
                      data-tooltip-id={index}
                      position='right'
                      data-tooltip-content={tooltipContent}
                      aria-hidden="true"
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 2 : 'auto',
                          justifyContent: 'center',
                        }}
                      >
                        {index === 0 ? <QuestionAnswerIcon /> :
                          index === 1 ?
                            <PeopleIcon /> :
                            index === 2 ? <AssessmentIcon /> :
                              index === 3 ? <PsychologyIcon /> :
                                index === 4 ? <PsychologyAltIcon /> :
                                  <QuestionAnswerIcon />}
                      </ListItemIcon>
                      <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                      {open && !isEnabled &&
                        <i
                          data-tooltip-id={index}
                          position='right'
                          data-tooltip-content={toolTipsInnovation[index] ? toolTipsInnovation[index] : ''}
                          className="fas fa-question-circle"></i>}
                    </ListItemButton>
                  </ListItem>
                );

              })}
            </List>

            <List sx={{ padding: 0 }}>
              {open &&
                <ListItem sx={{ fontWeight: '500', paddingTop: 1 }}>
                  CV tools
                </ListItem>
              }
              {appCVTools && appCVTools.map((text, index) => {
                const path = index === 0 ? '/MapApp/Map1' : index === 1 ? '/ResultsApp/Results1' : index === 2 ? '/CvApp/CV1' : '/MapApp/Map1';
                const isEnabled = [true, resultsLinkEnabled, cvLinkEnabled][index];

                return (
                  <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                      component={Link}
                      to={isEnabled ? path : ''}
                      sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                        ...getIconStyles(path, isEnabled),
                      }}
                      data-tooltip-id={index}
                      position="right"
                      data-tooltip-content={toolTipsCV[index] ? toolTipsCV[index] : ''}
                      aria-hidden="true"
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 0,
                          mr: open ? 2 : 'auto',
                          justifyContent: 'center'
                        }}
                      >
                        {index === 0 ? <MapIcon /> :
                          index === 1 ? <InsertDriveFileIcon /> :
                            index === 2 ? <ArticleIcon /> :
                              <MapIcon />}


                      </ListItemIcon>
                      <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                      {open && !isEnabled &&
                        <i
                          data-tooltip-id={index}
                          position='right'
                          data-tooltip-content={toolTipsCV[index]}
                          className="fas fa-question-circle"></i>}

                    </ListItemButton>
                  </ListItem>
                );
              })}

            </List>

            {role === 'admin' && (
              <>
                <Divider sx={{ margin: '10px 0' }} />
                <List sx={{ padding: 0 }}>
                  {open &&
                    <ListItem sx={{ fontWeight: '500', paddingTop: 1 }}>
                      Manager tools
                    </ListItem>}
                  {appAdminButtons && appAdminButtons.map((text, index) => {
                    const path = index === 0 ? '/ReportsApp/Reports1' : index === 1 ? '/admin' : '/ReportsApp/Reports1';
                    return (
                      <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                          component={Link}
                          to={index === 0 ? '/ReportsApp/Reports1' : index === 1 ? '/admin' : '/ReportsApp/Reports1'}
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            ...getIconStyles(path, true),
                          }}
                          data-tooltip-id={index}
                          position="right"
                          data-tooltip-content={toolTipsAdmin[index]}
                          aria-hidden="true"
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: open ? 2 : 'auto',
                              justifyContent: 'center',

                            }}
                          >
                            {index === 0 ? <GradingIcon /> :
                              index === 1 ? <AdminPanelSettingsIcon /> :
                                <AdminPanelSettingsIcon />}
                          </ListItemIcon>
                          <ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                      </ListItem>
                    )
                  })}
                </List>
              </>
            )}
            {isSuperAdmin && (
              <List sx={{ padding: 0 }}>
                <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    component={Link}
                    to={'/superadmin'}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      ...getIconStyles('/superadmin', true)
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : 'auto',
                        justifyContent: 'center'
                      }}
                    >
                      <AdminPanelSettingsIcon sx={{ ...getIconStyles('/superadmin', true) }} />
                    </ListItemIcon>
                    <ListItemText primary={'Super Admin'} sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
                </ListItem>
              </List>
            )}

          </Drawer>
        </>
      )}

      <Main open={open}>
        <Tooltip key="dashboard-tooltip" className="tooltip sidebar" id="dashboard" place="right" effect="solid" />

        {toolTipsInnovation && toolTipsInnovation.map((tip, index) => {
          if (index === 0) {
            return (
              <Tooltip key={`questions-tooltip-${index}`} className="tooltip sidebar" id='0' place="right" effect="solid" />
            )
          }
          return (
            <Tooltip key={`innovation-tooltip-${index}`} className="tooltip sidebar" id={index} place="right" effect="solid" />
          )
        })}
        {toolTipsCV && toolTipsCV.map((tip, index) => {
          if (index === 0) {
            return (
              <Tooltip key={`questions-tooltip-${index}`} className="tooltip sidebar" id='0' place="right" effect="solid" />
            )
          }
          return (
            <Tooltip key={`cv-tooltip-${index}`} className="tooltip sidebar" id={index} place="right" effect="solid" />
          )
        }
        )}

        {toolTipsAdmin && toolTipsAdmin.map((tip, index) => {
          if (index === 0) {
            return (
              <Tooltip key={`reports-tooltip-${index}`} className="tooltip sidebar" id='0' place="right" effect="solid" />
            )
          }
          return (
            <Tooltip key={`admin-tooltip-${index}`} className="tooltip sidebar" id={index} place="right" effect="solid" />
          )
        }
        )}

        {!(isLoginPage || isProfilePage) && <DrawerHeader />}
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Homepage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/superadmin" element={<SuperAdmin />} />
          <Route path="/profile/*" element={<Profile />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/cookiepolicy" element={<CookiePolicy />} />
          <Route path="/QuestionsApp/Questions1" element={<Questions1 />} />
          <Route path="/QuestionsApp/Questions2" element={<Questions2 />} />
          <Route path="/QuestionsApp/Questions3" element={<Questions3 />} />
          <Route path="/QuestionsApp/Questions4" element={<Questions4 />} />
          <Route path='/ContactsApp/Contacts1' element={<Contacts1 />} />
          <Route path='/ContactsApp/Contacts2' element={<Contacts2 />} />
          <Route path='/ContactsApp/Contacts3' element={<Contacts3 />} />
          <Route path='/AnalysisApp/Analysis1' element={<Analysis1 />} />
          <Route path='/AnalysisApp/Analysis2' element={<Analysis2 />} />
          <Route path='/AnalysisApp/Analysis3' element={<Analysis3 />} />
          <Route path='/PullingApp/Pulling1' element={<Pulling1 />} />
          <Route path='/ConcludingApp/Concluding1' element={<Concluding1 />} />
          <Route path='/MapApp/Map1' element={<Map1 />} />
          <Route path='/ResultsApp/Results1' element={<Results1 />} />
          <Route path='/CvApp/CV1' element={<CV1 />} />
          <Route path='/ReportsApp/Reports1' element={<Reports1 />} />
          <Route path='/ReportsApp/Reports2' element={<Reports2 />} />
          <Route path='/ReportsApp/Reports3' element={<Reports3 />} />
          <Route path='/ReportsApp/Reports4' element={<Reports4 />} />
        </Routes>
        {/* <Footer /> */}
        <CookieBanner />
      </Main>

    </Box >
  )
}

export default App;
