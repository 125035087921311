import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import { nanoid } from 'nanoid';
import Loading from '../../components/Loading';
import withAuthentication from "../../withAuthentication";
import { Tooltip } from 'react-tooltip'
// import jsPDF from 'jspdf';
// import { saveAs } from 'file-saver';
// import { Document, Packer, Paragraph, HeadingLevel, AlignmentType } from "docx";
import { TextField, Select, MenuItem } from '@mui/material';


function Questions1() {

    const { userState, updateData, loading } = useContext(Context);
    const { projects, currProjectId, contactsLists, interviews, conclusions } = userState;

    const [selectedQuestion, setSelectedQuestion] = useState('');
    const [isDisabled, setIsDisabled] = useState(false)
    const [message, setMessage] = useState('')
    const [allUserQuestions, setAllUserQuestions] = useState([])
    const [searchQuery, setSearchQuery] = useState('');

    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    let newProjectId = nanoid()
    const navigate = useNavigate()

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: 48 * 4.5 + 8,
                width: 250,
            },
        },
    };

    const newProject = {
        id: newProjectId,
        projectDate: new Date().toLocaleDateString('en-GB', dateOptions),
        step1: {
            MainQ: "", Q1: "", Q2: "", Q3: "", Q4: "", Q5: "", Q6: "", Q7: "", Q8: "", ImprovedQ: "", FinalQ: ""
        },
        step2: {
            zoomInQuestions: {
                zoomin1: '', zoomin2: '', zoomin3: '', zoomin4: '', zoomin5: '', zoomin6: '', zoomin7: '', zoomin8: '',
            },
            zoomOutQuestions: {
                zoomout1: '', zoomout2: '', zoomout3: '', zoomout4: '', zoomout5: '', zoomout6: '', zoomout7: '', zoomout8: '',
            }
        }
    }

    useEffect(() => {
        if (loading) return
        if (!projects || projects.length === 0) {
            setIsDisabled(true)
        } else if (projects && projects.length > 0) {
            // filter out projects without main question as they are empty
            const projectsWithMainQ = projects.filter(project => project.step1.MainQ)
            if (projectsWithMainQ.length === 0) {
                return setIsDisabled(true)
            }
            setAllUserQuestions(projectsWithMainQ)
        }
    }, [projects, userState, loading]);

    async function createNewProject() {
        try {
            await updateData('projects', [...projects, newProject])
            await updateData('currProjectId', newProjectId);
        }
        catch (error) {
            setMessage('Error creating project')
        }
        navigate('/QuestionsApp/Questions2')

    }

    async function deleteProject() {
        const questionId = currProjectId
        const isQuestionUsedInLists = contactsLists.some((list) => { return list.listQuestions.find((question) => question.questionId === questionId) })
        const isQuestionUsedInInterviews = interviews.some((interview) => interview.questionId === questionId)
        const isQuestionUsedInConclusions = conclusions.some((conclusion) => conclusion.questionId === questionId)

        if (isQuestionUsedInLists) {
            let warningMessage = (
                <div>
                    <p>This question is used in one or more of your contacts lists.</p>
                    <p>Please delete the contacts list using it before deleting the project.</p>
                </div>
            )
            setMessage(warningMessage)
            return
        } else if (isQuestionUsedInInterviews) {
            let warningMessage = (
                <div>
                    <p>This question is used in one or more of your interviews (Pulling Tool).</p>
                    <p>Please delete the interviews using it before deleting the project.</p>
                </div>
            )
            setMessage(warningMessage)
            return
        } else if (isQuestionUsedInConclusions) {
            let warningMessage = (
                <div>
                    <p>This question is used in one or more of your conclusions (Concluding Tool)</p>
                    <p>Please delete the interviews and conclusions using it before deleting the project.</p>
                </div>
            )
            setMessage(warningMessage)
            return
        }

        if (!window.confirm('Are you sure you want to delete this project?')) return

        // make sure project exists
        const selectedProject = projects.find(project => project.id === currProjectId)
        // console.log('selectedProject: ', selectedProject)
        // const selectedProjectId = currProjectId

        if (!selectedProject) {
            setMessage('Error deleting project')
            return
        }

        const newProjects = projects.filter(project => project.id !== selectedProject.id)
        try {
            await updateData('projects', newProjects)
            await updateData('currProjectId', '');
            setMessage('Project deleted successfully')
            setSelectedQuestion('')
        }
        catch (error) {
            console.error("Error deleting project: ", error);
            setMessage('Error deleting project')
        }
        setMessage('')

    }

    const handleChange = async (e) => {
        const { value } = e.target // question text

        if (value === '') {
            setSelectedQuestion('')
            return
        }

        let selectedProjectId
        setSelectedQuestion(value)
        const selectedProject = projects.find(project => project.step1.MainQ === value || project.step1.FinalQ === value || project.step1.ImprovedQ === value)
        selectedProjectId = selectedProject.id

        setIsDisabled(true)
        try {
            await updateData('currProjectId', selectedProjectId);
            setIsDisabled(false)
        } catch
        (error) {
            console.error("Error updating project: ", error);
            setMessage('Error updating project')
        }
    }

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
    };

    const filteredQuestions = allUserQuestions && allUserQuestions.filter(question => {
        return question.step1.MainQ.toLowerCase().includes(searchQuery.toLowerCase())
    }).map((question, index) => {
        return <MenuItem
            sx={{
                textAlign: 'left !important', // Align text to the left
                whiteSpace: 'normal !important', // Enable text wrapping
                wordBreak: 'break-word !important', // Ensure words break correctly
            }}
            key={question.id}
            data-id={question.id}
            value={question.step1.FinalQ ? question.step1.FinalQ : question.step1.MainQ} >
            {question.step1.FinalQ ? question.step1.FinalQ : question.step1.MainQ}
        </MenuItem>
    })


    if (loading) return <Loading />

    return (
        <div className='app-home-container' style={{ justifyContent: 'flex-start' }}>
            <div className='app-title'>
                <h4 >Questions Analysis Tool
                    {/* <i
                        data-tooltip-id="questions1Title"
                        position="right"
                        data-tooltip-content="In the next few screens, you will be asked to enter your business question and the questions you want to ask your interviewees."
                        className="fa fa-question-circle"
                        aria-hidden="true"
                    ></i>
                    <Tooltip className="tooltip" id="questions1Title" /> */}
                </h4>
                <h6>Prepare for your next interview by building and analyzing your bussiness questions</h6>
                <p>This tool will help you build and analyze your business questions. You can load an existing analysis, delete it, or start a new one.</p>
                <p>If your manager has sent you questions, you can select and analyze them in the next screen by starting a new analysis.</p>
            </div>

            <div className='questions-main-container'>
                <div className='questions-section'>
                    <div >
                        <h6>Load or delete an existing analysis</h6>
                        <p>Search or select a question. Your search will narrow down the options below</p>
                    </div>
                    <div className='search-input-wrapper' style={{ width: '100%', marginTop: '10px' }}>
                        <input
                            type='text'
                            placeholder='Search questions by keyword'
                            value={searchQuery || ''}
                            onChange={handleSearch}
                            className='search-input'
                            style={{ width: '100%', textAlign: 'center', padding: '16px' }}
                            disabled={isDisabled || filteredQuestions.length === 0}
                        />
                        <i style={{ left: '50px' }}
                            className="fas fa-solid fa-magnifying-glass search-icon"></i>
                    </div>
                    <Select
                        name='questionSelect'
                        disabled={isDisabled || filteredQuestions.length === 0}
                        value={selectedQuestion ? selectedQuestion : ''}
                        sx={{ textAlign: 'center', width: '100%', marginTop: '10px', padding: '0px' }}
                        displayEmpty
                        renderValue={(value) => {
                            if (value === '') {
                                return 'Select existing questions'
                            }
                            return value
                        }}
                        MenuProps={MenuProps}
                        onChange={handleChange}>
                        {filteredQuestions}
                    </Select>

                    <div className='questions-1-btns'>
                        <button
                            onClick={() => navigate('/QuestionsApp/Questions2')}
                            disabled={isDisabled || !selectedQuestion}>Load analysis
                        </button>
                        <button
                            className='greyButton'
                            onClick={deleteProject}
                            disabled={isDisabled || !selectedQuestion}>Delete analysis
                        </button>
                    </div>
                </div>
                <div className='questions-section'>
                    <div style={{ marginBottom: '5px' }}>
                        <h6>Analyze a new business question</h6>
                        <p>Questions sent by your manager or personal questions</p>

                    </div>
                    <button
                        style={{ width: '100%', padding: '16px' }}
                        onClick={createNewProject}>Start new analysis
                    </button>
                </div>

            </div>
            <div className='successMessage'>{message}</div>



            {/* <div className='pagination'>
                <button onClick={() => navigate('/')}>Back to homepage</button>
            </div> */}
        </div >

    )
}

export default withAuthentication(Questions1);
