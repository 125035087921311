import { useState, useEffect, useRef, useContext } from 'react';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Divider } from "@mui/material";
import { Context } from '../Context';
import { auth } from '../firebase';
import { signOut } from "firebase/auth";

export default function Header() {
    const { userState, setUserState, loading } = useContext(Context)
    const { firstName, lastName, email, questionsFromAdmin, projects } = userState;

    const location = useLocation();
    const navigate = useNavigate();
    let menuRef = useRef();
    const isLoginPage = location.pathname === "/login";
    const isSignupPage = location.pathname === "/signup";

    const [open, setOpen] = useState(false);
    const [notificationsOpen, setNotificationsOpen] = useState(false);
    const [notificationsSeen, setNotificationsSeen] = useState(false);
    const [userName, setUserName] = useState('')
    const [unansweredAdminQuestions, setUnansweredAdminQuestions] = useState([])

    // prepare user name with capital letters:
    useEffect(() => {
        if (!firstName || firstName.length === 0) return;
        let name = '';
        if (firstName) name += firstName.charAt(0).toUpperCase() + firstName.slice(1);
        if (lastName && lastName.length > 0) name += ' ' + lastName.charAt(0).toUpperCase() + lastName.slice(1);

        setUserName(name)
    }, [firstName, userState, lastName])

    // check if user has unanswered questions from admin
    useEffect(() => {
        if (loading) return

        if (questionsFromAdmin && Array.isArray(questionsFromAdmin) && questionsFromAdmin.length > 0) {
            const allQuestionsFromAdminArray = questionsFromAdmin.map(q => q.question)
            const mainQuestionsFromProjects = projects && projects.map(p => p.step1.MainQ)
            const unansweredQuestions = allQuestionsFromAdminArray.filter(q => !mainQuestionsFromProjects.includes(q))
            setUnansweredAdminQuestions(unansweredQuestions)
        }
    }, [questionsFromAdmin, loading, projects]);

    // close menu when clicking outside of it:
    useEffect(() => {
        let handler = (e) => {
            if (menuRef.current && !menuRef.current.contains(e.target)) {
                setOpen(false)
                setNotificationsOpen(false)
            }
        }
        document.addEventListener("mousedown", handler)
        return () => {
            document.removeEventListener("mousedown", handler)
        }
    }, [open, menuRef, setOpen, setNotificationsOpen])

    const handleNotificationsClick = () => {
        setNotificationsOpen(!notificationsOpen);
        setOpen(false);
        setNotificationsSeen(true);  // Mark notifications as seen
    };

    // sign out the user from firebase, state and local storage and redirect to login page:
    function logout() {
        signOut(auth).then(() => {
            // reset local state:
            setUserState(prevState => ({
                ...prevState,
                user: null,
                uid: '',
                tenantId: '',
                role: '',
                projects: [],
                currProjectId: '',
                contacts: [],
                contactsLists: [],
                currTable: [],
                currListId: '',
                interviews: [],
                conclusions: [],
                maps: [],
                results: [],
                cv: [],
                reports: [],
                currReportId: '',
                age: '',
                firstName: '',
                lastName: '',
                email: '',
                isSuperAdmin: false,
                hasChangedPassword: false,
                questionsFromAdmin: [], // questions that were sent by the admin to the user
                adminQuestions: [], // only admins use this
            }));
            navigate("/login")

        }).catch((error) => {
            console.log("error signing out: ", error)
        });
    }

    if (isLoginPage || isSignupPage) return null;

    return (
        <header className="header-container">
            <div className='menu-container' ref={menuRef}>
                <button
                    className='right-menu-icon bell-button'
                    onClick={handleNotificationsClick}>
                    <i className="fa fa-fw fa-bell"
                        aria-hidden="true"
                        style={{ fontSize: '1.35em' }} />
                    {unansweredAdminQuestions && unansweredAdminQuestions.length && !notificationsSeen ?
                        <span className="button__badge">
                            {unansweredAdminQuestions.length}
                        </span> : null
                    }
                </button>
                <button
                    className='right-menu-icon'
                    onClick={() => { navigate("/"); setNotificationsOpen(false); setOpen(false); }}>
                    <i className="fa fa-fw fa-home"
                        aria-hidden="true"
                        style={{ fontSize: '1.25em' }} />
                </button>

                <div onClick={() => { setOpen(!open); setNotificationsOpen(false) }}>
                    <button className='right-menu-icon' >
                        <i className="fa fa-user"
                            aria-hidden="true"
                            style={{ fontSize: '1.25em', padding: '0px 2px' }}
                        >
                        </i>
                    </button>
                </div>

                <div className={`dropdown-menu ${open ? 'active' : 'inactive'}`} >
                    <div className='dropdownItem'>
                        <p style={{ color: 'var(--dark-alt)' }}>{userName}</p>
                        <p style={{ fontSize: '0.9rem', color: '#8a8a8a' }}>{email}</p>
                    </div>
                    <Divider />
                    <ul>
                        <li className='dropdownItem'>
                            <a href="mailto:info@humints.com">Contact us</a>
                        </li>
                        <li className='dropdownItem'>
                            <Link to="/privacy">Privacy policy</Link>
                        </li>
                        <li className='dropdownItem'>
                            <Link to="/cookiepolicy">Cookie Policy</Link>
                        </li>
                        <Divider />
                        <li className='dropdownItem'>
                            <button style={{ textDecoration: 'none' }}
                                className='transparentBtn'
                                onClick={logout}> Log out
                                <i className="fas fa-sign-out"
                                    style={{ fontSize: '0.95em' }}
                                ></i>
                            </button>
                        </li>
                    </ul>
                </div>

                <div className={`dropdown-menu ${notificationsOpen ? 'active' : 'inactive'}`} >
                    <div className='dropdownItem'>
                        {unansweredAdminQuestions && unansweredAdminQuestions.length > 0 ?
                            <p style={{ color: 'var(--dark-alt)' }}>
                                You have {unansweredAdminQuestions && unansweredAdminQuestions.length} {unansweredAdminQuestions.length > 1 ? 'questions' : 'question'} from your manager waiting for you in the questions tool
                            </p>
                            : <p style={{ color: 'var(--dark-alt)' }}>No new questions from manager</p>}
                    </div>

                </div>
            </div>

        </header >)
}