import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import { nanoid } from 'nanoid'
import NetworkChart from '../../components/NetworkChart';
import ContactChart from '../../components/ContactChart';
import RadarChart from '../../components/RadarChart';
import Loading from '../../components/Loading';
import withAuthentication from "../../withAuthentication";
import { Tooltip } from 'react-tooltip'
import jsPDF from 'jspdf';
// import { saveAs } from 'file-saver';
// import { Document, Packer, Paragraph, HeadingLevel, AlignmentType, TableCell, TableRow, } from "docx";
// import autoTable from 'jspdf-autotable'

function Analysis3() {
    const { userState, updateData, loading } = useContext(Context);
    const { contactsLists, currListId, currProjectId, role } = userState;

    const [currList, setCurrList] = useState('')
    const [chosenListData, setChosenListData] = useState([])
    const [networkChartValues, setNetworkChartValues] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [currQuestionId, setCurrQuestionId] = useState(currProjectId ? currProjectId : '')
    // const [questionText, setQuestionText] = useState('')
    const [currContactName, setCurrContactName] = useState('')
    const [currContactAnswers, setCurrContactAnswers] = useState([])
    const [listOptions, setListOptions] = useState([])
    const [contactsDropDown, setContactsDropDown] = useState([])
    const [allContactsTable, setAllContactsTable] = useState([])
    const [tableMessage, setTableMessage] = useState('')
    const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' };
    const navigate = useNavigate();
    const categories = ['Relevant', 'Reliable', 'Accurate', 'Timing', 'Clarity', 'Knowledge', 'Conflict of Interests', 'Priority']
    // const categoriesForMapping = ['relevantInfo', 'reliableInfo', 'accurateInfo', 'time', 'clarity', 'knowledge', 'conflict', 'priority']

    const [contactChartValues, setContactChartValues] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [radarChartValues, setRadarChartValues] = useState([0, 0, 0, 0, 0, 0, 0, 0])

    // console.log(contacts, 'all user contacts')
    // console.log(contactsLists, 'contactsLists')
    // console.log(currList, 'currList')
    // console.log(currListId, 'currListId')
    // console.log(currProjectId, 'currProjectId')
    // console.log(currQuestionId, 'currQuestionId')
    // console.log(chosenListData, 'chosenListData')
    // console.log(allContactsTable, 'allContactsTable')


    // update lists options:
    useEffect(() => {
        if (loading || !contactsLists || contactsLists.length === 0) return

        // only render lists that have listData (contacts) in them and if listTable is not an array with length of 0:
        const lists = contactsLists.filter(list => { return list.listData && list.listData.length > 0 })
        const listOptions = lists.map(list => { return (<option key={nanoid()} value={list.listId}>{list.listName}</option>) })
        setListOptions(listOptions)

        // find the list that matches the currListId:
        const currList = contactsLists.find(list => { return list.listId === currListId })
        if (!currList) {
            setCurrList(contactsLists[0])
            return
        }
        setCurrList(currList)

    }, [loading, contactsLists, currListId, currProjectId]);

    // when currlist changes, update the chosenListData:
    useEffect(() => {
        if (loading || !currList) return
        // filter contacts without name
        if (!currList.listData || currList.listData.length === 0) return
        // prepare array of contacts with names only:
        const newListData = currList.listData.filter(contact => { return contact.fullName ? contact : null })

        setChosenListData(newListData)
        setCurrQuestionId(currList.listQuestions[0].questionId)
        // const currQuestion = currList.listQuestions.find(question => { return question.questionId === currQuestionId })
        // setQuestionText(currProjectId ? currList.listQuestions.find(question => { return question.questionId === currProjectId }).questionText : currList.listQuestions[0].questionText)

    }, [currList, loading, currContactName]);

    // set clarity and conflict table with all the contacts of the list
    useEffect(() => {
        if (loading || !chosenListData || chosenListData.length === 0) return

        const contactsTable = getContactsTable()
        setAllContactsTable(contactsTable)

    }, [chosenListData, loading, currContactName, currQuestionId])

    // update the contacts drop down with all the contacts of the list
    useEffect(() => {
        if (loading || !chosenListData || chosenListData.length === 0) return
        const selectedContactsDropDown = chosenListData.map(contact => {
            return (<option key={contact.id} value={contact.fullName}>{contact.fullName}</option>)
        })
        setContactsDropDown(selectedContactsDropDown)

    }, [chosenListData, loading, currContactName, currQuestionId])

    // update individual contact chart when currContactName changes:
    useEffect(() => {
        if (loading || !chosenListData || chosenListData.length === 0 || !currContactName) return
        const currContact = chosenListData.find(contact => { return contact.fullName === currContactName })
        if (!currContact) return

        // check if currContact's anaylze array isn't empty:
        if (!currContact.analyze || currContact.analyze.length === 0) {
            setCurrContactAnswers([{
                relevantInfo: '',
                reliableInfo: '',
                accurateInfo: '',
                time: '',
                knowledge: '',
                conflict: '',
                networking: '',
                role: '',
                years: '',
                status: '',
                describe: '',
            }])
            return
        }

        // check if the contact has answers for the current question, if not, set the answers to empty string:
        const contactAnswers = currContact.analyze.find(answer => { return answer.questionId === currQuestionId })
        if (!contactAnswers) {
            setCurrContactAnswers([{
                relevantInfo: '',
                reliableInfo: '',
                accurateInfo: '',
                time: '',
                knowledge: '',
                conflict: '',
                networking: '',
                role: '',
                years: '',
                status: '',
                describe: '',
            }])
            return
        }
        const answers = contactAnswers.answers
        setCurrContactAnswers(answers)
    }, [chosenListData, loading, currContactName, currQuestionId])

    // network chart calculations based on chosenListData:
    useEffect(() => {
        if (loading || !chosenListData || chosenListData.length === 0) return

        // 1. Calculations for network chart
        let networkMembers = chosenListData.length;
        // 5
        let maximalStrengthAquaintanceUpper = calculateSumOfSelectedValues(5, "upper");
        let maximalStrengthAquaintanceLower = calculateSumOfSelectedValues(5, "lower");
        let maximalStrengthAquaintance = (maximalStrengthAquaintanceUpper + maximalStrengthAquaintanceLower) / 2;
        // 1
        let minimalStrengthAquaintanceUpper = calculateSumOfSelectedValues(1, "upper");
        let minimalStrengthAquaintanceLower = calculateSumOfSelectedValues(1, "lower");
        let minimalStrengthAquaintance = (minimalStrengthAquaintanceUpper + minimalStrengthAquaintanceLower) / 2;
        // 2
        let twoWayRelationsUpper = calculateSumOfSelectedValues(2, "upper");
        let twoWayRelationsLower = calculateSumOfSelectedValues(2, "lower");
        let twoWayRelations = (twoWayRelationsUpper + twoWayRelationsLower) / 2;
        // 3
        let threeWayRelationsUpper = calculateSumOfSelectedValues(3, "upper");
        let threeWayRelationsLower = calculateSumOfSelectedValues(3, "lower");
        let threeWayRelations = (threeWayRelationsUpper + threeWayRelationsLower) / 2;
        // 4
        let fourWayRelationsUpper = calculateSumOfSelectedValues(4, "upper");
        let fourWayRelationsLower = calculateSumOfSelectedValues(4, "lower");
        let fourWayRelations = (fourWayRelationsUpper + fourWayRelationsLower) / 2;
        // 0
        let zeroClosenessUpper = calculateSumOfSelectedValues(0, "upper");
        let zeroClosenessLower = calculateSumOfSelectedValues(0, "lower");
        let zeroClosenessNum = (zeroClosenessUpper + zeroClosenessLower) / 2;

        // number of times each type of realtion closenes appears in table (minimial(1)+maximal(5)+2+3+4), excluding 0
        let relations = maximalStrengthAquaintance + minimalStrengthAquaintance +
            twoWayRelations + threeWayRelations + fourWayRelations;
        // sum of all relations - minimial(1)+maximal(5)+2+3+4, excluding 0
        let sumOfRelations = (5 * maximalStrengthAquaintance) + (1 * minimalStrengthAquaintance) +
            (2 * twoWayRelations) + (3 * threeWayRelations) + (4 * fourWayRelations);

        // sum of all relations, not including 0 closeness, divided by the sum of all relations, 
        //including 0 closeness:
        let networkRelationsStrength = (networkMembers - 1) !== 0 ? sumOfRelations / relations : 0;

        let maxmialDensity = (networkMembers * (networkMembers - 1)) / 2; // maximal number of relations in the network
        let acquaintancesNum = maxmialDensity - zeroClosenessNum;
        let networkDensity = acquaintancesNum / maxmialDensity;

        // console.log(networkMembers, 'networkMembers')
        // console.log(maximalStrengthAquaintance, 'maximalStrengthAquaintance')
        // console.log(minimalStrengthAquaintance, 'minimalStrengthAquaintance')
        // console.log(twoWayRelations, 'twoWayRelations')
        // console.log(threeWayRelations, 'threeWayRelations')
        // console.log(fourWayRelations, 'fourWayRelations')
        // console.log(zeroClosenessNum, 'zeroClosenessNum')
        // console.log(networkRelationsStrength, 'networkRelationsStrength')
        // console.log(maxmialDensity, 'maxmialDensity')
        // console.log(acquaintancesNum, 'acquaintancesNum')
        // console.log(networkDensity, 'networkDensity')


        let networkChartValues = [networkMembers, maxmialDensity, zeroClosenessNum, acquaintancesNum,
            networkDensity, networkRelationsStrength, minimalStrengthAquaintance, twoWayRelations,
            threeWayRelations, fourWayRelations, maximalStrengthAquaintance]
        setNetworkChartValues(networkChartValues)

    }, [chosenListData, currContactAnswers, currContactName, currQuestionId, loading]);

    // *Individual *Clarity&Conflict* and *RADAR* CHARTs calculations based on chosenListData:
    useEffect(() => {
        if (loading || !chosenListData || chosenListData.length === 0 || !currContactName) return

        const chosenContactData = chosenListData.find(contact => { return contact.fullName === currContactName })
        if (!chosenContactData) return
        // check if currContact's anaylze array isn't empty:
        if (!chosenContactData.analyze || chosenContactData.analyze.length === 0) {
            resetData()
            return
        }
        // check if the contact has answers for the current question, if not, set the answers to empty string:
        const chosenContactAnswers = chosenContactData.analyze.find(answer => { return answer.questionId === currQuestionId })
        if (!chosenContactAnswers || !chosenContactAnswers.answers) {
            resetData()
            return
        }

        setCurrContactAnswers(chosenContactAnswers.answers)
        setTableMessage('')

        // contact chart calculations:
        let newContactChartValues = [0, 0, 0, 0, 0, 0, 0, 0]
        let newRadarChartValues = [0, 0, 0, 0, 0]
        let clarity = ((Number(currContactAnswers.relevantInfo) + Number(currContactAnswers.reliableInfo) + Number(currContactAnswers.accurateInfo) + Number(currContactAnswers.time)) / 4)
        let priority = ((Number(currContactAnswers.knowledge) + (5 - Number(currContactAnswers.conflict))) / 2)

        newContactChartValues = [currContactAnswers.relevantInfo, currContactAnswers.reliableInfo, currContactAnswers.accurateInfo, currContactAnswers.time, clarity, currContactAnswers.knowledge, currContactAnswers.conflict, priority]
        setContactChartValues(newContactChartValues)

        // radar chart calculations:
        newRadarChartValues = [currContactAnswers.networking, currContactAnswers.role, currContactAnswers.years, currContactAnswers.status, currContactAnswers.describe]
        setRadarChartValues(newRadarChartValues)

    }, [chosenListData, currContactAnswers, currContactName, currQuestionId, loading]);


    // Clarity & Conflict Analysis TABLE for all contacts:
    function getContactsTable() {
        if (loading || !chosenListData || chosenListData.length === 0) return null

        const allContactsTable =
            <table style={{ width: '90%' }} className='analysis-contacts-table'>
                <thead>
                    <tr>
                        <td></td>
                        <td></td>
                        <th style={{ backgroundColor: '#888', color: 'white' }} className='colName' colSpan={5} scope="col">Clarity</th>
                        <th style={{ backgroundColor: '#888', color: 'white' }} className='colName' colSpan={3} scope="col">Knowledge vs. Conflict</th>
                    </tr>
                    <tr>
                        <td></td>
                        <td></td>
                        {categories.map(category => <th key={nanoid()} className={`colName tableNums ${category}`} scope="col">{category}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {chosenListData ? chosenListData.map(contact => {
                        return (
                            <tr key={nanoid()}>
                                <th className='tableNums' scope="row">{contact.contactNum || ''}</th>
                                <th className='tableNums' scope="row">{contact.fullName || 'No Data'}</th>
                                <td>{contact.analyze.find(answer => { return answer.questionId === currQuestionId })?.answers?.relevantInfo || 'No Data'}</td>
                                <td>{contact.analyze.find(answer => { return answer.questionId === currQuestionId })?.answers?.reliableInfo || 'No Data'}</td>
                                <td>{contact.analyze.find(answer => { return answer.questionId === currQuestionId })?.answers?.accurateInfo || 'No Data'}</td>
                                <td>{contact.analyze.find(answer => { return answer.questionId === currQuestionId })?.answers?.time || 'No Data'}</td>
                                <td style={{ backgroundColor: '#F7EC34' }}>
                                    {(((Number(contact.analyze.find(answer => { return answer.questionId === currQuestionId })?.answers?.relevantInfo) || 0) +
                                        (Number(contact.analyze.find(answer => { return answer.questionId === currQuestionId })?.answers?.reliableInfo) || 0) +
                                        (Number(contact.analyze.find(answer => { return answer.questionId === currQuestionId })?.answers?.accurateInfo) || 0) +
                                        (Number(contact.analyze.find(answer => { return answer.questionId === currQuestionId })?.answers?.time) || 0))) / 4 || 'No Data'}
                                </td>
                                <td>{contact.analyze.find(answer => { return answer.questionId === currQuestionId })?.answers?.knowledge || 'No Data'}</td>
                                <td>{contact.analyze.find(answer => { return answer.questionId === currQuestionId })?.answers?.conflict || 'No Data'}</td>
                                <td style={{ backgroundColor: '#F7EC34' }}>
                                    {((Number(contact.analyze.find(answer => { return answer.questionId === currQuestionId })?.answers?.knowledge) || 0) + (
                                        5 - (Number(contact.analyze.find(answer => { return answer.questionId === currQuestionId })?.answers?.conflict) || 0))) / 2 || 'No Data'}
                                </td>
                            </tr>
                        )
                    }) : ''}
                </tbody>
            </table>
        return allContactsTable
    }

    const jumpTo = (id) => {
        const element = document.getElementById(id);
        element.scrollIntoView({ behavior: 'smooth' });
    }
    const jumpToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    function handleChange(e) {
        const { name, value } = e.target
        if (!value || value === '') {
            setCurrList('')
            setCurrContactName('')
            setCurrContactAnswers('')
            return
        }
        // if the chosen value is a list, find it in contactsLists, set it to currList, set chosenListData to listData
        if (name === 'listSelect') {
            const chosenList = contactsLists.find(list => { return list.listId === value })

            try {
                updateData('currListId', value)
            } catch {
                // console.log('error')
            }

            setCurrList(chosenList)
        }
        if (name === 'contactSelect') {
            setCurrContactName(value) // contact name

        }
    }

    function convertTableObjectToArray(tableObject) {
        const tableArray = [];
        const rows = Object.keys(tableObject);
        const cols = Object.keys(tableObject[rows[0]]);
        const sortedCols = cols.map(col => parseInt(col.slice(3))).sort((a, b) => a - b);
        const sortedRows = rows.map(row => parseInt(row.slice(3))).sort((a, b) => a - b);

        for (var i = 0; i < sortedRows.length; i++) {
            var row = [];
            var rowIndex = sortedRows[i];
            for (var j = 0; j < sortedCols.length; j++) {
                var colIndex = sortedCols[j];
                var cell = {
                    selected: tableObject[`row${rowIndex}`][`col${colIndex}`].selected,
                    index: tableObject[`row${rowIndex}`][`col${colIndex}`].index,
                };
                row.push(cell);
            }
            tableArray.push(row);
        }
        return tableArray;
    }
    // calculate the sum of selected values in a table:
    function calculateSumOfSelectedValues(value, part) {
        if (!contactsLists || contactsLists.length === 0 || !currList.listTable || currList.listTable.length === 0) return

        const maxmialDensity = chosenListData.length * (chosenListData.length - 1);
        let table = currList.listTable
        const tableArr = convertTableObjectToArray(table)
        let counter = 0
        tableArr.forEach((row, i) => {
            row.forEach((cell, j) => {
                if (cell.index[0] === cell.index[1]) return // if the cell is on the diagonal, skip it
                if (part === "upper" && j <= i) return; // if the cell is in the upper part of the table, skip it
                if (part === "lower" && j >= i) return; // if the cell is in the lower part of the table, skip it
                else if (cell.selected === value) {
                    counter++
                }
            })
        })
        return Math.min(counter, maxmialDensity);
    }

    // functions to allow user to grab content of charts and download them as png:
    const chartRefs = {};
    function canvasToPngDataURL(canvas) {
        return canvas.toDataURL('image/png');
    }

    const handleCanvasReady = (chartName, canvas) => {
        chartRefs[chartName] = canvas;
    };

    const downloadChartAsPDF = (chartName) => {
        const pngData = canvasToPngDataURL(chartRefs[chartName]);
        //download as pdf:
        const pdf = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true,
            compress: true,
            precision: 2,
            lineHeight: 1.3,
        })

        let currentY = 14;
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`Individual Clarity & Conflict analysis for: "${currContactName}"`,
            pdf.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 5;
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'normal');
        pdf.text(`${new Date().toLocaleDateString("en-GB", dateOptions)} `,
            pdf.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 10;
        let imgWidth = 130;  // Width of the image in the PDF
        if (chartName === 'networkChart') {
            imgWidth = 140;  // Width of the image in the PDF
        }
        const imgHeight = 110; // Height of the image in the PDF
        const pageWidth = pdf.internal.pageSize.getWidth();
        const xCentered = (pageWidth - imgWidth) / 2;

        pdf.addImage(pngData, 'PNG', xCentered, currentY, imgWidth, imgHeight);  // 10 is the left margin, adjust as needed
        pdf.save(`${currContactName}_analysis.pdf`); // Generated PDF
    };

    const printTable = () => {
        // Grab the table using its class
        const table = document.querySelector('.analysis-contacts-table');
        // Convert it to PDF using documentjs library
        console.log(table, 'table')
        const pdf = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true,
            compress: true,
            precision: 2,
            lineHeight: 1.3,
        })
        let currentY = 20;
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'bold');
        pdf.text(`Clarity & Conflict Analysis for: "${currList.listName}"`,
            pdf.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 5;
        pdf.setFontSize(12);
        pdf.setFont('helvetica', 'normal');
        pdf.text(`${new Date().toLocaleDateString("en-GB", dateOptions)} `,
            pdf.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 10;

        pdf.autoTable({
            html: table,
            startY: currentY,
            didDrawCell: (data) => {
                if (data.column.index === 0) {
                    currentY = data.cell.y + 5;
                    pdf.setFontSize(10);
                    pdf.setFont('helvetica', 'normal');
                    pdf.text(data.cell.raw.innerText, data.cell.x + 2, currentY, {
                        align: 'left',
                    });
                }
            }

        });
        pdf.save(`${currList.listName}_analysis.pdf`); // Generated PDF
    }

    function resetData() {
        setCurrContactAnswers('')
        setContactChartValues([0, 0, 0, 0, 0, 0, 0, 0])
        setRadarChartValues([0, 0, 0, 0, 0])
        setTableMessage('*No data to display, please choose another contact')

    }

    if (loading) return <Loading />
    return (
        <div className="app-home-container">
            <div className='app-title'>
                <h4>Network Analysis
                    <i
                        style={{ marginLeft: '10px' }}
                        data-tooltip-id="analysis"
                        data-tooltip-content="Network Analysis is a method of analyzing the relationships between people in your contact list"
                        className="fa fa-question-circle"
                        aria-hidden="true">
                        <Tooltip className="tooltip" id="analysis" />
                    </i>
                </h4>
                <h6>Your contact list network overall status and individual contact analysis</h6>
            </div>
            <div className='anaylsis3-top-container'>
                <div className='analysis3-section element-body'>
                    <div >
                        <h6 >Selected list: </h6>
                        <p className='grey-strip' >{currList ? `${currList.listName}` : ''}</p>
                    </div>
                    <div >
                        <h6 >Date created:</h6>
                        <p className='grey-strip'>{currList ? currList.listDate : ''}</p>
                    </div>

                    <div >
                        <h6 >Change list:</h6>
                        <select
                            name='listSelect'
                            value={currList ? currList.listId : ''}
                            onChange={handleChange}>
                            <option value="">Select list</option>
                            {listOptions}
                        </select>
                    </div>
                </div>

                <div style={{ flexDirection: 'column', width: '50%' }} className='analysis3-section element-body'>
                    <div >
                        <h6>Jump to different graphs</h6>

                    </div>
                    <div className='analysis3-section' >
                        <button onClick={() => jumpTo('networkStrength')}>Network strength</button>
                        <button onClick={() => jumpTo('listContacts')}>Contacts analysis</button>
                        <button onClick={() => jumpTo('individualContact')}>Individual Analysis</button>
                    </div>

                </div>
            </div>

            <div className='analysis3-main-container '>
                <h6 className='element-title'>Network strength of contact list: "
                    <span style={{ fontWeight: 'normal' }}>{currList && currList.listName ? currList.listName : ''}
                    </span>"
                </h6>
                <div className='network-chart element-body' id='networkStrength'>
                    <NetworkChart networkChartValues={networkChartValues} onCanvasReady={(canvas) => handleCanvasReady('networkChart', canvas)} page={'analysis'} />
                    {role && role === 'admin' &&
                        <button onClick={() => downloadChartAsPDF('networkChart')}>Download this graph as PDF</button>
                    }
                </div>

                <h6 className='element-title'>Clarity & Conflict Analysis</h6>
                <div className='analysis-contact-table element-body' id='listContacts'>
                    {/* <div>
                        <select
                                style={{minWidth: '150px', width: 'fit-content', padding: '5px'}}
                                name='questionChange'
                                disabled={isDisabled}
                                value={currContactName || ''}
                                onChange={handleChange}>
                                <option value="">change main question</option>
                                {contactsDropDown}
                            </select>
                        </div> */}
                    {allContactsTable ? allContactsTable : 'No Data'}
                    {role && role === 'admin' &&
                        <button style={{ marginTop: '20px' }} onClick={printTable}>Download this table as PDF</button>
                    }
                </div>
                <h6 className='element-title'>{currContactName ? `Individual Clarity & Conflict analysis for: "${currContactName}"` : 'Individual Clarity & Conflict analysis'}</h6>
                <div id='individualContact' className='analysis-contact-table element-body' >
                    <select
                        style={{ minWidth: '150px', width: 'fit-content', padding: '5px' }}
                        name='contactSelect'
                        value={currContactName || ''}
                        onChange={handleChange}>
                        <option value="">Select contact</option>
                        {contactsDropDown}
                    </select>
                    <p style={{ marginTop: '25px' }} className='successMessage' >{tableMessage}</p>
                    <div style={{ width: '80%', marginRight: '30px' }}>
                        <ContactChart contactChartValues={contactChartValues} onCanvasReady={(canvas) => handleCanvasReady('contactChart', canvas)} />
                    </div>
                    {role && role === 'admin' &&
                        <button onClick={() => downloadChartAsPDF('contactChart')}>Download this graph as PDF</button>
                    }
                </div>
                <h6 className='element-title'>{currContactName ? `Network Analysis for: : "${currContactName}"` : 'Network Analysis'}</h6>
                <div id='individualContact' className='analysis-contact-table element-body ' >
                    <div style={{ width: '60%', marginLeft: '50px' }}>
                        <RadarChart radarChartValues={radarChartValues} onCanvasReady={(canvas) => handleCanvasReady('radarChart', canvas)} />
                    </div>
                    {role && role === 'admin' &&
                        <button onClick={() => downloadChartAsPDF('radarChart')}>Download this graph as PDF</button>
                    }
                </div>
                <div>
                    {role && role === 'admin' &&
                        <button onClick={(e) => window.print(e)}>Print / download whole page</button>
                    }
                    <button onClick={jumpToTop} >Return to top</button>
                </div>

            </div>
            <div className='pagination'>
                <button onClick={() => { navigate('/AnalysisApp/Analysis2') }}>Back to step 2</button>
                {/* <button onClick={() => { navigate('/') }}>Back to homepage</button> */}
            </div>
        </div >

    );

}

export default withAuthentication(Analysis3);

// let networkStrength = maximalStrengthAquaintance / minimalStrengthAquaintance;
// if both of these are 0 then the network strength is 0:
// if (maximalStrengthAquaintance === 0 && minimalStrengthAquaintance === 0) {
//     networkStrength = 0
// }
// let networkStrengthScore = networkStrength / networkRelationsStrength;
// if (isNaN(networkStrengthScore) || networkStrengthScore === Infinity) {
//     networkStrengthScore = 0
// }
// let networkDensityScore = networkDensity / networkRelationsStrength;
// if (isNaN(networkDensityScore) || networkDensityScore === Infinity) {
//     networkDensityScore = 0
// }
// let networkScore = (networkDensityScore + networkStrengthScore) / 2;

