import { useContext } from "react";
import { Context } from "../Context";
import Loading from "../components/Loading";

function Privacy() {
  const { loading } = useContext(Context);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="app-home-container">
      <div className="app-title">
        <h2>Privacy Policy</h2>
        <p>This Privacy Policy was last updated on: June 18, 2024</p>
      </div>
      <div className="privacy-container">
        <div className="privacy-text">
          <p>
            HUMINT Solutions ("Company", "us", "we", or "our") operates the
            https://tinokys.com/ and https://humints.com websites (hereinafter
            referred to as the "Service"). This page informs you of our policies
            regarding the collection, use and disclosure of personal data when
            you use our Service and the choices you have associated with that
            data. We use your data to provide and improve the Service. By using
            the Service, you agree to the collection and use of information in
            accordance with this policy. Questions or concerns? Reading this
            privacy notice will help you understand your privacy rights and
            choices. If you do not agree with our policies and practices, please
            do not use our Services. If you still have any questions or
            concerns, please contact us at{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="mailto: 'info@humints.com"
            >
              info@humints.com
            </a>
            .
          </p>
        </div>
        <div className="privacy-text">
          <h5>Definitions</h5>
          <ul>
            <li>
              <p>
                <strong>Service</strong>: Service is the https://tinokys.com/
                website operated by HUMINT Solutions
              </p>
            </li>
            <li>
              <p>
                <strong>Personal Data</strong>: Personal Data means data about a
                living individual who can be identified from those data (or from
                those and other information either in our possession or likely
                to come into our possession).
              </p>
            </li>
            <li>
              <p>
                <strong>Usage Data</strong>: Usage Data is data collected
                automatically either generated by the use of the Service or from
                the Service infrastructure itself (for example, the duration of
                a page visit).
              </p>
            </li>
            <li>
              <p>
                <strong>Cookies</strong>: Cookies are small files stored on your
                device (computer or mobile device).
              </p>
            </li>
          </ul>
        </div>
        <div className="privacy-text">
          <h5>Information Collection and Use</h5>
          <p>
            We collect several different types of information for various
            purposes to provide and improve our Service to you. Types of Data
            Collected:{" "}
          </p>
          <h6 style={{ marginTop: "10px" }}>Personal Data</h6>
          <p>
            While using our Service, we may ask you to provide us with certain
            personally identifiable information that can be used to contact or
            identify you ("Personal Data"). Personally identifiable information
            may include, but is not limited to:
          </p>
          <ul style={{ fontSize: "1rem" }}>
            <li>Email address</li>
            <li>First name and last name</li>
            <li>Phone number</li>
            <li>Address, State, Province, ZIP/Postal code, City</li>
            <li>Cookies and Usage Data</li>
          </ul>
          <h6 style={{ marginTop: "10px" }}>Log and Usage Data</h6>
          <p>
            When you access the Service by or through a mobile device, we may
            collect certain information automatically, including, but not
            limited to, the type of mobile device you use, your mobile device
            unique ID, the IP address of your mobile device, your mobile
            operating system, the type of mobile Internet browser you use,
            unique device identifiers and other diagnostic data ("Usage Data").
          </p>
          <h6 style={{ marginTop: "10px" }}>Tracking & Cookies Data</h6>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our Service and we hold certain information.
          </p>
          <p>
            Cookies are files with a small amount of data which may include an
            anonymous unique identifier. Cookies are sent to your browser from a
            website and stored on your device. Other tracking technologies are
            also used such as beacons, tags and scripts to collect and track
            information and to improve and analyse our Service.
          </p>
          <p>
            You can instruct your browser to refuse all cookies or to indicate
            when a cookie is being sent. However, if you do not accept cookies,
            you may not be able to use some portions of our Service.
          </p>
          <p>Examples of Cookies we use:</p>
          <ul>
            <li>
              <strong>Session Cookies.</strong> We use Session Cookies to
              operate our Service.
            </li>
            <li>
              <strong>Preference Cookies.</strong> We use Preference Cookies to
              remember your preferences and various settings.
            </li>
            <li>
              <strong>Security Cookies.</strong> We use Security Cookies for
              security purposes.
            </li>
          </ul>
          <p>
            For more information on how we use cookies, please read our{" "}
            <a href="/cookiepolicy">Cookie Policy</a>.
          </p>
        </div>
        <div className="privacy-text">
          <h5>Use of Data</h5>
          <p>HUMINT Solutions uses the collected data for various purposes:</p>
          <ul>
            <li>To provide and maintain our Service</li>
            <li>To notify you about changes to our Service</li>
            <li>
              To allow you to participate in interactive features of our Service
              when you choose to do so
            </li>
            <li>To provide customer support</li>
            <li>
              To gather analysis or valuable information so that we can improve
              our Service
            </li>
            <li>To monitor the usage of our Service</li>
            <li>To detect, prevent and address technical issues</li>
          </ul>
        </div>
        <div className="privacy-text">
          <h5>Retention of Data</h5>
          <p>
            HUMINT Solutions will retain your Personal Data only for as long as
            is necessary for the purposes set out in this Privacy Policy. We
            will retain and use your Personal Data to the extent necessary to
            comply with our legal obligations (for example, if we are required
            to retain your data to comply with applicable laws), resolve
            disputes and enforce our legal agreements and policies.
          </p>
          <p>
            HUMINT Solutions will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of our Service or we are legally obligated
            to retain this data for longer periods.
          </p>
        </div>
        <div className="privacy-text">
          <h5>Transfer of Data</h5>
          <p>
            Your information, including Personal Data, may be transferred to —
            and maintained on — computers located outside of your state,
            province, country or other governmental jurisdiction where the data
            protection laws may differ from those of your jurisdiction.
          </p>
          <p>
            If you are located outside United States and choose to provide
            information to us, please note that we transfer the data, including
            Personal Data, to United States and process it there.
          </p>
          <p>
            Your consent to this Privacy Policy followed by your submission of
            such information represents your agreement to that transfer.
          </p>
          <p>
            HUMINT Solutions will take all the steps reasonably necessary to
            ensure that your data is treated securely and in accordance with
            this Privacy Policy and no transfer of your Personal Data will take
            place to an organisation or a country unless there are adequate
            controls in place including the security of your data and other
            personal information.
          </p>
        </div>
        <div className="privacy-text">
          <h5>Disclosure of Data</h5>
          <h5>Business Transaction</h5>
          <p>
            If HUMINT Solutions is involved in a merger, acquisition or asset
            sale, your Personal Data may be transferred. We will provide notice
            before your Personal Data is transferred and becomes subject to a
            different Privacy Policy.
          </p>
          <h5>Disclosure for Law Enforcement</h5>
          <p>
            Under certain circumstances, HUMINT Solutions may be required to
            disclose your Personal Data if required to do so by law or in
            response to valid requests by public authorities (e.g. a court or a
            government agency).
          </p>
          <h5>Legal Requirements</h5>
          <p>
            HUMINT Solutions may disclose your Personal Data in the good faith
            belief that such action is necessary to:
          </p>
          <ul>
            <li>To comply with a legal obligation</li>
            <li>
              To protect and defend the rights or property of HUMINT Solutions
            </li>
            <li>
              To prevent or investigate possible wrongdoing in connection with
              the Service
            </li>
            <li>
              To protect the personal safety of users of the Service or the
              public
            </li>
            <li>To protect against legal liability</li>
          </ul>
        </div>
        <div className="privacy-text">
          <h5>Security of Data</h5>
          <p>
            The security of your data is important to us but remember that no
            method of transmission over the Internet or method of electronic
            storage is 100% secure. While we strive to use commercially
            acceptable means to protect your Personal Data, we cannot guarantee
            its absolute security.
          </p>
        </div>
        <div className="privacy-text">
          <h5>
            Our Policy on "Do Not Track" Signals under the California Online
            Protection Act (CalOPPA)
          </h5>
          <p>
            We do not support Do Not Track ("DNT"). Do Not Track is a preference
            you can set in your web browser to inform websites that you do not
            want to be tracked.
          </p>
          <p>
            You can enable or disable Do Not Track by visiting the Preferences
            or Settings page of your web browser.
          </p>
        </div>
        <div className="privacy-text">
          <h5>
            Your Data Protection Rights under the General Data Protection
            Regulation (GDPR)
          </h5>
          <p>
            If you are a resident of the European Union (EU) and European
            Economic Area (EEA), you have certain data protection rights,
            covered by GDPR. – See more at
            https://eur-lex.europa.eu/eli/reg/2016/679/oj
          </p>
          <p>
            We aim to take reasonable steps to allow you to correct, amend,
            delete or limit the use of your Personal Data.
          </p>
          <p>
            If you wish to be informed about what Personal Data we hold about
            you and if you want it to be removed from our systems, please email
            us at{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="mailto: 'info@humints.com"
            >
              info@humints.com
            </a>
            .
          </p>
          <p>
            In certain circumstances, you have the following data protection
            rights:
          </p>
          <ul>
            <li>
              <p>
                <strong>
                  The right to access, update or delete the information we have
                  on you.{" "}
                </strong>
                Whenever made possible, you can access, update or request
                deletion of your Personal Data directly within your account
                settings section. If you are unable to perform these actions
                yourself, please contact us to assist you.
              </p>
            </li>
            <li>
              <p>
                <strong>The right of rectification.</strong> You have the right
                to have your information rectified if that information is
                inaccurate or incomplete.
              </p>
            </li>
            <li>
              <p>
                <strong>The right to object.</strong> You have the right to
                object to our processing of your Personal Data.
              </p>
            </li>
            <li>
              <p>
                <strong>The right of restriction.</strong> You have the right to
                request that we restrict the processing of your personal
                information.
              </p>
            </li>
            <li>
              <p>
                <strong>The right to data portability.</strong> You have the
                right to be provided with a copy of your Personal Data in a
                structured, machine-readable and commonly used format.
              </p>
            </li>
            <li>
              <p>
                <strong>The right to withdraw consent.</strong> You also have
                the right to withdraw your consent at any time where we rely on
                your consent to process your personal information.
              </p>
            </li>
          </ul>
          <p>
            Please note that we may ask you to verify your identity before
            responding to such requests. Please note, we may not able to provide
            Service without some necessary data. You have the right to complain
            to a Data Protection Authority about our collection and use of your
            Personal Data. For more information, please contact your local data
            protection authority in the European Economic Area (EEA).
          </p>
        </div>
        <div className="privacy-text">
          <h5>Service Providers</h5>
          <p>
            We may employ third party companies and individuals to facilitate
            our Service ("Service Providers"), provide Service on our behalf,
            perform Service-related services or assist us in analysing how our
            Service is used. These third parties have access to your Personal
            Data only to perform these tasks on our behalf and are obligated not
            to disclose or use it for any other purpose. We may use third-party
            Service Providers to monitor and analyse the use of our Service.
          </p>
          <ul>
            <li>
              <p>
                <strong>Google Analytics</strong>
              </p>
              <p>
                Google Analytics is a web analytics service offered by Google
                that tracks and reports website traffic. Google uses the data
                collected to track and monitor the use of our Service. This data
                is shared with other Google services. Google may use the
                collected data to contextualise and personalise the ads of its
                own advertising network. For more information on the privacy
                practices of Google, please visit the Google Privacy Terms web
                page:{" "}
                <a href="https://policies.google.com/privacy?hl=en">
                  https://policies.google.com/privacy?hl=en
                </a>
                .
              </p>
            </li>
          </ul>
        </div>
        <div className="privacy-text">
          <h5>CI/CD tools</h5>
          <p>
            We may use third-party Service Providers to automate the development
            process of our Service.
          </p>
          <ul>
            <li>
              <p>
                <strong>GitHub</strong>
              </p>
              <p>GitHub is provided by GitHub, Inc.</p>
              <p>
                GitHub is a development platform to host and review code, manage
                projects, and build software.
              </p>
              <p>
                For more information on what data GitHub collects for what
                purpose and how the protection of the data is ensured, please
                visit GitHub Privacy Policy page:{" "}
                <a href="https://help.github.com/en/articles/github-privacy-statement">
                  https://help.github.com/en/articles/github-privacy-statement
                </a>
                .
              </p>
            </li>
          </ul>
        </div>
        <div className="privacy-text">
          <h5>Advertising</h5>
          <p>
            We may use third-party Service Providers to show advertisements to
            you to help support and maintain our Service.
          </p>
          <ul>
            <li>
              <p>
                <strong>Google AdSense DoubleClick Cookie</strong>
              </p>
              <p>
                Google, as a third party vendor, uses cookies to serve ads on
                our Service. Google's use of the DoubleClick cookie enables it
                and its partners to serve ads to our users based on their visit
                to our Service or other websites on the Internet.
              </p>
              <p>
                You may opt out of the use of the DoubleClick Cookie for
                interest-based advertising by visiting the Google Ads Settings
                web page:{" "}
                <a href="http://www.google.com/ads/preferences/">
                  http://www.google.com/ads/preferences/
                </a>
              </p>
            </li>
          </ul>
        </div>
        <div className="privacy-text">
          <h5>Children's Privacy</h5>
          <p>
            Our Services are not intended for use by children under the age of
            13 (<strong>"Child"</strong> or <strong>"Children"</strong>).
          </p>
          <p>
            We do not knowingly collect personally identifiable information from
            Children under 13. If you become aware that a Child has provided us
            with Personal Data, please contact us. If we become aware that we
            have collected Personal Data from Children without verification of
            parental consent, we take steps to remove that information from our
            servers.
          </p>
        </div>
        <div className="privacy-text">
          <h5>Changes to This Privacy Policy</h5>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the top
            of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
        </div>
        <div className="privacy-text">
          <h5>Rights of Data Subjects</h5>
          <p>
            If you are a resident of Brazil, you have the following data
            protection rights under the GDPL:
          </p>
          <ul>
            <li>
              <strong>Right to Access:</strong> You can request copies of your
              personal data.
            </li>
            <li>
              <strong>Right to Rectification:</strong> You can request
              correction of any inaccurate or incomplete data.
            </li>
            <li>
              <strong>Right to Erasure:</strong> You can request deletion of
              your personal data.
            </li>
            <li>
              <strong>Right to Data Portability:</strong> You can request
              transfer of your data to another organization.
            </li>
          </ul>
          <p>
            To exercise these rights, please contact us at{" "}
            <a href="mailto:info@humints.com">info@humints.com</a>.
          </p>
        </div>

        <div className="privacy-text">
          <h5>Contact Us</h5>
          <p>
            If you have any questions about this Privacy Policy, please{" "}
            <a
              style={{ textDecoration: "underline" }}
              href="mailto:info@humints.com"
            >
              email us
            </a>
            .
          </p>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
