import { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import { TextField, Select, MenuItem, Dialog, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { nanoid } from 'nanoid'
import Loading from '../../components/Loading';
import withAuthentication from "../../withAuthentication";
import { Tooltip } from 'react-tooltip';
import jsPDF from 'jspdf';
import { saveAs } from 'file-saver';
// const docx = require("docx");

import { Document, Packer, Paragraph, HeadingLevel, AlignmentType } from "docx";

function Concluding1() {
    const { userState, updateData, loading } = useContext(Context);
    const { projects, interviews, conclusions, role } = userState;

    const [currConclusion, setCurrConclusion] = useState('')
    const [currInterview, setCurrInterview] = useState('')
    const [currInterviewee, setCurrInterviewee] = useState('')
    const [pastInterviews, setPastInterviews] = useState('')
    const [pastConclusionsDropDown, setPastConclusionsDropDown] = useState('')
    const [isDisabled, setIsDisabled] = useState(true)
    const [isModified, setIsModified] = useState(false)
    const [selectedQuestion, setSelectedQuestion] = useState('')
    const [selectedInterviewee, setSelectedInterviewee] = useState(''); // for the checkbox selection
    const [mainQuestionsDropDown, setMainQuestionsDropDown] = useState('')
    const [message, setMessage] = useState('')
    const [errors, setErrors] = useState({})
    const [searchQuery, setSearchQuery] = useState('');
    const [isSaving, setIsSaving] = useState(false)
    const interviewSelectionRef = useRef(null); // for scrolling to the new concluding section when a new conclusion is created

    const navigate = useNavigate()

    const options = { year: 'numeric', month: 'long', day: 'numeric' };

    const newConclusion = {
        conclusionId: nanoid(),
        conclusionDate: new Date().toLocaleDateString("en-GB", options),
        questionId: '',
        interviewId: '',
        interviewee: '',
        internalPositiveKnowFact: '',
        internalPositiveKnowEst: '',
        internalPositiveHeardFact: '',
        internalPositiveHeardEst: '',
        internalNegativeKnowFact: '',
        internalNegativeKnowEst: '',
        internalNegativeHeardFact: '',
        internalNegativeHeardEst: '',
        externalPositiveKnowFact: '',
        externalPositiveKnowEst: '',
        externalPositiveHeardFact: '',
        externalPositiveHeardEst: '',
        externalNegativeKnowFact: '',
        externalNegativeKnowEst: '',
        externalNegativeHeardFact: '',
        externalNegativeHeardEst: '',
        finalConclusion: ''
    }

    const styles = {
        inputLabelProps: { shrink: true, disabled: false }, inputProps: { style: { fontSize: 16, size: "small" } }
    }
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const ITEM_WIDTH = '80%'

    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: ITEM_WIDTH,
                marginLeft: '100px',
            },
        },

    };

    // search functionality
    const filteredConclusions = (conclusions || []).filter((conclusion) => {
        if (searchQuery === '') return true

        const searchQueryLowerCase = searchQuery.toLowerCase()
        return (
            conclusion.conclusionData.selectedQuestion.toLowerCase().includes(searchQueryLowerCase) ||
            conclusion.conclusionData.interviewee.toLowerCase().includes(searchQueryLowerCase) ||
            conclusion.conclusionData.finalConclusion.toLowerCase().includes(searchQueryLowerCase) ||
            conclusion.conclusionData.conclusionDate.toLowerCase().includes(searchQueryLowerCase)
        )
    })

    // Dialog
    const [route, setRoute] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    // const handleDialogOpen = (route) => {
    //     if (isModified) {
    //         setOpenDialog(true);
    //         setRoute(route);
    //     } else {
    //         navigate(route);
    //     }
    // }

    const handleDialogClose = (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            navigate(route);
        }
    }

    // get all past conclusions and set them to pastConclusions
    useEffect(() => {
        if (loading || !conclusions) return
        // map over projects to show all Main Qs / Final Qs
        const mainQ = projects.filter(project => project.step1.FinalQ || project.step1.MainQ)
            .map(project => (
                <MenuItem
                    sx={{
                        textAlign: 'left !important', // Align text to the left
                        whiteSpace: 'normal !important', // Enable text wrapping
                        wordBreak: 'break-word !important', // Ensure words break correctly
                    }}
                    key={project.id} value={project.step1.FinalQ ? project.step1.FinalQ : project.step1.MainQ}>
                    {project.step1.FinalQ ? project.step1.FinalQ : project.step1.MainQ}
                </MenuItem>
            ));
        setMainQuestionsDropDown(mainQ)
        const getConclusionsData = conclusions.map((conclusion) => {
            return <MenuItem key={conclusion.conclusionData.conclusionId} value={conclusion.conclusionData.interviewee}>{conclusion.conclusionData.interviewee}</MenuItem>
        })
        setPastConclusionsDropDown(getConclusionsData)
    }, [conclusions, projects, loading])


    // get all past interviews and set them to pastInterviews
    useEffect(() => {
        if (loading || !interviews || interviews.length === 0) return

        // get all interviewees names
        const pastIntervieweesNames = interviews.map((interview) => { return interview.interviewData.interviewee })

        // check if interviewee name has laready been used in the conlcusions array, if so, filter it out:
        const filteredInterviewees = pastIntervieweesNames.filter((interviewee) => {
            return conclusions.every((conclusion) => { return conclusion.conclusionData.interviewee !== interviewee })
        })

        // filter out undefined values from filteredInterviewees array
        const filteredIntervieweesNames = filteredInterviewees.filter((interviewee) => { return interviewee !== undefined })

        // map over filteredIntervieweesNames array and return an option for each interviewee
        const pastInterviewees = filteredIntervieweesNames.map((interviewee) => { return <MenuItem key={interviewee} value={interviewee}>{interviewee}</MenuItem> })
        setPastInterviews(pastInterviewees)

    }, [interviews, conclusions, loading])

    // create new conclusion and set it to currConclusion
    function createNewConclusion() {
        setIsDisabled(false)
        setCurrConclusion(newConclusion)
        setCurrInterview('')
        setCurrInterviewee('')
        setSelectedInterviewee('')
        setIsModified(true)

        // scroll to the new concluding section
        if (interviewSelectionRef.current) {
            interviewSelectionRef.current.scrollIntoView({ behavior: 'smooth' })
        }
    }

    function handleChange(e) {
        const { name, value, checked } = e.target
        setMessage('')
        setIsModified(true)

        // user selects a past interviewee via checkbox
        if (name === 'conclusionSelection') {
            if (!value) {
                setIsDisabled(true)
                setCurrConclusion('')
                return
            }

            if (checked) {
                setSelectedInterviewee(value)

                const chosenConclusion = conclusions.find(pastConclusion => { return pastConclusion.conclusionData.interviewee === value })
                const chosenConclusionData = chosenConclusion.conclusionData
                setCurrConclusion(chosenConclusionData)
                setCurrInterviewee(chosenConclusionData.interviewee)

                // load interview data by interviewId and set it to currInterview
                const chosenInterview = interviews.find(pastInterview => { return pastInterview.interviewId === chosenConclusionData.interviewId })
                setCurrInterview(chosenInterview.interviewData)
                setIsDisabled(false)
            } else {
                setIsDisabled(true)
                setCurrConclusion('')
                setCurrInterview('')
                setCurrInterviewee('')
                setSelectedInterviewee('')
            }
        }

        if (name === 'selectedQuestion') {
            if (!value) {
                setSelectedQuestion('')
                setCurrConclusion(prevAnswers => {
                    return { ...prevAnswers, questionId: '' }
                })
                return
            }

            setSelectedQuestion(value)
            // based on the value, find the questionId and set it to currConclusion
            const chosenQuestion = projects.find(project => {
                return project.step1.MainQ === value || project.step1.FinalQ === value
            })
            // update currConclusion to include the questionId of the chosen question
            setCurrConclusion(prevAnswers => {
                return { ...prevAnswers, questionId: chosenQuestion.id }
            })
        }

        if (name === 'intervieweeName') {
            if (!value) {
                setCurrInterviewee('')
                setCurrInterview(prevAnswers => { return { ...prevAnswers, interviewee: '' } })
                setCurrConclusion(prevAnswers => { return { ...prevAnswers, interviewee: '' } })
                return
            }
            const chosenInterview = interviews.find(pastInterview => {
                return pastInterview.interviewData.interviewee === value
            })

            const interviewId = chosenInterview.interviewId
            const selectedInterviewData = chosenInterview.interviewData
            const intervieweeName = selectedInterviewData.interviewee
            const goalsArr = Object.values(selectedInterviewData.goals)  // update selectedInterviewData goals from object to array
            selectedInterviewData.goals = goalsArr
            selectedInterviewData.interviewId = interviewId

            setCurrInterview(selectedInterviewData)
            setCurrInterviewee(intervieweeName)

            // find the conclusion that matches the interviewId and set it to currConclusion
            const chosenConclusion = conclusions.find(pastConclusion => {
                return pastConclusion.conclusionData.interviewee === value
            })
            if (chosenConclusion) {
                const chosenConclusionData = chosenConclusion.conclusionData
                setCurrConclusion(chosenConclusionData)
                return
            }
            setCurrConclusion(prevAnswers => { return { ...prevAnswers, interviewee: intervieweeName, interviewId: interviewId } })
            return
        }

        if (name !== 'interviewSelection' || name !== 'selectedQuestion' || name !== 'conclusionSelection') {
            if (!value) {
                const conclusionWithEmptyField = { ...currConclusion, [name]: '' }
                setCurrConclusion(conclusionWithEmptyField)
                return
            }
            // console.log(name, value, currConclusion, 'name, value, currConclusion')
            setCurrConclusion(prevAnswers => { return { ...prevAnswers, [name]: value } })
        }

        // reset errors according to the new value
        if (errors[name]) {
            setErrors(oldErrors => ({
                ...oldErrors,
                [name]: false
            }))
        }
    }

    async function deleteConclusion() {

        // if the conclusion is not found, return
        if (!currConclusion) {
            setMessage('Please select a conclusion to delete')
            setTimeout(() => {
                setMessage('')
            }, 2000)
            return
        }
        // confirm deletion
        const confirmDelete = window.confirm(`Are you sure you want to delete the conclusion for ${currConclusion.interviewee}?`)
        if (!confirmDelete) return

        // find conclucsion in conclusions array by conclusionId and filter it out
        const filteredConclusions = conclusions.filter((conclusion) => { return conclusion.conclusionData.conclusionId !== currConclusion.conclusionId })
        try {
            await updateData('conclusions', filteredConclusions)
            setMessage('Conclusion deleted successfully')
        } catch (err) {
            console.log(err)
            setMessage('Something went wrong, please try again')
        }
        // reset all fields:
        setCurrConclusion(newConclusion)
        setCurrInterviewee('')
        setCurrInterview('')
        setSelectedQuestion('')
    }

    async function saveConclusion() {

        // check if all fields are filled
        // let newErrors = {}
        // for (const key in currConclusion) {
        //     if (!currConclusion[key]) {
        //         newErrors[key] = true
        //     }
        // }

        // if (Object.keys(newErrors).length > 0) {
        //     setErrors(newErrors)
        //     setMessage('Please fill all fields')
        //     setTimeout(() => {
        //         setMessage('')
        //     }, 2000)
        //     return
        // }


        let updatedConclusionArr = []
        const newConclusionObj = { conclusionId: currConclusion.conclusionId, conclusionData: currConclusion, questionId: currConclusion.questionId }
        const existingConclusion = conclusions.find((conclusion) => { return conclusion.conclusionId === currConclusion.conclusionId })

        if (existingConclusion) {
            existingConclusion.conclusionData = currConclusion
            updatedConclusionArr = conclusions

        } else {
            updatedConclusionArr = [...conclusions, newConclusionObj]
        }

        setIsSaving(true)
        try {
            await updateData('conclusions', updatedConclusionArr)
            setTimeout(() => {
                setIsSaving(false)
                setMessage('Conclusion saved')
            }, 2000)

            setTimeout(() => {
                setMessage('')
                setIsModified(false)

            }, 2500)
        } catch (error) {
            console.log(error)
            setMessage('Error saving data', error);
            setTimeout(() => {
                setMessage('');
            }, 2000);
        }

        // reset form fields
        setCurrConclusion('')
        setCurrInterview('')
        setCurrInterviewee('')
        setSelectedQuestion('')
        setSelectedInterviewee('')

    }

    // download conclusion as pdf
    function downloadConclusionPDF() {
        // Create a new instance of jsPDF
        const doc = new jsPDF({
            orientation: 'p',
            unit: 'mm',
            format: 'a4',
            putOnlyUsedFonts: true,
            compress: true,
            precision: 2,
            lineHeight: 1.3,
        })


        // Helper function to wrap text if it's too long
        function addWrappedText(text, x, y, maxWidth) {
            let splitText = doc.splitTextToSize(text, maxWidth);
            doc.text(splitText, x, y);
            return splitText.length; // Return number of lines
        }


        // content and formatting of the PDF document
        const maxWidth = doc.internal.pageSize.getWidth() - 20; // 10 units padding on each side
        let currentY = 20;


        // content and formatting of the PDF document
        doc.setFontSize(14);
        doc.setFont('helvetica', 'bold');
        doc.text(`Interview Conclusion: ${currInterviewee} `,
            doc.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 5;
        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        doc.text(`${new Date().toLocaleDateString("en-GB", options)} `,
            doc.internal.pageSize.getWidth() / 2, currentY, {
            align: 'center',
        });
        currentY += 20;


        // section 1
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text('Interview Overview', 10, currentY);
        currentY += 10;


        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        const fields = [
            `Interview Question: ${currConclusion.selectedQuestion}`,
            `Interviewee: ${currConclusion.interviewee}`,
            `First Hearing: ${currInterview.hearingFirst}`,
            `Second Hearing: ${currInterview.hearingSecond}`,
            `TIKO I Know (Fact): ${currInterview.knowFact}`,
            `TIKO I Know (Estimation): ${currInterview.knowEst}`,
            `TIKO I Heard (Fact): ${currInterview.heardFact}`,
            `TIKO I Heard (Estimation): ${currInterview.heardEst}`,
            `Comments: Interviewee: ${currInterview.commentsInterviewee}`,
            `Comments: Content: ${currInterview.commentsContent}`,
            `Comments: General: ${currInterview.commentsGeneral}`,
        ];


        fields.forEach(field => {
            let lines = addWrappedText(field, 10, currentY, maxWidth);
            currentY += lines * 7; // 7 units line height for font size 12
        });


        // add space between sections:
        currentY += 10;


        // section 2
        doc.setFontSize(12);
        doc.setFont('helvetica', 'bold');
        doc.text('Interview Analysis', 10, currentY);
        currentY += 10;


        doc.setFontSize(12);
        doc.setFont('helvetica', 'normal');
        const fields2 = [
            `Internal Positive Know (Fact): ${currConclusion.internalPositiveKnowFact}`,
            `Internal Positive Know (Estimation): ${currConclusion.internalPositiveKnowEst}`,
            `Internal Positive Heard (Fact): ${currConclusion.internalPositiveHeardFact}`,
            `Internal Positive Heard (Estimation): ${currConclusion.internalPositiveHeardEst}`,
            `Internal Negative Know (Fact): ${currConclusion.internalNegativeKnowFact}`,
            `Internal Negative Know (Estimation): ${currConclusion.internalNegativeKnowEst}`,
            `Internal Negative Heard (Fact): ${currConclusion.internalNegativeHeardFact}`,
            `Internal Negative Heard (Estimation): ${currConclusion.internalNegativeHeardEst}`,
            `External Positive Know (Fact): ${currConclusion.externalPositiveKnowFact}`,
            `External Positive Know (Estimation): ${currConclusion.externalPositiveKnowEst}`,
            `External Positive Heard (Fact): ${currConclusion.externalPositiveHeardFact}`,
            `External Positive Heard (Estimation): ${currConclusion.externalPositiveHeardEst}`,
            `External Negative Know (Fact): ${currConclusion.externalNegativeKnowFact}`,
            `External Negative Know (Estimation): ${currConclusion.externalNegativeKnowEst}`,
            `External Negative Heard (Fact): ${currConclusion.externalNegativeHeardFact}`,
            `External Negative Heard (Estimation): ${currConclusion.externalNegativeHeardEst}`,
            `Final Conclusion: ${currConclusion.finalConclusion}`,
        ];


        fields2.forEach(field => {
            let lines = addWrappedText(field, 10, currentY, maxWidth);
            currentY += lines * 7; // 7 units line height for font size 12
        });


        // Save the PDF
        doc.save(`${currInterviewee} - Interview Conclusion.pdf`)
    }
    // generate & download a docx document from the cv data
    function downloadConclusionDOC() {
        // create a new document
        const doc = new Document({
            // title: `Interview Conclusion: ${currInterviewee}`,
            // description: new Date().toLocaleDateString("en-GB", options),

            sections: [{
                children: [
                    new Paragraph({
                        text: `Interview Conclusion: ${currInterviewee}`,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        heading: HeadingLevel.HEADING_1,
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                        text: new Date().toLocaleDateString("en-GB", options),
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.CENTER,
                    }),
                    new Paragraph({
                        text: `Interview Overview`,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Interview Question: ${currConclusion.selectedQuestion}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Interviewee Name: ${currConclusion.interviewee}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `First Hearing: ${currInterview.hearingFirst}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Second Hearing: ${currInterview.hearingSecond}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `TIKO I Know Fact: ${currInterview.knowFact}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `TIKO I Know Estimation: ${currInterview.knowEst}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `TIKO I Heard Fact: ${currInterview.heardFact}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `TIKO I Heard Estimation: ${currInterview.heardEst}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Comments Interviewee: ${currInterview.commentsInterviewee}`,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Comments Content: ${currInterview.commentsContent}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Comments General: ${currInterview.commentsGeneral}`,
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Interview Analysis`,
                        spacing: {
                            before: 200,
                            after: 200,
                        },
                        heading: HeadingLevel.HEADING_2,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Internal Positive Know Fact: ${currConclusion.internalPositiveKnowFact}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Internal Positive Know Estimation: ${currConclusion.internalPositiveKnowEst}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Internal Positive Heard Fact: ${currConclusion.internalPositiveHeardFact}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Internal Positive Heard Estimation: ${currConclusion.internalPositiveHeardEst}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Internal Negative Know Fact: ${currConclusion.internalNegativeKnowFact}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Internal Negative Know Estimation: ${currConclusion.internalNegativeKnowEst}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Internal Negative Heard Fact: ${currConclusion.internalNegativeHeardFact}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Internal Negative Heard Estimation: ${currConclusion.internalNegativeHeardEst}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `External Positive Know Fact: ${currConclusion.externalPositiveKnowFact}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `External Positive Know Estimation: ${currConclusion.externalPositiveKnowEst}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `External Positive Heard Fact: ${currConclusion.externalPositiveHeardFact}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `External Positive Heard Estimation: ${currConclusion.externalPositiveHeardEst}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `External Negative Know Fact: ${currConclusion.externalNegativeKnowFact}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `External Negative Know Estimation: ${currConclusion.externalNegativeKnowEst}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `External Negative Heard Fact: ${currConclusion.externalNegativeHeardFact}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `External Negative Heard Estimation: ${currConclusion.externalNegativeHeardEst}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                    new Paragraph({
                        text: `Final Conclusion: ${currConclusion.finalConclusion}`,
                        spacing: {
                            before: 100,
                            after: 100,
                        },
                        heading: HeadingLevel.HEADING_3,
                        alignment: AlignmentType.LEFT,
                    }),
                ],
            }]

        });
        // Used to export the file into a .docx file
        Packer.toBlob(doc)
            .then((blob) => {
                saveAs(blob, `Interview Conclusion: ${currInterviewee}.docx`);
            })
            .catch((error) => {
                console.error("Error generating document: ", error);
            });
    }

    if (loading) return <Loading />

    return (
        <div className="app-home-container">
            <div className='app-title'>
                <h4>Interview Concluding Tool
                    {/* <i
                        data-tooltip-id="concludingTitle"
                        data-tooltip-place="bottom"
                        data-tooltip-content="???" className="fa fa-question-circle" aria-hidden="true"></i>
                    <Tooltip className="tooltip" id="concludingTitle" /> */}
                </h4>
                <p>Analyze your past interviews</p>
            </div>
            <div className='concluding-top-container'>
                <div className='element-title'>
                    <h6  >Search Conclusions</h6>
                </div>
                <div className='element-body' style={{ textAlign: 'left' }}>
                    <p style={{ marginLeft: '10px' }}>Select a conclusion to load its data below</p>
                    <div className='pulling-table-btns'>
                        <div className='search-input-wrapper' style={{ width: '45%' }}>
                            <input
                                type='text'
                                className='search-input'
                                placeholder='Search conclusions by question, interviewee or final conclusion...'
                                style={{ width: '100%' }}
                                value={searchQuery || ''}
                                onChange={(e) => { setSearchQuery(e.target.value) }}
                            />
                            <i className="fas fa-solid fa-magnifying-glass search-icon"></i>
                        </div>
                        <div>
                            <button
                                onClick={createNewConclusion}
                                disabled={interviews.length === 0 || pastInterviews.length === 0}
                            >Create new conclusion
                                <i
                                    data-tooltip-id="concludingTitle"
                                    position="right"
                                    style={{ color: 'white' }}
                                    data-tooltip-content="You can create a conclusion only for an interviewee that has not been analyzed yet. If you want to analyze an interviewee that has already been analyzed, please select the interviewee below."
                                    className="fa fa-question-circle"
                                    aria-hidden="true">

                                </i>
                                <Tooltip className="tooltip" id="concludingTitle" />
                            </button>
                            <button
                                style={{ marginLeft: '10px' }}
                                onClick={deleteConclusion}
                                disabled={!pastConclusionsDropDown || !pastConclusionsDropDown.length}
                            >Delete selected conclusion
                            </button>

                        </div>
                    </div>
                    <div className='table-container pulling-table-container '>
                        <table className='pulling-table'>
                            <thead>
                                <tr>
                                    <th>Select</th>
                                    <th>Interviewee</th>
                                    <th>Selected Question (objective)</th>
                                    <th>Final Conclusion</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredConclusions && filteredConclusions.map((conclusion) => {
                                    const interviewee = conclusion.conclusionData.interviewee || '';
                                    return (
                                        <tr key={conclusion.conclusionData.conclusionId}>
                                            <td>
                                                <input
                                                    type='checkbox'
                                                    name='conclusionSelection'
                                                    value={interviewee}
                                                    checked={selectedInterviewee === interviewee}
                                                    onChange={handleChange}
                                                />
                                            </td>
                                            <td>{conclusion.conclusionData.interviewee}</td>
                                            <td>{conclusion.conclusionData.selectedQuestion}</td>
                                            <td>{conclusion.conclusionData.finalConclusion}</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className='concluding-main-container' ref={interviewSelectionRef}>
                <div className='concluding-left' >
                    <div className='element-title' >
                        <h6 >1. Interview Selection
                            <i data-tooltip-id="interviewOverview"
                                position="right"
                                data-tooltip-content="Select the main objective and choose a past interviewee's name to analyze their interview. Review their answers below and proceed to analyze the answers in the right side of the screen."
                                className="fa fa-question-circle" aria-hidden="true"></i>
                            <Tooltip className="tooltip" id="interviewOverview" />
                        </h6>
                    </div>

                    <div className='element-body'
                        style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <div className='concluding-top-btns'>
                            <Select
                                name='selectedQuestion'
                                style={{ marginBottom: '15px' }}
                                value={currConclusion.selectedQuestion || ''}
                                disabled={isDisabled || mainQuestionsDropDown.length === 0}
                                error={errors.selectedQuestion}
                                displayEmpty={true}
                                sx={{
                                    '& .MuiSelect-select': {
                                        padding: '8px',
                                    },
                                }}
                                MenuProps={MenuProps}
                                renderValue={(selected) => selected || 'Choose main objective'}
                                onChange={handleChange}>
                                {mainQuestionsDropDown}
                            </Select>
                            <Select
                                name='intervieweeName'
                                value={currInterview.interviewee || ''}
                                disabled={isDisabled || !pastInterviews || !pastInterviews.length}
                                error={errors.interviewee}
                                displayEmpty={true}
                                sx={{
                                    '& .MuiSelect-select': {
                                        padding: '8px',
                                    },
                                }}
                                renderValue={(selected) => selected || 'Select interviewee name'}
                                onChange={handleChange}>
                                {pastInterviews}
                            </Select>
                        </div>
                        <h6 className='grey-strip' style={{ marginTop: '30px' }}>Selected interview overview:</h6>

                        <div className='concluding-group group1'>
                            <h6 className='grey-strip'  >Hearing</h6>
                            <TextField
                                className="TextField"
                                label="First Hearing"
                                name="hearingFirst"
                                value={currInterview.hearingFirst || ''}
                                onChange={handleChange}
                                variant="outlined"
                                disabled
                                multiline
                                rows={8}
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>
                            <TextField
                                className="TextField"
                                label="Second Hearing"
                                name="hearingSecond"
                                value={currInterview.hearingSecond || ''}
                                onChange={handleChange}
                                variant="outlined"
                                disabled
                                multiline
                                rows={8}
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>

                        </div>
                        <div className='concluding-group group2'>
                            <h6 className='grey-strip' style={{ marginBottom: '15px' }} >I know/ I heard</h6>
                            <div className='concluding-textarea-group know'>
                                <TextField
                                    className="TextField"
                                    label="TIKO I Know"
                                    name="knowFact"
                                    value={currInterview.knowFact || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled
                                    multiline
                                    rows={9}
                                    helperText='Fact'
                                    InputLabelProps={styles.inputLabelProps}
                                ></TextField>
                                <TextField
                                    className="TextField"
                                    label="TIKO I Know"
                                    name="knowEst"
                                    value={currInterview.knowEst || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled
                                    multiline
                                    rows={9}
                                    helperText='Estimation'
                                    InputLabelProps={styles.inputLabelProps}
                                ></TextField>
                            </div>
                            <div className='concluding-textarea-group know'>
                                <TextField
                                    className="TextField"
                                    label="TIKO I Heard"
                                    name="heardFact"
                                    value={currInterview.heardFact || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled
                                    multiline
                                    rows={9}
                                    helperText='Fact'
                                    InputLabelProps={styles.inputLabelProps}
                                ></TextField>
                                <TextField
                                    className="TextField"
                                    label="TIKO I Heard"
                                    name="heardEst"
                                    value={currInterview.heardEst || ''}
                                    onChange={handleChange}
                                    variant="outlined"
                                    disabled
                                    multiline
                                    rows={9}
                                    helperText='Estimation'
                                    InputLabelProps={styles.inputLabelProps}
                                ></TextField>
                            </div>
                        </div>
                        <div className='concluding-group group3'>
                            <h6 style={{ marginBottom: '15px' }} className='grey-strip'>Comments</h6>
                            <TextField
                                className="TextField"
                                label="Interviewee"
                                name="commentsInterviewee"
                                value={currInterview.commentsInterviewee || ''}
                                onChange={handleChange}
                                variant="outlined"
                                disabled
                                multiline
                                rows={7}
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>
                            <TextField
                                className="TextField"
                                label="Content"
                                name="commentsContent"
                                value={currInterview.commentsContent || ''}
                                onChange={handleChange}
                                variant="outlined"
                                disabled
                                multiline
                                rows={7}
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>
                            <TextField
                                className="TextField"
                                label="General"
                                name="commentsGeneral"
                                value={currInterview.commentsGeneral || ''}
                                onChange={handleChange}
                                variant="outlined"
                                disabled
                                multiline
                                rows={7}
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>

                        </div>

                    </div>

                </div>
                <div className='concluding-right'>
                    <div className='element-title'>
                        <h6 >2. Interview Analysis</h6>
                    </div>
                    <div className='element-body'
                        style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                        <p>Analyze your interviewee's answers by filling the table below. Start from left to right, going clockwise. Try to fill in all fields. Where you are not sure, write "not sure".</p>
                        <div className='concluding-top-row'>
                            <div className='concluding-group 1'>
                                <div className='row-titles'>
                                    <h6 className='grey-strip'>A. Internal Positive</h6>
                                </div>
                                <div className='group-row'>
                                    <TextField
                                        className="TextField"
                                        label="I Know"
                                        name="internalPositiveKnowFact"
                                        value={currConclusion.internalPositiveKnowFact || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.internalPositiveKnowFact}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Fact'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                    <TextField
                                        className="TextField"
                                        label="I Know"
                                        name="internalPositiveKnowEst"
                                        value={currConclusion.internalPositiveKnowEst || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.internalPositiveKnowEst}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Estimation'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                </div>
                                <div className='group-row'>
                                    <TextField
                                        className="TextField"
                                        label="I Heard"
                                        name="internalPositiveHeardFact"
                                        value={currConclusion.internalPositiveHeardFact || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.internalPositiveHeardFact}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Fact'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                    <TextField
                                        className="TextField"
                                        label="I Heard"
                                        name="internalPositiveHeardEst"
                                        value={currConclusion.internalPositiveHeardEst || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.internalPositiveHeardEst}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Estimation'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                </div>
                            </div>
                            <div className='concluding-group 2'>
                                <div className='row-titles'>
                                    <h6 className='grey-strip'>B. Internal Negative</h6>
                                </div>
                                <div className='group-row'>
                                    <TextField
                                        className="TextField"
                                        label="I Know"
                                        name="internalNegativeKnowFact"
                                        value={currConclusion.internalNegativeKnowFact || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.internalNegativeKnowFact}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Fact'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                    <TextField
                                        className="TextField"
                                        label="I Know"
                                        name="internalNegativeKnowEst"
                                        value={currConclusion.internalNegativeKnowEst || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.internalNegativeKnowEst}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Estimation'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                </div>
                                <div className='group-row'>
                                    <TextField
                                        className="TextField"
                                        label="I Heard"
                                        name="internalNegativeHeardFact"
                                        value={currConclusion.internalNegativeHeardFact || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.internalNegativeHeardFact}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Fact'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                    <TextField
                                        className="TextField"
                                        label="I Heard"
                                        name="internalNegativeHeardEst"
                                        value={currConclusion.internalNegativeHeardEst || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.internalNegativeHeardEst}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Estimation'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                </div>
                            </div>
                        </div>
                        <div className='concluding-bottom-row'>
                            <div className='concluding-group 3'>
                                <div className='row-titles'>
                                    <h6 className='grey-strip'>C. External Positive</h6>
                                </div>
                                <div className='group-row'>
                                    <TextField
                                        className="TextField"
                                        label="I Know"
                                        name="externalPositiveKnowFact"
                                        value={currConclusion.externalPositiveKnowFact || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.externalPositiveKnowFact}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Fact'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                    <TextField
                                        className="TextField"
                                        label="I Know"
                                        name="externalPositiveKnowEst"
                                        value={currConclusion.externalPositiveKnowEst || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.externalPositiveKnowEst}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Estimation'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                </div>
                                <div className='group-row'>
                                    <TextField
                                        className="TextField"
                                        label="I Heard"
                                        name="externalPositiveHeardFact"
                                        value={currConclusion.externalPositiveHeardFact || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.externalPositiveHeardFact}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Fact'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                    <TextField
                                        className="TextField"
                                        label="I Heard"
                                        name="externalPositiveHeardEst"
                                        value={currConclusion.externalPositiveHeardEst || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.externalPositiveHeardEst}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Estimation'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                </div>
                            </div>
                            <div className='concluding-group 4'>
                                <div className='row-titles 4'>
                                    <h6 className='grey-strip'>D. External Negative</h6>
                                </div>
                                <div className='group-row'>
                                    <TextField
                                        className="TextField"
                                        label="I Know"
                                        name="externalNegativeKnowFact"
                                        value={currConclusion.externalNegativeKnowFact || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.externalNegativeKnowFact}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Fact'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                    <TextField
                                        className="TextField"
                                        label="I Know"
                                        name="externalNegativeKnowEst"
                                        value={currConclusion.externalNegativeKnowEst || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.externalNegativeKnowEst}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Estimation'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                </div>
                                <div className='group-row'>
                                    <TextField
                                        className="TextField"
                                        label="I Heard"
                                        name="externalNegativeHeardFact"
                                        value={currConclusion.externalNegativeHeardFact || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.externalNegativeHeardFact}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Fact'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                    <TextField
                                        className="TextField"
                                        label="I Heard"
                                        name="externalNegativeHeardEst"
                                        value={currConclusion.externalNegativeHeardEst || ''}
                                        onChange={handleChange}
                                        variant="outlined"
                                        disabled={!currInterviewee}
                                        error={errors.externalNegativeHeardEst}
                                        multiline
                                        fullWidth
                                        rows={12}
                                        helperText='Estimation'
                                        InputLabelProps={styles.inputLabelProps}
                                        InputProps={styles.inputProps}
                                    ></TextField>
                                </div>
                            </div>
                        </div>
                        <div className='conclusion' style={{ paddingTop: '5px' }}>
                            <TextField
                                className="TextField"
                                label="Your Conclusion"
                                name="finalConclusion"
                                value={currConclusion.finalConclusion || ''}
                                onChange={handleChange}
                                variant="outlined"
                                disabled={!currInterviewee}
                                error={errors.finalConclusion}
                                multiline
                                fullWidth
                                minRows={15}
                                placeholder='Your final conclusion of the interview'
                                InputLabelProps={styles.inputLabelProps}
                            ></TextField>
                        </div>
                    </div>

                </div>
            </div>

            <div className='bottomNavigation' >
                {/* <p>Fill in all fields before saving</p> */}
                <button
                    style={{ width: '200px', margin: '10px' }}
                    className='savingBtn'
                    onClick={saveConclusion}
                    disabled={isDisabled}>Save Interview Conclusion
                </button>
                <div className="successMessage">
                    {message ? message : ''}
                    {isSaving && (
                        <div>
                            <i className="fas fa-spinner fa-spin"></i> Saving...
                        </div>
                    )}
                </div>
                <div >
                    {role && role === 'admin' &&
                        <div>
                            <p>Download your interview conclusion as a PDF or DOC file</p>
                            <button
                                style={{ width: '200px', margin: '10px' }}
                                onClick={downloadConclusionDOC}
                                disabled={isDisabled}>Download as DOC</button>
                            <button
                                style={{ width: '200px', margin: '10px' }}
                                onClick={downloadConclusionPDF}
                                disabled={isDisabled} >Download as PDF
                            </button>
                            <button style={{ width: '250px' }} onClick={(e) => window.print(e)}>Print / Download whole page</button>
                        </div>
                    }

                </div>
            </div>

            <div className='pagination'>
                <Dialog
                    open={openDialog}
                    onClose={() => handleDialogClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className="dialog-title" id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
                    <DialogContent className="dialog-content">
                        <DialogContentText id="alert-dialog-description">
                            You have unsaved changes. Are you sure you want to leave this page?
                        </DialogContentText>
                    </DialogContent>
                    <div className="dialog-actions">
                        <button style={{ backgroundColor: 'grey' }} className="dialog-button" onClick={() => handleDialogClose(false)}>Cancel</button>
                        <button className="dialog-button" onClick={() => handleDialogClose(true)} autoFocus>Yes</button>
                    </div>
                </Dialog>
                {/* <button onClick={() => handleDialogOpen('/')}>Back to homepage</button> */}
            </div>
        </div>
    );

}

export default withAuthentication(Concluding1);